import React from 'react'

import Moment from 'react-moment'
export default function DateField({
  fieldName,

  value,
}) {
  return (
    <React.Fragment>
      <div>
        <b>{fieldName && `${fieldName}`}</b>
      </div>

      <Moment format="DD.MM.YYYY">{value}</Moment>
    </React.Fragment>
  )
}
