import { fetchData } from '_helpers/fetchData'
import * as schema from '_schema/user'

const profile = () =>
  fetchData(schema.profile.get).then(
    response => ({ response }),
    error => ({ error })
  )

export const userService = {
  profile,
}
