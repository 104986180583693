import React from 'react'
import { authHeader } from '_helpers/authHeader'
import { Redirect } from 'react-router-dom'
import { notification } from '_helpers/notification'
import { EquipmentCreateForm } from './EquipmentCreateForm'

export const EquipmentCreate = ({
  fitnessEquipment,
  setFitnessEquipment,
  fitnessModelIri,
}) => {
  const [
    updatablefitnessEquipment,
    setUpdatablefitnessEquipment,
  ] = React.useState({})
  const [loading, setLoading] = React.useState(false)
  const [violations, setViolations] = React.useState([])

  const handleSave = () => {
    setLoading(true)

    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/fitness_equipments`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
      },
      body: JSON.stringify({
        ...updatablefitnessEquipment,
        model: fitnessModelIri,
      }),
    })
      .then((response) => {
        setLoading(false)
        if (response.ok) {
          return response.json()
        }
        throw response.json()
      })
      .then((response) => {
        setFitnessEquipment(response)
        notification('success', 'Zapisano', 'Komunikat')
        setViolations([])
      })
      .catch((errorPromise) => {
        errorPromise.then((error) => {
          if (error.violations) {
            console.log(error.violations)
            setViolations(error.violations)
            let errors = error.violations.map((e) => {
              return JSON.stringify(e.message)
            })

            errors = errors.join(',')

            notification('error', errors, 'Błąd')
          }
        })
      })
  }

  return (
    <>
      {!!fitnessEquipment.id ? (
        <Redirect to={`/equipment/list/${fitnessEquipment.id}`} />
      ) : (
        <div
          style={{
            position: 'relative',
            marginTop: '10px',
          }}
        >
          <EquipmentCreateForm
            fitnessEquipment={fitnessEquipment}
            updatablefitnessEquipment={updatablefitnessEquipment}
            setUpdatablefitnessEquipment={setUpdatablefitnessEquipment}
            handleSave={handleSave}
            loading={loading}
            violations={violations}
          />
        </div>
      )}
    </>
  )
}
