import React from 'react'
import { usePersistentCanvas } from './CanvasHooks'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  canvasParent: {
    position: 'relative',
    width: '679px',
    height: '446px',
  },
  canvasImg: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '100%',
  },
  canvas: {
    position: 'relative',
  },
  btn: {
    marginTop: '10px',
    marginRight: '10px',
  },
})
function CanvasContainer(props) {
  const classes = useStyles()
  const { breakId } = props
  // eslint-disable-next-line
  const [locations, setLocations, canvasRef] = usePersistentCanvas(breakId)

  return (
    <>
      <div className={classes.canvasParent}>
        <img
          className={classes.canvasImg}
          src="/images/canvas-background.png"
          alt="background"
        />
        <canvas
          ref={canvasRef}
          width={679}
          height={446}
          className={classes.canvas}
        />
      </div>
    </>
  )
}

export default CanvasContainer
