import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { authHeader } from '_helpers/authHeader'
import { Card } from './Card'
import Alert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))
export const Edit = ({ match }) => {
  const classes = useStyles()
  const {
    params: { userId },
  } = match
  const editState = {
    editIdx: -1,
  }

  const auth = useSelector((state) => state.auth)
  const [edit, setEdit] = useState(editState)
  const [data, setData] = useState(null)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchData = async (slug) => {
      setLoading(true)
      setError(false)
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_ENTRYPOINT}/${slug}`,
          {
            method: 'GET',
            headers: {
              accept: 'application/json',
              ...authHeader(),
            },
          }
        )

        const result = await response.json()

        setData(() => ({
          items: [].concat(result),
        }))
      } catch (error) {
        setError(true)
      }
      setLoading(false)
    }
    fetchData(`users/${userId}`)
  }, [auth.data.token, userId])

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {error && (
        <Alert severity="error">wystąpił błąd podczas pobierania danych</Alert>
      )}

      {data && (
        <Card
          data={data.items}
          editIdx={edit.editIdx}
          setData={setData}
          setEdit={setEdit}
          userId={userId}
          header={[
            {
              name: 'Email',
              prop: 'email',
            },
            {
              name: 'Nazwisko',
              prop: 'lastName',
            },
            {
              name: 'Imię',
              prop: 'firstName',
            },
            {
              name: 'NIP',
              prop: 'personalDetails.nip',
              dataProp: 'nip',
            },
            {
              name: 'Numer konta',
              prop: 'personalDetails.accountNumber',
              dataProp: 'accountNumber',
            },
            {
              name: 'Nazwa firmy',
              prop: 'personalDetails.companyName',
              dataProp: 'companyName',
            },
            {
              name: 'Ulica',
              prop: 'personalDetails.street',
              dataProp: 'street',
            },
            {
              name: 'Numer domu',
              prop: 'personalDetails.apartmentNumber',
              dataProp: 'apartmentNumber',
            },
            {
              name: 'Miasto',
              prop: 'personalDetails.city',
              dataProp: 'city',
            },
            {
              name: 'Kod pocztowy',
              prop: 'personalDetails.postCode',
              dataProp: 'postCode',
            },
            {
              name: 'Telefon',
              prop: 'personalDetails.phone',
              dataProp: 'phone',
            },
            {
              name: 'Status',
              prop: 'accepted',
              dataProp: 'accepted',
            },
          ]}
        />
      )}
    </>
  )
}
