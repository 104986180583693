import React from 'react'
import { authHeader } from '_helpers/authHeader'
import { get } from '_helpers/getProp'
import LeaseFieldFactory from './factory/LeaseFieldFactory'
import { makeStyles } from '@material-ui/core/styles'
import api from 'api'
import { notification } from '_helpers/notification'
import { leaseConstants } from '_constants'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles({
  gridParent: {
    display: 'grid',
    gridAutoRows: 'minmax(32px, auto)',
    gridTemplateColumns: 'repeat(2,1fr)',
    columnGap: '25px',
    rowGap: '5px',
  },
  gridChild: {
    alignSelf: 'center',
    display: 'grid',
    gridTemplateColumns: '200px 1fr',
    borderBottom: '1px solid lightgrey',
    paddingBottom: '5px',
  },
  icon: {
    position: 'absolute',
    right: '0',
    top: '-45px',
  },
  form: {
    position: 'relative',
    marginTop: '10px',
    marginBottom: '30px',
  },
})
const leaseForm = (
  classes,
  lease,
  updatableLease,
  setUpdatableLease,
  header,
  handleSave,
  loading,
  violations
) => {
  return (
    <div className={classes.gridParent}>
      {Object.keys(lease).length > 0 && (
        <>
          {header.map((y, k) => (
            <div className={classes.gridChild} key={`trc-${k}`}>
              <LeaseFieldFactory
                fieldName={y.name}
                type={y.prop}
                value={get(lease, y.prop, '')}
                violations={violations}
                updatableParentEntity={updatableLease}
                setUpdatableParentEntity={setUpdatableLease}
              />
            </div>
          ))}
        </>
      )}
    </div>
  )
}

export default function Lease({ lease, header }) {
  const [updatableLease, setUpdatableLease] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [violations, setViolations] = React.useState([])
  const classes = useStyles()
  const dispatch = useDispatch()
  const handleSave = () => {
    setLoading(true)

    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}${api.hires}/${lease.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
      },
      body: JSON.stringify(updatableLease),
    })
      .then((response) => {
        setLoading(false)
        if (response.ok) {
          return response.json()
        }
        throw response.json()
      })
      .then((response) => {
        dispatch({ type: leaseConstants.DATA_UPDATE_SAGA, data: response })

        notification('success', 'Zapisano', 'Komunikat')
        setViolations([])
      })
      .catch((errorPromise) => {
        errorPromise.then((error) => {
          if (error.violations) {
            setViolations(error.violations)
            let errors = error.violations.map((e) => {
              return JSON.stringify(e.message)
            })

            errors = errors.join(',')

            notification('error', errors, 'Błąd')
          }
        })
      })
  }

  return (
    <div className={classes.form}>
      {leaseForm(
        classes,
        lease,
        updatableLease,
        setUpdatableLease,
        header,
        handleSave,
        loading,
        violations
      )}
    </div>
  )
}
