import React, { useState } from 'react'

import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Title } from 'components/Title'
import Table from 'components/table/TableList'
import { authHeader } from '_helpers/authHeader'
import { useFetch } from '_helpers/useFetch'
import { makeStyles } from '@material-ui/core/styles'
import api from 'api'

const useStyles = makeStyles({
  gridParent: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '-48px',
    marginBottom: '20px',
  },
})

const endPoint = `${process.env.REACT_APP_API_ENTRYPOINT}${api.customers}`
const typingTimeout = 1000

const mainData = [
  {
    name: 'KLIENT',
    prop: 'personalDetails.companyName',
  },
  {
    name: 'EMAIL',
    prop: 'user.email',
  },
  {
    name: 'OSTATNI NAJEM',
    prop: 'null'
  },
  {
    name: 'NAJMÓW',
    prop: 'hires.length',
  },
  {
    name: 'TOTAL PROFIT',
    prop: 'moneySpent',
  },
  {
    name: 'ECODRIVING',
    prop: 'ecoDrivingMetric',
  },
  {
    name: 'ZGŁOSZENIA',
    prop: 'breakdowns.length',
  },
  {
    name: 'LEVEL',
    prop: 'discountLevel.name',
  },
  {
    name: 'RABAT',
    prop: 'discountLevel.discountRate',
  },
]

if (api.appType === 'fitness') mainData.splice(4, 1)

export const List = (link) => {
  const classes = useStyles()
  const auth = useSelector((state) => state.auth)
  const [queryParams, setQueryParams] = useState({
    search: '',
    page: 1,
  })
  const [typingState, setTypingState] = useState({
    timeoutId: null,
  })
  const [searchText, setSearchText] = useState('')
  const dispatch = useDispatch()

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const args = [auth.data.token, dispatch]
  const res = useFetch(endPoint, options, args, queryParams)

  const handleSearchChange = (e) => {
    const search = e.target.value
    setSearchText(search)

    clearTimeout(typingState.timeoutId)
    const timeoutId = setTimeout(() => {
      setTypingState({
        ...typingState,
        timeoutId: null,
      })
      setQueryParams({
        ...queryParams,
        search: search,
        page: 1,
      })
    }, typingTimeout)

    setTypingState({
      ...typingState,
      timeoutId: timeoutId,
    })
  }

  return (
    <React.Fragment>
      <Title>Klienci</Title>
      <div className={classes.gridParent}>
        <TextField
          label="Szukaj klienta"
          value={searchText}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <Table
        data={res.state.data?.content}
        isLoading={res.state.isLoading}
        error={res.state.error}
        mainData={mainData}
        dataLength={res.state.data?.totalItems}
        pagination={true}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        {...link}
      />
      <div>
        <Button
          component={Link}
          to="/customers/add"
          variant="contained"
          color="primary"
        >
          Dodaj klienta
        </Button>
      </div>
    </React.Fragment>
  )
}
