import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Title } from 'components/Title'
import Button from '@material-ui/core/Button'
import TableList from 'components/table/TableList'
import { useDispatch, useSelector } from 'react-redux'
import { authHeader } from '_helpers/authHeader'
import { useFetch } from '_helpers/useFetch'
import DeleteIcon from '@material-ui/icons/Delete'
import { requestActions } from '_actions'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { requestConstants } from '_constants'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import DialogActions from '@material-ui/core/DialogActions'
import EditIcon from '@material-ui/icons/Edit'
import {
  getCurrencyNumberFromApi,
  setCurrencyToApi,
} from '_helpers/currency'

const useStyles = makeStyles((theme) => ({
  gridParent: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '-48px',
    marginBottom: '20px',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

function AdditionalList({ endPoint, title, fields }) {
  const iriFunction = (id) => `${endPoint}/${id}`
  const classes = useStyles()

  const [queryParams, setQueryParams] = useState({
    page: 1,
    perPage: 12,
  })
  const auth = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  useEffect(() => {
    setQueryParams({
      page: 1,
      perPage: 12,
      refresh: true,
    })
  }, [endPoint])

  const args = [auth.data.token, dispatch]

  const res = useFetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}${endPoint}`,
    options,
    args,
    queryParams
  )

  const createHandleDelete = (id) => {
    return () => {
      const url = iriFunction(id)
      dispatch(requestActions.start(url))
      fetchDataHandleAuthError(
        url,
        'DELETE',
        {},
        () => {
          dispatch(requestActions.success())
          notification('success', 'Rekord usunięty', 'Zapisano')
          setQueryParams({
            ...queryParams,
            refresh: true,
          })
        },
        (error) => {
          notification(
            'error',
            error.response.violations.length
              ? 'Incorrect form'
              : error.response.detail,
            error.response.title
          )
        },
        dispatch,
        requestConstants.FAILURE
      )
    }
  }

  const mainData = [
    {
      name: 'ID',
      prop: 'id',
    },
    ...fields,
    {
      name: 'AKCJE',
      prop: '',
      renderValue: (row) => (
        <>
          <DeleteIcon onClick={createHandleDelete(row.id)} />
          <EditIcon onClick={handleClickEditOpen(row)} />
        </>
      ),
    },
  ]

  const [open, setOpen] = React.useState(false)
  const [data, setData] = useState({})

  useEffect(() => {
    let obj = {}
    fields.forEach((el) => {
      obj[el.prop] = el.type === 'number' ? 0 : ''
    })
    setData(obj)
  }, [fields])

  const handleClickOpen = () => {
    setOpen('Dodaj nową pozycję')
  }

  const handleClickEditOpen = (row) => {
    return () => {
      setOpen('Edytuj pozycję')
      let obj = { id: row.id }
      fields.forEach((el) => {
        obj[el.prop] = row[el.prop]
      })
      setData(obj)
    }
  }

  const handleClose = () => {
    setOpen(false)
    let obj = {}
    fields.forEach((el) => {
      obj[el.prop] = el.type === 'number' ? 0 : ''
    })
    setData(obj)
  }

  const handleChange = (e, field) => {
    const target = e.target
    setData((prevState) => ({
      ...prevState,
      [field.prop]:
        field.type === 'number' && field.prop !== 'discountRate'
          ? setCurrencyToApi(target.value)
          : field.prop === 'discountRate'
          ? Number(target.value)
          : target.value,
    }))
  }

  const handleSubmit = () => {
    const url = data.id !== undefined ? iriFunction(data.id) : endPoint
    const method = data.id !== undefined ? 'PUT' : 'POST'
    const fetchData = data
    delete fetchData.id
    dispatch(requestActions.start(url))
    fetchDataHandleAuthError(
      url,
      method,
      {
        body: JSON.stringify(fetchData),
      },
      () => {
        dispatch(requestActions.success())
        notification('success', 'Rekord został zapisany', 'Zapisano')
        setQueryParams({
          ...queryParams,
          refresh: true,
        })
        handleClose()
      },
      (error) => {
        notification(
          'error',
          error.response.violations.length
            ? 'Incorrect form'
            : error.response.detail,
          error.response.title
        )
      },
      dispatch,
      requestConstants.FAILURE
    )
  }

  return (
    <>
      <Title>{title}</Title>
      <div className={classes.gridParent}>
        <Button
          color="primary"
          className={classes.link}
          onClick={handleClickOpen}
        >
          Dodaj nową pozycję
        </Button>
      </div>
      <TableList
        data={res.state.data?.content}
        isLoading={res.state.isLoading}
        error={res.state.error}
        dataLength={res.state.data?.totalItems}
        colWidth="260px"
        pagination={true}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        mainData={mainData}
      />
      {/*<Edit />*/}
      <Dialog
        open={open ? true : false}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{open}</DialogTitle>
        <DialogContent>
          {fields.map((el) => (
            <TextField
              key={el.prop}
              margin="dense"
              id={el.prop}
              value={
                el.type === 'number' && el.prop !== 'discountRate'
                  ? getCurrencyNumberFromApi(data[el.prop])
                  : data[el.prop]
              }
              label={el.name}
              type={el.type}
              fullWidth
              onChange={(e) => handleChange(e, el)}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Anuluj
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Zapisz
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AdditionalList
