import React, { useState, useEffect } from 'react'
import TablePagination from '@material-ui/core/TablePagination'
import { Link } from 'react-router-dom'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { useSelector, useDispatch } from 'react-redux'
import { authHeader } from '_helpers/authHeader'
import { authActions } from '_actions'
import { Title } from 'components/Title'
import { get } from '_helpers/getProp'
import Moment from 'react-moment'
import { Button } from '@material-ui/core'
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  flex: {
    display: 'flex',
  },
  btn: {
    marginLeft: 'auto',
  },
}))
const HomeList = ({ match, boxes }) => {
  const classes = useStyles()
  const {
    params: { boxId },
  } = match

  const auth = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(12)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }
  let id
  useEffect(() => {
    const fetchData = async (slug) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENTRYPOINT}/${slug}`,
        {
          method: 'GET',
          headers: {
            accept: 'application/json',
            ...authHeader(),
          },
        }
      )

      if (response.status === 401 || response.status === 403) {
        dispatch(authActions.logout())
      }

      const json = await response.json()

      setData(json)
    }
    fetchData(boxId)
  }, [auth.data.token, dispatch, boxId])
  if (boxId === boxes[2].match) {
    id = 2
  } else {
    id = 3
  }

  function CustomCell(field) {
    const name = field.field

    const [data, setData] = useState([])
    useEffect(() => {
      const fetchData = async (slug) => {
        const response = await fetch(
          `${process.env.REACT_APP_API_ENTRYPOINT}${slug}`,
          {
            method: 'GET',
            headers: {
              accept: 'application/json',
              ...authHeader(),
            },
          }
        )

        if (response.status === 401 || response.status === 403) {
          dispatch(authActions.logout())
        }

        const json = await response.json()

        const fieldName = json?.toBePaid

        setData(fieldName)
      }
      fetchData(name)
    }, [name])

    return <TableCell>{data?.content}</TableCell>
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Title>
            <div className={classes.flex}>
              {boxes[id].title} ({data.totalItems})
              <Button
                color="textPrimary"
                component={Link}
                className={classes.btn}
                to={'/'}
              >
                ZAMKNIJ
              </Button>
            </div>
          </Title>
          <Table size="small">
            <TableHead>
              <TableRow>
                {boxes[id].header.map((name, i) => (
                  <TableCell key={i}> {name} </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.content &&
                data.content
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row.id}>
                      {boxes[id].keys.map((e, i) => {
                        const value = row[i]
                        if (
                          typeof value === 'string' &&
                          value.charAt(0) === '/'
                        ) {
                          return <CustomCell field={row[i]} />
                        } else {
                          if (
                            e === 'invoice.paymentDate' ||
                            e === 'createdAt'
                          ) {
                            return (
                              <TableCell>
                                <Moment format="DD.MM.YYYY">
                                  {get(row, e, '')}
                                </Moment>
                              </TableCell>
                            )
                          }
                          return (
                            <TableCell key={i}>
                              {get(row, e, '')}
                            </TableCell>
                          )
                        }
                      })}
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[1, 2, 12]}
            labelRowsPerPage="Pokaż na stronie:"
            component="div"
            count={data?.totalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    </Grid>
  )
}

export default HomeList
