import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  SortingState,
  EditingState,
  SummaryState,
  IntegratedSorting,
  IntegratedSummary,
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table,
  TableHeaderRow,
  ColumnChooser,
  TableColumnVisibility,
  Toolbar,
  TableEditRow,
  TableEditColumn,
  TableSummaryRow,
} from '@devexpress/dx-react-grid-material-ui'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TableCell from '@material-ui/core/TableCell'
import Alert from '@material-ui/lab/Alert'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import CancelIcon from '@material-ui/icons/Cancel'
import { withStyles } from '@material-ui/core/styles'
import { HighlightedCell } from './fields/highlighted-cell'
import { PercentTypeProvider } from './fields/percent-type-provider'
import { CurrencyTypeProvider } from './fields/currency-type-provider'
import { globalSalesValues } from 'dataconfig'
import BigNumber from 'bignumber.js'

BigNumber.set({
  FORMAT: {
    decimalSeparator: ',',
    groupSeparator: ' ',
  },
})

const styles = (theme) => ({
  lookupEditCell: {
    padding: theme.spacing(1),
  },
  inputRoot: {
    width: '100%',
  },
  selectMenu: {
    position: 'absolute !important',
  },
})

const AddButton = ({ onExecute }) => (
  <Button color="primary" onClick={onExecute} title="Create new row">
    Dodaj wiersz
  </Button>
)

const EditButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Edit row">
    <EditIcon />
  </IconButton>
)

const DeleteButton = ({ onExecute }) => (
  <IconButton
    onClick={() => {
      // eslint-disable-next-line
      if (window.confirm('Are you sure you want to delete this row?')) {
        onExecute()
      }
    }}
    title="Delete row"
  >
    <DeleteIcon />
  </IconButton>
)

const CommitButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Save changes">
    <SaveIcon />
  </IconButton>
)

const CancelButton = ({ onExecute }) => (
  <IconButton color="secondary" onClick={onExecute} title="Cancel changes">
    <CancelIcon />
  </IconButton>
)

const commandComponents = {
  add: AddButton,
  edit: EditButton,
  delete: DeleteButton,
  commit: CommitButton,
  cancel: CancelButton,
}

const Command = ({ id, onExecute }) => {
  const CommandButton = commandComponents[id]
  return <CommandButton onExecute={onExecute} />
}

const availableValues = {
  countType: globalSalesValues.countType,
  vat: Object.keys(globalSalesValues.vat),
}

const LookupEditCellBase = ({
  availableColumnValues,
  value,
  onValueChange,
  classes,
}) => (
  <TableCell className={classes.lookupEditCell}>
    <Select
      value={value}
      onChange={(event) => onValueChange(event.target.value)}
      MenuProps={{
        className: classes.selectMenu,
      }}
      input={<Input classes={{ root: classes.inputRoot }} />}
    >
      {availableColumnValues.map((item) => (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      ))}
    </Select>
  </TableCell>
)
export const LookupEditCell = withStyles(styles)(LookupEditCellBase)

const Cell = (props) => {
  const { column } = props
  if (
    column.name === 'amount' ||
    column.name === 'price' ||
    column.name === 'amountNetto' ||
    column.name === 'vatAmount'
  ) {
    return <HighlightedCell {...props} />
  }

  return <Table.Cell {...props} />
}

const EditCell = (props) => {
  const { column } = props
  const availableColumnValues = availableValues[column.name]

  if (availableColumnValues) {
    return (
      <LookupEditCell
        {...props}
        availableColumnValues={availableColumnValues}
      />
    )
  }
  return <TableEditRow.Cell {...props} />
}

const getRowId = (row) => row.id

export default function InvoiceEdit(props) {
  const { setRows, rows, discount, error, draftRows } = props

  const [columns] = useState([
    { name: 'name', title: 'Nazwa towaru lub usługi' },
    { name: 'units', title: 'ilość' },
    { name: 'countType', title: 'j.m.' },
    { name: 'price', title: 'Cena netto' },
    { name: 'amountNetto', title: 'Wartość netto' },
    { name: 'vat', title: 'Stawka VAT' },
    { name: 'vatAmount', title: 'Kwota VAT' },
    { name: 'amount', title: 'Wartość brutto' },
    { name: 'discount', title: 'Rabat *' },
    { name: 'pk', title: 'PKWiU/PKOB *' },
  ])

  let hiddenColumnNames = columns.reduce((r, v) => {
    if (
      (v.name === 'discount' || v.name === 'pk') &&
      !rows.find((obj) => obj[v.name] !== '')
    ) {
      r.push(v.name)
    }
    return r
  }, [])
  const [defaultHiddenColumnNames, setDefaultHiddenColumnNames] = useState(
    hiddenColumnNames
  )
  const [tableColumnVisibilityColumnExtensions] = useState([
    { columnName: 'name', togglingEnabled: false },
    { columnName: 'units', togglingEnabled: false },
    { columnName: 'countType', togglingEnabled: false },
    { columnName: 'price', togglingEnabled: false },
    { columnName: 'amountNetto', togglingEnabled: false },
    { columnName: 'vat', togglingEnabled: false },
    { columnName: 'vatAmount', togglingEnabled: false },
    { columnName: 'amount', togglingEnabled: false },
  ])

  React.useEffect(() => {
    if (discount > 0) {
      setDefaultHiddenColumnNames(['pk'])
    }
  }, [discount])

  const [editingStateColumnExtensions] = useState([
    { columnName: 'amountNetto', editingEnabled: false },
    { columnName: 'vatAmount', editingEnabled: false },
    { columnName: 'amount', editingEnabled: false },
  ])

  const [sorting, getSorting] = useState([])
  const [editingRowIds, getEditingRowIds] = useState([])
  const [addedRows, setAddedRows] = useState([])
  const [rowChanges, setRowChanges] = useState({})

  const [percentColumns] = useState(['discount'])

  const [totalSummaryItems] = useState([
    { columnName: 'amountNetto', type: 'sum' },
    { columnName: 'amount', type: 'sum' },
  ])

  const [currencyColumns] = useState(['units', 'price'])

  const counterRows = (row, key) => {
    const units = new BigNumber(row.units || (key ? rows[key].units : 0))
    const price = new BigNumber(row.price || (key ? rows[key].price : 0))
    const vat = new BigNumber(
      row.vat !== undefined
        ? globalSalesValues.vat[row.vat].value
        : key
        ? globalSalesValues.vat[rows[key].vat].value
        : globalSalesValues.vat[Object.keys(globalSalesValues.vat)[0]].value
    )
    const discount = new BigNumber(
      row.discount || (key && rows[key].discount ? rows[key].discount : 0)
    )
    const amountNetto = units
      .times(price)
      .minus(units.times(price).times(discount))
    const vatAmount = amountNetto.times(vat)
    const amount = amountNetto.plus(vatAmount)
    //const amountWithDiscount = amount.minus(amount.times(discount))
    const ret = {
      amountNetto: amountNetto.dp(2).toNumber(),
      vatAmount: vatAmount.dp(2).toNumber(),
      amount: amount.dp(2).toNumber(),
    }
    return ret
  }

  React.useEffect(() => {
    if (rows.length === 0 && draftRows.length > 0) {
      commitChanges({ added: draftRows, changed: false, deleted: false })
    }
    // eslint-disable-next-line
  }, [draftRows, rows])

  const changeAddedRows = (value) => {
    setAddedRows(
      value.map((row) =>
        Object.keys(row).length
          ? { ...row, ...counterRows(row, false) }
          : {
              name: '',
              units: 0,
              countType: availableValues.countType[0],
              price: 0,
              amountNetto: 0,
              vat: availableValues.vat[0],
              vatAmount: 0,
              amount: 0,
              discount: discount / 100 || 0,
              pk: '',
            }
      )
    )
  }

  const changeEditRows = (value) => {
    const keys = Object.keys(value)
    keys.forEach((el) => {
      setRowChanges({
        [el]: { ...value[el], ...counterRows(value[el], el) },
      })
    })
  }

  const deleteRows = (deletedIds) => {
    const rowsForDelete = rows.slice()
    deletedIds.forEach((rowId) => {
      const index = rowsForDelete.findIndex((row) => row.id === rowId)
      if (index > -1) {
        rowsForDelete.splice(index, 1)
      }
    })
    return rowsForDelete
  }

  const commitChanges = ({ added, changed, deleted }) => {
    let changedRows
    if (added) {
      const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0
      changedRows = [
        ...rows,
        ...added.map((row, index) => ({
          id: startingAddedId + index,
          ...row,
          ...counterRows(row, false),
        })),
      ]
    }
    if (changed) {
      changedRows = rows.map((row) =>
        changed[row.id] ? { ...row, ...changed[row.id] } : row
      )
    }
    if (deleted) {
      changedRows = deleteRows(deleted)
    }
    setRows(changedRows)
  }
  const showCols = {
    showColumnChooser: 'Dodaj pole',
  }
  return (
    <Paper>
      <Grid rows={rows} columns={columns} getRowId={getRowId}>
        <SortingState sorting={sorting} onSortingChange={getSorting} />
        <EditingState
          editingRowIds={editingRowIds}
          onEditingRowIdsChange={getEditingRowIds}
          rowChanges={rowChanges}
          onRowChangesChange={changeEditRows}
          addedRows={addedRows}
          onAddedRowsChange={changeAddedRows}
          onCommitChanges={commitChanges}
          columnExtensions={editingStateColumnExtensions}
        />
        <SummaryState totalItems={totalSummaryItems} />
        <IntegratedSorting />
        <IntegratedSummary />
        <CurrencyTypeProvider for={currencyColumns} />
        <PercentTypeProvider for={percentColumns} />
        <Table cellComponent={Cell} />
        <TableHeaderRow showSortingControls />
        <TableEditRow cellComponent={EditCell} />
        <TableEditColumn
          width={170}
          showAddCommand={!addedRows.length}
          showEditCommand
          showDeleteCommand
          commandComponent={Command}
        />
        <TableSummaryRow />
        <TableColumnVisibility
          hiddenColumnNames={defaultHiddenColumnNames}
          onHiddenColumnNamesChange={setDefaultHiddenColumnNames}
          defaultHiddenColumnNames={defaultHiddenColumnNames}
          columnExtensions={tableColumnVisibilityColumnExtensions}
        />
        <Toolbar />
        <ColumnChooser messages={showCols} />
      </Grid>
      {error?.rows && (
        <Alert elevation={6} variant="filled" severity="error">
          Dodaj co najmniej jedną pozycję do faktury
        </Alert>
      )}
    </Paper>
  )
}

InvoiceEdit.propTypes = {
  setRows: PropTypes.func.isRequired,
  rows: PropTypes.array.isRequired,
  draftRows: PropTypes.array.isRequired,
  discount: PropTypes.number,
  error: PropTypes.object.isRequired,
}
