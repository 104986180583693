import React from 'react'
import TextField from '@material-ui/core/TextField'
import { get } from '_helpers/getProp'
import CheckIcon from '@material-ui/icons/Check'
import CancelIcon from '@material-ui/icons/Cancel'
import { withStyles } from '@material-ui/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { default as SwitchMode } from '@material-ui/core/Switch/Switch'
import FormGroup from '@material-ui/core/FormGroup'

const styles = () => ({
  gridParent: {
    alignItems: 'center',
    display: 'grid',
    gridTemplateColumns: '100px 300px',
  },

  input: {
    marginRight: '20px',
  },
  icon: {
    position: 'absolute',
    left: '400px',
    top: '0',
  },
})

class Form extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      values: {
        ...props.x,
      },
      errors: {
        email: '',
        lastName: '',
      },
    }
  }

  change = (e, dataProp) => {
    const { name, value } = e.target

    if (name === 'firstName' || name === 'lastName') {
      this.setState((state) => ({
        values: {
          ...state.values,
          [name]: value,
        },
      }))
    } else if (dataProp === 'accepted') {
      this.setState((state) => ({
        values: {
          ...state.values,
          accepted: !state.accepted,
        },
      }))
    } else {
      this.setState((state) => ({
        values: {
          ...state.values,
          personalDetails: {
            ...state.values.personalDetails,
            [dataProp]: value,
          },
        },
      }))
    }
  }

  validate = () => {
    let isError = false
    const errors = {
      email: '',
      lastName: '',
    }

    const { email } = this.state.values

    if (email.indexOf('@') === -1) {
      isError = true
      errors.email = 'Niepoprawny email'
    }

    this.setState({
      errors,
      isError,
    })

    return isError
  }

  onSubmit = (e) => {
    e.preventDefault()
    const err = this.validate()
    if (!err) {
      this.props.handleSave(this.props.i, this.state.values)
    }
  }

  render() {
    const { header } = this.props
    const { classes } = this.props

    return [
      header.map((y, k) => (
        <div key={`trc-${k}`} className={classes.gridParent}>
          <b>{y.name}: </b>
          {y.prop !== 'accepted' ? (
            <TextField
              className={classes.input}
              name={y.prop}
              onChange={(e) => this.change(e, y.dataProp)}
              value={get(this.state.values, y.prop, '-') || ''}
              error={this.state.isError}
              helperText={this.state.errors[y.prop]}
            />
          ) : (
            <FormGroup>
              <FormControlLabel
                control={
                  <SwitchMode
                    checked={get(this.state.values, y.prop, false)}
                    onChange={(e) => this.change(e, y.dataProp)}
                    name={y.prop}
                  />
                }
              />
            </FormGroup>
          )}
        </div>
      )),
      <div className={classes.icon} key="icon-row-column">
        <CheckIcon onClick={this.onSubmit} />
        <CancelIcon onClick={this.props.stopEditing} />
      </div>,
    ]
  }
}

export default withStyles(styles)(Form)
