export const excludeInnerObjectsWithIds = (outerObject) => {
  const toReturnObject = {}

  for (let [key, innerObject] of Object.entries(outerObject)) {
    if (innerObject === null || innerObject.id === undefined) {
      toReturnObject[key] = innerObject
    }
  }
  return toReturnObject
}
