import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { Button } from '@material-ui/core'
import { Save } from '@material-ui/icons'
import BigNumber from 'bignumber.js'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  inputs: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  btns: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginLeft: theme.spacing(3),
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
  },
}))

export default function InvoiceForm(props) {
  const { rows, setPay, pay, comment, setComment, submit, edit, backId } = props
  const classes = useStyles()

  const [total, setTotal] = React.useState('0')

  React.useEffect(() => {
    let amount = new BigNumber(0)
    rows.forEach((el) => {
      const value = new BigNumber(el.amount)
      amount = amount.plus(value)
    })

    const payNumber = new BigNumber(pay)
    const sum = amount.minus(payNumber)

    setTotal(sum.toFormat(2))
  }, [rows, pay])

  return (
    <form className={classes.root} onSubmit={submit}>
      <div className={classes.inputs}>
        <TextField
          label="Sprzedawca"
          className={classes.textField}
          disabled
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label="Nabywca"
          className={classes.textField}
          disabled
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label="Zapłacono"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          value={pay}
          onChange={(e) => setPay(e.target.value)}
          InputProps={{
            step: 'any',
            type: 'number',
            disabled: edit,
          }}
        />
        <TextField
          label="Do zapłaty"
          disabled
          value={total}
          className={classes.textField}
          margin="normal"
          variant="outlined"
        />

        <TextField
          id="outlined-full-width"
          label="Uwagi"
          fullWidth
          margin="normal"
          multiline
          rows="4"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
        />
      </div>
      <div className={classes.btns}>
        <Button
          color="primary"
          size="large"
          component={Link}
          to={backId ? `/invoices/list/${backId}` : '/invoices/list'}
        >
          Anuluj
        </Button>

        <Button
          type="submit"
          variant="contained"
          color="secondary"
          startIcon={<Save />}
          size="large"
        >
          Zapisz
        </Button>
      </div>
    </form>
  )
}

InvoiceForm.propTypes = {
  rows: PropTypes.array.isRequired,
  setPay: PropTypes.func.isRequired,
  pay: PropTypes.number.isRequired,
  comment: PropTypes.string.isRequired,
  setComment: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
  backId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
}
