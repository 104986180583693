import { Route, Redirect } from 'react-router-dom'
import {
  Home,
  LoginContainer,
  Fleet,
  Leases,
  Settings,
  ActivateUser,
  NotFound,
  Customers,
  Invoices,
  Documents,
  Reports,
  Equipments,
} from 'pages'
import { AnonymousRoute } from 'components/AnonymousRoute'
import { PrivateRoute } from 'components/PrivateRoute'

const routes = () => [
  {
    type: PrivateRoute,
    component: Home,
    path: '/home',
  },
  {
    type: AnonymousRoute,
    component: LoginContainer,
    path: '/login',
    exact: true,
  },
  {
    type: PrivateRoute,
    component: Fleet,
    path: '/fleet',
  },
  {
    type: PrivateRoute,
    component: Equipments,
    path: '/equipment',
  },
  {
    type: PrivateRoute,
    component: Leases,
    path: '/leases',
  },
  {
    type: PrivateRoute,
    component: Customers,
    path: '/customers',
  },
  {
    type: PrivateRoute,
    component: Invoices,
    path: '/invoices',
  },
  {
    type: PrivateRoute,
    component: Documents,
    path: '/documents',
  },
  {
    type: PrivateRoute,
    component: Reports,
    path: '/reports',
  },
  {
    type: PrivateRoute,
    component: Settings,
    path: '/settings',
  },
  {
    type: AnonymousRoute,
    component: ActivateUser,
    path: '/security',
  },
  {
    type: Redirect,
    from: '/',
    to: '/home',
    exact: true,
  },
  {
    type: Route,
    component: NotFound,
    path: '*',
  },
]

export default routes
