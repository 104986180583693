import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import DeleteIcon from '@material-ui/icons/Delete'
import AddCircleIcon from '@material-ui/icons/AddCircle'

import PublishIcon from '@material-ui/icons/Publish'

import { excludeInnerObjectsWithIds } from '_helpers/excludeInnerObjectsWithIds'
import { authHeader } from '_helpers/authHeader'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
})

export default function EquipmentPartList({
  entities,
  moduleEndpoint,
  moduleName,
  createPrototype = () => {},
  compareFunction,
  createSpecificColumns,
  readOnly = false,
}) {
  const equipmentPartIri = (id) => `${moduleEndpoint}/${id}`

  const classes = useStyles()

  const [rows, setRows] = React.useState(entities.sort(compareFunction))
  const [creationRow, setCreationRow] = React.useState(createPrototype())
  const [violationColumns, setViolationColumns] = React.useState({})

  const createSetRow = (id) => {
    if (id === 0) {
      return setCreationRow
    }
    return (row) => {
      const rowIndex = rows.findIndex((row) => row.id === id)
      const newRows = [
        ...rows.slice(0, rowIndex),
        row,
        ...rows.slice(rowIndex + 1),
      ]
      setRows(newRows)
    }
  }

  const createHandleDelete = (id) => {
    const deleteData = async (slug) => {
      await fetch(`${process.env.REACT_APP_API_ENTRYPOINT}${slug}`, {
        method: 'DELETE',
        headers: {
          accept: 'application/json',
          ...authHeader(),
        },
      })
    }
    return () => {
      deleteData(equipmentPartIri(id))
      const newRows = rows
        .filter((item) => item.id !== id)
        .sort(compareFunction)
      setRows(newRows)
    }
  }

  const createHandleUpdate = (id) => {
    return () => {
      const putData = async (slug, data) => {
        const response = await fetch(
          `${process.env.REACT_APP_API_ENTRYPOINT}${slug}`,
          {
            method: 'PUT',
            headers: {
              accept: 'application/json',
              ...authHeader(),
              'Content-Type': 'application/json',
            },
            body: data,
          }
        )
        if (response.ok) {
          const result = await response.json()
          const newRows = [
            result,
            ...rows.filter((item) => item.id !== id),
          ].sort(compareFunction)
          setRows(newRows)
          setViolationColumns([])
        } else {
          const errorBody = await response.json()
          console.log(errorBody)
          if (errorBody.violations) {
            setViolationColumns({
              [id]: errorBody.violations,
            })
          }
        }
      }

      putData(
        equipmentPartIri(id),
        JSON.stringify(
          excludeInnerObjectsWithIds(rows.find((item) => item.id === id))
        )
      )
    }
  }

  const handleCreate = () => {
    const postData = async (slug, data) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENTRYPOINT}${slug}`,
        {
          method: 'POST',
          headers: {
            accept: 'application/json',
            ...authHeader(),
            'Content-Type': 'application/json',
          },
          body: data,
        }
      )
      if (response.ok) {
        const result = await response.json()
        const newRows = [result, ...rows]
        newRows.sort(compareFunction)
        setRows(newRows)
        setCreationRow({ ...createPrototype() })
        setViolationColumns([])
      } else {
        const errorBody = await response.json()
        console.log(errorBody)
        if (errorBody.violations) {
          setViolationColumns({
            0: errorBody.violations,
          })
        }
      }
    }

    const entityToCreate = { ...creationRow }
    delete entityToCreate.id
    postData(moduleEndpoint, JSON.stringify(entityToCreate))
  }
  /*
  const columns = [
    ...createSpecificColumns(createSetRow),
    ...(!readOnly && [
      {
        id: 'update',
        label: '',
        minWidth: 50,
        align: 'right',
        renderValue: (item, _) => (
          <>
            {item.id ? (
              <PublishIcon onClick={createHandleUpdate(item.id)} />
            ) : (
              <></>
            )}
          </>
        ),
      },
      {
        id: 'addOrRemove',
        label: '',
        minWidth: 50,
        align: 'right',
        renderValue: (item, _) => (
          <>
            {item.id ? (
              <DeleteIcon onClick={createHandleDelete(item.id)} />
            ) : (
              <AddCircleIcon onClick={handleCreate} />
            )}
          </>
        ),
      },
    ]),
  ]
  */

  const columns = [...createSpecificColumns(createSetRow)]
  if (!readOnly) {
    columns.push(
      {
        id: 'update',
        label: '',
        minWidth: 50,
        align: 'right',
        renderValue: (item, _) => (
          <>
            {item.id ? (
              <PublishIcon onClick={createHandleUpdate(item.id)} />
            ) : (
              <></>
            )}
          </>
        ),
      },
      {
        id: 'addOrRemove',
        label: '',
        minWidth: 50,
        align: 'right',
        renderValue: (item, _) => (
          <>
            {item.id ? (
              <DeleteIcon onClick={createHandleDelete(item.id)} />
            ) : (
              <AddCircleIcon onClick={handleCreate} />
            )}
          </>
        ),
      }
    )
  }

  return (
    <div className={classes.root}>
      <h2>{moduleName}</h2>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!readOnly && (
              <TableRow selected key={creationRow.startDate}>
                {columns.map((column) => {
                  return (
                    <TableCell key={column.id} align={column.align}>
                      {column.renderValue(creationRow, violationColumns[0])}
                    </TableCell>
                  )
                })}
              </TableRow>
            )}
            {rows.map((row) => {
              return (
                <TableRow hover key={row.id}>
                  {columns.map((column) => {
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.renderValue(row, violationColumns[row.id])}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
