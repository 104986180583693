import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import { red } from '@material-ui/core/colors'
import { polishPlural } from '_helpers/polishPlural'
import api from 'api'
import { useRemoveDoc } from '_helpers/useRemoveDoc'

const useStyles = makeStyles({
  delete: {
    backgroundColor: red[600],
    color: '#fff',
  },
})
export function DeleteDialog(props) {
  const classes = useStyles()
  const { onClose, selectedValue, open, numSelected, selected } = props
  const [del, setDel] = React.useState(false)

  const iriFunction = (selected) => {
    let url = `${api.invoices}?`
    selected.forEach((el, i) => {
      url += `${i !== 0 ? '&' : ''}id[]=${el}`
    })
    return url
  }

  const { ret, startRemove, setNewUrl } = useRemoveDoc()

  React.useEffect(() => {
    if (del) {
      setNewUrl(iriFunction(selected))
      startRemove()
    }
    // eslint-disable-next-line
  }, [del])

  React.useEffect(() => {
    if (del && !ret) {
      onClose(ret)
      setDel(false)
    }
    // eslint-disable-next-line
  }, [ret, del])

  const handleClose = () => {
    onClose(selectedValue)
  }
  const handleListItemClick = () => {
    setDel(true)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">
        Czy napewno chcesz usunąć {numSelected}
        {polishPlural(' pozycję', ' pozycje', ' pozycji', numSelected)}?
        Operacja może spowodować zaburzenie numeracji faktur.
      </DialogTitle>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Anuluj
        </Button>

        <Button
          variant="contained"
          onClick={(e) => handleListItemClick(e.target.textContent)}
          startIcon={<DeleteIcon />}
          className={classes.delete}
        >
          Usuń
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DeleteDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
}

const DeleteDialogBtn = React.forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false)
  const [selectedValue, setSelectedValue] = React.useState('')
  const { numSelected, selected, handleRefreshList } = props

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = (value) => {
    setOpen(false)
    setSelectedValue(value)
    handleRefreshList()
  }

  return (
    <div ref={ref}>
      <IconButton aria-label="delete" onClick={handleClickOpen}>
        <DeleteIcon />
      </IconButton>

      <DeleteDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        numSelected={numSelected}
        selected={selected}
      />
    </div>
  )
})

export default DeleteDialogBtn
