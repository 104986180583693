import React, { useState, useEffect } from 'react'
import { authHeader } from '_helpers/authHeader'
import { Link } from 'react-router-dom'
import TableCell from '@material-ui/core/TableCell'
import Button from '@material-ui/core/Button'
import { get } from '_helpers/getProp'
export default function CustomCell({ fieldName, fieldProp, isLink, isArray }) {
  const [data, setData] = useState()

  useEffect(() => {
    const fetchData = async slug => {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENTRYPOINT}${slug}`,
        {
          method: 'GET',
          headers: {
            accept: 'application/json',
            ...authHeader(),
          },
        }
      )
      const json = await response.json()

      const prop = get(json, fieldProp, '')

      setData(prop)
    }
    if (fieldName != null) {
      fetchData(fieldName)
    }
  }, [fieldName, fieldProp])

  return data && !isArray ? (
    <TableCell>
      {data && isLink ? (
        <Button component={Link} color="primary" to={`/leases/current/${data}`}>
          zobacz
        </Button>
      ) : (
        <div>{data}</div>
      )}
    </TableCell>
  ) : (
    <div>{data}</div>
  )
}
