import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import { validate } from '_helpers/validate'

export const PasswordRepeatType = ({
  name,
  label,
  hint,
  disabled,
  validate: validators,
  setValue,
  setValidator,
  setErrorMessageSetter,
}) => {
  const [state, setState] = useState({
    password: {
      value: '',
      error: { status: false, message: false },
    },
    repeat: {
      value: '',
      error: { status: false, message: false },
    },
  })

  const handleChange = {
    password: e => {
      const value = e.target.value
      setState(state => ({ ...state, password: { ...state.password, value } }))

      validator.complete(value, state.repeat.value)
    },
    repeat: e => {
      const value = e.target.value
      setState(state => ({ ...state, repeat: { ...state.repeat, value } }))

      validator.repeat(state.password.value, value)
    },
  }

  const validator = {
    password: value => {
      const valid = validate(validators, value)
      setState(state => ({
        ...state,
        password: {
          ...state.password,
          error: { status: !valid.result, message: valid.message },
        },
      }))
      setValue(name, !valid.result ? new Error(valid.message) : value)

      return valid.result
    },
    repeat: (password, repeat) => {
      const valid = {
        result: password === repeat,
        message: password === repeat || 'Hasła nie zgadzają się',
      }

      setState(state => ({
        ...state,
        repeat: {
          ...state.repeat,
          error: { status: !valid.result, message: valid.message },
        },
      }))
      setValue(
        name + 'Repeat',
        !valid.result ? new Error(valid.message) : repeat
      )

      return valid.result
    },
    complete: (password, repeat) => {
      const isPasswordValid = validator.password(password)
      const isRepeatValid = validator.repeat(password, repeat)
      return isPasswordValid && isRepeatValid
    },
  }

  const setErrorMessage = message => {
    setState(state => ({
      ...state,
      password: {
        ...state.password,
        error: { status: true, message },
      },
    }))
  }

  useEffect(() => {
    setValue(name, state.password.value)
    setValue(name + 'Repeat', state.repeat.value)
    setValidator(name, validator.complete, [name, name + 'Repeat'])
    setErrorMessageSetter(name, setErrorMessage)
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <TextField
        name={name}
        type="password"
        label={label + ' *'}
        value={state.password.value}
        disabled={disabled}
        onChange={handleChange.password}
        error={state.password.error.status}
        helperText={
          state.password.error.status
            ? state.password.error.message
            : hint.origin
        }
      />
      <div
        style={{
          marginBottom: '12px',
        }}
      >
        <TextField
          name={name + 'Repeat'}
          type="password"
          label={label + ' repeat *'}
          value={state.repeat.value}
          disabled={disabled}
          onChange={handleChange.repeat}
          error={state.repeat.error.status}
          helperText={
            state.repeat.error.status ? state.repeat.error.message : hint.repeat
          }
        />
      </div>
    </>
  )
}
