import { requestConstants } from '_constants'

const start = url => ({
  type: requestConstants.START,
  payload: { url },
})

const success = () => ({
  type: requestConstants.SUCCESS,
})

const failure = error => ({
  type: requestConstants.FAILURE,
  payload: { error },
})

export const requestActions = {
  start,
  success,
  failure,
}
