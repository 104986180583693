import React from 'react'
import { useSelector } from 'react-redux'

import { authHeader } from '_helpers/authHeader'
import { useFetch } from '_helpers/useFetch'

import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import { Title } from 'components/Title'
import Chart from './Report'
import Alert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import api from 'api'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  root: {
    marginTop: theme.spacing(6),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}))

export const ReportContainer = ({ match }) => {
  const classes = useStyles()
  const {
    params: { reportId },
  } = match

  const auth = useSelector((state) => state.auth)

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const args = [auth.data.token, reportId]

  let endpoint
  if (reportId === 'whole') {
    endpoint = `/company_configs/${api.appType}/profitabilities`
  } else {
    endpoint = `${api.reports}/${reportId}/profitability`
  }

  const res = useFetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}${endpoint}`,
    options,
    args
  )

  const resData = { ...res.state.data }

  const [open, setOpen] = React.useState(true)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  return (
    <>
      <Backdrop className={classes.backdrop} open={res.state.isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {res.state.error && (
        <Alert severity="error">wystąpił błąd podczas pobierania danych</Alert>
      )}
      {res.state.data && (
        <main className={classes.content}>
          <Title>
            {reportId === 'whole' ? 'Cała firma' : `Raport nr ${reportId}`}
          </Title>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={3}>
              <Chart data={resData} reportId={reportId} />
            </Grid>
          </Container>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="info" variant="filled">
              Kliknij na kolumnę z rokiem by zobaczyć szczegółowe dane
            </Alert>
          </Snackbar>
        </main>
      )}
    </>
  )
}
