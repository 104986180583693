import { put } from 'redux-saga/effects'
import { AuthError } from './authError'
import { authActions } from '_actions'
import { processError } from './processError'

const def = (dispatch, action, error) => {
  dispatch({
    type: action,
    payload: { error: processError(error).response },
  })

  if (error instanceof AuthError) {
    dispatch(authActions.logout())
  }

  return
}

function* saga(action, error) {
  yield put({
    type: action,
    payload: { error: processError(error).response },
  })

  if (error instanceof AuthError) {
    yield put(authActions.logout())
  }

  return
}

export const handleAuthError = {
  default: def,
  saga,
}
