import React, { useState } from 'react'
import { Title } from 'components/Title'
import { useSelector } from 'react-redux'
import { authHeader } from '../../../_helpers/authHeader'
import { useFetch } from '../../../_helpers/useFetch'
import CarModelCreation from './CarModelCreation'

const carModelEndpoint = '/car_models'
const carModelIriFunction = id => `${carModelEndpoint}/${id}`

const CarModelCreationContainer = ({ match }) => {
  const {
    params: { modelId },
  } = match
  const [carSerie, setCarSerie] = useState({})

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const auth = useSelector(state => state.auth)
  const args = [auth.data.token, modelId]

  const carModelIri = carModelIriFunction(modelId)
  const carModelRes = useFetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}${carModelIri}`,
    options,
    args
  )
  console.log(carModelRes)

  return (
    <>
      <>
        <Title>{carModelRes?.state?.data?.fullName}</Title>
        <CarModelCreation
          carSerie={carSerie}
          setCarSerie={setCarSerie}
          carModelIri={carModelIri}
        />
      </>
    </>
  )
}

export default CarModelCreationContainer
