const api = {
  pdfs: '/pdfs',
  emails: '/emails/send',
  configs: '/configs/',
  users: '/users',
}

const carApi = {
  ...api,
  appType: 'cars',
  home: 'car',
  customers: '/car_customers',
  hires: '/car_hires',
  hireItems: '/car_hire_items',
  invoices: '/car_invoices',
  reports: '/cars',
  documentTemplate: '/car_document_templates',
  pdfsInvoicesFull: '/pdfs/carInvoicesFullList',
  pdfsInvoicesShort: '/pdfs/carInvoicesShortenList',
  invoicingNumberings: '/car_invoicing_numberings',
  companyConfig: '/company_configs/car',
  hireDocument: '/pdfs/carHireDocument',
  hireItemDocument: '/pdfs/carHireItemDocument',
  returns: '/car_returns',
  repairs: '/car_repairs',
  segment: '/car_segments',
  discountLevel: '/car_discount_levels',
  archived: '/car_archiveds',
  iri: {
    invoices: '/car_invoices/',
    seller: '/users/',
    customer: '/car_customers/',
    invoicingNumbering: '/car_invoicing_numberings/',
    hires: '/car_hires/',
    hireItems: '/car_hire_items/',
    documentTemplate: '/car_document_templates/',
    equipment: '/cars/',
  },
}

const fitnessApi = {
  ...api,
  appType: 'fitness',
  home: 'fitness',
  customers: '/fitness_customers',
  hires: '/fitness_hires',
  hireItems: '/fitness_hire_items',
  invoices: '/fitness_invoices',
  reports: '/fitness_equipments',
  documentTemplate: '/fitness_document_templates',
  pdfsInvoicesFull: '/pdfs/fitnessInvoicesFullList',
  pdfsInvoicesShort: '/pdfs/fitnessInvoicesShortenList',
  invoicingNumberings: '/fitness_invoicing_numberings',
  companyConfig: '/company_configs/fitness',
  hireDocument: '/pdfs/fitnessHireDocument',
  hireItemDocument: '/pdfs/fitnessHireItemDocument',
  returns: '',
  repairs: '/fitness_repairs',
  segment: '/fitness_segments',
  discountLevel: '/fitness_discount_levels',
  archived: '/fitness_equipment_archiveds',
  iri: {
    invoices: '/fitness_invoices/',
    seller: '/users/',
    customer: '/fitness_customers/',
    invoicingNumbering: '/fitness_invoicing_numberings/',
    hires: '/fitness_hires/',
    hireItems: '/fitness_hire_items/',
    documentTemplate: '/fitness_document_templates/',
    equipment: '/fitness_equipments/',
  },
}

export default process.env.REACT_APP_TYPE === 'cars' ? carApi : fitnessApi
