import React from 'react'
import { authHeader } from '../../../_helpers/authHeader'
import FitnessModelForm from './FitnessModelForm'
import { notification } from '_helpers/notification'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  right: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '-48px',
    marginBottom: '20px',
  },
})

const FitnessModelDetail = ({ fitnessModel, setfitnessModel }) => {
  const classes = useStyles()

  const [updatablefitnessModel, setUpdatablefitnessModel] = React.useState({})
  const [loading, setLoading] = React.useState(false)
  const [violations, setViolations] = React.useState([])

  const handleSave = () => {
    setLoading(true)

    fetch(
      `${process.env.REACT_APP_API_ENTRYPOINT}/fitness_models/${fitnessModel.id}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(),
        },
        body: JSON.stringify(updatablefitnessModel),
      }
    )
      .then((response) => {
        setLoading(false)
        if (response.ok) {
          return response.json()
        }
        throw response.json()
      })
      .then((response) => {
        console.log(response)
        setfitnessModel(response)
        notification('success', 'Zapisano', 'Komunikat')
        setViolations([])
      })
      .catch((errorPromise) => {
        errorPromise.then((error) => {
          if (error.violations) {
            setViolations(error.violations)
            let errors = error.violations.map((e) => {
              return JSON.stringify(e.message)
            })

            errors = errors.join(',')

            notification('error', errors, 'Błąd')
          }
        })
      })
  }

  return (
    <>
      <div className={classes.right}>
        <Button
          to={`/equipment/models/${fitnessModel.id}/new`}
          color={'primary'}
          variant={'contained'}
          component={Link}
        >
          Dodaj maszynę
        </Button>
      </div>
      <div>
        <FitnessModelForm
          fitnessModel={fitnessModel}
          updatablefitnessModel={updatablefitnessModel}
          setUpdatablefitnessModel={setUpdatablefitnessModel}
          handleSave={handleSave}
          loading={loading}
          violations={violations}
        />
      </div>
    </>
  )
}

export default FitnessModelDetail
