import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import SeriesComponent from '../Components/SeriesComponent'
import SellerComponent from '../Components/SellerComponent.js'
import BuyerComponent from '../Components/BuyerComponent'
import DateComponent from '../Components/DateComponent'
import InvoiceEdit from '../Edit/InvoiceEdit'
import InvoiceForm from '../Edit/InvoiceForm'
import BigNumber from 'bignumber.js'
import { authHeader } from '_helpers/authHeader'
import api from 'api'
import { globalSalesValues } from 'dataconfig'
import { requestActions } from '_actions'
import { useDispatch, useSelector } from 'react-redux'
import { notification } from '_helpers/notification'
import { useHistory, useParams } from 'react-router-dom'
import { getCurrencyNumberFromApi, setCurrencyToApi } from '_helpers/currency'
import TabsContainer from '../Tabs'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  root: {
    marginTop: theme.spacing(6),
  },
  formControl: {
    minWidth: 250,
    marginTop: theme.spacing(3),
  },
}))

export const InvoiceNewContainer = () => {
  const classes = useStyles()
  const [series, setSeries] = React.useState(null)
  const [seller, setSeller] = React.useState(null)
  const [buyer, setBuyer] = React.useState(null)
  const [rows, setRows] = React.useState([])
  const [draftRows, setDraftRows] = React.useState([])
  const [pay, setPay] = React.useState(0)
  const [comment, setComment] = React.useState('')
  const [payment, setPayment] = React.useState('')
  const [billingDate, setBillingDate] = React.useState(new Date())
  const [saleDate, setSaleDate] = React.useState(new Date())
  const [paymentDate, setPaymentDate] = React.useState(new Date())
  const [error, setError] = React.useState({})
  const [connected, setConnected] = React.useState({
    warnings: false,
    connectedInvoices: [],
  })
  const [invoiceEdit, setInvoiceEdit] = React.useState({})
  const user = useSelector((state) => state.user)

  const dispatch = useDispatch()
  let history = useHistory()
  let params = useParams()
  const [connectedId] = React.useState(
    params?.connectedId ||
      params?.correctionId ||
      params?.similarId ||
      params?.editId ||
      null
  )

  React.useEffect(() => {
    const abortController = new AbortController()
    const options = {
      method: 'GET',
      headers: {
        accept: 'application/json',
        ...authHeader(),
      },
    }
    const fetchData = async () => {
      fetch(
        `${process.env.REACT_APP_API_ENTRYPOINT}${api.invoices}/${connectedId}`,
        {
          ...options,
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setBuyer(res.customer)
          if (params?.connectedId || params?.correctionId) {
            setConnected({
              warnings: false,
              connectedInvoices: [res],
            })
          }
          if (params?.editId) {
            setConnected({
              warnings: false,
              connectedInvoices: res.connectedInvoices,
            })
            setInvoiceEdit(res)
          }
          if (params?.correctionId || params?.similarId || params?.editId) {
            setSeries(res.invoicingNumbering.id)
            // setSeries(1)
            setDraftRows(
              res.rows.map((row) => ({
                name: row.name,
                units: row.quantity,
                countType: row.unit,
                price: getCurrencyNumberFromApi(row.priceNetto),
                vat: `${row.vatRate}%`,
                discount: row.discountRate / 100,
                pk: row.pkwiu,
              }))
            )
            setPayment(res.paymentType)
            setComment(res.note)
          }
          if (params?.correctionId || params?.editId) {
            setBillingDate(new Date(res.billingDate))
            setPaymentDate(new Date(res.paymentDate))
            setSaleDate(new Date(res.saleDate))
            setPay(getCurrencyNumberFromApi(res.paid))
          }
        })
    }
    if (connectedId) {
      fetchData()
    }
    return () => {
      abortController.abort()
    }
  }, [connectedId, params])

  const handleChangeSeries = (value) => {
    setSeries(value)
  }

  const handleSetSeller = (value) => {
    setSeller(value)
  }

  const handleSetBuyer = (value) => {
    setBuyer(value)
    if (value === null) {
      setError((prev) => ({
        ...prev,
        buyer: true,
      }))
    } else {
      setError((prev) => ({
        ...prev,
        buyer: false,
      }))
    }
  }

  const handleSetRows = (value) => {
    setRows(value)
    if (value.length === 0) {
      setError((prev) => ({
        ...prev,
        rows: true,
      }))
    } else {
      setError((prev) => ({
        ...prev,
        rows: false,
      }))
    }
  }

  const handleSetPay = (value) => {
    const val = new BigNumber(value)
    setPay(val.toNumber())
  }

  const handleSetComment = (value) => {
    setComment(value)
  }

  const handleSetDate = (value, type) => {
    switch (type) {
      case 'billing':
        setPaymentDate(value)
        setBillingDate(value)
        setSaleDate(value)
        break
      case 'sale':
        setSaleDate(value)
        break
      case 'payment':
        setPaymentDate(value)
        break
      case 'type':
        setPayment(value)
        setError((prev) => ({
          ...prev,
          payment: false,
        }))
        break
      default:
        break
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    let err = false
    if (buyer === null) {
      setError((prev) => ({
        ...prev,
        buyer: true,
      }))
      err = true
    }
    if (payment === '') {
      setError((prev) => ({
        ...prev,
        payment: true,
      }))
      err = true
    }
    if (rows.length === 0) {
      setError((prev) => ({
        ...prev,
        rows: true,
      }))
      err = true
    }
    if (!err) {
      const data = {
        seller: `${api.iri.seller}${user.profile.id}`,
        customer: `${api.iri.customer}${buyer.id}`,
        billingDate: billingDate,
        saleDate: saleDate,
        paymentDate: paymentDate,
        paymentType: payment,
        note: comment,
        paid: setCurrencyToApi(pay),
        invoicingNumbering: `${api.iri.invoicingNumbering}${series}`,
        salePlace: seller.mainAddress.city,
        rows: rows.map((el) => ({
          name: el.name,
          unit: el.countType,
          quantity: parseInt(el.units),
          priceNetto: setCurrencyToApi(el.price),
          vatRate: setCurrencyToApi(globalSalesValues.vat[el.vat].value),
          discountRate: setCurrencyToApi(el.discount),
          pkwiu: el.pk || '',
        })),
        connectedInvoices: connected.connectedInvoices.map(
          (el) => `${api.iri.invoices}${el.id}`
        ),
      }
      const options = {
        method: params?.editId ? 'PUT' : 'POST',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
          ...authHeader(),
        },
        body: JSON.stringify(data),
      }
      dispatch(requestActions.start(api.emails))
      fetch(
        `${process.env.REACT_APP_API_ENTRYPOINT}${api.invoices}${
          params?.editId ? '/' + invoiceEdit.id : ''
        }`,
        options
      )
        .then((resp) => resp.json())
        .then((res) => {
          if (res.id) {
            dispatch(requestActions.success())
            notification(
              'success',
              params?.editId ? 'Zmiany zapisane' : 'Faktura dodana',
              params?.editId ? 'Faktura' : 'Nowa faktura'
            )
            history.push(`/invoices/list/${res.id}`)
          } else {
            notification(
              'error',
              'Zmiany nie zostały zapisane. Sprawdź dane w formularzu.',
              'Błąd'
            )
          }
        })
    }
    return false
  }
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={9}>
          {params?.editId ? (
            <Typography variant={'h5'}>
              Edycja faktury nr: {invoiceEdit?.number}
            </Typography>
          ) : (
            <SeriesComponent
              onChange={handleChangeSeries}
              classes={classes}
              type={params?.correctionId ? 'FC' : false}
            />
          )}
          <div className={classes.root}>
            <Grid container spacing={4} justify="space-evenly">
              <Grid item xs={6} sm={4}>
                <SellerComponent setSeller={handleSetSeller} />
              </Grid>
              <Grid item xs={6} sm={4}>
                <BuyerComponent
                  classes={classes}
                  setBuyer={handleSetBuyer}
                  buyer={buyer}
                  error={error}
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <DateComponent
                  classes={classes}
                  seller={seller}
                  payment={payment}
                  billingDate={billingDate}
                  saleDate={saleDate}
                  paymentDate={paymentDate}
                  setData={handleSetDate}
                  error={error}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <div className={classes.root}>
        <InvoiceEdit
          setRows={handleSetRows}
          rows={rows}
          //discount={buyer?.discountLevel?.discountRate}
          discount={rows?.[0]?.discount}
          error={error}
          draftRows={draftRows}
        />
      </div>
      <Grid container>
        <Grid item xs={12}>
          <InvoiceForm
            rows={rows}
            setPay={handleSetPay}
            pay={pay}
            comment={comment}
            setComment={handleSetComment}
            submit={handleSubmit}
            edit={params?.editId ? true : false}
            backId={connectedId}
          />
        </Grid>
      </Grid>
      {connected && (
        <Grid container>
          <Grid item xs={12}>
            <TabsContainer data={connected} />
          </Grid>
        </Grid>
      )}
    </>
  )
}
