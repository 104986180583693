import React from 'react'
import PropTypes from 'prop-types'
import { Switch } from 'react-router-dom'
import { CssBaseline, Container, Grid, Paper } from '@material-ui/core'
import { useStyles } from './PageStyles'
import { RegularBar } from 'components/bar/RegularBar'
import { Breadcrumbs } from 'components/Breadcrumbs'

export const Page = ({ menu, routes, breadcrumbs }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CssBaseline />
      <RegularBar sideMenuItems={menu} />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Breadcrumbs paths={breadcrumbs} className={classes.marB} />
                <Switch>{routes}</Switch>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  )
}

Page.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
}
