import CircularProgress from '@material-ui/core/CircularProgress'
import SaveIcon from '@material-ui/icons/Save'
import React from 'react'
import FitnessModelFormFieldFactory from './FitnessModelFormFieldFactory'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  gridParent: {
    display: 'grid',
    gridAutoRows: 'minmax(32px, auto)',
    gridTemplateColumns: 'repeat(2,1fr)',
    columnGap: '25px',
    rowGap: '5px',
    position: 'relative',
  },
  gridChild: {
    alignSelf: 'center',
    display: 'grid',
    gridTemplateColumns: '250px 1fr',
    borderBottom: '1px solid lightgrey',
    paddingBottom: '5px',
  },
  icon: {
    position: 'absolute',
    right: '0',
    top: '-15px',
  },
})
const HEADER = [
  {
    name: 'Identyfikator',
    prop: 'id',
  },
  {
    name: 'Nazwa',
    prop: 'name',
  },
  {
    name: 'Opis',
    prop: 'description',
  },
  {
    name: 'Rozmiar',
    prop: 'size',
  },
  {
    name: 'Waga',
    prop: 'weight',
  },
  {
    name: 'Kategoria',
    prop: 'category',
  },
  {
    name: 'Cena za miesiąc',
    prop: 'monthlyPrice',
  },
  {
    name: 'Zdjęcia',
    prop: 'photos',
  },
]

const FitnessModelForm = ({
  fitnessModel,
  updatablefitnessModel,
  setUpdatablefitnessModel,
  handleSave,
  loading,
  violations,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.gridParent}>
      {HEADER.map((y, k) => (
        <div className={classes.gridChild} key={`trc-${k}`}>
          <FitnessModelFormFieldFactory
            fieldName={y.name}
            type={y.prop}
            value={fitnessModel[y.prop]}
            violations={violations}
            updatableParentEntity={updatablefitnessModel}
            setUpdatableParentEntity={setUpdatablefitnessModel}
          />
        </div>
      ))}
      <div className={classes.icon}>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <SaveIcon onClick={handleSave} color="primary" />
        )}
      </div>
    </div>
  )
}
export default FitnessModelForm
