import React from 'react'

import SimpleDateModule from './SimpleDateModule'

const moduleName = 'Przeglądy rejestracyjne'
const moduleEndpoint = '/registration_services'

export default function RegistrationServiceList({ entities, carIri }) {
  return (
    <SimpleDateModule
      entities={entities}
      carIri={carIri}
      moduleEndpoint={moduleEndpoint}
      moduleName={moduleName}
    />
  )
}
