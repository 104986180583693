import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { paymentType } from 'dataconfig'

const DateComponent = (props) => {
  const {
    error,
    classes,
    seller,
    payment,
    billingDate,
    saleDate,
    paymentDate,
    setData,
  } = props

  const handleChange = (event) => {
    setData(event.target.value, 'type')
  }

  return (
    <>
      <Typography variant="h6" color="textPrimary" gutterBottom>
        Data i Płatność
      </Typography>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          margin="normal"
          id="date-picker-dialog"
          label="Data wystawienia"
          format="DD.MM.YYYY"
          value={billingDate}
          onChange={(date, value) => {
            setData(date, 'billing')
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <KeyboardDatePicker
          margin="normal"
          id="date-picker-dialog-2"
          label="Data sprzedaży"
          format="DD.MM.YYYY"
          value={saleDate}
          onChange={(date, value) => {
            setData(date, 'sale')
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <KeyboardDatePicker
          margin="normal"
          id="date-picker-dialog-3"
          label="Termin zapłaty "
          format="DD.MM.YYYY"
          value={paymentDate}
          onChange={(date, value) => {
            setData(date, 'payment')
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </MuiPickersUtilsProvider>
      <FormControl
        variant="outlined"
        className={classes.formControl}
        style={{ minWidth: 250, marginTop: 16 }}
      >
        <InputLabel id="demo-simple-select-outlined-label">
          Sposób płatności
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={payment}
          onChange={handleChange}
          label="Sposób płatności"
          error={error?.payment}
        >
          {paymentType.map((el) => (
            <MenuItem key={el.name} value={el.name}>
              {el.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Typography
        variant="subtitle1"
        color="textSecondary"
        className={classes.formControl}
      >
        {seller && seller.accountNumber && (
          <>Konto : {seller && seller.accountNumber}</>
        )}
      </Typography>
    </>
  )
}

DateComponent.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
  seller: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.object]),
  payment: PropTypes.string.isRequired,
  billingDate: PropTypes.object.isRequired,
  saleDate: PropTypes.object.isRequired,
  paymentDate: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  error: PropTypes.object.isRequired,
}

export default DateComponent
