import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormHelperText from '@material-ui/core/FormHelperText'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import GridListTileBar from '@material-ui/core/GridListTileBar'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import Input from '@material-ui/core/Input'
import CloseIcon from '@material-ui/icons/Close'
import { authHeader } from '_helpers/authHeader'

const useStyles = makeStyles((theme) => ({
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    '& ul': {
      width: '100%',
    },
  },
  item: {
    border: '1px solid black',
    height: '160px',
    width: '100%',
  },
  titleBar: {
    background: 'transparent',
  },
  rightIcon: {
    float: 'right',
    fontWeight: 'strong',
  },
  centerIcon: {
    position: 'relative',
    top: 'calc(50% - 12px)',
    left: 'calc(50% - 12px)',
  },
}))

const endPoint = 'photos'
const iri = (item) => `${endPoint}/${item.id}`

export default function GalleryField({
  fieldName,
  type,
  photos,
  violations,
  updatableParentEntity,
  setUpdatableParentEntity,
}) {
  const [openGallery, setOpenGallery] = React.useState(false)
  const [openUpload, setOpenUpload] = React.useState(false)
  const [currentPhotos, setCurrentPhotos] = React.useState(photos || [])
  const [uploadViolations, setUploadViolations] = React.useState([])

  const classes = useStyles()

  const fieldViolations = violations.filter(
    (violation) => violation.propertyPath === type
  )

  const handleOpenGallery = () => setOpenGallery(true)
  const handleCloseGallery = () => setOpenGallery(false)
  const handleOpenUpload = () => setOpenUpload(true)
  const handleCloseUpload = () => setOpenUpload(false)
  const handleUpload = () => {
    const file = document.querySelector('#file').files[0]
    const formData = new FormData()
    formData.append('file', file)
    const postData = async (slug, data) => {
      fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/${slug}`, {
        method: 'POST',
        headers: {
          accept: 'application/json',
          ...authHeader(),
        },
        body: data,
      })
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
          throw response.json()
        })
        .then((json) => {
          setNewPhotos([...currentPhotos, json])
          setUploadViolations([])
          setOpenUpload(false)
        })
        .catch((errorPromise) => {
          errorPromise.then((error) => {
            if (error.violations) {
              setUploadViolations(error.violations)
            }
          })
        })
    }
    postData(endPoint, formData)
  }

  const createHandleDelete = (id) => {
    return () => {
      const newPhotos = currentPhotos.filter((photo) => photo.id !== id)
      setNewPhotos(newPhotos)
    }
  }

  const setNewPhotos = (newPhotos) => {
    setCurrentPhotos(newPhotos)
    setUpdatableParentEntity({
      ...updatableParentEntity,
      [type]: newPhotos.map(iri),
    })
  }

  return (
    <React.Fragment>
      <div>
        <b>{fieldName}:</b>
      </div>
      <div>
        <div onClick={handleOpenGallery}>
          {currentPhotos.length} zdjęć
          <span>
            <ChevronRightIcon className={classes.rightIcon} />
          </span>
          {fieldViolations.length
            ? fieldViolations.map((violation, i) => (
                <FormHelperText key={i} error={true}>
                  {violation.message}
                </FormHelperText>
              ))
            : ''}
        </div>
        <Dialog
          open={openGallery}
          onClose={handleCloseGallery}
          maxWidth="md"
          fullWidth
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {fieldName}

            <CloseIcon
              onClick={handleCloseGallery}
              className={classes.rightIcon}
            />
          </DialogTitle>
          <DialogContent>
            <div className={classes.list}>
              <GridList cols={3} cellHeight={160} spacing={10}>
                {currentPhotos.map((photo) => (
                  <GridListTile key={photo.id}>
                    <img
                      src={`${process.env.REACT_APP_API_ENTRYPOINT}${photo.url}`}
                      alt=""
                      className={classes.item}
                    />
                    <GridListTileBar
                      titlePosition="top"
                      actionPosition="left"
                      className={classes.titleBar}
                      actionIcon={
                        <RemoveIcon onClick={createHandleDelete(photo.id)} />
                      }
                    ></GridListTileBar>
                  </GridListTile>
                ))}
                <GridListTile key={0}>
                  <div className={classes.item}>
                    <AddIcon
                      className={classes.centerIcon}
                      onClick={handleOpenUpload}
                    />
                  </div>
                </GridListTile>
              </GridList>
            </div>
          </DialogContent>
        </Dialog>

        <Dialog
          open={openUpload}
          onClose={handleCloseUpload}
          maxWidth="md"
          fullWidth
          aria-labelledby="upload-dialog"
        >
          <DialogTitle id="upload-dialog">Dodaj zdjęcie</DialogTitle>
          <DialogContent>
            <Input type="file" name="file" id="file" />
            {uploadViolations.length ? (
              <FormHelperText error={true}>
                {uploadViolations[0].message}
              </FormHelperText>
            ) : (
              ''
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleUpload} color="primary">
              Prześlij
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment>
  )
}
