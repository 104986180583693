import React from 'react'
import NumberField from './NumberField'
import StringField from './StringField'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    '& > div': {
      flexGrow: 1,
      flexBasis: 0,
    },
  },
}))

export default function UnitNumberField({
  fieldName,
  type,
  value,
  violations = [],
  updatableParentEntity,
  setUpdatableParentEntity,
  readOnly = false,
}) {
  const [currentValue, setCurrentValue] = React.useState(value || {})
  const classes = useStyles()

  const fieldViolations = violations.filter(
    violation => violation.propertyPath === type
  )

  const setUnitableValue = value => {
    setCurrentValue(value)
    setUpdatableParentEntity({
      ...updatableParentEntity,
      [type]: value,
    })
  }

  return (
    <React.Fragment>
      {fieldName && (
        <div>
          <b>{fieldName}:</b>
        </div>
      )}
      <div className={classes.container}>
        <NumberField
          fieldName={'Wartość'}
          value={currentValue?.value}
          type={'value'}
          violations={fieldViolations}
          updatableParentEntity={currentValue}
          setUpdatableParentEntity={setUnitableValue}
          readOnly={readOnly}
        />
        <StringField
          fieldName={'Jednostka'}
          value={currentValue?.unit}
          type={'unit'}
          violations={fieldViolations}
          updatableParentEntity={currentValue}
          setUpdatableParentEntity={setUnitableValue}
          readOnly={readOnly}
        />
      </div>
    </React.Fragment>
  )
}
