import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import StringField from './StringField'
import { fetchDataHandleAuthError } from '../../_helpers/fetchDataHandleAuthError'
import { useDispatch } from 'react-redux'
import { requestConstants } from '_constants'
import FormHelperText from '@material-ui/core/FormHelperText'

const useStyles = makeStyles((theme) => ({
  newBrandButton: {
    marginLeft: '20px',
  },
}))

export default function AddNamedEntityButton({
  children,
  endpoint,
  method,
  fieldName,
  initialEntityState,
  updatableEntitiesList,
  setUpdatableEntitiesList,
  variant,
  value,
  setRefresh,
}) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [createdEntity, setCreatedEntity] = useState(initialEntityState || {})
  const [violations, setViolations] = useState([])

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const dispatch = useDispatch()

  const handleCreating = () => {
    fetchDataHandleAuthError(
      endpoint,
      method,
      { body: JSON.stringify(createdEntity) },
      (item) => {
        setUpdatableEntitiesList([...updatableEntitiesList, item])
        handleClose()
        setRefresh && setRefresh((prevState) => !prevState)
      },
      (error) => {
        setViolations(error.response.violations)
      },
      dispatch,
      requestConstants.FAILURE
    )
  }

  return (
    <>
      <Button
        color={'primary'}
        variant={variant}
        className={classes.newBrandButton}
        onClick={handleOpen}
      >
        {children}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{fieldName}</DialogTitle>
        <DialogContent>
          <StringField
            type={'name'}
            updatableParentEntity={createdEntity}
            setUpdatableParentEntity={setCreatedEntity}
            value={value}
          />
          {violations.length
            ? violations.map((violation, i) => (
                <FormHelperText key={i} error={true}>
                  {violation.message}
                </FormHelperText>
              ))
            : ''}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Anuluj
          </Button>
          <Button onClick={handleCreating} color="primary">
            Dodaj
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
