import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '1.5em',
    marginBottom: '1.5em',
    display: 'inherit',
    gridTemplateColumns: 'repeat(2,1fr)',
  },
  save: {
    marginTop: '1.5em',
  },
}))
