import { userConstants } from '_constants'

export const userReducer = (state = {}, action) => {
  switch (action.type) {
    case userConstants.PROFILE_SUCCESS:
    case userConstants.PROFILE_UPDATE:
      return {
        profile: {
          ...state.profile,
          ...action.payload,
        },
      }
    case userConstants.PROFILE_FAILURE:
    case userConstants.PROFILE_ERASE:
      return {}
    default:
      return state
  }
}
