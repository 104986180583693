import React from 'react'
import { Schemable } from 'components/Schemable'
import { Loader } from 'components/Loader'

import { CustomerForm } from './CustomerForm'

import Grid from '@material-ui/core/Grid'
export const CustomerFormContainer = () => {
  const customer = {}

  return (
    <Schemable>
      {customer ? (
        <Grid container spacing={3}>
          <Grid item xs={4} style={{ display: 'grid' }}>
            <CustomerForm resource={customer} />
          </Grid>
        </Grid>
      ) : (
        <div>
          <Loader align="center" />
        </div>
      )}
    </Schemable>
  )
}
