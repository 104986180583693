import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import { Link } from 'react-router-dom'

import DialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide'
import MapPage from 'components/map/Map'
import CarForm from './CarForm'

import { authHeader } from '_helpers/authHeader'
import { makeStyles } from '@material-ui/core/styles'
import ReactivateButton from './buttons/ReactivateButton'
import ArchiveButton from './buttons/ArchiveButton'
import { notification } from '_helpers/notification'

const useStyles = makeStyles({
  form: {
    position: 'relative',
    marginTop: '10px',
  },

  buttonContainer: {
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'row-reverse',
    '& > *': {
      marginLeft: '10px',
    },
  },
})

const mapCanRecord = (car) => ({
  lat: car?.canRegister?.lastRecord.latitude,
  lng: car?.canRegister?.lastRecord.longitude,
})

export default function CarDetail({ car, setCar }) {
  const classes = useStyles()
  const styles = (theme) => ({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    toRight: {
      marginLeft: 'auto',
    },
  })

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    )
  })

  const [updatableCar, setUpdatableCar] = React.useState({})
  const [loading, setLoading] = React.useState(false)
  const [violations, setViolations] = React.useState([])
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
  })
  const [open, setOpen] = React.useState(false)

  const handleClose = () => {
    setOpen(false)
  }
  const handleOpen = () => {
    setOpen(true)
  }
  const handleSave = () => {
    setLoading(true)

    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/cars/${car.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
      },
      body: JSON.stringify(updatableCar),
    })
      .then((response) => {
        setLoading(false)
        if (response.ok) {
          return response.json()
        }
        throw response.json()
      })
      .then((response) => {
        setCar(response)
        notification('success', 'Zapisano', 'Komunikat')
        setViolations([])
      })
      .catch((errorPromise) => {
        errorPromise.then((error) => {
          if (error.violations) {
            setViolations(error.violations)
            let errors = error.violations.map((e) => {
              return JSON.stringify(e.message)
            })

            errors = errors.join(',')

            notification('error', errors, 'Błąd')
          }
        })
      })
  }
  function AlertDialogSlide() {
    return (
      <div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          fullScreen={true}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {car.registrationNumber}
          </DialogTitle>
          <DialogContent>
            {open && <MapPage lastCanRecord={mapCanRecord(car)} />}
          </DialogContent>
        </Dialog>
      </div>
    )
  }

  return (
    <div className={classes.form}>
      <CarForm
        car={car}
        updatableCar={updatableCar}
        setUpdatableCar={setUpdatableCar}
        handleSave={handleSave}
        loading={loading}
        violations={violations}
      />
      <div className={classes.buttonContainer}>
        {car?.canRegister?.lastRecord?.id && (
          <Button onClick={handleOpen} color="primary" variant="outlined">
            zobacz na mapie
          </Button>
        )}
        {car?.archived?.id ? (
          <ReactivateButton item={car} setItem={setCar} />
        ) : (
          <ArchiveButton item={car} setItem={setCar} />
        )}
        {car?.status === 'rentableItem:Repair' && (
          <ReactivateButton item={car} setItem={setCar} text="Przywróć" />
        )}
        {car?.canRegister?.id && (
          <Button
            component={Link}
            to={`/fleet/carscan/${car.id}/register/${car.canRegister.id}`}
            color="primary"
            variant="outlined"
          >
            Rejestr CAN
          </Button>
        )}
      </div>
      <AlertDialogSlide />
    </div>
  )
}
