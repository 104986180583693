import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu'
import { default as MUIMenuItem } from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'

import { makeStyles } from '@material-ui/core/styles'
import {templateDocumentContexMenuTrans} from "../../../dataconfig";

const useStyles = makeStyles({
  btn: {
    display: 'flex',
    marginLeft: 'auto',
  },
  textField: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'center',
  },
  textFieldTitle: {
    marginBottom: '50px',
  },
  resize: {
    fontSize: '1.5rem',
    marginLeft: '0.25rem',
  },
  resizeTitle: {
    fontSize: '2rem',
    minWidth: '500px',
  },
  contextmenu: {
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid rgba(0, 0, 0, 0.15)',
    borderRadius: '0.25rem',
    color: '#373a3c',
    fontSize: '16px',
    margin: '2px 0 0',
    minWidth: '160px',
    outline: 'none',
    opacity: '0',
    padding: '5px 0',
    pointerEvents: 'none',
    textAlign: 'left',
    transition: 'opacity 250ms ease',
  },
})

export default function TemplateContent(props) {
  const {
    template,
    defVariableKeys,
    changeContent,
    changeTitle,
    changeIntro,
  } = props
  const classes = useStyles()

  const insertAt = (str, sub, pos) =>
    `${str.slice(0, pos)}${sub}${str.slice(pos)}`

  const [selectionStart, setSelectionStart] = React.useState()
  const updateSelectionStart = (value) => {
    setSelectionStart(value)
  }

  const [variableKeys, setVariableKeys] = useState(defVariableKeys)

  useEffect(() => {
    setVariableKeys(defVariableKeys)
  }, [defVariableKeys])

  const deleteItem = (i) => {
    const { paragraphs } = template
    paragraphs.splice(i, 1)
    changeContent([...paragraphs])
  }

  const addItem = () => {
    const item = {
      title: '',
      text: '',
    }
    changeContent([...template.paragraphs, item])
  }

  const handleInput = (value, i) => {
    const newValue = () => {
      const newItems = [...template.paragraphs]
      newItems[i].title = value

      return newItems
    }
    changeContent(newValue())
  }

  const handleTextArea = (value, i) => {
    const newValue = () => {
      const newItems = [...template.paragraphs]

      newItems[i].text = value
      return newItems
    }
    changeContent(newValue())
  }

  const MENU_TYPE = 'MULTI'

  function collect(props) {
    return { name: props.name }
  }

  const handleClick = (e, data, target) => {
    const propValue = data.action

    const i = data.name
    const newValue = () => {
      const newItems = [...template.paragraphs]

      const value = insertAt(newItems[i]?.text, propValue, selectionStart)

      newItems[i].text = value
      return newItems
    }
    const newValueIntro = () => {
      const value = insertAt(template.intro, propValue, selectionStart)
      return value
    }
    if (i === 'intro') {
      changeIntro(newValueIntro())
    } else {
      changeContent(newValue())
    }
  }

  return (
    <>
      <div>
        <div className={`${classes.textFieldTitle} ${classes.textField}`}>
          <TextField
            onChange={(e) => changeTitle(e.target.value)}
            value={template.name}
            margin="normal"
            InputProps={{
              classes: {
                input: classes.resizeTitle,
              },
            }}
          />
        </div>
        <div>
          <div className={classes.textField}>
            <Typography
              variant="h5"
              align="center"
              component="p"
              gutterBottom={true}
            >
              Wstęp
            </Typography>
          </div>

          <ContextMenuTrigger
            id={MENU_TYPE}
            name="intro"
            holdToDisplay={1000}
            collect={collect}
          >
            <TextField
              onClick={(e) => updateSelectionStart(e.target.selectionStart)}
              onChange={(e) => changeIntro(e.target.value)}
              multiline
              value={template.intro}
              fullWidth
              margin="normal"
            />
          </ContextMenuTrigger>
        </div>
        {template.paragraphs.map((item, i) => {
          return (
            <div key={`row-${i}`}>
              <Button
                className={classes.btn}
                onClick={deleteItem.bind(this, i)}
                startIcon={<RemoveCircleIcon />}
              >
                Usuń paragraf
              </Button>
              <div className={classes.textField}>
                <Typography
                  variant="h5"
                  align="center"
                  component="p"
                  gutterBottom={true}
                >
                  §{i + 1}
                </Typography>
                <TextField
                  onChange={(e) => handleInput(e.target.value, i)}
                  value={item.title}
                  margin="normal"
                  InputProps={{
                    classes: {
                      input: classes.resize,
                    },
                  }}
                />
              </div>

              <ContextMenuTrigger
                id={MENU_TYPE}
                name={i}
                holdToDisplay={1000}
                collect={collect}
              >
                <TextField
                  onClick={(e) => updateSelectionStart(e.target.selectionStart)}
                  onChange={(e) => handleTextArea(e.target.value, i)}
                  multiline
                  value={item.text}
                  fullWidth
                  margin="normal"
                />
              </ContextMenuTrigger>
            </div>
          )
        })}
        <ContextMenu id={MENU_TYPE} className={classes.contextmenu}>
          {variableKeys.map((el, key) => (
            <MenuItem key={key} onClick={handleClick} data={{ action: el }}>
              <MUIMenuItem>{el} - {templateDocumentContexMenuTrans[el] !== undefined && templateDocumentContexMenuTrans[el]}</MUIMenuItem>
            </MenuItem>
          ))}
        </ContextMenu>

        <Button
          onClick={addItem}
          className={classes.btn}
          startIcon={<AddCircleIcon />}
        >
          Dodaj kolejny paragraf
        </Button>
      </div>
    </>
  )
}

TemplateContent.propTypes = {
  template: PropTypes.object.isRequired,
  defVariableKeys: PropTypes.array.isRequired,
  changeContent: PropTypes.func.isRequired,
  changeTitle: PropTypes.func.isRequired,
  changeIntro: PropTypes.func.isRequired,
}
