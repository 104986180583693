import React from 'react'
import RentableItemPartField from '../fields/RentableItemPartField'
import StringField from 'components/fields/StringField'
import DatePickerField from 'components/fields/DatePickerField'
import EquipmentPage from '../fields/EquipmentPage'
import SelectEntityField from 'components/fields/SelectEntityField'
import MoneyField from 'components/fields/MoneyField'

const parameterizedEndpoint = '/fitness_segments'

export default function EquipmentFieldFactory({
  fieldName,
  type,
  value,
  violations,
  updatableParentEntity,
  setUpdatableParentEntity,
  equipmentId,
}) {
  switch (type) {
    case 'identifier':
      return (
        <StringField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
          readOnly={false}
        />
      )
    case 'fitnessService':
      return (
        <RentableItemPartField
          fieldName={fieldName}
          value={value}
          listEndpoint={`/equipment/list/${equipmentId}/fitness_service`}
          type={type}
          readOnly={!equipmentId}
        />
      )
    case 'repair':
      return (
        <RentableItemPartField
          fieldName={fieldName}
          value={value}
          listEndpoint={`/equipment/list/${equipmentId}/repair`}
          type={type}
          readOnly={!equipmentId}
        />
      )
    case 'hireItem.cost':
      return (
        <MoneyField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
          readOnly={true}
        />
      )
    case 'fullName':
      return (
        <EquipmentPage
          fieldName={fieldName}
          value={value}
          listEndpoint={`/equipment/list/${equipmentId}/detail`}
          type={type}
          readOnly={!equipmentId}
        />
      )
    case 'model.brand.name':
      return (
        <StringField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
          readOnly={true}
        />
      )
    case 'segment':
      return (
        <SelectEntityField
          fieldName={fieldName}
          selectableEntity={value}
          type={type}
          endPoint={parameterizedEndpoint}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'rentalPlace.city':
      return (
        <StringField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
          readOnly={true}
        />
      )

    case 'yearOfProduction':
      return (
        <StringField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
          readOnly={false}
        />
      )

    case 'dateOfObtainment':
      return (
        <DatePickerField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )

    case 'itemStatus':
      return (
        <StringField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
          readOnly={true}
        />
      )

    default:
      return (
        <StringField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
          readOnly={true}
        />
      )
  }
}
