import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import moment from 'moment'

import MoneyField from 'components/fields/MoneyField'
import CheckboxField from 'components/fields/CheckboxField'
import DatePickerField from 'components/fields/DatePickerField'
import StringField from 'components/fields/StringField'
import CarPartModelField from 'components/fields/CarPartModelField'
import KilometreField from 'components/fields/KilometreField'

import Warning from '../../../../components/Warning'
import CarPartList from '../CarPartList'

import WbSunnyIcon from '@material-ui/icons/WbSunny'
import AcUnitIcon from '@material-ui/icons/AcUnit'

const useValueStyles = makeStyles(theme => ({
  icon: {
    position: 'relative',
    top: '4px',
    marginRight: '5px',
  },
  sunIcon: {
    color: 'orange',
  },
  winterIcon: {
    color: 'blue',
  },
}))
export const TyresValue = ({ item }) => {
  const classes = useValueStyles()
  return (
    <>
      {`${item?.model?.brand?.name} | `}
      {item.forSummer && (
        <WbSunnyIcon
          fontSize="small"
          className={`${classes.icon} ${classes.sunIcon}`}
        />
      )}
      {item.forWinter && (
        <AcUnitIcon
          fontSize="small"
          className={`${classes.icon} ${classes.winterIcon}`}
        />
      )}
      <span>{` ${item?.model?.name} `}</span>
      {item.warnings.length ? (
        <Warning messages={item.warnings.map(warning => warning.message)} />
      ) : (
        <></>
      )}
    </>
  )
}

const compareFunction = (a, b) =>
  new Date(b.deadlineDate).getTime() - new Date(a.deadlineDate).getTime()

const updateDeadlineDistance = item => ({
  ...item,
  deadlineDistance: parseInt(item.startDistance) + parseInt(item.limitDistance),
})

export default function TyresList({
  entities,
  carIri,
  currentDistanceMeter,
  moduleEndpoint,
  moduleName,
  moduleType,
}) {
  const createSpecificColumns = createSetRow => [
    {
      id: 'startDate',
      label: 'Data rozpoczęcia',
      minWidth: 200,
      renderValue: (item, violations) => (
        <DatePickerField
          type={'startDate'}
          value={item.startDate}
          violations={violations ? violations : []}
          updatableParentEntity={item}
          setUpdatableParentEntity={createSetRow(item.id)}
        />
      ),
    },
    {
      id: 'deadlineDate',
      label: 'Data zakończenia',
      minWidth: 200,
      renderValue: (item, violations) => (
        <DatePickerField
          type={'deadlineDate'}
          value={item.deadlineDate}
          violations={violations ? violations : []}
          updatableParentEntity={item}
          setUpdatableParentEntity={createSetRow(item.id)}
        />
      ),
    },
    {
      id: 'startDistance',
      label: 'Początkowy stan licznika',
      minWidth: 50,
      align: 'center',
      renderValue: (item, violations) => (
        <KilometreField
          type={'startDistance'}
          value={item.startDistance}
          violations={violations ? violations : []}
          updatableParentEntity={item}
          setUpdatableParentEntity={row => {
            const setRow = createSetRow(item.id)
            setRow(updateDeadlineDistance(row))
          }}
        />
      ),
    },

    {
      id: 'limitDistance',
      label: 'Limit kilometrów',
      minWidth: 50,
      align: 'center',
      renderValue: (item, violations) => (
        <KilometreField
          type={'limitDistance'}
          value={item.limitDistance}
          violations={violations ? violations : []}
          updatableParentEntity={item}
          setUpdatableParentEntity={row => {
            const setRow = createSetRow(item.id)
            setRow(updateDeadlineDistance(row))
          }}
        />
      ),
    },
    {
      id: 'deadlineDistance',
      label: 'Maksymalny stan licznika',
      minWidth: 50,
      align: 'center',
      renderValue: (item, violations) => (
        <KilometreField
          key={item.deadlineDistance}
          type={'deadlineDistance'}
          value={item.deadlineDistance}
          violations={violations ? violations : []}
          updatableParentEntity={item}
          setUpdatableParentEntity={createSetRow(item.id)}
          readOnly
        />
      ),
    },

    {
      id: 'model',
      label: 'Model',
      minWidth: 150,
      align: 'center',
      renderValue: (item, violations) => (
        <CarPartModelField
          type={'model'}
          carPartType={moduleType}
          modelEntity={item.model}
          violations={violations ? violations : []}
          updatableParentEntity={item}
          setUpdatableParentEntity={createSetRow(item.id)}
        />
      ),
    },
    {
      id: 'size',
      label: 'Rozmiar',
      minWidth: 100,
      align: 'center',
      renderValue: (item, violations) => (
        <StringField
          type={'size'}
          value={item.size}
          violations={violations ? violations : []}
          updatableParentEntity={item}
          setUpdatableParentEntity={createSetRow(item.id)}
          center
        />
      ),
    },
    {
      id: 'forSummer',
      label: 'Letnie',
      minWidth: 50,
      align: 'center',
      renderValue: (item, violations) => (
        <CheckboxField
          type={'forSummer'}
          value={item.forSummer}
          violations={violations ? violations : []}
          updatableParentEntity={item}
          setUpdatableParentEntity={createSetRow(item.id)}
        />
      ),
    },
    {
      id: 'forWinter',
      label: 'Zimowe',
      minWidth: 50,
      align: 'center',
      renderValue: (item, violations) => (
        <CheckboxField
          type={'forWinter'}
          value={item.forWinter}
          violations={violations ? violations : []}
          updatableParentEntity={item}
          setUpdatableParentEntity={createSetRow(item.id)}
        />
      ),
    },
    {
      id: 'cost',
      label: 'Cena',
      minWidth: 100,
      renderValue: (item, violations) => (
        <MoneyField
          type={'cost'}
          value={item.cost}
          violations={violations ? violations : []}
          updatableParentEntity={item}
          setUpdatableParentEntity={createSetRow(item.id)}
        />
      ),
    },
    {
      id: 'includeCost',
      label: 'Wliczaj',
      minWidth: 50,
      renderValue: (item, violations) => (
        <CheckboxField
          type={'includeCost'}
          value={item.includeCost}
          violations={violations ? violations : []}
          updatableParentEntity={item}
          setUpdatableParentEntity={createSetRow(item.id)}
        />
      ),
    },
  ]

  const createPrototype = () => {
    return {
      id: 0,
      car: carIri,
      startDate: moment().format(),
      deadlineDate: moment().format(),
      model: null,
      size: '',
      cost: 0,
      includeCost: false,
      forSummer: false,
      forWinter: false,
      startDistance: currentDistanceMeter,
      limitDistance: 0,
      deadlineDistance: currentDistanceMeter,
    }
  }

  return (
    <CarPartList
      entities={entities}
      moduleEndpoint={moduleEndpoint}
      moduleName={moduleName}
      createPrototype={createPrototype}
      compareFunction={compareFunction}
      createSpecificColumns={createSpecificColumns}
    />
  )
}
