import React from 'react'

import ModelModuleWithDateAndDistanceValue from './ModelModuleWithDateAndDistance'

const moduleName = 'Pasek rozrządu'
const moduleEndpoint = '/timing_belts'
const moduleType = 'timingBelt'

export default function TimingBeltList({
  entities,
  carIri,
  currentDistanceMeter,
}) {
  return (
    <ModelModuleWithDateAndDistanceValue
      entities={entities}
      carIri={carIri}
      currentDistanceMeter={currentDistanceMeter}
      moduleEndpoint={moduleEndpoint}
      moduleName={moduleName}
      moduleType={moduleType}
    />
  )
}
