import React from 'react'
import RentalPlaceForm from './RentalPlaceForm'

import { authHeader } from '_helpers/authHeader'
import { makeStyles } from '@material-ui/core/styles'
import { notification } from '_helpers/notification'

const useStyles = makeStyles({
  form: {
    position: 'relative',
    marginTop: '10px',
  },

  buttonContainer: {
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'row-reverse',
    '& > *': {
      marginLeft: '10px',
    },
  },
})
export default function RentalPlaceDetail({
  rentalPlace,
  setRentalPlace,
  iri,
}) {
  const classes = useStyles()
  const [updatableRentalPlace, setUpdatableRentalPlace] = React.useState({})
  const [loading, setLoading] = React.useState(false)
  const [violations, setViolations] = React.useState([])

  const handleSave = () => {
    setLoading(true)

    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}${iri}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
      },
      body: JSON.stringify(updatableRentalPlace),
    })
      .then((response) => {
        setLoading(false)
        if (response.ok) {
          return response.json()
        }
        throw response.json()
      })
      .then((response) => {
        console.log(response)
        setRentalPlace(response)
        notification('success', 'Zapisano', 'Komunikat')
        setViolations([])
      })
      .catch((errorPromise) => {
        errorPromise.then((error) => {
          if (error.violations) {
            setViolations(error.violations)
            let errors = error.violations.map((e) => {
              return JSON.stringify(e.message)
            })

            errors = errors.join(',')

            notification('error', errors, 'Błąd')
          }
        })
      })
  }

  return (
    <div className={classes.form}>
      <RentalPlaceForm
        rentalPlace={rentalPlace}
        updatableRentalPlace={updatableRentalPlace}
        setUpdatableRentalPlace={setUpdatableRentalPlace}
        handleSave={handleSave}
        loading={loading}
        violations={violations}
      />
    </div>
  )
}
