import React from 'react'
import clsx from 'clsx'
import { Drawer, IconButton, Divider } from '@material-ui/core'
import { ChevronLeft } from '@material-ui/icons'
import { useStyles } from './SideBarStyles'
import { SideMenu } from 'components/menu/SideMenu'
import PropTypes from 'prop-types'

export const SideBar = ({ handlers, status, sideMenuItems }) => {
  const classes = useStyles()
  const { isExpanded } = status
  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(
          classes.drawerPaper,
          isExpanded || classes.drawerPaperClose
        ),
      }}
      open={isExpanded}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={handlers.handleExpand}>
          <ChevronLeft />
        </IconButton>
      </div>
      <Divider />
      <SideMenu items={sideMenuItems} />
    </Drawer>
  )
}

SideBar.propTypes = {
  handlers: PropTypes.shape({
    handleExpand: PropTypes.func.isRequired,
  }).isRequired,
  status: PropTypes.shape({
    isExpanded: PropTypes.bool,
  }).isRequired,
  sideMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      icon: PropTypes.elementType.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}
