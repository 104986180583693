import React from 'react'

import ModelModuleWithDateList from './ModelModuleWithDate'

const moduleName = 'Akumlator'
const moduleEndpoint = '/accumlators'
const moduleType = 'accumlator'

export default function AccumlatorList({
  entities,
  carIri,
  currentDistanceMeter,
}) {
  return (
    <ModelModuleWithDateList
      entities={entities}
      carIri={carIri}
      currentDistanceMeter={currentDistanceMeter}
      moduleEndpoint={moduleEndpoint}
      moduleName={moduleName}
      moduleType={moduleType}
    />
  )
}
