import React, { useState } from 'react'

import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'

import { useSelector, useDispatch } from 'react-redux'

import Table from 'components/table/TableList'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import { authHeader } from '_helpers/authHeader'
import { useFetch } from '_helpers/useFetch'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  gridParent: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '-48px',
    marginBottom: '20px',
  },
  formControl: {
    minWidth: 250,
  },
})

const typingTimeout = 1000
const endPoint = `${process.env.REACT_APP_API_ENTRYPOINT}/fitness_equipment_archiveds`
const categoriesEndPoint = `${process.env.REACT_APP_API_ENTRYPOINT}/fitness_categories`

const mainData = [
  {
    name: 'ID',
    prop: 'fitnessEquipment.id',
    link: (id) => `/equipment/archive/${id}`,
  },
  {
    name: 'NAZWA',
    prop: 'fitnessEquipment.fullName',
  },
  {
    name: 'RODZAJ',
    prop: 'fitnessEquipment.model.category.name',
  },
  {
    name: 'RENTOWNOŚĆ',
    prop: 'profitablityValue',
  },
]

export const ArchiveList = (link) => {
  const classes = useStyles()

  const handleChange = (value) => {
    setQueryParams({
      ...queryParams,
      'fitnessEquipment.model.category': value,
    })
  }

  const auth = useSelector((state) => state.auth)
  const [queryParams, setQueryParams] = useState({
    search: '',
    page: 1,
    'fitnessEquipment.model.category': null,
  })

  const [typingState, setTypingState] = useState({
    timeoutId: null,
  })
  const [searchText, setSearchText] = useState('')
  const dispatch = useDispatch()

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const args = [auth.data.token, dispatch]
  const res = useFetch(endPoint, options, args, queryParams)
  const categoriesRes = useFetch(categoriesEndPoint, options, args)

  const handleSearchChange = (e) => {
    const search = e.target.value
    setSearchText(search)

    clearTimeout(typingState.timeoutId)
    const timeoutId = setTimeout(() => {
      setTypingState({
        ...typingState,
        timeoutId: null,
      })
      setQueryParams({
        ...queryParams,
        search: search,
        page: 1,
      })
    }, typingTimeout)

    setTypingState({
      ...typingState,
      timeoutId: timeoutId,
    })
  }

  return (
    <React.Fragment>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel>Wybierz rodzaj</InputLabel>
        <Select
          value={queryParams.category}
          onChange={(e) => handleChange(e.target.value)}
          label="Wybierz rodzaj"
        >
          <MenuItem value={null}>Wszystkie rodzaje</MenuItem>
          {categoriesRes.state.data &&
            categoriesRes.state.data.content.map((category) => (
              <MenuItem key={category.id} value={category.id}>
                {category.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <div className={classes.gridParent}>
        <TextField
          label="Szukaj maszyny"
          value={searchText}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <Table
        data={res.state.data?.content}
        isLoading={res.state.isLoading}
        error={res.state.error}
        mainData={mainData}
        dataLength={res.state.data?.totalItems}
        pagination={true}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        {...link}
      />
    </React.Fragment>
  )
}
