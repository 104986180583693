import React from 'react'
import StringField from 'components/fields/StringField'
import GalleryField from 'components/fields/GalleryField'
import SelectEntityField from "../../../../../../components/fields/SelectEntityField";

const parameterizedEndpoint = '/fitness_segments'

export default function EquipmentDetailFieldFactory({
  fieldName,
  type,
  value,
  violations,
  updatableParentEntity,
  setUpdatableParentEntity,
  equipmentId,
}) {
  switch (type) {
    case 'model.brand.name':
      return (
        <StringField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
          readOnly={true}
        />
      )
    case 'model.size':
      return (
        <StringField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
          readOnly={true}
        />
      )
    case 'model.weight':
      return (
        <StringField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
          readOnly={true}
        />
      )

    case 'segment':
      return (
        <SelectEntityField
          fieldName={fieldName}
          selectableEntity={value}
          type={type}
          endPoint={parameterizedEndpoint}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )

    case 'model.description':
      return (
        <StringField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
          readOnly={true}
        />
      )
    case 'photos':
      return (
        <GalleryField
          fieldName={fieldName}
          photos={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )

    default:
      return 'Nic'
  }
}
