import React from 'react'
import { Link } from 'react-router-dom'
import { default as MUILink } from '@material-ui/core/Link'
import { default as MUIBreadcrumbs } from '@material-ui/core/Breadcrumbs'
import Typography from '@material-ui/core/Typography'

const Breadcrumbs = ({ paths, ...rest }) => {
  return (
    <MUIBreadcrumbs separator="›" aria-label="breadcrumbs" {...rest}>
      <MUILink color="textPrimary" component={Link} to="/">
        Home
      </MUILink>
      {paths &&
        paths.map((item, index, array) =>
          array.length === index + 1 ? (
            <Typography key={index}>{item.title}</Typography>
          ) : (
            <MUILink
              color="textPrimary"
              component={Link}
              to={item.path}
              key={index}
            >
              {item.title}
            </MUILink>
          )
        )}
    </MUIBreadcrumbs>
  )
}

export { Breadcrumbs }
