export const paymentType = [
  {
    title: 'Przelew',
    name: 'transfer',
  },
  {
    title: 'Gotówka',
    name: 'inCash',
  },
]

export const globalSalesValues = {
  vat: {
    '23%': {
      value: 0.23,
    },
    '8%': {
      value: 0.08,
    },
  },
  countType: ['szt', 'kg'],
}

export const invoicesType = ['Fakturę VAT', 'Fakturę Korygującą']

export const invoicesPrintType = [
  'Listę z podsumowaniem',
  'Każdą fakturę oddzielnie',
]

// jesli status ma tochange ustawione na true,
// to znaczy, że adminisstrato może ustawić
// ten status. W przeciwnym wypadku status
// ustawia się automatycznie, lub ustawia go
// klient w aplikacji mobilnej
export const hireStatus = [
  {
    type: 'hire',
    id: 'hire:Reserved',
    name: 'Rezerwacja',
    tochange: false,
  },
  {
    type: 'hire',
    id: 'hire:Accepted',
    name: 'Zarezerwowano',
    tochange: 'accept',
  },
  {
    type: 'hire',
    id: 'hire:Hire',
    name: 'Wynajem aktywny',
    tochange: 'start',
  },
  {
    type: 'hire',
    id: 'hire:Finished',
    name: 'Wynajem zakończony',
    tochange: 'finish',
  },
  {
    type: 'hire',
    id: 'hire:Cancelled',
    name: 'Wynajem anulowany',
    tochange: 'cancel',
  },
  {
    type: 'hireItem',
    id: 'hireItem:Reserved',
    name: 'Rezerwacja',
    tochange: false,
  },
  {
    type: 'hireItem',
    id: 'hireItem:Hire',
    name: 'Wynajem',
    tochange: 'start',
  },
  {
    type: 'hireItem',
    id: 'hireItem:Breakdown',
    name: 'Awaria',
    tochange: false,
  },
  {
    type: 'hireItem',
    id: 'hireItem:Return',
    name: 'Zwrot',
    tochange: true,
  },
  {
    type: 'hireItem',
    id: 'hireItem:Repair',
    name: 'Naprawa',
    tochange: true,
  },
  {
    type: 'hireItem',
    id: 'hireItem:Reactivate',
    name: 'Reaktywuj do najmu',
    tochange: 'reactivate',
  },
  {
    type: 'hireItem',
    id: 'hireItem:Finished',
    name: 'Zakończony wynajem',
    tochange: 'finish',
  },
  {
    type: 'hireItem',
    id: 'hireItem:Cancelled',
    name: 'Anulowanie',
    tochange: 'cancel',
  },
  {
    type: 'rentableItem',
    id: 'rentableItem:Archived',
    name: 'Archiwum',
    tochange: false,
  },
  {
    type: 'rentableItem',
    id: 'rentableItem:Active',
    name: 'Aktywny',
    tochange: false,
  },
  {
    type: 'rentableItem',
    id: 'rentableItem:Repair',
    name: 'Naprawa',
    tochange: false,
  },

]

export const invoiceStatusTrans = {
  notPaid: 'nie zapłacona',
  overdue: 'po terminie',
  paid: 'zapłacona',
}

export const templateDocumentContexMenuTrans = {
  '{{DEAL_DATE}}': 'Data transakcji',
  '{{COMPANY_DATA}}': 'Dane firmy',
  '{{CUSTOMER_DATA}}': 'Dane klienta',
  '{{RENTABLE_ITEMS_LIST_WITH_PAID_ADDITIONS}}':
    'Lista pozycji do wynajęcia z płatnymi dodatkami',
  '{{RENTABLE_ITEMS_LIST}}': 'Lista pozycji do wynajęcia',
  '{{CURRENT_DISCOUNT_RATE}}': 'Aktualna stopa procentowa',
  '{{END_RENT_TIME}}': 'Czas zakończenie najmu',
  '{{START_RENT_TIME}}': 'Czas rozpoczęcia najmu',
  '{{DEAL_COST}}': 'Koszt transakcji',
  '{{END_RENT_PLACE}}': 'Miejsce zakończenia najmu',
  '{{START_RENT_PLACE}}': 'Miejsce rozpoczęcia najmu',
  '{{END_RENT_DATE}}': 'Data zakończenia najmu',
  '{{START_RENT_DATE}}': 'Data rozpoczęcia najmu',
  '{{RENTABLE_ITEM_PAID_ADDITIONS}}': 'Lista płatnych dodatków do wynajęcia',
  '{{RENTABLE_ITEM_IDENTIFIER}}': 'Identyfikator pozycji do wynajęcia',
  '{{RENTABLE_ITEM_FULL_NAME}}': 'Nazwa pozycji do wynajęcia',
  '{{BREAKDOWN_DATE}}': 'Data zgłoszenia',
  '{{BREAKDOWN_REPORT_DATE}}': 'Data raportu zgłoszenia',
  '{{BREAKDOWN_TIME}}': 'Czas zgłoszenia',
  '{{BREAKDOWN_REPORT_TIME}}': 'Czas raportu zgłoszenia',
  '{{BREAKDOWN_DESCRIPTION}}': 'Opis zgłoszenia',
  '{{RENTAL_PLACE}}': 'Wypożyczalnia',
  '{{CAR_NAME}}': 'Nazwa samochodu',
  '{{CAR_REGISTRATION_NUMBER}}': 'Numer rejestracyjny samochodu',
  '{{RETURN_START_DATE}}': 'Data rozpoczęcia zwrotu',
  '{{RETURN_END_DATE}}': 'Data zakończenia zwrotu',
  '{{RETURN_COST}}': 'Koszt zwrotu',
  '{{REPAIR_START_DATE}}': 'Data rozpoczęcia naprawy',
  '{{REPAIR_END_DATE}}': 'Data zakończenia naprawy',
  '{{REPAIR_COST}}': 'Koszt naprawy',
  '{{REPIAR_NAME}}': 'Nazwa naprawy',
}
