import React from 'react'
import { authHeader } from '_helpers/authHeader'
import { Title } from 'components/Title'
import Alert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import Template from './Template'
import TemplateContent from './TemplateContent'
import api from 'api'
import { hireStatus } from 'dataconfig'
import { useHistory } from 'react-router-dom'

export const TemplateContainerNew = () => {
  let history = useHistory()

  const [variableKeys, setVariableKeys] = React.useState([])
  // eslint-disable-next-line
  const [startData, setStartData] = React.useState({
    suggestedSegments: [],
    suggestedRentalPlaces: [],
  })
  const [data, setData] = React.useState({
    kind: hireStatus[0].id,
    name: 'Nazwa dokumentu',
    title: 'Tytuł dokumentu',
    intro:
      'Zawarta dnia {{DEAL_DATE}} w {{START_RENT_PLACE}} pomiędzy: {{COMPANY_DATA}} zwanym dalej Wynajmującym, a {{CUSTOMER_DATA}} zwanym dalej Najemcą.',
    paragraphs: [
      {
        title: 'Przykładowy paragraf',
        text: 'Treść przykładowego paragrafu',
      },
    ],
    firstSignatureTitle: 'Podpis Wynajmującego',
    secondSignatureTitle: 'Podpis Najemcy',
    suggestedSegments: [],
    suggestedRentalPlaces: [],
  })

  const [open, setOpen] = React.useState(true)
  const [newItem, setNewItem] = React.useState({})

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const handleChengeTitle = (name) => {
    setData((prev) => ({
      ...prev,
      name: name,
    }))
  }

  const handleChangeContent = (value) => {
    setData((prev) => ({
      ...prev,
      paragraphs: value,
    }))
  }

  const handleChangeIntro = (intro) => {
    setData((prev) => ({
      ...prev,
      intro: intro,
    }))
  }

  const handleChangeInfo = (value) => {
    let data
    if ('suggestedSegments' in value) {
      data = {
        suggestedSegments: value.suggestedSegments,
      }
    }
    if ('suggestedRentalPlaces' in value) {
      data = {
        suggestedRentalPlaces: value.suggestedRentalPlaces,
      }
    }
    if ('kind' in value) {
      data = {
        kind: value.kind,
      }
    }
    setData((prev) => ({
      ...prev,
      ...data,
    }))
  }

  React.useEffect(() => {
    const abortController = new AbortController()
    const options = {
      method: 'GET',
      headers: {
        accept: 'application/json',
        ...authHeader(),
      },
    }
    const fetchData = async () => {
      fetch(
        `${process.env.REACT_APP_API_ENTRYPOINT}${api.documentTemplate}/variable_keys?kind=${data.kind}`,
        {
          ...options,
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setVariableKeys(res.content)
        })
    }

    if (data.kind.length > 0) {
      fetchData()
    } else {
      setVariableKeys([])
    }

    return () => {
      abortController.abort()
    }
    // eslint-disable-next-line
  }, [data.kind])

  React.useEffect(() => {
    if (newItem.data !== undefined) {
      history.push(`/documents/templates/${newItem.data.id}`)
    }
    // eslint-disable-next-line
  }, [newItem])

  return (
    <>
      {data && (
        <>
          <Template
            template={data}
            setTemplate={setNewItem}
            templateId={false}
            changeInfo={handleChangeInfo}
            startData={startData}
            header={[
              {
                name: 'STATUS',
                prop: 'kind',
              },
              {
                name: 'Sugerowany segment cennika',
                prop: 'suggestedSegments',
              },
              {
                name: 'Sugerowana wypożyczalnia',
                prop: 'suggestedRentalPlaces',
              },
            ]}
          />
          <Title>Treść szablonu</Title>

          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="info" variant="filled">
              Kliknij prawym przyciskiem myszy na paragraf by wstawić dane
            </Alert>
          </Snackbar>
          <TemplateContent
            template={data}
            defVariableKeys={variableKeys}
            changeContent={handleChangeContent}
            changeTitle={handleChengeTitle}
            changeIntro={handleChangeIntro}
          />
        </>
      )}
    </>
  )
}
