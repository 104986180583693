import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom'

import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DescriptionIcon from '@material-ui/icons/Description'
import { invoicesType as invoices } from 'dataconfig'

import { blue } from '@material-ui/core/colors'
import { Link } from 'react-router-dom'

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  btn: {
    width: '100%',
  },
})

function AddDialog(props) {
  const classes = useStyles()
  const { onClose, selectedValue, open } = props

  const handleClose = () => {
    onClose(selectedValue)
  }

  const handleListItemClick = (value) => {
    onClose(value)
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Wystaw </DialogTitle>
      <List>
        {invoices.map((invoice) => (
          <ListItem
            button
            onClick={() => handleListItemClick(invoice)}
            key={invoice}
            selected={selectedValue === invoice ? true : false}
          >
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <DescriptionIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={invoice} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  )
}

AddDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
}

export default function AddDialogBtn({ label, invoiceID = false }) {
  const classes = useStyles()

  const [open, setOpen] = React.useState(false)
  const [selectedValue, setSelectedValue] = React.useState('')

  const history = useHistory()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = (value) => {
    setOpen(false)
    setSelectedValue(value)
    if (value === invoices[0]) {
      history.push(`/invoices/list/new/connected/${invoiceID}`)
    } else if (value === invoices[1]) {
      history.push(`/invoices/list/new/correction/${invoiceID}`)
    }
  }

  return (
    <div>
      {invoiceID ? (
        <>
          <Button
            className={classes.btn}
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
          >
            {label} {selectedValue}
          </Button>

          <AddDialog
            selectedValue={selectedValue}
            open={open}
            onClose={handleClose}
          />
        </>
      ) : (
        <Button
          className={classes.btn}
          variant="contained"
          color="primary"
          component={Link}
          to={'/invoices/list/new'}
        >
          {label}
        </Button>
      )}
    </div>
  )
}
