import React from 'react'
import Typography from '@material-ui/core/Typography'
import { useSelector } from 'react-redux'
import { authHeader } from '_helpers/authHeader'
import { useFetch } from '_helpers/useFetch'
import api from 'api'
import PropTypes from 'prop-types'

const SellerComponent = ({ setSeller }) => {
  const auth = useSelector((state) => state.auth)

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const args = [auth.data.token]

  const res = useFetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}${api.companyConfig}`,
    options,
    args
  )

  React.useEffect(() => {
    setSeller(res.state.data)
    // eslint-disable-next-line
  }, [res.state.data])

  return (
    <>
      <Typography variant="h6" color="textPrimary" gutterBottom>
        Sprzedawca
      </Typography>
      {res.state.data && (
        <>
          <Typography variant="subtitle1" color="textSecondary">
            {res.state.data.companyName}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {res.state.data.mainAddress && res.state.data.mainAddress.street}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {res.state.data.mainAddress &&
              `${res.state.data.mainAddress.postCode} ${res.state.data.mainAddress.city}`}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            NIP: {res.state.data.nip}
          </Typography>
        </>
      )}
    </>
  )
}

SellerComponent.propTypes = {
  setSeller: PropTypes.func.isRequired,
}

export default SellerComponent
