export const leaseConstants = {
  DATA_REQUEST: 'LEASE_REQUEST',
  DATA_SUCCESS: 'LEASE_SUCCESS',
  DATA_FAILURE: 'LEASE_FAILURE',
  DATA_FETCH_SAGA: 'LEASE_DATA_FETCH',
  DATA_REMOVE_SAGA: 'LEASE_DATA_REMOVE',
  DATA_UPDATE_SAGA: 'LEASE_DATA_UPDATE',
  DATA_UPDATE: 'LEASE_UPDATE',
  DATA_ERASE: 'LEASE_ERASE',
}
