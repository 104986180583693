import React from 'react'
import NumberField from './NumberField'
import {
  getCurrencyNumberFromApi,
  setCurrencyToApi,
  getFixedValue,
} from '_helpers/currency'

export default function MoneyField({
  fieldName,
  type,
  value,
  violations,
  updatableParentEntity,
  setUpdatableParentEntity,
  readOnly = false,
}) {
  return (
    <NumberField
      fieldName={fieldName}
      value={getCurrencyNumberFromApi(value)}
      type={type}
      violations={violations}
      updatableParentEntity={updatableParentEntity}
      setUpdatableParentEntity={setUpdatableParentEntity}
      renderValue={(value) => (value ? getFixedValue(value) : '')}
      uploadValue={(value) => setCurrencyToApi(value)}
      inputProps={{ step: '0.01' }}
      readOnly={readOnly}
    />
  )
}
