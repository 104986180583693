import React from 'react'
import Moment from 'react-moment'
import moment from 'moment'

import MoneyField from 'components/fields/MoneyField'
import CheckboxField from 'components/fields/CheckboxField'
import DatePickerField from 'components/fields/DatePickerField'
import StringField from 'components/fields/StringField'

import Warning from '../../../../components/Warning'
import CarPartList from '../CarPartList'

const moduleName = 'Planowane serwisy'
const moduleEndpoint = '/additional_services'

export const AdditionalServiceValue = ({ item }) => {
  return (
    <>
      <Moment format="DD.MM.YYYY">{item.deadlineDate}</Moment>
      {` | ${item.name} `}
      {item.warnings.length ? (
        <Warning messages={item.warnings.map(warning => warning.message)} />
      ) : (
        <></>
      )}
    </>
  )
}

const compareFunction = (a, b) =>
  new Date(b.deadlineDate).getTime() - new Date(a.deadlineDate).getTime()

const createSpecificColumns = createSetRow => [
  {
    id: 'startDate',
    label: 'Data rozpoczęcia',
    minWidth: 100,
    renderValue: (item, violations) => (
      <DatePickerField
        type={'startDate'}
        value={item.startDate}
        violations={violations ? violations : []}
        updatableParentEntity={item}
        setUpdatableParentEntity={createSetRow(item.id)}
      />
    ),
  },
  {
    id: 'name',
    label: 'Nazwa',
    minWidth: 100,
    align: 'center',
    renderValue: (item, violations) => (
      <StringField
        type={'name'}
        value={item.name}
        violations={violations ? violations : []}
        updatableParentEntity={item}
        setUpdatableParentEntity={createSetRow(item.id)}
        center
      />
    ),
  },
  {
    id: 'cost',
    label: 'Cena',
    minWidth: 100,
    align: 'center',
    renderValue: (item, violations) => (
      <MoneyField
        type={'cost'}
        value={item.cost}
        violations={violations ? violations : []}
        updatableParentEntity={item}
        setUpdatableParentEntity={createSetRow(item.id)}
      />
    ),
  },
  {
    id: 'includeCost',
    label: 'Wliczaj',
    minWidth: 100,
    renderValue: (item, violations) => (
      <CheckboxField
        type={'includeCost'}
        value={item.includeCost}
        violations={violations ? violations : []}
        updatableParentEntity={item}
        setUpdatableParentEntity={createSetRow(item.id)}
      />
    ),
  },
]

export default function AdditionalServiceList({ entities, carIri }) {
  const createPrototype = () => {
    return {
      id: 0,
      car: carIri,
      startDate: moment().format(),
      name: '',
      cost: 0,
      includeCost: false,
    }
  }

  return (
    <CarPartList
      entities={entities}
      moduleEndpoint={moduleEndpoint}
      moduleName={moduleName}
      createPrototype={createPrototype}
      compareFunction={compareFunction}
      createSpecificColumns={createSpecificColumns}
    />
  )
}
