import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Moment from 'react-moment'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { getPLNFromApi } from '_helpers/currency'

const headCells1 = [
  {
    label: 'Numer',
  },
  {
    label: 'Nazwa klienta',
  },
  {
    label: 'Data wystawienia',
  },
  {
    label: ' Wartość netto',
  },
]

const headCells2 = [
  {
    label: 'Opis',
  },
]

function DenseTable(props) {
  const { rows, headCells } = props

  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {headCells.map((cell, i) => (
              <TableCell key={`cell-${i}`}>{cell.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <TableRow key={`${row.id}-${i}`}>
              {row?.number && (
                <TableCell component="th" scope="row">
                  {row?.number}
                </TableCell>
              )}
              {row?.customer && (
                <TableCell>
                  {row?.customer?.personalDetails?.companyName}
                </TableCell>
              )}
              {row?.billingDate && (
                <TableCell>
                  <Moment format="DD.MM.YYYY">{row?.billingDate}</Moment>
                </TableCell>
              )}
              {row?.costNetto && (
                <TableCell>{getPLNFromApi(row?.costNetto)}</TableCell>
              )}
              {row?.message && <TableCell>{row?.message}</TableCell>}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
function TabPanel(props) {
  const { value, index, rows, headCells } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <DenseTable rows={rows} headCells={headCells}></DenseTable>
      )}
    </Typography>
  )
}

TabPanel.propTypes = {
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
})

export default function TabsContainer({ data }) {
  const rows1 = data?.connectedInvoices
  const rows2 = data?.warnings

  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="simple tabs example"
        indicatorColor="primary"
        textColor="primary"
      >
        {rows1 && <Tab label="Faktury powiązane" {...a11yProps(0)} />}
        {rows2 && <Tab label="Uwagi" {...a11yProps(1)} />}
      </Tabs>

      {rows1 && (
        <TabPanel
          value={value}
          index={0}
          rows={rows1}
          headCells={headCells1}
        ></TabPanel>
      )}
      {rows2 && (
        <TabPanel
          value={value}
          index={1}
          rows={rows2}
          headCells={headCells2}
        ></TabPanel>
      )}
    </div>
  )
}
