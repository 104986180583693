import { put, call } from 'redux-saga/effects'
import { userConstants } from '_constants'
import { userService } from '_services'
import { handleAuthError } from '_helpers/handleAuthError'
import { processError } from '_helpers/processError'
import * as schema from '_schema/user'

function* fetchProfile() {
  yield put({ type: userConstants.PROFILE_REQUEST })

  const { response, error } = yield call(userService.profile)

  if (response) {
    yield put({
      type: userConstants.PROFILE_SUCCESS,
      payload: {
        ...['id', 'roles', ...Object.keys(schema.profile.properties)].reduce(
          (obj, key) => Object.assign(obj, { [key]: response[key] }),
          {}
        ),
      },
    })

    return
  }

  yield call(
    handleAuthError.saga,
    userConstants.PROFILE_FAILURE,
    processError(error).response
  )

  return
}

function* eraseProfile() {
  yield put({ type: userConstants.PROFILE_ERASE })

  return
}

const updateProfile = values => ({
  type: userConstants.PROFILE_UPDATE,
  payload: values,
})

export const userActions = {
  fetchProfile,
  eraseProfile,
  updateProfile,
}
