import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  card: {
    width: 412,
  },
  flex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: theme.palette.background.default,
  },
  pos: {
    marginBottom: 8,
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    marginLeft: 8,
    marginBottom: 8,
  },
}))
