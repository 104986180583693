import React from 'react'

import { FitnessServiceValue } from '../FitnessService'
import { RepairValue } from '../RepairList'

export default function RentableItemValueFactory({ type, item }) {
  switch (type) {
    case 'fitnessService':
      return <FitnessServiceValue item={item} />
    case 'repair':
      return <RepairValue item={item} />
    default:
      return 'Nic'
  }
}
