import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import WarningIcon from '@material-ui/icons/Warning'

const useStyles = makeStyles(theme => ({
  icon: {
    position: 'relative',
    left: '10px',
    top: '4px',
  },
}))

export default function Warning({ messages }) {
  const classes = useStyles()

  return (
    <Tooltip
      title={messages.map((message, i) => (
        <div key={i}>{message}</div>
      ))}
      className={classes.icon}
    >
      <WarningIcon color="error" fontSize="small" />
    </Tooltip>
  )
}
