import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Moment from 'react-moment'

import MoneyField from 'components/fields/MoneyField'
import DatePickerField from 'components/fields/DatePickerField'
import StringField from 'components/fields/StringField'

import CarPartList from '../CarPartList'

const moduleName = 'Naprawy'
const moduleEndpoint = '/car_repairs'

export const RepairValue = ({ item }) => {
  return (
    <>
      <Moment format="DD.MM.YYYY">{item?.hireItem?.hire?.startDate}</Moment>
      {' - '}
      <Moment format="DD.MM.YYYY">{item?.hireItem?.hire?.endDate}</Moment>
      {` | ${item?.name} `}
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
}))

const compareFunction = (a, b) =>
  new Date(b.endDate).getTime() - new Date(a.endDate).getTime()

export default function RepairList({ entities }) {
  const classes = useStyles()
  const createSpecificColumns = (createSetRow) => [
    {
      id: 'hire',
      label: 'Wynajem',
      minWidth: 100,
      renderValue: (item, violations) => (
        <Link
          to={`/leases/leases/${item.hireItem.hire.id}`}
          className={classes.link}
        >
          {item.hireItem.hire.id}
        </Link>
      ),
    },
    {
      id: 'customerName',
      label: 'Klient',
      minWidth: 100,
      renderValue: (item, violations) => (
        <StringField
          value={item?.hireItem?.hire?.customer?.user?.name}
          readOnly
        />
      ),
    },
    {
      id: 'car',
      label: 'Auto',
      minWidth: 100,
      renderValue: (item, violations) => (
        <StringField value={item?.car?.fullName} readOnly />
      ),
    },
    {
      id: 'startDate',
      label: 'Data rozpoczęcia',
      minWidth: 200,
      renderValue: (item, violations) => (
        <DatePickerField value={item.startDate} readOnly />
      ),
    },
    {
      id: 'endDate',
      label: 'Data zakończenia',
      minWidth: 200,
      renderValue: (item, violations) => (
        <DatePickerField
          type={'endDate'}
          value={item.endDate}
          updatableParentEntity={item}
          violations={violations ? violations : []}
          setUpdatableParentEntity={createSetRow(item.id)}
        />
      ),
    },
    {
      id: 'sourceRentalPlace',
      label: 'Wypożyczalnia startowa',
      minWidth: 100,
      renderValue: (item, violations) => (
        <StringField
          value={item?.hireItem?.hire?.sourceRentalPlace?.name}
          readOnly
        />
      ),
    },
    {
      id: 'targetRentalPlace',
      label: 'Wypożyczalnia końcowa',
      minWidth: 100,
      renderValue: (item, violations) => (
        <StringField
          value={item?.hireItem?.hire?.targetRentalPlace?.name}
          readOnly
        />
      ),
    },
    {
      id: 'cost',
      label: 'Wartość szkód',
      minWidth: 100,
      renderValue: (item, violations) => (
        <MoneyField
          type={'cost'}
          value={item.cost}
          updatableParentEntity={item}
          violations={violations ? violations : []}
          setUpdatableParentEntity={createSetRow(item.id)}
        />
      ),
    },
  ]

  return (
    <CarPartList
      entities={entities}
      moduleName={moduleName}
      moduleEndpoint={moduleEndpoint}
      compareFunction={compareFunction}
      createSpecificColumns={createSpecificColumns}
      addable={false}
    />
  )
}
