import React, { useState, Fragment, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Select from '@material-ui/core/Select'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { authHeader } from '_helpers/authHeader'
import api from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { requestActions } from '_actions'
import { notification } from '_helpers/notification'
import Moment from 'react-moment'
import { leaseConstants } from '_constants'
import { getPLNFromApi, setCurrencyToApi } from '_helpers/currency'
import NewInvoice from '../modals/NewInvoice'

const useStyles = makeStyles({
  gridParent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: '42px',
  },
  input: {
    marginBottom: '20px',
  },
  icon: {
    float: 'right',
    fontWeight: 'strong',
  },
})

export default function SelectEntityField({
  fieldName,
  selectableEntity,
  listStatus,
  leaseId,
}) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const lease = useSelector((state) => state.lease)

  const [open, setOpen] = useState(false)
  const [step1, setStep1] = useState(false)
  const [step2, setStep2] = useState(false)
  const [optionReturn, setOptionReturn] = useState(false)
  const [fetchedEntities] = useState(listStatus)
  const [entity, setEntity] = useState(selectableEntity)
  const [othersDocuments, setOthersDocuments] = useState([])
  const [suggestedDocuments, setSuggestedDocuments] = useState([])
  const [toPrint, setToPrint] = useState({})
  const [print, setPrint] = useState(false)
  const [pdf, setPdf] = useState(null)
  const [hire, setHire] = useState(false)
  const [changeStatus, setChangeStatus] = useState(false)
  const [returnData, setReturnData] = useState({
    endDate: new Date(),
    hireItem: '',
    car: '',
    startDate: new Date(),
    cost: 0,
    includeCost: true,
  })
  const [refactor, setRefactor] = useState(false)
  const [openNewInvoice, setOpenNewInvoice] = useState(false)
  const [refactorId, setRefactorId] = useState(0)
  const [refactorCost, setRefactorCost] = useState(0)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleAccept = () => {
    setOpen(false)

    if (
      othersDocuments.length > 0 ||
      suggestedDocuments.length > 0 ||
      entity.tochange
    ) {
      setStep1(true)
    }
  }

  const handleCloseStep1 = () => {
    setStep1(false)
  }

  const handleOpenStep2 = () => {
    setStep2(true)
  }

  const handleCloseStep2 = () => {
    setStep2(false)
  }

  const handleCloseOptionReturn = () => {
    setOptionReturn(true)
  }

  const handleChange = (event) => {
    const select = event.target
    const newId = select.value
    setEntity(fetchedEntities.find((el) => el.id === newId))
  }

  React.useEffect(() => {
    const abort = new AbortController()
    const fetchData = async () => {
      await fetch(
        `${process.env.REACT_APP_API_ENTRYPOINT}${api.documentTemplate}/suggested?kind=${entity.id}`,
        {
          method: 'GET',
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
            ...authHeader(),
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setOthersDocuments(res.others.content)
          setSuggestedDocuments(res.suggested.content)
        })
    }
    if (entity.id) {
      fetchData()
    }
    return () => {
      return abort.abort()
    }
    // eslint-disable-next-line
  }, [entity])

  const handleChangeDocument = (e) => {
    setToPrint((prevState) => {
      if (!prevState[e.target.value]) {
        return {
          ...prevState,
          [e.target.value]: {
            id: e.target.value,
            date: new Date(),
          },
        }
      } else {
        const data = prevState
        delete data[e.target.value]
        return {
          ...data,
        }
      }
    })
  }

  const handleChangeDate = (date, id) => {
    setToPrint((prevState) => ({
      ...prevState,
      [id]: {
        id: id,
        date: date,
      },
    }))
  }

  const handleDateChangeRepair = (date, name) => {
    setReturnData((prevState) => ({
      ...prevState,
      [name]: date,
    }))
  }

  // print documents
  const handlePrint = () => {
    setPrint(true)
  }

  React.useEffect(() => {
    const abort = new AbortController()
    const fetchData = async (d) => {
      let url
      let data
      switch (entity?.type) {
        case 'hire':
          url = `${process.env.REACT_APP_API_ENTRYPOINT}${api.hireDocument}`
          data = {
            hire: `${api.iri.hires}${leaseId}`,
            template: `${api.iri.documentTemplate}${d.id}`,
            dealDate: d.date,
          }
          break
        case 'hireItem':
          url = `${process.env.REACT_APP_API_ENTRYPOINT}${api.hireItemDocument}`
          data = {
            hireItem: `${api.iri.hireItems}${leaseId}`,
            template: `${api.iri.documentTemplate}${d.id}`,
            dealDate: d.date,
          }
          break
        default:
          url = ''
          data = {}
          break
      }
      await fetch(url, {
        method: 'POST',
        redirect: 'follow',
        body: JSON.stringify(data),
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
          ...authHeader(),
        },
      })
        .then((res) => res.json())
        .then((res) => {
          setPdf(res)
        })
    }
    if (Object.keys(toPrint).length > 0 && print) {
      Object.keys(toPrint).forEach((el) => {
        fetchData(toPrint[el])
      })
      setPrint(false)
    } else {
      setPrint(false)
    }
    return () => {
      return abort.abort()
    }
    // eslint-disable-next-line
  }, [toPrint, print, entity])

  React.useEffect(() => {
    const abort = new AbortController()
    const fetchData = async (slug) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENTRYPOINT}${slug}`,
        {
          method: 'GET',
          headers: {
            accept: 'application/pdf',
            ...authHeader(),
          },
        }
      )
      const blob = await response.blob()

      const file = new Blob([blob], { type: 'application/pdf' })

      const fileURL = URL.createObjectURL(file)
      window.open(fileURL)
    }
    if (pdf) {
      fetchData(`${api.pdfs}/${pdf.id}/print`)
    }
    return () => {
      return abort.abort()
    }
    // eslint-disable-next-line
  }, [pdf])

  // start Hire
  const startHire = () => {
    setHire(true)
  }

  useEffect(() => {
    const abort = new AbortController()
    const endpoint = entity.type === 'hire' ? api.hires : api.hireItems
    const url = `${process.env.REACT_APP_API_ENTRYPOINT}${endpoint}/${leaseId}/start`
    const fetchData = async () => {
      dispatch(requestActions.start(url))
      await fetch(url, {
        method: 'PUT',
        body: JSON.stringify({}),
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
          ...authHeader(),
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.id !== undefined && res.id === leaseId) {
            dispatch(requestActions.success())
            dispatch({ type: leaseConstants.DATA_FETCH_SAGA, id: lease.id })
            notification(
              'success',
              `Status zmieniony - ${entity.name}`,
              'Status został zmieniony'
            )
            setStep1(false)
            setStep2(false)
          } else {
            notification(
              'error',
              res.violations === undefined || res.violations.length === 0
                ? 'Bład wynajmu'
                : res.detail,
              res.title
            )
          }
        })
        .catch((error) => {
          notification(
            'error',
            error.response.violations !== undefined &&
              error.response.violations.length
              ? 'Bład wynajmu'
              : error.response.detail,
            error.response.title
          )
        })
    }

    if (hire) {
      fetchData()
      setHire(false)
    }

    return () => {
      setHire(false)
      return abort.abort()
    }
    // eslint-disable-next-line
  }, [hire, lease.id])

  useEffect(() => {
    const abort = new AbortController()
    const changeStatusFetch = async () => {
      const endpoint = entity.type === 'hire' ? api.hires : api.hireItems
      const url = `${process.env.REACT_APP_API_ENTRYPOINT}${endpoint}/${leaseId}/${entity.tochange}`
      dispatch(requestActions.start(url))
      await fetch(url, {
        method: 'PUT',
        body: JSON.stringify({}),
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
          ...authHeader(),
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.id !== undefined && res.id === leaseId) {
            dispatch(requestActions.success())
            dispatch({ type: leaseConstants.DATA_FETCH_SAGA, id: lease.id })
            notification(
              'success',
              `Status zmieniony - ${entity.name}`,
              'Status został zmieniony'
            )
            setStep1(false)
            setStep2(false)
          } else {
            notification(
              'error',
              res.violations === undefined || res.violations.length === 0
                ? 'Bład zmiany statusu'
                : res.detail,
              res.title
            )
          }
        })
        .catch((error) => {
          notification(
            'error',
            error?.response?.violations !== undefined &&
              error?.response?.violations?.length
              ? 'Bład zmiany statusu'
              : error?.response?.detail,
            error?.response?.title
          )
        })
    }
    if (changeStatus) {
      changeStatusFetch()
      setChangeStatus(false)
    }
    return () => {
      setChangeStatus(false)
      return abort.abort()
    }
    // eslint-disable-next-line
  }, [changeStatus, entity])

  useEffect(() => {
    const abort = new AbortController()
    const changeStatusReturn = async () => {
      const endpoint =
        entity.id === 'hireItem:Return' ? api.returns : api.repairs
      const url = `${process.env.REACT_APP_API_ENTRYPOINT}${endpoint}`
      const hireItem = lease.hiredItems.find((el) => el.id === leaseId)
      const data = {
        ...returnData,
        cost: setCurrencyToApi(returnData.cost),
        hireItem: `${api.iri.hireItems}${hireItem.id}`,
        car: `${api.iri.equipment}${hireItem.car.id}`,
      }
      await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
          ...authHeader(),
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.hireItem !== undefined && res?.hireItem?.id === leaseId) {
            dispatch(requestActions.success())
            dispatch({ type: leaseConstants.DATA_FETCH_SAGA, id: lease.id })
            notification(
              'success',
              `Status zmieniony - ${entity.name}`,
              'Status został zmieniony'
            )
            setStep1(false)
            setStep2(false)
            if (refactor) {
              setRefactorId(res.id)
              setRefactorCost(res.cost)
              setOpenNewInvoice(true)
            }
          } else {
            notification(
              'error',
              res.violations === undefined || res.violations.length === 0
                ? 'Bład zmiany statusu'
                : res.detail,
              res.title
            )
          }
        })
        .catch((error) => {
          notification(
            'error',
            error?.response?.violations !== undefined &&
              error?.response?.violations?.length
              ? 'Bład zmiany statusu'
              : error?.response?.detail,
            error?.response?.title
          )
        })
    }

    if (optionReturn) {
      changeStatusReturn()
      setOptionReturn(false)
    }
    return () => {
      setOptionReturn(false)
      return abort.abort()
    }
    // eslint-disable-next-line
  }, [optionReturn, returnData, refactor])

  const handleCloseNewInvoice = () => {
    setOpenNewInvoice(false)
  }

  const SpecificActions = () => {
    if (entity.id === 'hire:Hire' || entity.id === 'hireItem:Hire') {
      return (
        <>
          <Button onClick={handleOpenStep2} color="primary">
            Dalej
          </Button>
        </>
      )
    } else if (
      entity.id === 'hireItem:Return' ||
      entity.id === 'hireItem:Repair'
    ) {
      return (
        <>
          <div className={classes.gridParent}>
            <Button
              onClick={handleCloseOptionReturn}
              color="primary"
              variant="contained"
            >
              Zapisz
            </Button>
            <FormControlLabel
              control={
                <Checkbox
                  checked={refactor}
                  value="checkedB"
                  color="primary"
                  onChange={(e) => setRefactor(e.target.checked)}
                />
              }
              label="Zapisz i refakturuj najemcy"
            />
          </div>
        </>
      )
    } else if (entity.tochange && entity.tochange !== true) {
      return (
        <>
          <Button onClick={() => setChangeStatus(true)} color="primary">
            Zapisz
          </Button>
        </>
      )
    }
    return null
  }

  const StartDate = () => {
    if (lease.startDate) {
      return (
        <>
          od: <Moment format="DD.MM.YYYY">{lease.startDate}</Moment>
        </>
      )
    }
    return null
  }

  const disableSelect =
    entity.id !== 'hire:Cancelled' &&
    entity.id !== 'hire:Finished' &&
    entity.id !== 'hireItem:Cancelled' &&
    entity.id !== 'hireItem:Finished'
  return (
    <Fragment>
      <div>
        <b>{fieldName}:</b>
      </div>
      <div>
        <div onClick={disableSelect && handleOpen}>
          {selectableEntity.name}
          <span>
            {disableSelect && <ExpandMoreIcon className={classes.icon} />}
          </span>
        </div>
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{fieldName}</DialogTitle>
          <DialogContent>
            <Select native value={entity.id} fullWidth onChange={handleChange}>
              {fetchedEntities.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                )
              })}
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAccept} color="primary">
              Akceptuj
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={step1}
          onClose={handleCloseStep1}
          fullWidth
          aria-labelledby="form-dialog-title-2"
        >
          <DialogTitle id="form-dialog-title-2">
            {suggestedDocuments.length > 0 || othersDocuments.length > 0 ? (
              <>{entity.name} - Wydrukuj i podpisz dokumenty</>
            ) : (
              <>{entity.name} - Czy zmienić status?</>
            )}
          </DialogTitle>
          <DialogContent>
            {(entity.id === 'hireItem:Return' ||
              entity.id === 'hireItem:Repair') && (
              <>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <div>
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      label="od"
                      format="DD.MM.YYYY"
                      value={returnData.startDate}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      onChange={(date) =>
                        handleDateChangeRepair(date, 'startDate')
                      }
                    />
                  </div>
                  <div>
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      label="do"
                      format="DD.MM.YYYY"
                      value={returnData.endDate}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      onChange={(date) =>
                        handleDateChangeRepair(date, 'endDate')
                      }
                    />
                  </div>
                </MuiPickersUtilsProvider>

                <div>
                  <TextField
                    id="standard-basic"
                    label="koszt"
                    value={returnData.cost}
                    type="number"
                    onChange={(e) =>
                      setReturnData((prevState) => ({
                        ...prevState,
                        cost: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className={classes.input}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={returnData.includeCost}
                        value="checkedB"
                        color="primary"
                        onChange={(e) =>
                          setReturnData((prev) => ({
                            ...prev,
                            includeCost: e.target.checked,
                          }))
                        }
                      />
                    }
                    label="wliczaj koszt"
                  />
                </div>
              </>
            )}
            {suggestedDocuments.length > 0 && (
              <>
                <Typography color="textSecondary" variant="body2">
                  Sugerowane dokumenty
                </Typography>
                <Divider />
              </>
            )}
            {suggestedDocuments.map((el) => {
              return (
                <div key={el.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={el.id}
                        color="primary"
                        onChange={handleChangeDocument}
                        checked={toPrint[el.id] ? true : false}
                      />
                    }
                    label={el.name}
                  />
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      label="wybierz datę"
                      format="DD.MM.YYYY"
                      value={toPrint[el.id] ? toPrint[el.id].date : new Date()}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      onChange={(date) => handleChangeDate(date, el.id)}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              )
            })}
            {othersDocuments.length > 0 && (
              <>
                <Typography color="textSecondary" variant="body2">
                  Inne dokumenty
                </Typography>
                <Divider />
              </>
            )}
            {othersDocuments.map((el) => {
              return (
                <div key={el.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={el.id}
                        color="primary"
                        onChange={handleChangeDocument}
                        checked={toPrint[el.id] ? true : false}
                      />
                    }
                    label={el.name}
                  />
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      label="wybierz datę"
                      format="DD.MM.YYYY"
                      value={toPrint[el.id] ? toPrint[el.id].date : new Date()}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      onChange={(date) => handleChangeDate(date, el.id)}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              )
            })}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseStep1} color="primary">
              Anuluj
            </Button>
            {(othersDocuments.length > 0 || suggestedDocuments.length > 0) && (
              <Button onClick={handlePrint} color="primary">
                Drukuj zaznaczone
              </Button>
            )}
            {entity?.tochange && <SpecificActions />}
          </DialogActions>
        </Dialog>

        <Dialog
          open={step2}
          onClose={handleCloseStep2}
          fullWidth
          aria-labelledby="form-dialog-title-3"
        >
          <DialogTitle id="form-dialog-title-3">
            Sprawdź podpisane dokumenty i zmień status
          </DialogTitle>
          <DialogContent>
            <Typography color="textSecondary" variant="body1">
              {entity.name} <StartDate />
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseStep2} color="primary">
              Anuluj
            </Button>

            <Button onClick={startHire} color="primary">
              Rozpocznij najem
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {refactor && (
        <>
          <NewInvoice
            name={entity.name}
            open={openNewInvoice}
            close={handleCloseNewInvoice}
            cost={getPLNFromApi(refactorCost)}
            type={entity.id === 'hireItem:Return' ? '/return' : '/repair'}
            iri={`${
              entity.id === 'hireItem:Return' ? api.returns : api.repairs
            }/${refactorId}`}
          />
        </>
      )}
    </Fragment>
  )
}
