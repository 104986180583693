import { authHeader } from './authHeader'
import { handleResponse as defaultHandleResponse } from './handleResponse'

export const fetchData = (
  url,
  method = 'GET',
  request = {},
  handleResponse = null,
  absoluteUrl = false
) =>
  fetch(absoluteUrl ? url : `${process.env.REACT_APP_API_ENTRYPOINT}${url}`, {
    method,
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      ...authHeader(),
    },
    ...request,
  }).then(handleResponse || defaultHandleResponse)
