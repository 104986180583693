import React, { useState, useEffect } from 'react'
import CarModelDetail from './CarModelDetail'
import { Title } from 'components/Title'
import { useSelector } from 'react-redux'
import { authHeader } from '../../../_helpers/authHeader'
import Alert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))
const carSerieEndpoint = '/car_series'
const carSerieIriFunction = (id) => `${carSerieEndpoint}/${id}`

const CarModelDeatilContainer = ({ match }) => {
  const classes = useStyles()
  const {
    params: { serieId },
  } = match

  const [carSerie, setCarSerie] = useState(null)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const auth = useSelector((state) => state.auth)

  useEffect(() => {
    const fetchData = async (slug) => {
      setLoading(true)
      setError(false)
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_ENTRYPOINT}${slug}`,
          {
            method: 'GET',
            headers: {
              accept: 'application/json',
              ...authHeader(),
            },
          }
        )
        const result = await response.json()
        setCarSerie(() => result)
      } catch (error) {
        setError(true)
      }
      setLoading(false)
    }
    fetchData(carSerieIriFunction(serieId))
  }, [auth.data.token, serieId])
  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {error && (
        <Alert severity="error">wystąpił błąd podczas pobierania danych</Alert>
      )}

      {carSerie && (
        <>
          <Title>{carSerie.fullName}</Title>
          <CarModelDetail carSerie={carSerie} setCarSerie={setCarSerie} />
        </>
      )}
    </>
  )
}

export default CarModelDeatilContainer
