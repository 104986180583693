export const authConstants = {
  LOGIN_REQUEST: 'AUTH_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'AUTH_LOGIN_FAILURE',

  LOGIN_RESTORE: 'AUTH_LOGIN_RESTORE',

  REFRESH_REQUEST: 'AUTH_REFRESH_REQUEST',
  REFRESH_SUCCESS: 'AUTH_REFRESH_SUCCESS',
  REFRESH_FAILURE: 'AUTH_REFRESH_FAILURE',

  LOGOUT: 'AUTH_LOGOUT',
}
