import React from 'react'
import { useSelector } from 'react-redux'

import { authHeader } from '_helpers/authHeader'
import { useFetch } from '_helpers/useFetch'
import { Title } from 'components/Title'
import Alert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import Template from './Template'

import { makeStyles } from '@material-ui/core/styles'
import TemplateContent from './TemplateContent'
import api from 'api'
import { hireStatus } from 'dataconfig'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  root: {
    marginTop: theme.spacing(6),
  },
  textField: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'center',
  },
}))

export const TemplateContainer = ({ match }) => {
  const classes = useStyles()
  const {
    params: { templateId },
  } = match
  const auth = useSelector((state) => state.auth)

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const args = [auth.data.token, templateId]

  const res = useFetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}${api.documentTemplate}/${templateId}`,
    options,
    args
  )

  const [variableKeys, setVariableKeys] = React.useState([])
  const [startData, setStartData] = React.useState({
    suggestedSegments: [],
    suggestedRentalPlaces: [],
  })
  const [data, setData] = React.useState({
    kind: hireStatus[0].id,
    name: 'Nazwa dokumentu',
    title: 'Tytuł dokumentu',
    intro:
      'Zawarta dnia {{DEAL_DATE}} w {{START_RENT_PLACE}} pomiędzy: {{COMPANY_DATA}} zwanym dalej Wynajmującym, a {{CUSTOMER_DATA}} zwanym dalej Najemcą.',
    paragraphs: [
      {
        title: 'Przykładowy paragraf',
        text: 'Treść przykładowego paragrafu',
      },
    ],
    firstSignatureTitle: 'Podpis Wynajmującego',
    secondSignatureTitle: 'Podpis Najemcy',
    suggestedSegments: [],
    suggestedRentalPlaces: [],
  })

  React.useEffect(() => {
    const dataRes = res.state.data
    if (dataRes) {
      setData({
        kind: dataRes.kind,
        name: dataRes.name,
        title: dataRes.title,
        intro: dataRes.intro,
        paragraphs: dataRes.paragraphs,
        firstSignatureTitle: dataRes.firstSignatureTitle,
        secondSignatureTitle: dataRes.secondSignatureTitle,
        suggestedSegments: dataRes.suggestedSegments.map(
          (el) => `/${api.home}_segments/${el.id}`
        ),
        suggestedRentalPlaces: dataRes.suggestedRentalPlaces.map(
          (el) => `/${api.home}_rental_places/${el.id}`
        ),
      })
      setVariableKeys(dataRes.variableKeys)
      setStartData({
        suggestedSegments: dataRes.suggestedSegments,
        suggestedRentalPlaces: dataRes.suggestedRentalPlaces,
        kind: dataRes.kind,
      })
    }
  }, [res.state.data])

  const [open, setOpen] = React.useState(true)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const handleChengeTitle = (name) => {
    setData((prev) => ({
      ...prev,
      name: name,
    }))
  }

  const handleChangeContent = (value) => {
    setData((prev) => ({
      ...prev,
      paragraphs: value,
    }))
  }

  const handleChangeIntro = (intro) => {
    setData((prev) => ({
      ...prev,
      intro: intro,
    }))
  }

  const handleChangeInfo = (value) => {
    let data
    if ('suggestedSegments' in value) {
      data = {
        suggestedSegments: value.suggestedSegments,
      }
    }
    if ('suggestedRentalPlaces' in value) {
      data = {
        suggestedRentalPlaces: value.suggestedRentalPlaces,
      }
    }
    if ('kind' in value) {
      data = {
        kind: value.kind,
      }
    }
    setData((prev) => ({
      ...prev,
      ...data,
    }))
  }

  React.useEffect(() => {
    const abortController = new AbortController()
    const options = {
      method: 'GET',
      headers: {
        accept: 'application/json',
        ...authHeader(),
      },
    }
    const fetchData = async () => {
      fetch(
        `${process.env.REACT_APP_API_ENTRYPOINT}${api.documentTemplate}/variable_keys?kind=${data.kind}`,
        {
          ...options,
        }
      )
        .then((res) => res.json())
        .then((res) => {
          // console.log(res)
          setVariableKeys(res.content)
        })
    }

    if (data.kind.length > 0) {
      fetchData()
    } else {
      setVariableKeys([])
    }

    return () => {
      abortController.abort()
    }
  }, [data.kind])

  return (
    <>
      <Backdrop className={classes.backdrop} open={res.state.isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {res.state.error && (
        <Alert severity="error">wystąpił błąd podczas pobierania danych</Alert>
      )}

      {data && (
        <>
          <Template
            template={data}
            setTemplate={res.setState}
            templateId={res.state.data && res.state.data.id}
            changeInfo={handleChangeInfo}
            startData={startData}
            header={[
              {
                name: 'STATUS',
                prop: 'kind',
              },
              {
                name: 'Sugerowany segment cennika',
                prop: 'suggestedSegments',
              },
              {
                name: 'Sugerowana wypożyczalnia',
                prop: 'suggestedRentalPlaces',
              },
            ]}
          />
          <Title>Treść szablonu</Title>

          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="info" variant="filled">
              Kliknij prawym przyciskiem myszy na paragraf by wstawić dane
            </Alert>
          </Snackbar>
          <TemplateContent
            template={data}
            defVariableKeys={variableKeys}
            changeContent={handleChangeContent}
            changeTitle={handleChengeTitle}
            changeIntro={handleChangeIntro}
          />
        </>
      )}
    </>
  )
}
