import React, { useState } from 'react'
import CarCreation from './CarCreation'
import { Title } from 'components/Title'
import { useSelector } from 'react-redux'
import { authHeader } from '../../_helpers/authHeader'
import { useFetch } from '../../_helpers/useFetch'

const carSerieIriFunction = id => `/car_series/${id}`

const CarCreationContainer = ({ match }) => {
  const {
    params: { carSerieId },
  } = match
  const [car, setCar] = useState({})

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const auth = useSelector(state => state.auth)
  const args = [auth.data.token, carSerieId]

  const carSerieIri = carSerieIriFunction(carSerieId)
  const carSerieRes = useFetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}${carSerieIri}`,
    options,
    args
  )

  return (
    <>
      <>
        <Title>{carSerieRes?.state?.data?.fullName || ''}</Title>
        <CarCreation car={car} setCar={setCar} carSerieIri={carSerieIri} />
      </>
    </>
  )
}

export default CarCreationContainer
