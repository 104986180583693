import React from 'react'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Moment from 'react-moment'
import { useSelector } from 'react-redux'
import { authHeader } from '_helpers/authHeader'
import { useFetch } from '_helpers/useFetch'
import api from 'api'
export default function InvoiceDataCols({ customer, data }) {
  const auth = useSelector((state) => state.auth)

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const args = [auth.data.token]

  const res = useFetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}${api.companyConfig}`,
    options,
    args
  )
  return (
    <Grid container spacing={4} justify="space-evenly">
      <Grid item xs={6} sm={4}>
        <Typography variant="h6" color="textPrimary" gutterBottom>
          Nabywca
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          Nazwa:{' '}
          <Button
            component={Link}
            color="primary"
            to={`/customers/list/${customer?.id}`}
          >
            {customer?.personalDetails?.companyName}
          </Button>
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          Adres: {customer?.personalDetails?.street},{' '}
          {customer?.personalDetails?.apartmentNumber},{' '}
          {customer?.personalDetails?.postCode},{' '}
          {customer?.personalDetails?.city}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          NIP: {customer?.personalDetails?.nip}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          E-mail: {customer?.personalDetails?.email}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          Tel: {customer?.personalDetails?.phone}
        </Typography>
      </Grid>
      <Grid item xs={6} sm={4}>
        <Typography variant="h6" color="textPrimary" gutterBottom>
          Daty
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          Data wystawienia:{' '}
          <Moment format="DD.MM.YYYY">{data?.billingDate}</Moment>
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          Data sprzedaży: <Moment format="DD.MM.YYYY">{data?.saleDate}</Moment>
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          Termin płatności:{' '}
          <Moment format="DD.MM.YYYY">{data?.paymentDate}</Moment>
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          Miejsce wystawienia: {data?.salePlace}
        </Typography>
      </Grid>
      <Grid item xs={6} sm={4}>
        <Typography variant="h6" color="textPrimary" gutterBottom>
          Płatność
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          Płatność: {data?.paymentType}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          Nr konta: {res?.state?.data?.accountNumber}
        </Typography>
      </Grid>
    </Grid>
  )
}
