import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { useSelector } from 'react-redux'
import { authHeader } from '_helpers/authHeader'
import { useFetch } from '_helpers/useFetch'
import api from 'api'

const SeriesComponent = (props) => {
  const { onChange, classes, type } = props

  const [series, setSeries] = React.useState(0)

  const auth = useSelector((state) => state.auth)

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const args = [auth.data.token]

  const res = useFetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}${api.invoicingNumberings}`,
    options,
    args
  )

  const handleChangeSeries = (event) => {
    setSeries(event.target.value)
    onChange(event.target.value)
  }

  React.useEffect(() => {
    if (res.state.data && Object.keys(res.state.data).length > 0) {
      let defaultSerie = res.state.data.content.find(
        (el) => el.defaultNumbering
      )
      if (type) {
        defaultSerie = res.state.data.content.find(
          (el) => el.typeInvoice === type
        )
      }
      setSeries(defaultSerie.id)
      onChange(defaultSerie.id)
    }
    // eslint-disable-next-line
  }, [res.state.data, type])

  return (
    <>
      {res.state.data && (
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="fv-serie">Seria</InputLabel>
          <Select
            labelId="fv-serie"
            id="fv-serie"
            value={series}
            onChange={handleChangeSeries}
            label="Seria"
          >
            {res.state.data.content.map((el) => (
              <MenuItem value={el.id} key={el.id}>
                {el.name} - {el.typeInvoice} {el.example}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  )
}

SeriesComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
  type: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
}

export default SeriesComponent
