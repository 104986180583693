import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Lease from './Lease'
import { Title } from 'components/Title'
import Table from 'components/table/TableList'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Slide from '@material-ui/core/Slide'
import MapPage from 'components/map/Map'
import CanvasContainer from './canvas/CanvasContainer'
import LeaseModal from './LeaseModal'
import Alert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import api from 'api'
import { leaseConstants } from '_constants'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  img: {
    width: '348px',
  },
}))
export const LeaseContainer = ({ match }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    params: { leaseId, breakIdM, carIdM },
  } = match

  useEffect(() => {
    dispatch({ type: leaseConstants.DATA_FETCH_SAGA, id: leaseId })
    return () => {
      dispatch({ type: leaseConstants.DATA_REMOVE_SAGA })
    }
    // eslint-disable-next-line
  }, [leaseId])

  const lease = useSelector((state) => state.lease)

  let mainData
  mainData = [
    {
      name: api.appType === 'cars' ? 'FLOTA' : 'MASZYNA',
      prop:
        api.appType === 'cars' ? 'car.fullName' : 'fitnessEquipment.fullName',
    },
    {
      name: 'NR REJESTRACYJNY',
      prop: 'car.registrationNumber',
    },
    {
      name: 'CAN SYSTEM',
      prop: 'canAlerts.length',
    },
    {
      name: 'AWARIE',
      prop: 'breakdowns.length',
    },
  ]
  if (api.appType === 'fitness') {
    const removeValFromIndex = [1, 2, 3]

    const indexSet = new Set(removeValFromIndex)

    mainData = mainData.filter((value, i) => !indexSet.has(i))
  }

  let expandData
  if (api.appType === 'cars') {
    expandData = [
      {
        name: 'Przejechane w najmie',
        prop: 'canStatistics.distanceRidden',
      },
      {
        name: 'Czas pracy silnika',
        prop: 'canStatistics.engineWorkSeconds',
      },
      {
        name: 'Last known location',
        prop: 'canStatistics.lastCanRecord.latitude',
      },
      {
        name: 'Ecodriving',
        prop: 'canStatistics.lastCanRecord.ecoDrivingMetric',
      },
      {
        name: 'Bieżące spalanie',
        prop: 'canStatistics.lastCanRecord.momentaryFuelConsumption',
      },
      {
        name: 'Średnie spalanie',
        prop: 'canStatistics.lastCanRecord.momentaryAverageFuelConsumption',
      },
      {
        name: 'Prędkość pojazdu',
        prop: 'canStatistics.lastCanRecord.speed',
      },
      {
        name: 'Obroty silnika',
        prop: 'canStatistics.lastCanRecord.revolutionsPerMinute',
      },
      {
        name: 'Moment obrotowy',
        prop: 'canStatistics.lastCanRecord.torque',
      },
      {
        name: 'Nacisk na osie',
        prop: 'canStatistics.lastCanRecord.axleLoad',
      },
    ]
  } else {
    expandData = [
      {
        name: 'Data rozpoczęcia',
        prop: 'startDate',
      },
      {
        name: 'Data zakończenia',
        prop: 'deadlineDate',
      },
    ]
  }

  const styles = (theme) => ({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
  })
  const [breakOpen, setBreakOpen] = React.useState(false)
  const [breakId, setBreakId] = React.useState(null)
  const [lastCanRecord, setLastCanRecord] = React.useState({})
  const [car, setCar] = React.useState({})

  useEffect(() => {
    if (breakIdM && Object.keys(lease).length > 0) {
      setCar(lease.hiredItems.find((el) => el.id === Number(carIdM)))
      setBreakId(`/car_breakdowns/${breakIdM}`)
      setBreakOpen(true)
    }
  }, [breakIdM, lease, carIdM])

  const handleClose = () => {
    setBreakOpen(false)
  }

  const [mapOpen, setMapOpen] = React.useState(false)

  const handleMapClose = () => {
    setMapOpen(false)
  }

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    )
  })
  function DialogSlide() {
    return (
      <div>
        <Dialog
          open={breakOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          fullScreen={true}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Najem nr {lease.id} - zgłoszenie awarii i wypadku
          </DialogTitle>
          <DialogContent>
            {breakOpen && (
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  {car?.car?.photos?.[0]?.url && (
                    <img
                      className={classes.img}
                      src={`${process.env.REACT_APP_API_ENTRYPOINT}${car?.car?.photos?.[0]?.url}`}
                      alt="car"
                    />
                  )}
                  <Typography variant="h6" gutterBottom>
                    Miejsce uszkodzenia
                  </Typography>
                  <CanvasContainer breakId={breakId} />
                </Grid>
                <Grid item xs={6}>
                  <LeaseModal
                    breakId={breakId}
                    customer={lease.customer}
                    car={car.car ? car.car : {}}
                    item={car}
                  />
                </Grid>
              </Grid>
            )}
          </DialogContent>
        </Dialog>
      </div>
    )
  }
  function MapDialogSlide() {
    return (
      <div>
        <Dialog
          open={mapOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleMapClose}
          aria-labelledby="alert-dialog-slide-title"
          fullScreen={true}
        >
          <DialogTitle id="map-dialog-title" onClose={handleMapClose}>
            Last known location
          </DialogTitle>
          <DialogContent>
            {mapOpen && <MapPage lastCanRecord={lastCanRecord} />}
          </DialogContent>
        </Dialog>
      </div>
    )
  }
  return (
    <>
      <Backdrop
        className={classes.backdrop}
        open={Object.keys(lease).length === 0}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {lease.error && (
        <Alert severity="error">wystąpił błąd podczas pobierania danych</Alert>
      )}

      {Object.keys(lease).length > 0 && (
        <>
          <Title>Rezerwacje i najmy</Title>
          <Lease
            lease={lease}
            header={[
              {
                name: 'ID',
                prop: 'id',
              },
              {
                name: 'KLIENT',
                prop: 'customer.personalDetails.companyName',
              },

              {
                name: 'OD',
                prop: 'startDate',
              },
              {
                name: 'DO',
                prop: 'endDate',
              },
              {
                name: 'WYPOŻYCZALNIA',
                prop: 'sourceRentalPlace.name',
              },
              {
                name: 'STATUS',
                prop: 'hireStatus',
              },
              {
                name: 'POZOSTAŁO',
                prop: 'message',
              },
              {
                name: 'WARTOŚĆ',
                prop: 'cost',
              },
              {
                name: 'FAKTURA',
                prop: 'invoice.invoiceStatus',
              },
              {
                name: 'Wystawione dokumenty',
                prop: 'documents',
              },
            ]}
          />
          <Table
            data={lease.hiredItems}
            mainData={mainData}
            expandData={expandData}
            colWidth="50%"
            setBreakOpen={setBreakOpen}
            setMapOpen={setMapOpen}
            setLastCanRecord={setLastCanRecord}
            setBreakId={setBreakId}
            setCar={setCar}
            startDate={lease.startDate}
          />

          <DialogSlide />

          <MapDialogSlide />
        </>
      )}
    </>
  )
}
