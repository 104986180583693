import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormHelperText from '@material-ui/core/FormHelperText'
import TextField from '@material-ui/core/TextField'
import CreateIcon from '@material-ui/icons/Create'

export default function DoubleNumberField({
  fieldName,
  firstType,
  secondType,
  firstValue,
  secondValue,
  unit,
  violations = [],
  updatableParentEntity,
  setUpdatableParentEntity,
  renderValue = (firstValue, secondValue) => `${+firstValue} - ${+secondValue}`,
  uploadValue = (value) => +value,
  inputProps,
  readOnly = false,
  isInteger = false,
}) {
  const [open, setOpen] = React.useState(false)
  const [currentFirstValue, setCurrentFirstValue] = React.useState(
    firstValue === null || firstValue === undefined ? '' : firstValue
  )
  const [currentSecondValue, setCurrentSecondValue] = React.useState(
    secondValue === null || secondValue === undefined ? '' : secondValue
  )

  const handleOpen = () => {
    setOpen(!readOnly && true)
  }
  const handleClose = () => setOpen(false)

  const handleChangeFirst = (event) => {
    const newValue = event.target.value
    setCurrentFirstValue(newValue)
    setUpdatableParentEntity({
      ...updatableParentEntity,
      [firstType]: uploadValue(newValue),
      unit: unit,
    })
  }

  const handleChangeSecond = (event) => {
    const newValue = event.target.value
    setCurrentSecondValue(newValue)
    setUpdatableParentEntity({
      ...updatableParentEntity,
      [secondType]: uploadValue(newValue),
      unit: unit,
    })
  }

  return (
    <React.Fragment>
      {fieldName && (
        <div>
          <b>{fieldName}:</b>
        </div>
      )}
      <div>
        <div onClick={handleOpen}>
          {renderValue(currentFirstValue, currentSecondValue) ||
            (!readOnly && <CreateIcon fontSize="small" />)}
          {violations.length
            ? violations.map((violation, i) => (
                <FormHelperText key={i} error={true}>
                  {violation.message}
                </FormHelperText>
              ))
            : ''}
        </div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            <span>{fieldName}</span>
          </DialogTitle>
          <DialogContent>
            <TextField
              defaultValue={currentFirstValue}
              onChange={handleChangeFirst}
              type="number"
              inputProps={inputProps}
            />
            <TextField
              defaultValue={currentSecondValue}
              onChange={handleChangeSecond}
              type="number"
              inputProps={inputProps}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Akceptuj
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment>
  )
}
