import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import { fetchDataHandleAuthError } from '../../../_helpers/fetchDataHandleAuthError'
import { useDispatch } from 'react-redux'
import { requestActions } from '_actions'
import { notification } from '_helpers/notification'
import { requestConstants } from '_constants'
import api from 'api'

const iri = (id) => `${api.iri.equipment}${id}/reactivate`

export default function ReactivateButton({
  item,
  setItem,
  text = 'Dearchiwizuj',
}) {
  const dispatch = useDispatch()

  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleClick = () => {
    const url = iri(item.id)
    dispatch(requestActions.start(url))
    fetchDataHandleAuthError(
      url,
      'PUT',
      { body: JSON.stringify({}) },
      (response) => {
        setItem(response)
        handleClose()
        dispatch(requestActions.success())
        notification('success', 'Rekord zaktualizowany', 'Zapisano')
      },
      (error) => {
        notification(
          'error',
          error.response.violations.length
            ? 'Incorrect form'
            : error.response.detail,
          error.response.title
        )
      },
      dispatch,
      requestConstants.FAILURE
    )
  }

  return (
    <>
      <Button onClick={handleClickOpen} color="primary" variant="outlined">
        {text}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{text}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Czy na pewno wykonać operację {text}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Zamknij
          </Button>
          <Button onClick={handleClick} color="primary" autoFocus>
            {text}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
