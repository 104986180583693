import React from 'react'

import Moment from 'react-moment'
import moment from 'moment'

import MoneyField from 'components/fields/MoneyField'
import CheckboxField from 'components/fields/CheckboxField'
import DatePickerField from 'components/fields/DatePickerField'
import CarPartModelField from 'components/fields/CarPartModelField'

import Warning from '../../../../components/Warning'
import CarPartList from '../CarPartList'

export const ModelModuleWithDateValue = ({ item }) => {
  return (
    <>
      {`${item?.model?.fullName} | do `}
      <Moment format="DD.MM.YYYY">{item.deadlineDate}</Moment>
      {item.warnings.length ? (
        <Warning messages={item.warnings.map(warning => warning.message)} />
      ) : (
        <></>
      )}
    </>
  )
}

const compareFunction = (a, b) =>
  new Date(b.deadlineDate).getTime() - new Date(a.deadlineDate).getTime()

export default function ModelModuleWithDateList({
  entities,
  carIri,
  moduleEndpoint,
  moduleName,
  moduleType,
}) {
  const createSpecificColumns = createSetRow => [
    {
      id: 'startDate',
      label: 'Data rozpoczęcia',
      minWidth: 200,
      renderValue: (item, violations) => (
        <DatePickerField
          type={'startDate'}
          value={item.startDate}
          violations={violations ? violations : []}
          updatableParentEntity={item}
          setUpdatableParentEntity={createSetRow(item.id)}
        />
      ),
    },
    {
      id: 'deadlineDate',
      label: 'Data zakończenia',
      minWidth: 200,
      renderValue: (item, violations) => (
        <DatePickerField
          type={'deadlineDate'}
          value={item.deadlineDate}
          violations={violations ? violations : []}
          updatableParentEntity={item}
          setUpdatableParentEntity={createSetRow(item.id)}
        />
      ),
    },
    {
      id: 'model',
      label: 'Model',
      minWidth: 150,
      align: 'center',
      renderValue: (item, violations) => (
        <CarPartModelField
          type={'model'}
          carPartType={moduleType}
          modelEntity={item.model}
          violations={violations ? violations : []}
          updatableParentEntity={item}
          setUpdatableParentEntity={createSetRow(item.id)}
        />
      ),
    },
    {
      id: 'cost',
      label: 'Cena',
      minWidth: 100,
      renderValue: (item, violations) => (
        <MoneyField
          type={'cost'}
          value={item.cost}
          violations={violations ? violations : []}
          updatableParentEntity={item}
          setUpdatableParentEntity={createSetRow(item.id)}
        />
      ),
    },
    {
      id: 'includeCost',
      label: 'Wliczaj',
      minWidth: 50,
      renderValue: (item, violations) => (
        <CheckboxField
          type={'includeCost'}
          value={item.includeCost}
          violations={violations ? violations : []}
          updatableParentEntity={item}
          setUpdatableParentEntity={createSetRow(item.id)}
        />
      ),
    },
  ]

  const createPrototype = () => {
    return {
      id: 0,
      car: carIri,
      startDate: moment().format(),
      deadlineDate: moment().format(),
      model: null,
      cost: 0,
      includeCost: false,
    }
  }

  return (
    <CarPartList
      entities={entities}
      moduleEndpoint={moduleEndpoint}
      moduleName={moduleName}
      createPrototype={createPrototype}
      compareFunction={compareFunction}
      createSpecificColumns={createSpecificColumns}
    />
  )
}
