import React from 'react'
import ReactDOM from 'react-dom'
import Root from 'containers/root'

const render = (Component) =>
  ReactDOM.render(<Component />, document.querySelector('#root'))

render(Root)

if (module.hot) {
  module.hot.accept('./containers/root', () => {
    const NextRoot = require('./containers/root').default
    render(NextRoot)
  })
}
