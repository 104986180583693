import React from 'react'

import FormHelperText from '@material-ui/core/FormHelperText'
import Checkbox from '@material-ui/core/Checkbox'

export default function CheckboxField({
  fieldName,
  type,
  value,
  violations,
  updatableParentEntity,
  setUpdatableParentEntity,
}) {
  const [currentValue, setCurrentValue] = React.useState(value)

  const fieldViolations = violations.filter(
    violation => violation.propertyPath === type
  )

  const handleChange = event => {
    const newValue = !currentValue
    setCurrentValue(newValue)
    setUpdatableParentEntity({
      ...updatableParentEntity,
      [type]: newValue,
    })
  }

  return (
    <React.Fragment>
      {fieldName && (
        <div>
          <b>{fieldName}:</b>
        </div>
      )}
      <div>
        <div>
          <Checkbox
            checked={currentValue}
            onChange={handleChange}
            color="primary"
          />
          {fieldViolations.length
            ? fieldViolations.map((violation, i) => (
                <FormHelperText key={i} error={true}>
                  {violation.message}
                </FormHelperText>
              ))
            : ''}
        </div>
      </div>
    </React.Fragment>
  )
}
