export const getDefinitionProperties = (definition, properties = null) => {
  if (!properties) {
    return definition.properties
  }

  return Object.keys(properties).reduce(
    (obj, name) =>
      Object.assign(obj, {
        [name]: { ...definition.properties[name], ...properties[name] },
      }),
    {}
  )
}
