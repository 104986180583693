import React from 'react'
import { useSelector } from 'react-redux'
import Customer from './Customer'
import Moment from 'react-moment'
import { authHeader } from '_helpers/authHeader'
import { useFetch } from '_helpers/useFetch'
import { Title } from 'components/Title'
import Table from 'components/table/TableList'
import Alert from '@material-ui/lab/Alert'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { default as MUITable } from '@material-ui/core/Table'
import { makeStyles } from '@material-ui/core/styles'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import api from 'api'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  gridParent: {
    display: 'grid',
    gridAutoRows: 'minmax(32px, auto)',
    gridTemplateColumns: 'repeat(2,1fr)',
    columnGap: '25px',
    rowGap: '5px',
  },
  flexParent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

export const CustomerContainer = ({ match }) => {
  const classes = useStyles()
  const {
    params: { customerId },
  } = match

  const auth = useSelector((state) => state.auth)

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const args = [auth.data.token, customerId]

  const res = useFetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}${api.customers}/${customerId}`,
    options,
    args
  )

  const resHires = useFetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}${api.customers}/${customerId}/hires`,
    options,
    args
  )
  const mainData = [
    {
      name: 'ID',
      prop: 'id',
    },
    {
      name: api.appType === 'cars' ? 'FLOTA' : 'MASZYNY',
      prop: 'hiredItems.length',
    },
    {
      name: 'OD',
      prop: 'startDate',
    },
    {
      name: 'DO',
      prop: 'endDate',
    },
    {
      name: 'WYPOŻYCZALNIA',
      prop: 'sourceRentalPlace.name',
    },
    {
      name: 'STATUS',
      prop: 'hireStatus',
    },
    {
      name: 'POZOSTAŁO',
      prop: 'endDate',
    },
    {
      name: 'WARTOŚĆ',
      prop: 'cost',
    },
    {
      name: 'FAKTURA',
      prop: 'invoice.invoiceStatus',
    },
    {
      name: 'ZNIŻKA',
      prop: 'discountLevel.discountRate',
    },
  ]

  const fleet = { ...resHires.state.data }
  const docs = { ...res.state.data }

  return (
    <>
      <Backdrop className={classes.backdrop} open={res.state.isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {res.state.error && (
        <Alert severity="error">wystąpił błąd podczas pobierania danych</Alert>
      )}

      {res.state.data && (
        <>
          <div className={classes.gridParent}>
            <div>
              <div className={classes.flexParent}>
                <Title>Dane klienta</Title>
                <IconButton
                  aria-label="edit"
                  component={Link}
                  to={`/customers/list/${res.state.data.id}/edit/${res.state.data.user.id}`}
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
              </div>
              <Customer
                customer={res.state.data}
                setCustomer={res.setState}
                header={[
                  {
                    name: 'KLIENT',
                    prop: 'personalDetails.companyName',
                  },
                  {
                    name: 'NIP',
                    prop: 'personalDetails.nip',
                  },
                  {
                    name: 'ULICA, NR',
                    prop: 'personalDetails.street',
                  },
                  {
                    name: 'MIASTO',
                    prop: 'personalDetails.city',
                  },
                  {
                    name: 'KOD POCZTOWY',
                    prop: 'personalDetails.postCode',
                  },
                  {
                    name: 'E-MAIL',
                    prop: 'user.email',
                  },
                ]}
              />
            </div>
            <div>
              <Title>Ostatnie dokumenty</Title>

              <MUITable size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>DATA </TableCell>
                    <TableCell>PDF </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {docs.documents.slice(0, 5).map((e, i) => {
                    return (
                      <TableRow key={`${i}-d`}>
                        <TableCell>
                          <Moment format="DD.MM.YYYY">{e.createdAt}</Moment>
                        </TableCell>
                        <TableCell>{e.id} </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </MUITable>
            </div>
          </div>
          <Title>Historia najmów</Title>
          <Table data={fleet.content} mainData={mainData} />
        </>
      )}
    </>
  )
}
