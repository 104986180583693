import React from 'react'
import StringField from '../../../components/fields/StringField'
import UnitNumberField from '../../../components/fields/UnitNumberField'
import SelectEntityField from '../../../components/fields/SelectEntityField'

const parameterizedEndpoint = '/car_specification_parameterizeds'

export default function CarModelFormFieldFactory({
  fieldName,
  type,
  value,
  violations,
  updatableParentEntity,
  setUpdatableParentEntity,
  carSerieId,
}) {
  switch (type) {
    case 'id':
      return (
        <StringField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
          readOnly={true}
        />
      )
    case 'name':
      return (
        <StringField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'productionStart':
      return (
        <StringField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'productionEnd':
      return (
        <StringField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'enginePower':
      return (
        <UnitNumberField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'engineCubicCapacity':
      return (
        <UnitNumberField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'seatsAmount':
      return (
        <UnitNumberField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'bodyType':
      return (
        <SelectEntityField
          fieldName={fieldName}
          selectableEntity={value}
          type={type}
          endPoint={parameterizedEndpoint}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'engineFuel':
      return (
        <SelectEntityField
          fieldName={fieldName}
          selectableEntity={value}
          type={type}
          endPoint={parameterizedEndpoint}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'gearBox':
      return (
        <SelectEntityField
          fieldName={fieldName}
          selectableEntity={value}
          type={type}
          endPoint={parameterizedEndpoint}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'gearsAmount':
      return (
        <UnitNumberField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'drivetrain':
      return (
        <SelectEntityField
          fieldName={fieldName}
          selectableEntity={value}
          type={type}
          endPoint={parameterizedEndpoint}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'fuelConsumptionCity':
      return (
        <UnitNumberField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'fuelConsumptionMixed':
      return (
        <UnitNumberField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'fuelConsumptionLongHaul':
      return (
        <UnitNumberField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'fuelTank':
      return (
        <UnitNumberField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'length':
      return (
        <UnitNumberField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'width':
      return (
        <UnitNumberField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'height':
      return (
        <UnitNumberField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'minimumTrunkSpace':
      return (
        <UnitNumberField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'maximumTrunkSpace':
      return (
        <UnitNumberField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'loadCapacity':
      return (
        <UnitNumberField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    default:
      return 'Nic'
  }
}
