import BigNumber from 'bignumber.js'

BigNumber.set({
  FORMAT: {
    decimalSeparator: '.',
    groupSeparator: ' ',
  },
})

const setCurrencyToApi = (value) => {
  const number = new BigNumber(value)
  const answer = number.times(100)
  return answer.dp(0).toNumber()
}

const getPLNFromApi = (value) => {
  const number = new BigNumber(value)
  const answer = number.div(100)
  return `${answer.toFormat(2)} zł`
}

const getFixedValue = (value) => {
  const number = new BigNumber(value)
  return `${number.toFormat(2)} zł`
}

const getCurrencyNumberFromApi = (value) => {
  const number = new BigNumber(Number(value))
  const answer = number.div(100)
  return answer.dp(2).toNumber()
}

export { setCurrencyToApi, getCurrencyNumberFromApi, getPLNFromApi, getFixedValue }
