import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FormFactory } from 'components/factory/FormFactory'
import * as schema from '_schema/user'
import { getDefinitionProperties } from '_helpers/getDefinitionProperties'
import { userActions } from '_actions'

export const ProfileForm = ({ resource }) => {
  const definitions = useSelector(state => state.schema?.definitions)
  const properties = getDefinitionProperties(
    definitions[schema.profile.definition],
    schema.profile.properties
  )

  const dispatch = useDispatch()
  const handleSuccess = values => {
    dispatch(userActions.updateProfile(values))
  }

  return (
    <FormFactory
      title="Profil"
      url={schema.profile.set}
      method="PUT"
      properties={properties}
      resource={resource}
      handleSuccess={handleSuccess}
    />
  )
}
