import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormHelperText from '@material-ui/core/FormHelperText'
import TextField from '@material-ui/core/TextField'
import CreateIcon from '@material-ui/icons/Create'

const useStyles = makeStyles(theme => ({
  centerBox: {
    textAlign: 'center',
    padding: '5px 0px',
  },
}))

export default function StringField({
  fieldName,
  type,
  value,
  violations = [],
  updatableParentEntity,
  setUpdatableParentEntity,
  readOnly = false,
  center = false,
}) {
  const classes = useStyles()

  const [open, setOpen] = React.useState(false)
  const [currentValue, setCurrentValue] = React.useState(value || '')

  const fieldViolations = violations.filter(
    violation => violation.propertyPath === type
  )

  const handleOpen = () => {
    setOpen(!readOnly)
  }
  const handleClose = () => setOpen(false)

  const handleChange = event => {
    const newValue = event.target.value
    setCurrentValue(newValue)
    setUpdatableParentEntity({
      ...updatableParentEntity,
      [type]: newValue,
    })
  }

  return (
    <React.Fragment>
      <div>
        <b>{fieldName && `${fieldName}`}</b>
      </div>
      <div>
        <div onClick={handleOpen} className={center ? classes.centerBox : ''}>
          {currentValue || (!readOnly && <CreateIcon fontSize="small" />)}
          {fieldViolations.length
            ? fieldViolations.map((violation, i) => (
                <FormHelperText key={i} error={true}>
                  {violation.message}
                </FormHelperText>
              ))
            : ''}
        </div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{fieldName || ''}</DialogTitle>
          <DialogContent>
            <TextField defaultValue={currentValue} onChange={handleChange} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Akceptuj
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment>
  )
}
