import React from 'react'
import Moment from 'react-moment'
import moment from 'moment'

import MoneyField from 'components/fields/MoneyField'
import CheckboxField from 'components/fields/CheckboxField'
import DatePickerField from 'components/fields/DatePickerField'
import StringField from 'components/fields/StringField'

import Warning from '../../../../components/Warning'
import CarPartList from '../CarPartList'

const moduleName = 'Ubezpieczenia'
const moduleEndpoint = '/insurances'

export const InsuranceValue = ({ item }) => {
  const insuranceKinds = []
  if (item.isOC) insuranceKinds.push('OC')
  if (item.isAC) insuranceKinds.push('AC')
  if (item.isNW) insuranceKinds.push('NW')

  return (
    <>
      {`${insuranceKinds.join('/')} |`}
      {` ${item.companyName} |`}
      {` do `}
      <Moment format="DD.MM.YYYY">{item.deadlineDate}</Moment>
      {item.warnings.length ? (
        <Warning messages={item.warnings.map(warning => warning.message)} />
      ) : (
        <></>
      )}
    </>
  )
}

const compareFunction = (a, b) =>
  new Date(b.deadlineDate).getTime() - new Date(a.deadlineDate).getTime()

const createSpecificColumns = createSetRow => [
  {
    id: 'startDate',
    label: 'Data rozpoczęcia',
    minWidth: 100,
    renderValue: (item, violations) => (
      <DatePickerField
        type={'startDate'}
        value={item.startDate}
        violations={violations ? violations : []}
        updatableParentEntity={item}
        setUpdatableParentEntity={createSetRow(item.id)}
      />
    ),
  },
  {
    id: 'deadlineDate',
    label: 'Data zakończenia',
    minWidth: 100,
    renderValue: (item, violations) => (
      <DatePickerField
        type={'deadlineDate'}
        value={item.deadlineDate}
        violations={violations ? violations : []}
        updatableParentEntity={item}
        setUpdatableParentEntity={createSetRow(item.id)}
      />
    ),
  },
  {
    id: 'companyName',
    label: 'Nazwa firmy',
    minWidth: 100,
    align: 'center',
    renderValue: (item, violations) => (
      <StringField
        type={'companyName'}
        value={item.companyName}
        violations={violations ? violations : []}
        updatableParentEntity={item}
        setUpdatableParentEntity={createSetRow(item.id)}
        center
      />
    ),
  },
  {
    id: 'isOC',
    label: 'OC',
    minWidth: 50,
    align: 'center',
    renderValue: (item, violations) => (
      <CheckboxField
        type={'isOC'}
        value={item.isOC}
        violations={violations ? violations : []}
        updatableParentEntity={item}
        setUpdatableParentEntity={createSetRow(item.id)}
      />
    ),
  },
  {
    id: 'isAC',
    label: 'AC',
    minWidth: 50,
    align: 'center',
    renderValue: (item, violations) => (
      <CheckboxField
        type={'isAC'}
        value={item.isAC}
        violations={violations ? violations : []}
        updatableParentEntity={item}
        setUpdatableParentEntity={createSetRow(item.id)}
      />
    ),
  },
  {
    id: 'isNW',
    label: 'NW',
    minWidth: 50,
    align: 'center',
    renderValue: (item, violations) => (
      <CheckboxField
        type={'isNW'}
        value={item.isNW}
        violations={violations ? violations : []}
        updatableParentEntity={item}
        setUpdatableParentEntity={createSetRow(item.id)}
      />
    ),
  },
  {
    id: 'cost',
    label: 'Cena',
    minWidth: 100,
    renderValue: (item, violations) => (
      <MoneyField
        type={'cost'}
        value={item.cost}
        violations={violations ? violations : []}
        updatableParentEntity={item}
        setUpdatableParentEntity={createSetRow(item.id)}
      />
    ),
  },
  {
    id: 'includeCost',
    label: 'Wliczaj',
    minWidth: 50,
    renderValue: (item, violations) => (
      <CheckboxField
        type={'includeCost'}
        value={item.includeCost}
        violations={violations ? violations : []}
        updatableParentEntity={item}
        setUpdatableParentEntity={createSetRow(item.id)}
      />
    ),
  },
]

export default function InsuranceList({ entities, carIri }) {
  const createPrototype = () => {
    return {
      id: 0,
      car: carIri,
      startDate: moment().format(),
      deadlineDate: moment().format(),
      companyName: '',
      cost: 0,
      includeCost: false,
      isOC: false,
      isAC: false,
      isNW: false,
    }
  }

  return (
    <CarPartList
      entities={entities}
      moduleEndpoint={moduleEndpoint}
      moduleName={moduleName}
      createPrototype={createPrototype}
      compareFunction={compareFunction}
      createSpecificColumns={createSpecificColumns}
    />
  )
}
