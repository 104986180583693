import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormHelperText from '@material-ui/core/FormHelperText'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import IconButton from '@material-ui/core/IconButton'

import { useDispatch } from 'react-redux'
import { authHeader } from '_helpers/authHeader'
import { authActions } from '_actions'

import StringField from './StringField'
import SelectEntitySimpleField from './SelectEntitySimpleField'
import { Grid } from '@material-ui/core'
import DialogContentText from '@material-ui/core/DialogContentText'
import { notification } from '_helpers/notification'

const MODEL_ENDPOINT = '/car_part_models'
const BRAND_ENDPOINT = '/car_part_brands'

const modelIri = (id) => `${MODEL_ENDPOINT}/${id}`
const brandIri = (id) => `${BRAND_ENDPOINT}/${id}`

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& div': {
      marginBottom: '10px',
    },
  },
}))

const typeEditValues = ['new', 'edit', 'delete']

export default function CarPartModelField({
  fieldName,
  carPartType,
  type,
  modelEntity,
  updatableParentEntity,
  setUpdatableParentEntity,
  violations,
}) {
  const dispatch = useDispatch()
  const classes = useStyles()

  const fieldViolations = violations.filter(
    (violation) => violation.propertyPath === type
  )

  const createModelPrototype = () => ({
    type: carPartType,
    stat: true,
    name: '',
    brand: modelEntity?.brand ? brandIri(modelEntity.brand.id) : null,
  })

  const createBrandPrototype = () => ({
    type: carPartType,
    stat: true,
    name: '',
  })

  const [openModel, setOpenModel] = React.useState(false)
  const [openModelCreation, setOpenModelCreation] = React.useState(false)
  const [openBrandCreation, setOpenBrandCreation] = React.useState(false)
  const [modelId, setModelId] = React.useState(modelEntity?.id)
  const [modelValue, setModelValue] = React.useState(modelEntity?.name)
  const [brandId, setBrandId] = React.useState(modelEntity?.brand?.id)
  const [brandValue, setBrandValue] = React.useState(modelEntity?.brand?.name)
  const [modelViolations, setModelViolations] = React.useState([])
  const [brandViolations, setBrandViolations] = React.useState([])
  const [creationModel, setCreationModel] = React.useState(
    createModelPrototype()
  )
  const [creationBrand, setCreationBrand] = React.useState(
    createBrandPrototype()
  )
  const [typeEdit, setTypeEdit] = React.useState(typeEditValues[0])

  const handleOpenModel = () => {
    setOpenModel(true)
  }
  const handleCloseModel = () => setOpenModel(false)

  const handleOpenModelCreation = () => {
    setOpenModelCreation(true)
    setTypeEdit(typeEditValues[0])
  }
  const handleOpenModelEdit = () => {
    setOpenModelCreation(true)
    setTypeEdit(typeEditValues[1])
  }
  const handleOpenModelRemove = () => {
    setOpenModelCreation(true)
    setTypeEdit(typeEditValues[2])
  }
  const handleCloseModelCreation = () => setOpenModelCreation(false)
  const handleOpenBrandCreation = () => {
    setOpenBrandCreation(true)
    setTypeEdit(typeEditValues[0])
  }
  const handleOpenBrandEdit = () => {
    setOpenBrandCreation(true)
    setTypeEdit(typeEditValues[1])
  }
  const handleOpenBrandRemove = () => {
    setOpenBrandCreation(true)
    setTypeEdit(typeEditValues[2])
  }
  const handleCloseBrandCreation = () => setOpenBrandCreation(false)

  const postData = async (slug, data, type = 'POST') => {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENTRYPOINT}${slug}`,
      {
        method: type,
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
          ...authHeader(),
        },
        body: data,
      }
    )

    if (response.status === 401 || response.status === 403) {
      dispatch(authActions.logout())
    }

    if (response.ok) {
      return await response.json()
    } else {
      throw await response.json()
    }
  }

  const handleCreateModel = () => {
    const createModel = async () => {
      try {
        const response = await postData(
          MODEL_ENDPOINT,
          JSON.stringify(creationModel)
        )
        setModelId(response.id)
        setModelValue(response.name)
        setUpdatableParentEntity({
          ...updatableParentEntity,
          [type]: modelIri(response.id),
        })
        handleCloseModelCreation()
        setModelViolations([])
      } catch (error) {
        setModelViolations(error.violations ? error.violations : [])
      }
    }
    createModel()
  }

  const handleEditModel = () => {
    const editModel = async () => {
      try {
        const response = await postData(
          `${MODEL_ENDPOINT}/${modelId}`,
          JSON.stringify(creationModel),
          'PUT'
        )
        setModelId(response.id)
        setModelValue(response.name)
        setUpdatableParentEntity({
          ...updatableParentEntity,
          [type]: modelIri(response.id),
        })
        handleCloseModelCreation()
        setModelViolations([])
      } catch (error) {
        setModelViolations(error.violations ? error.violations : [])
      }
    }
    editModel()
  }

  const handleRemoveModel = () => {
    const removeModel = async () => {
      try {
        const response = await postData(
          `${MODEL_ENDPOINT}/${modelId}`,
          JSON.stringify({}),
          'DELETE'
        )
        setModelId(response.id)
        setModelValue(response.name)
        setUpdatableParentEntity({
          ...updatableParentEntity,
          [type]: modelIri(response.id),
        })
        handleCloseModelCreation()
        setModelViolations([])
      } catch (error) {
        setModelViolations(error.violations ? error.violations : [])
        notification(
          'error',
          error.detail !== undefined
            ? error.detail
            : 'Rekord nie został usunięty',
          'Błąd'
        )
      }
    }
    removeModel()
  }

  const handleCreateBrand = () => {
    const createBrand = async () => {
      try {
        const response = await postData(
          BRAND_ENDPOINT,
          JSON.stringify(creationBrand)
        )
        setBrandId(response.id)
        setCreationModel({
          ...creationModel,
          brand: brandIri(response.id),
        })

        handleCloseBrandCreation()
        setBrandViolations([])
      } catch (error) {
        setBrandViolations(error.violations ? error.violations : [])
      }
    }
    createBrand()
  }

  const handleEditBrand = () => {
    const editBrand = async () => {
      try {
        const response = await postData(
          `${BRAND_ENDPOINT}/${brandId}`,
          JSON.stringify(creationBrand),
          'PUT'
        )
        setBrandId(response.id)
        setCreationModel({
          ...creationModel,
          brand: brandIri(response.id),
        })

        handleCloseBrandCreation()
        setBrandViolations([])
      } catch (error) {
        setBrandViolations(error.violations ? error.violations : [])
      }
    }
    editBrand()
  }

  const handleRemoveBrand = () => {
    const removeBrand = async () => {
      try {
        const response = await postData(
          `${BRAND_ENDPOINT}/${brandId}`,
          JSON.stringify({}),
          'DELETE'
        )
        setBrandId(response.id)
        setCreationModel({
          ...creationModel,
          brand: brandIri(response.id),
        })

        handleCloseBrandCreation()
        setBrandViolations([])
      } catch (error) {
        setBrandViolations(error.violations ? error.violations : [])
        notification(
          'error',
          error.detail !== undefined
            ? error.detail
            : 'Rekord nie został usunięty',
          'Błąd'
        )
      }
    }
    removeBrand()
  }

  return (
    <React.Fragment>
      <div>
        <b>{fieldName && `${fieldName} :`}</b>
      </div>
      <div>
        <div onClick={handleOpenModel}>
          {modelValue}
          <span>
            <ExpandMoreIcon
              style={{
                float: modelValue ? 'right' : '',
                fontWeight: 'strong',
              }}
            />
          </span>
          {fieldViolations.length
            ? fieldViolations.map((violation, i) => (
                <FormHelperText key={i} error={true}>
                  {violation.message}
                </FormHelperText>
              ))
            : ''}
        </div>
        <Dialog open={openModel} onClose={handleCloseModel} fullWidth>
          <DialogTitle>
            <span>{fieldName}</span>
          </DialogTitle>
          <DialogContent className={classes.dialog}>
            <Grid container spacing={4} alignItems="center">
              <Grid item sm={9}>
                <SelectEntitySimpleField
                  fieldName={'Marka'}
                  type={'brand'}
                  endPoint={`${BRAND_ENDPOINT}`}
                  getParameters={{ type: carPartType }}
                  updatableParentEntity={creationModel}
                  setUpdatableParentEntity={setCreationModel}
                  id={brandId}
                  setId={setBrandId}
                  violations={modelViolations}
                  setValue={setBrandValue}
                />
              </Grid>
              <Grid item sm={3}>
                <IconButton
                  color="primary"
                  size="small"
                  edge="end"
                  onClick={handleOpenBrandCreation}
                >
                  <AddIcon />
                </IconButton>
                {brandId && (
                  <>
                    <IconButton
                      color="primary"
                      size="small"
                      edge="end"
                      onClick={handleOpenBrandEdit}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="default"
                      size="small"
                      edge="end"
                      onClick={handleOpenBrandRemove}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </>
                )}
              </Grid>
            </Grid>
            {brandId && (
              <Grid container spacing={4}>
                <Grid item sm={9}>
                  <SelectEntitySimpleField
                    fieldName={'Model'}
                    type={'model'}
                    endPoint={`${BRAND_ENDPOINT}/${brandId}/models`}
                    getParameters={{ type: carPartType }}
                    updatableParentEntity={updatableParentEntity}
                    setUpdatableParentEntity={setUpdatableParentEntity}
                    setValue={setModelValue}
                    setId={setModelId}
                    id={modelId}
                    violations={fieldViolations}
                    iriFetch={modelIri}
                  />
                </Grid>
                <Grid item sm={3}>
                  <IconButton
                    color="primary"
                    size="small"
                    edge="end"
                    onClick={handleOpenModelCreation}
                  >
                    <AddIcon />
                  </IconButton>
                  {modelId && (
                    <>
                      <IconButton
                        color="primary"
                        size="small"
                        edge="end"
                        onClick={handleOpenModelEdit}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="default"
                        size="small"
                        edge="end"
                        onClick={handleOpenModelRemove}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModel} color="primary">
              Akceptuj
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Dialog
        open={openModelCreation}
        onClose={handleCloseModelCreation}
        fullWidth
      >
        <DialogTitle>
          {(() => {
            let title = ''
            switch (typeEdit) {
              case typeEditValues[0]:
                title = 'Dodaj nowy model'
                break
              case typeEditValues[1]:
                title = 'Edytuj model'
                break
              case typeEditValues[2]:
                title = 'Usuń model'
                break
              default:
                title = ''
            }
            return (
              <span>
                {title} {fieldName}
              </span>
            )
          })()}
        </DialogTitle>
        <DialogContent className={classes.dialog}>
          <div>
            {typeEdit !== typeEditValues[2] ? (
              <StringField
                fieldName={'Nazwa'}
                type={'name'}
                updatableParentEntity={creationModel}
                setUpdatableParentEntity={setCreationModel}
                violations={modelViolations}
                value={typeEdit === typeEditValues[1] ? modelValue : ''}
              />
            ) : (
              <DialogContentText>
                Czy na pewno usunąć model {modelValue}?
              </DialogContentText>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          {(() => {
            switch (typeEdit) {
              case typeEditValues[0]:
                return (
                  <>
                    <Button onClick={handleCreateModel} color="primary">
                      Dodaj
                    </Button>
                  </>
                )
              case typeEditValues[1]:
                return (
                  <>
                    <Button onClick={handleEditModel} color="primary">
                      Zapisz
                    </Button>
                  </>
                )
              case typeEditValues[2]:
                return (
                  <>
                    <Button onClick={handleRemoveModel} color="primary">
                      Usuń
                    </Button>
                  </>
                )
              default:
                return <></>
            }
          })()}
          <Button onClick={handleCloseModelCreation} color="primary">
            Anuluj
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openBrandCreation}
        onClose={handleCloseBrandCreation}
        fullWidth
      >
        <DialogTitle>
          {(() => {
            let title = ''
            switch (typeEdit) {
              case typeEditValues[0]:
                title = 'Dodaj nową markę'
                break
              case typeEditValues[1]:
                title = 'Edytuj markę'
                break
              case typeEditValues[2]:
                title = 'Usuń markę'
                break
              default:
                title = ''
            }
            return (
              <span>
                {title} {fieldName}
              </span>
            )
          })()}
        </DialogTitle>
        <DialogContent className={classes.dialog}>
          <div>
            {typeEdit !== typeEditValues[2] ? (
              <StringField
                fieldName={'Nazwa'}
                type={'name'}
                updatableParentEntity={creationBrand}
                setUpdatableParentEntity={setCreationBrand}
                violations={brandViolations}
                value={typeEdit === typeEditValues[1] ? brandValue : ''}
              />
            ) : (
              <DialogContentText>
                Czy na pewno usunąć markę {brandValue}?
              </DialogContentText>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          {(() => {
            switch (typeEdit) {
              case typeEditValues[0]:
                return (
                  <>
                    <Button onClick={handleCreateBrand} color="primary">
                      Dodaj
                    </Button>
                  </>
                )
              case typeEditValues[1]:
                return (
                  <>
                    <Button onClick={handleEditBrand} color="primary">
                      Zapisz
                    </Button>
                  </>
                )
              case typeEditValues[2]:
                return (
                  <>
                    <Button onClick={handleRemoveBrand} color="primary">
                      Usuń
                    </Button>
                  </>
                )
              default:
                return <></>
            }
          })()}
          <Button onClick={handleCloseBrandCreation} color="primary">
            Anuluj
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
