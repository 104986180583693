import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import { default as SwitchMode } from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import { useTheme } from 'components/ThemeProvider'
import { makeStyles } from '@material-ui/core/styles'
import { useFetch } from '_helpers/useFetch'
import { authHeader } from '_helpers/authHeader'
import { useDispatch, useSelector } from 'react-redux'
import api from 'api'
import { Save } from '@material-ui/icons'
import { Button } from '@material-ui/core'
import { notification } from '_helpers/notification'
import { limitMappings } from '../Fleet/CanRegister/CanLimits'
import LimitField from '../../components/fields/LimitField'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const endPoint = `${process.env.REACT_APP_API_ENTRYPOINT}${api.configs}`

const useStyles = makeStyles((theme) => ({
  formControl: {
    maxWidth: 350,
    marginTop: theme.spacing(3),
  },
  save: {
    maxWidth: 100,
    marginTop: theme.spacing(3),
  },
  limits: {
    marginTop: theme.spacing(3),
  },
}))
export const General = () => {
  const themeState = useTheme()
  const classes = useStyles()
  const [time, setTime] = React.useState(1)
  const [percent, setPercent] = React.useState(1)
  const [id, setId] = React.useState(0)
  const [submit, setSubmit] = React.useState(false)
  const auth = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const [queryParams] = useState({})
  const [template, setTemplate] = React.useState({})
  const [templateViolations, setTemplateViolations] = React.useState([])

  const user = useSelector((state) => state.user.profile)

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }
  const args = [auth.data.token, dispatch]
  const res = useFetch(`${endPoint}main`, options, args, queryParams)

  useEffect(() => {
    if (res.state?.data?.amortizationMonths) {
      setTime(res?.state?.data?.amortizationMonths)
    }
    if (res.state?.data?.minimumItemProfitability) {
      setPercent(res?.state?.data?.minimumItemProfitability)
    }
    if (res.state?.data?.id) {
      setId(res?.state?.data?.id)
    }
    if (res.state?.data?.templateCanLimitAggregator) {
      setTemplate(res?.state?.data?.templateCanLimitAggregator)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [res.state])

  const handleChange = (event) => {
    setTime(event.target.value)
  }
  const handleChangePercent = (event) => {
    setPercent(event.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setSubmit(true)
  }

  useEffect(() => {
    const abotr = new AbortController()
    const fetchData = () => {
      const opt = {
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
          ...authHeader(),
        },
        method: 'PUT',
        body: JSON.stringify({
          amortizationMonths: Number(time),
          minimumItemProfitability: Number(percent),
          templateCanLimitAggregator: template,
        }),
      }
      const url = `${endPoint}${id}`
      fetch(url, opt)
        .then((response) => {
          if (response.ok) {
            return response.json()
          }
          throw response.json()
        })
        .then((response) => {
          notification('success', 'Ustawienia zostały zapisane', 'Zapisano')
          setTemplateViolations([])
          setSubmit(false)
        })
        .catch((errorPromise) => {
          errorPromise.then((error) => {
            notification(
              'error',
              'Błąd wysyłki formularza. Spróbuj jeszcze raz.',
              'Błąd zapisu'
            )
            setSubmit(false)
            setTemplateViolations(
              error.violations
                .filter((violation) =>
                  violation.propertyPath.includes('templateCanLimitAggregator')
                )
                .map((violation) => ({
                  ...violation,
                  propertyPath: violation.propertyPath.split('.')[1],
                }))
            )
          })
        })
    }

    if (submit) {
      fetchData()
    }

    return () => {
      abotr.abort()
    }
  }, [submit, id, time, percent, template])

  return (
    <form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={3}>
          <FormGroup>
            <FormControlLabel
              control={
                <SwitchMode
                  checked={themeState.dark}
                  onChange={() => themeState.toggle()}
                  aria-label="login switch"
                />
              }
              label={
                themeState.dark
                  ? 'Przełącz na tryb jasny'
                  : 'Przełącz na tryb ciemny'
              }
            />
            {user?.roles?.includes('ROLE_SUPER_ADMIN') && (
              <>
                <TextField
                  label="Czas amortyzacji (miesiące)"
                  variant="outlined"
                  type="number"
                  inputProps={{
                    min: 0,
                    step: 1,
                  }}
                  value={time}
                  onChange={handleChange}
                  classes={{ root: classes.formControl }}
                />
                <TextField
                  label="Minimalna zakładana rentowność (%)"
                  variant="outlined"
                  type="number"
                  inputProps={{
                    min: 0,
                    step: 1,
                    max: 100,
                  }}
                  value={percent}
                  onChange={handleChangePercent}
                  classes={{ root: classes.formControl }}
                />
                <Card variant="outlined" classes={{ root: classes.limits }}>
                  <CardContent>
                    <Typography variant="h5">
                      Szablon limitów rejestru CAN
                    </Typography>
                  </CardContent>
                </Card>
                <Card variant="outlined">
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs={4} key={`none`}>
                        <b>Rodzaj</b>
                      </Grid>
                      <Grid item xs={4} key={`limits`}>
                        Limit
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                {limitMappings
                  .filter((mapping) => mapping.limitIndex != null)
                  .map((mapping, index) => (
                    <Card variant="outlined" key={`limit-${index}`} m={3}>
                      <CardContent>
                        <LimitField
                          fieldName={mapping.fieldName}
                          type={mapping.limitIndex}
                          limitValue={template[mapping.limitIndex]}
                          violations={templateViolations}
                          updatableParentEntity={template}
                          setUpdatableParentEntity={setTemplate}
                          showLastValue={false}
                          isInteger={mapping.isInteger}
                        />
                      </CardContent>
                    </Card>
                  ))}
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  size="small"
                  startIcon={<Save />}
                  className={classes.save}
                  disabled={submit}
                >
                  Zapisz
                </Button>
              </>
            )}
          </FormGroup>
        </Grid>
      </Grid>
    </form>
  )
}
