import { schemaConstants } from '_constants'

export const schemaReducer = (state = null, action) => {
  switch (action.type) {
    case schemaConstants.SCHEMA_SUCCESS:
      return action.payload.schema
    case schemaConstants.SCHEMA_FAILURE:
    case schemaConstants.SCHEMA_ERASE:
      return null
    default:
      return state
  }
}
