import React from 'react'

import TyresList from './Tyres'

const moduleName = 'Opona zapasowa'
const moduleEndpoint = '/spare_tyres'
const moduleType = 'spareTyre'

export default function SpareTyresList({
  entities,
  carIri,
  currentDistanceMeter,
}) {
  return (
    <TyresList
      entities={entities}
      carIri={carIri}
      currentDistanceMeter={currentDistanceMeter}
      moduleName={moduleName}
      moduleEndpoint={moduleEndpoint}
      moduleType={moduleType}
    />
  )
}
