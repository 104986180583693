import React from 'react'

import SaveIcon from '@material-ui/icons/Save'
import CircularProgress from '@material-ui/core/CircularProgress'

import { authHeader } from '_helpers/authHeader'
import { get } from '_helpers/getProp'
import TemplateFieldFactory from './factory/TemplateFieldFactory'
import { makeStyles } from '@material-ui/core/styles'
import api from 'api'
import { useDispatch } from 'react-redux'
import { requestActions } from '../../../_actions'
import { notification } from '../../../_helpers/notification'

const useStyles = makeStyles({
  gridParent: {
    display: 'grid',
    gridAutoRows: 'minmax(32px, auto)',
    gridTemplateColumns: 'repeat(2,1fr)',
    columnGap: '25px',
    rowGap: '5px',
  },
  gridChild: {
    alignSelf: 'center',
    display: 'grid',
    gridTemplateColumns: '200px 1fr',
    borderBottom: '1px solid lightgrey',
    paddingBottom: '5px',
  },
  icon: {
    position: 'absolute',
    right: '0',
    top: '-45px',
  },
  form: {
    position: 'relative',
    marginTop: '10px',
    marginBottom: '30px',
  },
})
const templateForm = (
  classes,
  template,
  updatableTemplate,
  setUpdatableTemplate,
  header,
  handleSave,
  loading,
  violations,
  changeInfo,
  startData
) => {
  return (
    <div className={classes.gridParent}>
      {header.map((y, k) => (
        <div className={classes.gridChild} key={`trc-${k}`}>
          <TemplateFieldFactory
            fieldName={y.name}
            type={y.prop}
            value={get(startData, y.prop, 'Wybierz')}
            violations={violations}
            updatableParentEntity={updatableTemplate}
            setUpdatableParentEntity={setUpdatableTemplate}
            templateId={template.id}
            changeInfo={changeInfo}
          />
        </div>
      ))}
      <div className={classes.icon}>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <SaveIcon onClick={handleSave} color="primary" />
        )}
      </div>
    </div>
  )
}

export default function Template({
  template,
  setTemplate,
  header,
  changeInfo,
  startData,
  templateId,
}) {
  const [updatableTemplate, setUpdatableTemplate] = React.useState({})
  const [loading, setLoading] = React.useState(false)
  const [violations, setViolations] = React.useState([])
  const classes = useStyles()
  const dispatch = useDispatch()
  const handleSave = () => {
    setLoading(true)

    let url = `${process.env.REACT_APP_API_ENTRYPOINT}${api.documentTemplate}`
    if (templateId) {
      url = `${process.env.REACT_APP_API_ENTRYPOINT}${api.documentTemplate}/${templateId}`
    }
    dispatch(requestActions.start(url))
    fetch(url, {
      method: templateId ? 'PUT' : 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
      },
      body: JSON.stringify(template),
    })
      .then((response) => {
        setLoading(false)
        if (response.ok) {
          return response.json()
        }
        throw response.json()
      })
      .then((response) => {
        dispatch(requestActions.success())
        notification('success', 'Szablon został zapisany', 'Szablon')
        setTemplate((prev) => ({
          ...prev,
          data: response,
        }))

        setViolations([])
      })
      .catch((errorPromise) => {
        notification('error', 'Błąd zapisu. Spróbuj jeszcze raz.', 'Szablon')
        errorPromise.then((error) => {
          if (error.violations) {
            setViolations(error.violations)
          }
        })
      })
  }

  return (
    <div className={classes.form}>
      {templateForm(
        classes,
        template,
        updatableTemplate,
        setUpdatableTemplate,
        header,
        handleSave,
        loading,
        violations,
        changeInfo,
        startData
      )}
    </div>
  )
}
