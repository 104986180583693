import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import { Title } from 'components/Title'
import { authHeader } from '_helpers/authHeader'
import { useSelector, useDispatch } from 'react-redux'
import { useFetch } from '_helpers/useFetch'
import NestedTableList from '../../../components/table/NestedTableList'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  gridParent: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '-48px',
    marginBottom: '20px',
  },
})
const typingTimeout = 1000
const archiveCarsEndpoint = '/car_archiveds'
const replacementEndpointFunction = (item) =>
  `/car_series/${item.car.carSerie.id}/replacements`

const outerListMapping = [
  {
    name: 'ID',
    prop: 'car.id',
    link: (id) => `/fleet/cars/${id}`,
  },
  {
    name: 'MARKA',
    prop: 'car.carSerie.model.brand.name',
  },
  {
    name: 'MODEL',
    prop: 'car.carSerie.model.name',
  },
  {
    name: 'ROCZNIK',
    prop: 'car.productionYear',
  },
  {
    name: 'SILNIK',
    prop: 'car.carSerie.enginePower.name',
  },
  {
    name: 'TYP',
    prop: 'car.carSerie.bodyType.name',
  },
  {
    name: 'NR REJ',
    prop: 'car.registrationNumber',
  },
  {
    name: 'LOKALIZACJA',
    prop: 'car.rentalPlace.city',
  },
  {
    name: 'STATUS',
    prop: 'car.status',
  },
  {
    name: 'POCZĄTEK UŻYTKOWANIA',
    prop: 'car.dateOfObtainment',
  },
  {
    name: 'KONIEC UŻYTKOWANIA',
    prop: 'dateOfDisposal',
  },
  {
    name: 'RENTOWNOŚĆ',
    prop: 'profitablityValue',
  },
  {
    name: 'ZAMIENNIKI',
    prop: '',
    collapsable: true,
  },
]
const mediumListMapping = [
  {
    name: 'MARKA',
    prop: 'model.brand.name',
    link: (id) => `/fleet/models/series/${id}`,
  },
  {
    name: 'MODEL',
    prop: 'model.name',
  },
  {
    name: 'SILNIK',
    prop: 'enginePower.name',
  },
  {
    name: 'TYP',
    prop: 'bodyType.name',
  },
]
export default function CarArchiveList(link) {
  const classes = useStyles()
  const [queryParams, setQueryParams] = useState({
    search: '',
    page: 1,
  })
  const [typingState, setTypingState] = useState({
    timeoutId: null,
  })
  const [searchText, setSearchText] = useState('')
  const auth = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const args = [auth.data.token, dispatch]

  const res = useFetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}${archiveCarsEndpoint}`,
    options,
    args,
    queryParams
  )

  const handleSearchChange = (e) => {
    const search = e.target.value
    setSearchText(search)

    clearTimeout(typingState.timeoutId)
    const timeoutId = setTimeout(() => {
      setTypingState({
        ...typingState,
        timeoutId: null,
      })
      setQueryParams({
        ...queryParams,
        search: search,
        page: 1,
      })
    }, typingTimeout)

    setTypingState({
      ...typingState,
      timeoutId: timeoutId,
    })
  }

  return (
    <React.Fragment>
      <Title>Pojazdy</Title>
      <div className={classes.gridParent}>
        <TextField
          label="Szukaj pojazdu"
          value={searchText}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <NestedTableList
        data={res.state.data?.content}
        isLoading={res.state.isLoading}
        error={res.state.error}
        outerListMapping={outerListMapping}
        mediumListMapping={mediumListMapping}
        mediumListEndpointFunction={replacementEndpointFunction}
        dataLength={res.state.data?.totalItems}
        colWidth="260px"
        pagination={true}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        {...link}
      />
    </React.Fragment>
  )
}
