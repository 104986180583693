import React from 'react'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { authHeader } from '_helpers/authHeader'
import InlineForm from 'components/InlineForm'
import { makeStyles } from '@material-ui/core/styles'
import { get } from '_helpers/getProp'
import { notification } from '_helpers/notification'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { default as SwitchMode } from '@material-ui/core/Switch/Switch'
import FormGroup from '@material-ui/core/FormGroup'

const useStyles = makeStyles({
  gridParent: {
    display: 'grid',
    gridAutoRows: 'minmax(32px, auto)',
  },
  gridChild: {
    alignSelf: 'center',
    display: 'grid',
    gridTemplateColumns: '100px 300px',
  },
  icon: {
    position: 'absolute',
    left: '400px',
    top: '0',
  },
  form: {
    position: 'relative',
    marginTop: '10px',
  },
})
const row = (
  classes,
  x,
  i,
  header,
  handleRemove,
  startEditing,
  editIdx,
  handleSave,
  stopEditing,
  handleChangeStatus,
  roleDelete = false
) => {
  const currentlyEditing = editIdx === i
  return currentlyEditing ? (
    <div className={classes.gridParent} key={`inline-form-${i}`}>
      <InlineForm
        handleSave={handleSave}
        header={header}
        x={x}
        i={i}
        stopEditing={stopEditing}
      />
    </div>
  ) : (
    <div className={classes.gridParent} key={`tr-${i}`}>
      {header.map((y, k) => (
        <div className={classes.gridChild} key={`trc-${k}`}>
          <b style={{ marginTop: y.prop === 'accepted' ? 35 : 0 }}>{y.name}:</b>
          {y.prop === 'accepted' ? (
            <>
              <FormGroup style={{ marginTop: 30 }}>
                <FormControlLabel
                  control={
                    <SwitchMode
                      checked={get(x, y.prop, '')}
                      onChange={handleChangeStatus}
                    />
                  }
                />
              </FormGroup>
            </>
          ) : (
            <>{get(x, y.prop, '')}</>
          )}
        </div>
      ))}
      <div className={classes.icon}>
        <EditIcon onClick={() => startEditing(i)} />
        {(roleDelete || !x.roles.includes('ROLE_SUPER_ADMIN')) && (
          <DeleteIcon onClick={() => handleRemove(i)} />
        )}
      </div>
    </div>
  )
}

export const Card = ({ data, header, setData, setEdit, editIdx, userId }) => {
  const classes = useStyles()
  let history = useHistory()

  const user = useSelector((state) => state.user.profile)

  const handleRemove = (i) => {
    fetch(
      `${process.env.REACT_APP_API_ENTRYPOINT}/users/${userId}/softDelete`,
      {
        method: 'PUT',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
          ...authHeader(),
        },
        body: JSON.stringify({}),
      }
    )
      .then((response) => {
        if (response.ok) {
          return response
        }
        throw new Error(response)
      })
      .then(() => {
        notification('success', 'Usunięto użytkownika', 'Komunikat')
        history.push('/settings/users')
      })
      .catch((error) => {
        // errorPromise.then((error) => {
        if (error?.violations !== undefined) {
          let errors = error.violations.map((e) => {
            return JSON.stringify(e.message)
          })

          errors = errors.join(',')

          notification('error', errors, 'Błąd')
        } else {
          notification('error', 'Spróbuj ponownie', 'Błąd')
        }
        // })
      })

    stopEditing()
  }

  const startEditing = (i) => {
    setEdit({ editIdx: i })
  }

  const stopEditing = () => {
    setEdit({ editIdx: -1 })
  }

  const handleSave = (i, x) => {
    setData(() => ({
      items: data.map((row, j) => (j === i ? x : row)),
    }))
    fetch(
      data[0].carCustomer || data[0].fitnessCustomer
        ? `${process.env.REACT_APP_API_ENTRYPOINT}/users/${userId}/customer`
        : `${process.env.REACT_APP_API_ENTRYPOINT}/users/${userId}/administrator`,
      {
        method: 'PUT',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          ...authHeader(),
        },
        body: JSON.stringify({
          lastName: x.lastName || '',
          firstName: x.firstName || '',
          accepted: x.accepted,
          ...x.personalDetails,
        }),
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw response.json()
      })
      .then(() => {
        notification('success', 'Zapisano', 'Komunikat')
      })
      .catch((errorPromise) => {
        errorPromise.then((error) => {
          if (error.violations) {
            let errors = error.violations.map((e) => {
              return JSON.stringify(e.message)
            })

            errors = errors.join(',')

            notification('error', errors, 'Błąd')
          } else {
            notification('error', 'Spróbuj ponownie', 'Błąd')
          }
        })
      })

    stopEditing()
  }

  const [open, setOpen] = React.useState(false)

  const handleClickOpen = (i) => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChangeStatus = (e) => {
    setData((prevState) => ({ ...prevState, accepted: e.target.checked }))
    fetch(
      `${process.env.REACT_APP_API_ENTRYPOINT}/users/${userId}/acceptCustomer`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(),
        },
        body: JSON.stringify({
          accepted: e.target.checked,
        }),
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw response.json()
      })
      .then((response) => {
        setData(() => ({
          items: [].concat(response),
        }))
        notification('success', 'Zapisano', 'Komunikat')
      })
      .catch((errorPromise) => {
        errorPromise.then((error) => {
          if (error.violations) {
            let errors = error.violations.map((e) => {
              return JSON.stringify(e.message)
            })

            errors = errors.join(',')

            notification('error', errors, 'Błąd')
          }
        })
      })
  }

  return (
    <div className={classes.form}>
      {data.map((x, i) =>
        row(
          classes,
          x,
          i,
          header,
          handleClickOpen,
          startEditing,
          editIdx,
          handleSave,
          stopEditing,
          handleChangeStatus,
          user?.roles?.includes('ROLE_SUPER_ADMIN') ? true : false
        )
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Czy na pewno chcesz usunąć wybranego użytkownika?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Usunięcie użytkownika jest nieodwracalne.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Anuluj
          </Button>
          <Button onClick={handleRemove} color="primary" autoFocus>
            Usuń
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
