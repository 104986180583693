import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import { Route, Switch } from 'react-router-dom'
import { Copyright } from 'components/Copyright'
import { HomeAppBar } from 'components/bar/HomeAppBar'
import HomeBoxes from './HomeBoxes'
import HomeList from './HomeList'
import api from 'api'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}))

export const Home = () => {
  const classes = useStyles()

  let boxes = [
    {
      //match: `${api.home}_hires?hireStatus[]=hire:Reserved&hireStatus[]=hire:Accepted&hireStatus[]=hire:Hire`,
      match: `${api.home}_hires`,
      queryParams: {
        'hireStatus[]': ['hire:Reserved', 'hire:Accepted', 'hire:Hire'],
        'order[startDate]': 'DESC',
      },
      title: 'Ostatnie rezerwacje i najmy',
      keys: [
        'customer.personalDetails.companyName',
        'hiredItems.length',
        'startDate',
        'endDate',
        'sourceRentalPlace.name',
        'hireStatus',
      ],
      header: [
        'KLIENT',
        'WYPOŻYCZONYCH OBIEKTÓW',
        'OD',
        'DO',
        'WYPOŻYCZALNIA',
        'STATUS',
      ],
      linkMore: '/leases',
      md: 6,
    },
    {
      match: 'car_breakdowns',
      queryParams: {
        'hireItem.hireItemStatus': 'hireItem:Breakdown',
        'order[breakdownDatetime]': 'DESC',
      },
      title: 'Awarie i usterki',
      keys: [
        'hireItem.hire.customer.personalDetails.companyName',
        'hireItem.car.fullName',
        'breakdownDatetime',
      ],
      header: ['KLIENT', 'FLOTA', 'CZAS'],
      linkMore: '/leases/notifications',
      md: 3,
    },
    {
      match: 'can_alerts',
      queryParams: {
        'order[createdAt]': 'DESC',
      },
      title: 'Nadużycia',
      keys: [
        'hireItem.hire.customer.personalDetails.companyName',
        'register.car.fullName',
        'message',
      ],
      header: ['KLIENT', 'FLOTA', 'TREŚĆ'],
      linkMore: '/leases/notifications',
      md: 3,
    },
    {
      match: 'car_part_warnings',
      queryParams: {},
      title:
        'Zbliżające się terminy przeglądów, ubezpieczeń i obsługi pojazdów ',
      keys: [
        'carPart.car.fullName',
        'carPart.car.registrationNumber',
        'carPart.car.rentalPlace.name',
        'message',
        'carPart.type',
      ],
      header: ['NAZWA', 'NR REJ', 'WYPOŻYCZALNIA', 'POZOSTAŁO', 'RODZAJ'],
      md: 6,
    },
    {
      match: `payment_${api.home}_invoice_warnings`,
      queryParams: {
        'order[invoice.paymentDate]': 'DESC',
      },
      title: 'Zaległe płatności',
      keys: [
        'invoice.customer.personalDetails.companyName',
        'invoice.hire.hiredItems.length',
        'invoice.hire.sourceRentalPlace.name',
        'invoice.paymentDate',
        'message',
        'invoice.toBePaid',
      ],
      header: [
        'KLIENT',
        'WYPOŻYCZONYCH OBIEKTÓW',
        'WYPOŻYCZALNIA',
        'TERMIN',
        'CZAS',
        'ZALEGŁOŚĆ',
      ],
      md: 6,
    },
    {
      match: `fitness_repairs`,
      queryParams: {
        perPage: '5',
      },
      title: 'Ostatnie naprawy',
      keys: [
        'fitnessEquipment.fullName',
        'startDate',
        'cost',
        'name',
        'endDate',
      ],
      header: [
        'WYPOSAŻENIE',
        'DATA ROZPOCZĘCIA',
        'KOSZT',
        'NAZWA',
        'DATA ZAKOŃCZENIA',
      ],
      md: 6,
    },
  ]

  if (api.appType === 'fitness') {
    const removeValFromIndex = [1, 2, 3]

    const indexSet = new Set(removeValFromIndex)

    boxes = boxes.filter((value, i) => !indexSet.has(i))

    const lastBoxremoveValFromIndex = [2]
    const lastBoxindexSet = new Set(lastBoxremoveValFromIndex)

    boxes[boxes.length - 1].keys = boxes[boxes.length - 1].keys.filter(
      (value, i) => !lastBoxindexSet.has(i)
    )
    boxes[boxes.length - 1].header = boxes[boxes.length - 1].header.filter(
      (value, i) => !lastBoxindexSet.has(i)
    )
  } else {
    const removeValFromIndex = [5]

    const indexSet = new Set(removeValFromIndex)

    boxes = boxes.filter((value, i) => !indexSet.has(i))

    const lastBoxremoveValFromIndex = [2]
    const lastBoxindexSet = new Set(lastBoxremoveValFromIndex)

    boxes[boxes.length - 1].keys = boxes[boxes.length - 1].keys.filter(
      (value, i) => !lastBoxindexSet.has(i)
    )
    boxes[boxes.length - 1].header = boxes[boxes.length - 1].header.filter(
      (value, i) => !lastBoxindexSet.has(i)
    )
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <HomeAppBar />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          <Switch>
            <Route
              exact
              path="/home/:boxId"
              render={(match) => <HomeList {...match} boxes={boxes} />}
            />

            <Route component={() => <HomeBoxes {...boxes} />} />
          </Switch>
        </Container>
        <Copyright />
      </main>
    </div>
  )
}
