import React from 'react'
import { authHeader } from '_helpers/authHeader'
import { Redirect } from 'react-router-dom'
import FitnessModelForm from './FitnessModelForm'
import { notification } from '_helpers/notification'

export default function FitnessModelCreation({
  fitnessModel,
  setFitnessModel,
  fitnessBrandIri,
}) {
  const [updatablefitnessModel, setUpdatablefitnessModel] = React.useState({})
  const [loading, setLoading] = React.useState(false)
  const [violations, setViolations] = React.useState([])
  const handleSave = () => {
    setLoading(true)

    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/fitness_models`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
      },
      body: JSON.stringify({
        ...updatablefitnessModel,
        brand: fitnessBrandIri,
      }),
    })
      .then((response) => {
        setLoading(false)
        if (response.ok) {
          return response.json()
        }
        throw response.json()
      })
      .then((response) => {
        setFitnessModel(response)
        notification('success', 'Zapisano', 'Komunikat')
        setViolations([])
      })
      .catch((errorPromise) => {
        errorPromise.then((error) => {
          if (error.violations) {
            console.log(error.violations)
            setViolations(error.violations)
            let errors = error.violations.map((e) => {
              return JSON.stringify(e.message)
            })

            errors = errors.join(',')

            notification('error', errors, 'Błąd')
          }
        })
      })
  }

  return (
    <>
      {!!fitnessModel.id ? (
        <Redirect to={`/equipment/models/${fitnessModel.id}`} />
      ) : (
        <div
          style={{
            position: 'relative',
            marginTop: '10px',
          }}
        >
          <FitnessModelForm
            fitnessModel={fitnessModel}
            updatablefitnessModel={updatablefitnessModel}
            setUpdatablefitnessModel={setUpdatablefitnessModel}
            handleSave={handleSave}
            loading={loading}
            violations={violations}
          />
        </div>
      )}
    </>
  )
}
