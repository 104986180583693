import React from 'react'

import ModelModuleWithDateAndDistanceValue from './ModelModuleWithDateAndDistance'

const moduleName = 'Filtr oleju'
const moduleEndpoint = '/oil_filters'
const moduleType = 'oilFilter'

export default function OilFilterList({
  entities,
  carIri,
  currentDistanceMeter,
}) {
  return (
    <ModelModuleWithDateAndDistanceValue
      entities={entities}
      carIri={carIri}
      currentDistanceMeter={currentDistanceMeter}
      moduleEndpoint={moduleEndpoint}
      moduleName={moduleName}
      moduleType={moduleType}
    />
  )
}
