import React from 'react'
import { prefixPaths } from '_helpers/prefixPaths'
import { generateRoutes } from '_helpers/generateRoutes'
import { generateBreadcrumbs } from '_helpers/generateBreadcrumbs'
import { TrendingUp } from '@material-ui/icons'
import { Route, Redirect } from 'react-router-dom'
import { Page } from 'pages/Page'
import { NotFound } from 'pages/NotFound'
import List from './List'
import { ReportContainer } from './Report'

const menu = () => [routes()[0]]

const routes = () => [
  {
    title: 'Raport rentowności',
    type: Route,
    render: () => <List link="cars" />,
    path: '/profitability',
    exact: true,
    icon: TrendingUp,
  },

  {
    title: 'Wskaźnik rentowności sprzedaży',
    type: Route,
    component: ReportContainer,
    path: '/profitability/:reportId',
    exact: true,
  },

  {
    title: 'Raporty',
    type: Redirect,
    from: '/',
    to: '/profitability',
    exact: true,
  },
  {
    type: Route,
    component: NotFound,
    path: '/*',
  },
]

export const Reports = (props) => {
  const { match, location } = props
  return (
    <Page
      menu={prefixPaths(match.url, menu())}
      routes={generateRoutes(
        prefixPaths(match.url, routes(), ['path', 'from', 'to'])
      )}
      breadcrumbs={generateBreadcrumbs(
        location.pathname,
        prefixPaths(match.url, routes(), ['path', 'from'])
      )}
    />
  )
}
