import { authConstants } from '_constants'
import { tokenExpiration } from '_helpers/tokenExpiration'

export const authReducer = (state = {}, action) => {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      return {
        isLogging: true,
        data: { email: action.payload.email },
      }
    case authConstants.LOGIN_SUCCESS:
    case authConstants.REFRESH_SUCCESS:
    case authConstants.LOGIN_RESTORE:
      return {
        isLogged: true,
        data: {
          token: action.payload.token,
          exp: tokenExpiration(action.payload.token),
        },
      }
    case authConstants.REFRESH_REQUEST:
      return {
        isRefreshing: true,
        ...state,
      }
    case authConstants.REFRESH_FAILURE:
    case authConstants.LOGIN_FAILURE:
    case authConstants.LOGOUT:
      return {}
    default:
      return state
  }
}
