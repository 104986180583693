import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import HomeBox from './HomeBox'
import api from 'api'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: '100%',
  },
}))

export default function HomeBoxes(boxes) {
  const classes = useStyles()

  return (
    <Grid container spacing={3}>
      {Object.keys(boxes).map((e, i) => (
        <Grid
          item
          xs={12}
          md={i === 2 && api.appType === 'fitness' ? 12 : boxes[i].md}
          key={`hm-${i}`}
        >
          <Paper className={classes.paper}>
            {boxes && <HomeBox {...boxes[i]}></HomeBox>}
          </Paper>
        </Grid>
      ))}
    </Grid>
  )
}
