import React from 'react'
import { prefixPaths } from '_helpers/prefixPaths'
import { generateRoutes } from '_helpers/generateRoutes'
import { generateBreadcrumbs } from '_helpers/generateBreadcrumbs'
import { ListAlt, Person } from '@material-ui/icons'
import { Route, Redirect } from 'react-router-dom'
import { Page } from 'pages/Page'
import { NotFound } from 'pages/NotFound'
import { List } from './List'
import { CustomerContainer } from './Customer'
import { CustomerFormContainer } from './Customer/form/CustomerFormContainer'
import { Edit as UserEdit } from 'pages/Settings/Users/Edit'
import AdditionalList from 'pages/Fleet/AdditionalList/AdditionalList'
import api from 'api'

const menu = () => [routes()[0], routes()[2], routes()[3]]

const routes = () => [
  {
    title: 'Lista klientów',
    type: Route,
    render: () => <List link="list" />,
    path: '/list',
    exact: true,
    icon: Person,
  },
  {
    title: 'Edycja klienta',
    type: Route,
    component: UserEdit,
    path: '/list/:customerId/edit/:userId',
    exact: true,
  },
  {
    title: 'Segment cennika',
    type: Route,
    render: () => (
      <AdditionalList
        endPoint={api.segment}
        title="Segment cennika"
        fields={[
          {
            name: 'Nazwa',
            prop: 'name',
            type: 'text',
          },
          {
            name: 'Kaucja',
            prop: 'bail',
            type: 'number',
          },
        ]}
      />
    ),
    path: '/segment',
    exact: true,
    icon: ListAlt,
  },
  {
    title: 'Poziom zniżek',
    type: Route,
    render: () => (
      <AdditionalList
        endPoint={api.discountLevel}
        title="Poziom zniżek"
        fields={[
          {
            name: 'Nazwa',
            prop: 'name',
            type: 'text',
          },
          {
            name: 'Zniżka',
            prop: 'discountRate',
            type: 'number',
          },
          {
            name: 'Minimalne wydatki',
            prop: 'minExpenses',
            type: 'number',
          },
          {
            name: 'Maksymalne wydatki',
            prop: 'maxExpenses',
            type: 'number',
          },
        ]}
      />
    ),
    path: '/discount-level',
    exact: true,
    icon: ListAlt,
  },
  {
    title: 'Edycja klienta',
    type: Route,
    component: CustomerContainer,
    path: '/list/:customerId',
    exact: true,
  },
  {
    title: 'Dodaj klienta',
    type: Route,
    component: CustomerFormContainer,
    path: '/add',
    exact: true,
  },
  {
    title: 'Klienci',
    type: Redirect,
    from: '/',
    to: '/list',
    exact: true,
  },
  {
    type: Route,
    component: NotFound,
    path: '/*',
  },
]

export const Customers = (props) => {
  const { match, location } = props
  return (
    <Page
      menu={prefixPaths(match.url, menu())}
      routes={generateRoutes(
        prefixPaths(match.url, routes(), ['path', 'from', 'to'])
      )}
      breadcrumbs={generateBreadcrumbs(
        location.pathname,
        prefixPaths(match.url, routes(), ['path', 'from'])
      )}
    />
  )
}
