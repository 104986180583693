import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import SaveIcon from '@material-ui/icons/Save'
import { makeStyles } from '@material-ui/core/styles'
import {EquipmentCreateFormFieldFactory} from "./EquipmentCreateFormFieldFactory";

const useStyles = makeStyles({
  gridParent: {
    display: 'grid',
    gridAutoRows: 'minmax(32px, auto)',
    gridTemplateColumns: 'repeat(2,1fr)',
    columnGap: '25px',
    rowGap: '5px',
    position: 'relative',
  },
  gridChild: {
    alignSelf: 'center',
    display: 'grid',
    gridTemplateColumns: '250px 1fr',
    borderBottom: '1px solid lightgrey',
    paddingBottom: '5px',
  },
  icon: {
    position: 'absolute',
    right: '0',
    top: '-15px',
  },
})

const HEADER = [
  {
    name: 'Id',
    prop: 'id',
  },
  {
    name: 'Identyfikator',
    prop: 'identifier',
  },
  {
    name: 'Rok produkcji',
    prop: 'yearOfProduction',
  },
  {
    name: 'Segment cennika',
    prop: 'segment',
  },
  {
    name: 'Data zakupu',
    prop: 'dateOfObtainment',
  },
  {
    name: 'Cena zakupu',
    prop: 'purchasePrice',
  },
]

export const EquipmentCreateForm = ({
  fitnessEquipment,
  updatablefitnessEquipment,
  setUpdatablefitnessEquipment,
  handleSave,
  loading,
  violations,
}) => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.gridParent}>
        {HEADER.map((y, k) => (
          <div className={classes.gridChild} key={`trc-${k}`}>
            <EquipmentCreateFormFieldFactory
              value={fitnessEquipment[y.prop]}
              fieldName={y.name}
              setUpdatableParentEntity={setUpdatablefitnessEquipment}
              type={y.prop}
              updatableParentEntity={updatablefitnessEquipment}
              violations={violations}
            />
          </div>
        ))}
        <div className={classes.icon}>
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <SaveIcon onClick={handleSave} color="primary" />
          )}
        </div>
      </div>
    </>
  )
}
