import React from 'react'
import StringField from 'components/fields/StringField'
import SelectEntityField from 'components/fields/SelectEntityField'
import MoneyField from 'components/fields/MoneyField'
import DatePickerField from 'components/fields/DatePickerField'

const parameterizedEndpoint = '/fitness_segments'

export const EquipmentCreateFormFieldFactory = ({
  fieldName,
  type,
  value,
  violations,
  updatableParentEntity,
  setUpdatableParentEntity,
}) => {
  switch (type) {
    case 'id':
      return (
        <StringField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
          readOnly={true}
        />
      )
    case 'purchasePrice':
      return (
        <MoneyField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'dateOfObtainment':
      return (
        <DatePickerField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'segment':
      return (
        <SelectEntityField
          fieldName={fieldName}
          selectableEntity={value}
          type={type}
          endPoint={parameterizedEndpoint}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    default:
      return (
        <StringField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
  }
}
