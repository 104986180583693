import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  CssBaseline,
  TextField,
} from '@material-ui/core'
import { validate } from 'email-validator'
import { Copyright } from 'components/Copyright'
import { useStyles } from './LoginStyles'

export const Login = ({ values, handlers, status }) => {
  const classes = useStyles()

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.avatar}>
          <img
            src={`/images/${
              process.env.REACT_APP_TYPE === 'cars' ? 'logo' : 'logo-f-sm'
            }.png`}
            alt="logo"
          />
        </div>
        <Typography component="h1" variant="h5">
          Logowanie
        </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={handlers.handleSubmit}
        >
          <TextField
            error={status.submitted && !validate(values.email)}
            helperText={
              status.submitted && !validate(values.email)
                ? 'Niepoprawny e-mail'
                : null
            }
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={values.email}
            onChange={handlers.handleChange}
          />
          <TextField
            error={status.submitted && !values.password}
            helperText={
              status.submitted && !values.password
                ? 'Hasło jest wymagane'
                : null
            }
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Hasło"
            type="password"
            id="password"
            autoComplete="current-password"
            value={values.password}
            onChange={handlers.handleChange}
          />

          <Button
            disabled={status.isLogging}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {status.isLogging ? (
              <img
                className={classes.spinner}
                src={`/images/${
                  process.env.REACT_APP_TYPE === 'cars' ? 'logo' : 'logo-f-sm'
                }.png`}
                alt="loading icon"
              />
            ) : (
              'Zaloguj się'
            )}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/security/startResettingPassword" variant="body2">
                Zapomniałeś hasła?
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  )
}

Login.propTypes = {
  values: PropTypes.shape({
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
  }).isRequired,
  handlers: PropTypes.shape({
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  }).isRequired,
  status: PropTypes.shape({
    submitted: PropTypes.bool,
    isLogging: PropTypes.bool,
  }).isRequired,
}
