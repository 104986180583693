import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import RentalPlaceDetail from './RentalPlaceDetail'
import { authHeader } from '_helpers/authHeader'
import Alert from '@material-ui/lab/Alert'
import { Title } from 'components/Title'
import { makeStyles } from '@material-ui/core/styles'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))
const RentalPlaceDetailContainer = ({ match, endPoint }) => {
  const classes = useStyles()
  const {
    params: { rentalPlaceId },
  } = match

  const iriFunction = (id) => `${endPoint}/${id}`

  const [rentalPlace, setRentalPlace] = useState(null)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const auth = useSelector((state) => state.auth)

  useEffect(() => {
    const fetchData = async (slug) => {
      setLoading(true)
      setError(false)
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_ENTRYPOINT}${slug}`,
          {
            method: 'GET',
            headers: {
              accept: 'application/json',
              ...authHeader(),
            },
          }
        )
        const result = await response.json()
        setRentalPlace(() => result)
      } catch (error) {
        setError(true)
      }
      setLoading(false)
    }
    fetchData(iriFunction(rentalPlaceId))
    // eslint-disable-next-line
  }, [auth.data.token, rentalPlaceId])

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {error && (
        <Alert severity="error">wystąpił błąd podczas pobierania danych</Alert>
      )}

      {rentalPlace && (
        <>
          <Title>{rentalPlace.name}</Title>
          <RentalPlaceDetail
            rentalPlace={rentalPlace}
            setRentalPlace={setRentalPlace}
            iri={iriFunction(rentalPlaceId)}
          />
        </>
      )}
    </>
  )
}

export default RentalPlaceDetailContainer
