import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { Link } from 'react-router-dom'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

const useStyles = makeStyles((theme) => ({
  rightIcon: {
    float: 'right',
    fontWeight: 'strong',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
}))

export default function EquipmentPage({
  fieldName,
  value,
  listEndpoint,
  type,
  readOnly = false,
}) {
  const classes = useStyles()

  return (
    <React.Fragment>
      <div>
        <b>{fieldName}:</b>
      </div>
      {readOnly ? (
        <span>Edytowalne po dodaniu obiektu</span>
      ) : (
        <Link to={listEndpoint} className={classes.link}>
          {value}
          <span>
            <ChevronRightIcon className={classes.rightIcon} />
          </span>
        </Link>
      )}
    </React.Fragment>
  )
}
