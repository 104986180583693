import React, { useState } from 'react'

import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import { useSelector, useDispatch } from 'react-redux'
import { Title } from 'components/Title'
import Table from 'components/table/TableList'
import { authHeader } from '_helpers/authHeader'
import { useFetch } from '_helpers/useFetch'
import { makeStyles } from '@material-ui/core/styles'
import api from 'api'

const useStyles = makeStyles({
  gridParent: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '-48px',
    marginBottom: '20px',
  },
})
const endPoint = `${process.env.REACT_APP_API_ENTRYPOINT}${api.hireItems}?hireItemStatus[]=hireItem:Breakdown&`
const typingTimeout = 1000

const mainData = [
  {
    name: 'KLIENT',
    prop: 'hire.customer.personalDetails.companyName',
  },
  {
    name: api.appType === 'cars' ? 'FLOTA' : 'MASZYNA',
    prop:
      api.appType === 'cars' ? 'car.fullName' : 'fitnessEquipment.model.name',
  },
  {
    name: 'NR REJESTRACYJNY',
    prop: 'car.registrationNumber',
  },
  {
    name: 'WYPOŻYCZALNIA',
    prop: 'hire.sourceRentalPlace.name',
  },
  {
    name: 'OD',
    prop: 'startDate',
  },
  {
    name: 'DO',
    prop: 'deadlineDate',
  },
  {
    name: 'CZAS',
    prop: 'breakdowns',
  },

  {
    name: 'STATUS',
    prop: 'hireItemStatus',
  },
]

if (api.appType === 'fitness') mainData.splice(2, 1)

export const Breakdowns = (link) => {
  const classes = useStyles()
  const auth = useSelector((state) => state.auth)
  const [queryParams, setQueryParams] = useState({
    search: '',
    page: 1,
  })
  const [typingState, setTypingState] = useState({
    timeoutId: null,
  })
  const [searchText, setSearchText] = useState('')
  const dispatch = useDispatch()

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const args = [auth.data.token, dispatch]
  const res = useFetch(endPoint, options, args, queryParams)

  const handleSearchChange = (e) => {
    const search = e.target.value
    setSearchText(search)

    clearTimeout(typingState.timeoutId)
    const timeoutId = setTimeout(() => {
      setTypingState({
        ...typingState,
        timeoutId: null,
      })
      setQueryParams({
        ...queryParams,
        search: search,
        page: 1,
      })
    }, typingTimeout)

    setTypingState({
      ...typingState,
      timeoutId: timeoutId,
    })
  }

  return (
    <React.Fragment>
      <Title>Zgłoszenia</Title>
      <div className={classes.gridParent}>
        <TextField
          label="Szukaj najmu"
          value={searchText}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <Table
        data={res.state.data?.content}
        isLoading={res.state.isLoading}
        error={res.state.error}
        mainData={mainData}
        dataLength={res.state.data?.totalItems}
        pagination={true}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        {...link}
      />
    </React.Fragment>
  )
}
