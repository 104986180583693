import React from 'react'
import { prefixPaths } from '_helpers/prefixPaths'
import { generateRoutes } from '_helpers/generateRoutes'
import { generateBreadcrumbs } from '_helpers/generateBreadcrumbs'
import { Description } from '@material-ui/icons'
import { Route, Redirect } from 'react-router-dom'
import { Page } from 'pages/Page'
import { NotFound } from 'pages/NotFound'
import EnhancedTable from './List'
import { InvoiceContainer } from './Invoice'
import { InvoiceNewContainer } from './Invoice'
const menu = () => [routes()[0]]

const routes = () => [
  {
    title: 'Lista faktur',
    type: Route,
    render: () => <EnhancedTable />,
    path: '/list',
    exact: true,
    icon: Description,
  },
  {
    title: 'Nowa faktura',
    type: Route,
    component: InvoiceNewContainer,
    path: '/list/new',
    exact: true,
  },
  {
    title: 'Nowa faktura',
    type: Route,
    component: InvoiceNewContainer,
    path: '/list/new/connected/:connectedId',
    exact: true,
  },
  {
    title: 'Nowa faktura korygująca',
    type: Route,
    component: InvoiceNewContainer,
    path: '/list/new/correction/:correctionId',
    exact: true,
  },
  {
    title: 'Nowa faktura',
    type: Route,
    component: InvoiceNewContainer,
    path: '/list/new/similar/:similarId',
    exact: true,
  },
  {
    title: 'Faktura',
    type: Route,
    component: InvoiceContainer,
    path: '/list/:invoiceId',
    exact: true,
  },

  {
    title: 'Edycja faktury',
    type: Route,
    component: InvoiceNewContainer,
    path: '/list/edit/:editId',
    exact: true,
  },

  {
    title: 'Faktury',
    type: Redirect,
    from: '/',
    to: '/list',
    exact: true,
  },
  {
    type: Route,
    component: NotFound,
    path: '/*',
  },
]

export const Invoices = props => {
  const { match, location } = props
  return (
    <Page
      menu={prefixPaths(match.url, menu())}
      routes={generateRoutes(
        prefixPaths(match.url, routes(), ['path', 'from', 'to'])
      )}
      breadcrumbs={generateBreadcrumbs(
        location.pathname,
        prefixPaths(match.url, routes(), ['path', 'from'])
      )}
    />
  )
}
