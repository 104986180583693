import { combineReducers } from 'redux'
import { authReducer } from './auth.reducer'
import { schemaReducer } from './schema.reducer'
import { userReducer } from './user.reducer'
import { leaseReducer } from './lease.reducer'

const reducers = combineReducers({
  auth: authReducer,
  schema: schemaReducer,
  user: userReducer,
  lease: leaseReducer,
})

export default reducers
