import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import Avatar from '@material-ui/core/Avatar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import PrintIcon from '@material-ui/icons/Print'
import IconButton from '@material-ui/core/IconButton'
import DescriptionIcon from '@material-ui/icons/Description'
import { blue } from '@material-ui/core/colors'
import { polishPlural } from '_helpers/polishPlural'
import { authHeader } from '_helpers/authHeader'
import api from 'api'
import { invoicesPrintType as invoices } from 'dataconfig'
import { notification } from '../../../_helpers/notification'

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
})
export function PrintDialog(props) {
  const classes = useStyles()
  const {
    onClose,
    selectedValue,
    open,
    numSelected,
    newModal,
    stay,
    selected,
    print,
  } = props
  const [openStep2, setOpenStep2] = React.useState(false)
  const [toPrint, setToPrint] = React.useState(false)
  const [data, setData] = useState([])

  useEffect(() => {
    if (print && open) {
      setOpenStep2(true)
    }
  }, [print, open])
  useEffect(() => {
    const abort = new AbortController()
    const fetchData = async (slug) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENTRYPOINT}${slug}`,
        {
          method: 'POST',
          body: JSON.stringify({
            invoices: selected.map((el) => `${api.iri.invoices}${el}`),
          }),
          redirect: 'follow',
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
            ...authHeader(),
          },
        }
      )
      const json = await response.json()
      setData(json)
    }
    if (toPrint === invoices[0]) {
      fetchData(api.pdfsInvoicesShort)
    } else if (toPrint === invoices[1]) {
      fetchData(api.pdfsInvoicesFull)
    }
    return () => {
      return abort.abort()
    }
    // eslint-disable-next-line
  }, [toPrint, selected])

  useEffect(() => {
    const abort = new AbortController()
    const fetchData = async (slug) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENTRYPOINT}${slug}`,
        {
          method: 'GET',
          headers: {
            accept: 'application/pdf',
            ...authHeader(),
          },
        }
      )
      const blob = await response.blob()

      const file = new Blob([blob], { type: 'application/pdf' })

      const fileURL = URL.createObjectURL(file)
      onClose(false)
      window.open(fileURL)
    }

    if (toPrint && data.id !== undefined && data.id !== null) {
      fetchData(`${api.pdfs}/${data.id}/print`)
      setToPrint(false)
      setData([])
    } else if (toPrint && data.id === null) {
      notification(
        'error',
        'Błąd generowania faktury PDF. Spróbuj ponownie później.',
        'Błąd'
      )
      setToPrint(false)
      setData([])
    }

    return () => {
      return abort.abort()
    }
    // eslint-disable-next-line
  }, [data, toPrint])

  const handleClose = () => {
    setOpenStep2(false)
    onClose()
  }
  const handleStep2Close = (value) => {
    setOpenStep2(false)
    if (value === 'Drukuj') {
      setToPrint(invoices[1])
      onClose(true)
    }
    if (print) {
      onClose()
    }
  }

  const handleListItemClick = (value) => {
    if (value === invoices[0]) {
      stay(value)
    } else {
      newModal(value)
    }
  }
  const handleBtnClick = (value) => {
    if (selectedValue) {
      if (selectedValue === invoices[0]) {
        onClose(true)
        setToPrint(selectedValue)
      } else {
        setOpenStep2(true)
      }
    }
  }

  return (
    <>
      <Dialog
        open={open && !print}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          Drukuj {numSelected} pozycji
        </DialogTitle>
        <List>
          {invoices.map((invoice) => (
            <ListItem
              button
              onClick={() => handleListItemClick(invoice)}
              key={invoice}
              selected={selectedValue === invoice ? true : false}
            >
              <ListItemAvatar>
                <Avatar className={classes.avatar}>
                  <DescriptionIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={invoice} />
            </ListItem>
          ))}
        </List>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Anuluj
          </Button>

          <Button
            variant="contained"
            onClick={(e) => handleBtnClick(e.target.textContent)}
            startIcon={<PrintIcon />}
            color="primary"
          >
            Drukuj
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openStep2}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title-2"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title-2">
          Drukuj {numSelected}
          {polishPlural(' pozycję', ' pozycje', ' pozycji', numSelected)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Uwaga! Do drukujesz {numSelected}
            {polishPlural(
              ' dokument.',
              ' oddzielne dokumenty.',
              ' oddzielnych dokumentów.',
              numSelected
            )}
            Jesteś pewnien?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleStep2Close} color="primary">
            Anuluj
          </Button>

          <Button
            variant="contained"
            onClick={(e) => handleStep2Close(e.target.textContent)}
            startIcon={<PrintIcon />}
            color="primary"
          >
            Drukuj
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

PrintDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  selected: PropTypes.array,
  print: PropTypes.bool,
  numSelected: PropTypes.number.isRequired,
  newModal: PropTypes.func.isRequired,
  stay: PropTypes.func.isRequired,
}

const PrintDialogBtn = React.forwardRef(({ numSelected, selected }, ref) => {
  const [open, setOpen] = React.useState(false)
  const [selectedValue, setSelectedValue] = React.useState('')

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = (value) => {
    setOpen(false)

    setSelectedValue(value)
  }
  const handleStay = (value) => {
    setSelectedValue(value)
  }
  const handleNewModal = (value) => {
    setSelectedValue(value)
  }

  return (
    <div ref={ref}>
      <IconButton aria-label="print" onClick={handleClickOpen}>
        <PrintIcon />
      </IconButton>

      <PrintDialog
        selectedValue={selectedValue}
        numSelected={numSelected}
        open={open}
        onClose={handleClose}
        newModal={handleNewModal}
        stay={handleStay}
        selected={selected}
      />
    </div>
  )
})
export default PrintDialogBtn
