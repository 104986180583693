import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export const Loader = ({ align }) => {
  const classes = useStyles()

  return (
    <div
      className={`${classes.root +
        ' ' +
        (align === 'center' ? ' ' + classes.center : '')}`}
    >
      <CircularProgress size="2rem" color="secondary" />
    </div>
  )
}
