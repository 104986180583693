import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import { Title } from 'components/Title'
import NestedTableList from 'components/table/NestedTableList'
import { authHeader } from '_helpers/authHeader'
import { useSelector, useDispatch } from 'react-redux'
import { useFetch } from '_helpers/useFetch'
import Button from '@material-ui/core/Button'
import AddNamedEntityButton from 'components/fields/AddNamedEntityButton'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  flex: {
    display: 'flex',
  },
  gridParent: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '-48px',
    marginBottom: '20px',
  },
})
const typingTimeout = 1000

export default function FitnessModelList(link) {
  const classes = useStyles()
  const [queryParams, setQueryParams] = useState({
    search: '',
    page: 1,
  })
  const [typingState, setTypingState] = useState({
    timeoutId: null,
  })
  const [searchText, setSearchText] = useState('')
  const auth = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const outerListMapping = [
    {
      name: 'ID',
      prop: 'id',
      collapsable: true,
    },
    {
      name: 'MARKA',
      prop: 'name',
    },
    {
      name: 'Dostępne modele',
      prop: 'availableModelsCount',
    },
    {
      name: 'Posiadane modele',
      prop: 'ownModelsCount',
    },
  ]

  const mediumListMapping = [
    {
      name: 'MODEL',
      prop: 'name',
      link: (id) => `/equipment/models/${id}`,
    },
  ]

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const args = [auth.data.token, dispatch]

  const res = useFetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}/fitness_brands`,
    options,
    args,
    queryParams
  )

  const handleSearchChange = (e) => {
    const search = e.target.value
    setSearchText(search)

    clearTimeout(typingState.timeoutId)
    const timeoutId = setTimeout(() => {
      setTypingState({
        ...typingState,
        timeoutId: null,
      })
      setQueryParams({
        ...queryParams,
        search: search,
        page: 1,
      })
    }, typingTimeout)

    setTypingState({
      ...typingState,
      timeoutId: timeoutId,
    })
  }

  return (
    <React.Fragment>
      <div className={classes.flex}>
        <Title>Biblioteka modeli</Title>
        <AddNamedEntityButton
          endpoint={'/fitness_brands'}
          method={'POST'}
          variant={'contained'}
          fieldName="Marka"
          updatableEntitiesList={res.state.data?.content}
          setUpdatableEntitiesList={() =>
            setQueryParams({
              ...queryParams,
              page: 1,
            })
          }
        >
          Dodaj markę
        </AddNamedEntityButton>
      </div>
      <div className={classes.gridParent}>
        <TextField
          label="Szukaj marki"
          value={searchText}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <NestedTableList
        data={res.state.data?.content}
        isLoading={res.state.isLoading}
        error={res.state.error}
        outerListMapping={outerListMapping}
        outerEndpoint="/fitness_brands"
        mediumListMapping={mediumListMapping}
        mediumListEndpointFunction={(item) =>
          `/fitness_brands/${item.id}/models`
        }
        mediumEndpoint="/fitness_models"
        mediumCreateComponentFunction={(brandId) => (
          <Button
            to={`/equipment/models/newModel/${brandId}`}
            color={'primary'}
            variant={'contained'}
            component={Link}
          >
            Dodaj model
          </Button>
        )}
        dataLength={res.state.data?.totalItems}
        colWidth="260px"
        pagination={true}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        {...link}
      />
    </React.Fragment>
  )
}
