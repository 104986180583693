import React from 'react'
import SelectEntityField from 'components/fields/SelectEntityField'
import SelectMultipleEntityField from 'components/fields/SelectMultipleEntityField'
import api from 'api'
import { hireStatus } from 'dataconfig'

export default function TemplateFieldFactory({
  fieldName,
  type,
  value,
  violations,
  changeInfo,
}) {
  switch (type) {
    case 'kind':
      const val = hireStatus.find((el) => el.id === value)
      return (
        <SelectEntityField
          fieldName={fieldName}
          selectableEntity={val !== undefined ? val.name : value}
          endPoint={false}
          configVariable="kindDocumentTemplate"
          type={type}
          violations={violations}
          updatableParentEntity={{}}
          setUpdatableParentEntity={changeInfo}
        />
      )
    case 'suggestedSegments':
      return (
        <SelectMultipleEntityField
          fieldName={fieldName}
          selectableEntities={value}
          type={type}
          endPoint={`/${api.home}_segments`}
          violations={violations}
          renderValue={(item) => item.name}
          updatableParentEntity={value}
          setUpdatableParentEntity={changeInfo}
        />
      )
    case 'suggestedRentalPlaces':
      return (
        <SelectMultipleEntityField
          fieldName={fieldName}
          selectableEntities={value}
          type={type}
          endPoint={`/${api.home}_rental_places`}
          violations={violations}
          renderValue={(item) => item.name}
          updatableParentEntity={value}
          setUpdatableParentEntity={changeInfo}
        />
      )

    default:
      return 'Nic'
  }
}
