import React from 'react'
import { Schemable } from 'components/Schemable'
import { Loader } from 'components/Loader'
import { useSelector } from 'react-redux'
import { ProfileForm } from './ProfileForm'
import { ChangePasswordForm } from './ChangePasswordForm'

import Grid from '@material-ui/core/Grid'
export const ProfileContainer = () => {
  const profile = useSelector(state => state.user?.profile)

  return (
    <Schemable>
      {profile ? (
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <ProfileForm resource={profile} />
          </Grid>
          <Grid item xs={2}>
            <ChangePasswordForm resource={profile} />
          </Grid>
        </Grid>
      ) : (
        <div>
          <Loader align="center" />
        </div>
      )}
    </Schemable>
  )
}
