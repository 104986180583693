import React, { useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import { useSelector } from 'react-redux'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import SeriesComponent from '../../../Invoices/Invoice/Components/SeriesComponent'
import DateComponent from '../../../Invoices/Invoice/Components/DateComponent'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import api from 'api'
import { setCurrencyToApi } from '_helpers/currency'
import { authHeader } from '_helpers/authHeader'
import { notification } from '_helpers/notification'
import { useHistory } from 'react-router-dom'

export default function NewInvoice(props) {
  const { name, open, close, cost, type, iri } = props
  const lease = useSelector((state) => state.lease)
  const user = useSelector((state) => state.user)
  let history = useHistory()

  const [series, setSeries] = React.useState(null)
  const [comment, setComment] = useState('')
  const handleChangeSeries = (value) => {
    setSeries(value)
  }
  // eslint-disable-next-line
  const [seller, setSeller] = React.useState(null)
  const [payment, setPayment] = React.useState('')
  const [billingDate, setBillingDate] = React.useState(new Date())
  const [saleDate, setSaleDate] = React.useState(new Date())
  const [paymentDate, setPaymentDate] = React.useState(new Date())
  const [error, setError] = React.useState({})
  const [pay, setPay] = useState(0)
  const handleSetDate = (value, type) => {
    switch (type) {
      case 'billing':
        setPaymentDate(value)
        setBillingDate(value)
        setSaleDate(value)
        break
      case 'sale':
        setSaleDate(value)
        break
      case 'payment':
        setPaymentDate(value)
        break
      case 'type':
        setPayment(value)
        setError((prev) => ({
          ...prev,
          payment: false,
        }))
        break
      default:
        break
    }
  }

  const [send, setSend] = useState(false)
  const handleClick = () => {
    setSend(true)
  }

  useEffect(() => {
    const abort = new AbortController()
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_API_ENTRYPOINT}${api.invoices}${type}`
      const data = {
        invoicingNumbering: `${api.iri.invoicingNumbering}${series}`,
        seller: `${api.iri.seller}${user.profile.id}`,
        billingDate: billingDate,
        saleDate: saleDate,
        paymentDate: paymentDate,
        paymentType: payment,
        note: comment,
        paid: setCurrencyToApi(pay),
      }
      switch (type) {
        case '/hire':
          data.hire = iri
          break
        case '/return':
          data.return = iri
          break
        case '/repair':
          data.repair = iri
          break
        default:
          data.hire = iri
      }
      const options = {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
          ...authHeader(),
        },
        body: JSON.stringify(data),
      }
      fetch(url, options)
        .then((resp) => resp.json())
        .then((resp) => {
          if (resp.id) {
            notification(
              'success',
              'Faktura została wystawiona',
              'Nowa faktura'
            )
            history.push(`/invoices/list/${resp.id}`)
          } else {
            notification('error', 'Faktura nie została wystawiona.', 'Błąd')
          }
        })
        .catch((error) => {
          notification('error', error?.details, 'Błąd')
        })
    }

    if (send) {
      fetchData()
      setSend(false)
    }
    return () => {
      setSend(false)
      return abort.abort()
    }
    // eslint-disable-next-line
  }, [send])

  return (
    <>
      <Dialog open={open} onClose={close}>
        <DialogTitle>Wystaw fakturę: {name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Wystaw fakturę dla {lease.customer?.personalDetails?.companyName} na
            kwotę <strong>{cost}</strong>
          </DialogContentText>
          <DialogContentText>Uzupełnij wymagane dane:</DialogContentText>
          <Grid container spacing={4}>
            <Grid item md={6}>
              <Typography variant="h6" color="textPrimary" gutterBottom>
                Seria i wpłacona kwota
              </Typography>
              <SeriesComponent
                onChange={handleChangeSeries}
                classes={false}
                type={false}
              />
              <TextField
                label="Zapłacono"
                margin="normal"
                variant="outlined"
                value={pay}
                onChange={(e) => setPay(e.target.value)}
                InputProps={{
                  step: 'any',
                  type: 'number',
                  disabled: false,
                }}
              />
            </Grid>
            <Grid item md={6}>
              <DateComponent
                classes={false}
                seller={seller}
                payment={payment}
                billingDate={billingDate}
                saleDate={saleDate}
                paymentDate={paymentDate}
                setData={handleSetDate}
                error={error}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item sm={12}>
              <TextField
                id="outlined-full-width"
                label="Uwagi"
                fullWidth
                margin="normal"
                multiline
                rows="4"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <DialogContentText>
            Po zatwierdzeniu wyświetli się nowe okno z możliwością edycji
            faktury.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClick}>Wystaw fakturę</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
