import React from 'react'

import StringField from '../../components/fields/StringField'

export default function RentalPlaceFormFieldFactory({
  fieldName,
  type,
  value,
  violations,
  updatableParentEntity,
  setUpdatableParentEntity,
}) {
  switch (type) {
    case 'id':
      return (
        <StringField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
          readOnly={true}
        />
      )
    default:
      return (
        <StringField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
  }
}
