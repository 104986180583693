import CircularProgress from '@material-ui/core/CircularProgress'
import SaveIcon from '@material-ui/icons/Save'
import React from 'react'
import CarModelFormFieldFactory from './CarModelFormFieldFactory'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  gridParent: {
    display: 'grid',
    gridAutoRows: 'minmax(32px, auto)',
    gridTemplateColumns: 'repeat(2,1fr)',
    columnGap: '25px',
    rowGap: '5px',
    position: 'relative',
  },
  gridChild: {
    alignSelf: 'center',
    display: 'grid',
    gridTemplateColumns: '250px 1fr',
    borderBottom: '1px solid lightgrey',
    paddingBottom: '5px',
  },
  icon: {
    position: 'absolute',
    right: '0',
    top: '-15px',
  },
})
const HEADER = [
  {
    name: 'Identyfikator',
    prop: 'id',
  },
  {
    name: 'Nazwa',
    prop: 'name',
  },
  {
    name: 'Początek produkcji',
    prop: 'productionStart',
  },
  {
    name: 'Koniec produkcji',
    prop: 'productionEnd',
  },
  {
    name: 'Moc silnika',
    prop: 'enginePower',
  },
  {
    name: 'Paliwo',
    prop: 'engineFuel',
  },
  {
    name: 'Pojemność silnika',
    prop: 'engineCubicCapacity',
  },
  {
    name: 'Typ nadwozia',
    prop: 'bodyType',
  },
  {
    name: 'Liczba siedzeń',
    prop: 'seatsAmount',
  },
  {
    name: 'Rodzaj skrzyni',
    prop: 'gearBox',
  },
  {
    name: 'Liczba biegów',
    prop: 'gearsAmount',
  },
  {
    name: 'Układ przeniesienia napędu',
    prop: 'drivetrain',
  },
  {
    name: 'Zużycie paliwa w mieście',
    prop: 'fuelConsumptionCity',
  },
  {
    name: 'Zużycie paliwa w jeździe mieszanej',
    prop: 'fuelConsumptionMixed',
  },
  {
    name: 'Zużycie paliwa w długiej podróży',
    prop: 'fuelConsumptionLongHaul',
  },
  {
    name: 'Pojemność zbiornika',
    prop: 'fuelTank',
  },
  {
    name: 'Długość',
    prop: 'length',
  },
  {
    name: 'Szerokość',
    prop: 'width',
  },
  {
    name: 'Wysokość',
    prop: 'height',
  },
  {
    name: 'Minimalna objętość bagażnika',
    prop: 'minimumTrunkSpace',
  },
  {
    name: 'Maksymalna objętość bagażnika',
    prop: 'maximumTrunkSpace',
  },
  {
    name: 'Pojemność',
    prop: 'loadCapacity',
  },
]

const CarModelForm = ({
  carSerie,
  updatableCarSerie,
  setUpdatableCarSerie,
  handleSave,
  loading,
  violations,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.gridParent}>
      {HEADER.map((y, k) => (
        <div className={classes.gridChild} key={`trc-${k}`}>
          <CarModelFormFieldFactory
            fieldName={y.name}
            type={y.prop}
            value={carSerie[y.prop]}
            violations={violations}
            updatableParentEntity={updatableCarSerie}
            setUpdatableParentEntity={setUpdatableCarSerie}
            carSerieId={carSerie.id}
          />
        </div>
      ))}
      <div className={classes.icon}>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <SaveIcon onClick={handleSave} color="primary" />
        )}
      </div>
    </div>
  )
}
export default CarModelForm
