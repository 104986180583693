import React, { useState, useEffect } from 'react'
import TablePagination from '@material-ui/core/TablePagination'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Table from '@material-ui/core/Table'
import Collapse from '@material-ui/core/Collapse'
import Skeleton from '@material-ui/lab/Skeleton'
import Alert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import Button from '@material-ui/core/Button'
import { default as SwitchMode } from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import moment from 'moment'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'
import { get } from '_helpers/getProp'
import { authHeader } from '_helpers/authHeader'
import { polishPlural } from '_helpers/polishPlural'
import api from 'api'
import StatusField from 'pages/Leases/Lease/fields/StatusField'
import { hireStatus, invoiceStatusTrans } from 'dataconfig'
import { notification } from '_helpers/notification'
import EditIcon from '@material-ui/icons/Edit'
import { getPLNFromApi } from '_helpers/currency'

const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'grid',
    padding: '9px',
  },
  paragraph: {
    margin: '5px',
  },
  paragraphWide: {
    margin: '5px',
    gridColumn: '-1/1',
  },
  align: {
    verticalAlign: 'middle',
  },
  cursor: {
    cursor: 'pointer',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  tableHC: {
    display: 'flex',
    alignItems: 'center',
  },
  padNone: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  marL: {
    marginLeft: '10px',
  },
}))

export default function TableList({
  data,
  isLoading,
  error,
  mainData,
  expandData,
  setBreakOpen,
  setMapOpen,
  setLastCanRecord,
  setBreakId,
  colWidth,
  dataLength,
  link,
  pagination,
  queryParams,
  setQueryParams = () => {},
  setCar,
  startDate,
}) {
  const classes = useStyles()
  const initialState = {
    collapsedRow: null,
  }
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(12)
  const [collapse, setCollapse] = useState(initialState)
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setQueryParams({
      ...queryParams,
      page: newPage + 1,
    })
  }

  const handleChangeRowsPerPage = (event) => {
    const perPage = +event.target.value
    setRowsPerPage(perPage)
    setPage(0)
    setQueryParams({
      ...queryParams,
      perPage: perPage,
      page: 1,
    })
  }

  const handleExpandClick = (id) => {
    const isOpen = collapse.collapsedRow

    setCollapse({
      collapsedRow: isOpen ? null : id,
    })
  }

  const handleOpen = (e, row, b) => {
    setCar(row ? row : {})
    setBreakOpen(true)
    setBreakId(b)
  }
  const handleMapOpen = (lat, lng) => {
    setMapOpen(true)
    setLastCanRecord({ lat: lat, lng: lng })
  }
  // eslint-disable-next-line
  const [checked, setChecked] = React.useState([])
  const handleSwitch = (value, index, id) => {
    setChecked(() => {
      const newData = [...data]
      newData[index].accepted = value

      return { newData }
    })

    fetch(
      `${process.env.REACT_APP_API_ENTRYPOINT}/users/${id}/acceptCustomer`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(),
        },
        body: JSON.stringify({
          accepted: data[index].accepted,
        }),
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
        throw response.json()
      })
      .then((response) => {
        notification('success', 'Zapisano', 'Komunikat')
      })
      .catch((errorPromise) => {
        errorPromise.then((error) => {
          if (error.violations) {
            let errors = error.violations.map((e) => {
              return JSON.stringify(e.message)
            })

            errors = errors.join(',')

            notification('error', errors, 'Błąd')
          }
        })
      })
  }

  function CustomCell(field) {
    const name = Array.isArray(field.field) ? field.field[0] : field.field

    const [data, setData] = useState()

    useEffect(() => {
      const fetchData = async (slug) => {
        const response = await fetch(
          `${process.env.REACT_APP_API_ENTRYPOINT}${slug}`,
          {
            method: 'GET',
            headers: {
              accept: 'application/json',
              ...authHeader(),
            },
          }
        )
        const json = await response.json()

        setData(json)
      }
      fetchData(name)
    }, [name])

    return (
      <TableCell>
        {Array.isArray(field.field) ? (
          <div>{moment(data?.breakdownDatetime).format('MM.DD.YYYY')}</div>
        ) : (
          <div>{data?.discountRate} %</div>
        )}
      </TableCell>
    )
  }

  return (
    <React.Fragment>
      <Table>
        <TableHead>
          <TableRow>
            {expandData && (
              <TableCell key={'thc-id'}>
                <div className={classes.tableHC}>
                  <span>ID</span>
                </div>
              </TableCell>
            )}
            {mainData.map((x, i) => (
              <TableCell key={`thc-${i}`}>
                <div className={classes.tableHC}>
                  <span>{x.name}</span>
                </div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {isLoading &&
            [...Array(rowsPerPage)].map((i, key) => (
              <TableRow key={key}>
                <TableCell colSpan={10}>
                  <Skeleton animation="wave" />
                </TableCell>
              </TableRow>
            ))}
          {error && (
            <TableRow>
              <TableCell colSpan={10} style={{ padding: 0 }}>
                <Alert severity="error">
                  wystąpił błąd podczas pobierania danych
                </Alert>
              </TableCell>
            </TableRow>
          )}
          {!isLoading &&
            data &&
            data.map((row, index) => {
              return (
                <React.Fragment key={row.id}>
                  <TableRow>
                    {expandData && (
                      <TableCell
                        className={classes.cursor}
                        onClick={() => handleExpandClick(row.id)}
                      >
                        {collapse.collapsedRow === row.id ? (
                          <ArrowDownwardIcon
                            className={classes.align}
                            fontSize="small"
                          />
                        ) : (
                          <ArrowForwardIcon
                            className={classes.align}
                            fontSize="small"
                          />
                        )}

                        {row.id}
                      </TableCell>
                    )}
                    {mainData.map((e, i) => {
                      if (e.prop === mainData[0].prop) {
                        return (
                          <TableCell key={i}>
                            {link ? (
                              <Button
                                component={Link}
                                color="primary"
                                to={`${link}/${
                                  e.prop ===
                                  'hire.customer.personalDetails.companyName'
                                    ? row.hire.id
                                    : e.prop === 'fitnessEquipment.id'
                                    ? get(row, mainData[0].prop, '')
                                    : row.id
                                }`}
                              >
                                {get(row, mainData[0].prop, '') === null ? (
                                  <EditIcon />
                                ) : (
                                  get(row, mainData[0].prop, '')
                                )}
                              </Button>
                            ) : (
                              get(row, mainData[0].prop, '')
                            )}
                          </TableCell>
                        )
                      } else if (e.renderValue) {
                        return (
                          <TableCell key={i}>{e.renderValue(row)}</TableCell>
                        )
                      } else {
                        if (e.prop === 'hiredItems.length') {
                          const car = get(row, e.prop, '')
                          return (
                            <TableCell key={i}>
                              {car}
                              {api.appType === 'cars'
                                ? polishPlural(
                                    ' pojazd',
                                    ' pojazdy',
                                    ' pojazdów',
                                    car
                                  )
                                : polishPlural(
                                    ' maszyna',
                                    ' maszyny',
                                    ' maszyn',
                                    car
                                  )}
                            </TableCell>
                          )
                        }

                        const customField = get(row, e.prop, '')
                        if (
                          (typeof customField === 'string' &&
                            customField.charAt(0) === '/') ||
                          Array.isArray(customField)
                        ) {
                          return <CustomCell key={i} field={customField} />
                        }

                        if (
                          [
                            'moneySpent',
                            'cost',
                            'price',
                            'minExpenses',
                            'maxExpenses',
                            'bail',
                          ].indexOf(e.prop) > -1
                        ) {
                          return (
                            <TableCell key={i}>
                              {getPLNFromApi(customField)}
                            </TableCell>
                          )
                        }
                        if (e.prop === 'invoice.invoiceStatus') {
                          return (
                            <TableCell key={i}>
                              {invoiceStatusTrans[customField] !== undefined
                                ? invoiceStatusTrans[customField]
                                : customField}
                            </TableCell>
                          )
                        }

                        if (
                          e.prop === 'discountLevel.discountRate' ||
                          e.prop === 'discountRate'
                        ) {
                          return <TableCell key={i}>{customField}%</TableCell>
                        }
                        if (e.prop === 'ecoDrivingMetric') {
                          return (
                            <TableCell key={i}>
                              {customField !== 0 && customField}
                            </TableCell>
                          )
                        }

                        if (e.name === 'OSTATNI NAJEM') {
                          return (
                            <TableCell key={i}>
                              {row?.hires[0]?.startDate && (
                                <Moment
                                  date={row?.hires[0]?.startDate}
                                  format="DD.MM.Y"
                                />
                              )}
                              {row?.hires[0]?.endDate && (
                                <>
                                  {' '}
                                  -{' '}
                                  <Moment
                                    date={row?.hires[0]?.endDate}
                                    format="DD.MM.Y"
                                  />
                                </>
                              )}
                            </TableCell>
                          )
                        }

                        if (e.name === 'STATUS' && e.prop !== 'accepted') {
                          const statusId = get(row, e.prop, '')
                          const statusTrans = hireStatus.find(
                            (el) => el.id === statusId
                          )
                          return (
                            <TableCell key={i}>
                              {statusTrans ? statusTrans.name : statusId}
                            </TableCell>
                          )
                        }

                        if (e.name === 'POZOSTAŁO') {
                          let days
                          const now = moment(new Date())
                          const end = moment(get(row, e.prop, ''))
                          const duration = moment.duration(now.diff(end))

                          days = parseInt(duration.asDays())

                          if (days > 0) {
                            days = 0
                          } else {
                            days = Math.abs(days)
                          }

                          return <TableCell key={i}>{days}</TableCell>
                        }
                        if (
                          e.prop === 'startDate' ||
                          e.prop === 'endDate' ||
                          e.prop === 'deadlineDate'
                        ) {
                          return (
                            <TableCell key={i}>
                              <Moment format="DD.MM.YYYY">
                                {get(row, e.prop, '')}
                              </Moment>
                            </TableCell>
                          )
                        }
                        if (e.prop === 'accepted') {
                          return (
                            <TableCell key={i}>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <SwitchMode
                                      checked={get(row, e.prop, '')}
                                      onChange={(e) =>
                                        handleSwitch(
                                          e.target.checked,
                                          index,
                                          row.id
                                        )
                                      }
                                    />
                                  }
                                />
                              </FormGroup>
                            </TableCell>
                          )
                        }

                        return (
                          <TableCell key={i}>{get(row, e.prop, '')}</TableCell>
                        )
                      }
                    })}
                  </TableRow>

                  {expandData && (
                    <TableRow>
                      <TableCell className={classes.padNone} colSpan={10}>
                        <Collapse
                          in={collapse.collapsedRow === row.id}
                          timeout="auto"
                          unmountOnExit
                        >
                          <div
                            className={classes.grid}
                            style={{
                              gridTemplateColumns: `repeat(auto-fit, minmax(${colWidth}, 1fr))`,
                            }}
                          >
                            {expandData.map((e, i) => {
                              if (
                                e.prop ===
                                'canStatistics.lastCanRecord.latitude'
                              ) {
                                const lat = get(
                                  row,
                                  'canStatistics.lastCanRecord.latitude',
                                  ''
                                )
                                const lng = get(
                                  row,
                                  'canStatistics.lastCanRecord.longitude',
                                  ''
                                )

                                return (
                                  lat !== null && (
                                    <p key={i} className={classes.paragraph}>
                                      <b>{e.name}: </b>

                                      <Button
                                        onClick={(e) => handleMapOpen(lat, lng)}
                                        color="primary"
                                        variant="outlined"
                                        className={classes.marL}
                                      >
                                        Zobacz na mapie
                                      </Button>
                                    </p>
                                  )
                                )
                              }
                              if (e.prop === 'dailyPrice') {
                                return (
                                  <p key={i} className={classes.paragraph}>
                                    <b>{e.name}: </b>
                                    {getPLNFromApi(get(row, e.prop, ''))}
                                  </p>
                                )
                              }
                              if (
                                e.prop === 'canStatistics.engineWorkSeconds'
                              ) {
                                return (
                                  <p key={i} className={classes.paragraph}>
                                    <b>{e.name}: </b>
                                    <span>
                                      {Math.floor(
                                        get(row, e.prop, '') / (60 * 60)
                                      )}{' '}
                                      h
                                    </span>
                                  </p>
                                )
                              } else {
                                return (
                                  <p key={i} className={classes.paragraph}>
                                    <b>{e.name}: </b>
                                    {e.prop === 'startDate' ||
                                    e.prop === 'deadlineDate' ? (
                                      <Moment format="D.MM.YYYY">
                                        {get(row, e.prop, '')}
                                      </Moment>
                                    ) : (
                                      get(row, e.prop, '')
                                    )}
                                  </p>
                                )
                              }
                            })}
                            {row?.pernamentAccessories && (
                              <p className={classes.paragraphWide}>
                                <b>Wyposażenie: </b>
                                {row?.pernamentAccessories.map((pa, index) => (
                                  <span>
                                    {pa?.name}
                                    {index <
                                    row?.pernamentAccessories.length - 1
                                      ? ', '
                                      : ''}
                                  </span>
                                ))}
                              </p>
                            )}
                            {row?.optionalAccessories && (
                              <p className={classes.paragraphWide}>
                                <b>Wyposażenie dodatkowe: </b>
                                {row?.optionalAccessories?.map((oa, index) => (
                                  <span>
                                    {oa?.name}
                                    {index < row?.optionalAccessories.length - 1
                                      ? ', '
                                      : ''}
                                  </span>
                                ))}
                              </p>
                            )}
                            {row?.optionalServices && (
                              <p className={classes.paragraphWide}>
                                <b>Usługi dodatkowe: </b>
                                {row?.optionalServices?.map((os, index) => (
                                  <span>
                                    {os?.name}
                                    {index < row?.optionalServices.length - 1
                                      ? ', '
                                      : ''}
                                  </span>
                                ))}
                              </p>
                            )}
                            {row?.hireItemStatus && (
                              <div className={classes.paragraph}>
                                <StatusField
                                  selectableEntity={hireStatus.find(
                                    (el) => el.id === row.hireItemStatus
                                  )}
                                  listStatus={hireStatus.filter(
                                    (el) => el.type === 'hireItem'
                                  )}
                                  leaseId={row?.id}
                                  fieldName={'Status'}
                                  startDate={
                                    startDate !== undefined ? startDate : ''
                                  }
                                />
                              </div>
                            )}
                            {row?.breakdowns && api.appType === 'cars' && (
                              <p className={classes.paragraphWide}>
                                <b>Zgłoszenia awarii / Kolizji: </b>
                                {row?.breakdowns?.map((b, i) => {
                                  return (
                                    <Button
                                      onClick={(e) => handleOpen(e, row, b)}
                                      color="primary"
                                      variant="outlined"
                                      className={classes.marL}
                                      key={i}
                                    >
                                      Awaria/Kolizja nr {i + 1}
                                    </Button>
                                  )
                                })}
                              </p>
                            )}
                          </div>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              )
            })}
        </TableBody>
      </Table>
      {pagination && (
        <TablePagination
          rowsPerPageOptions={[1, 2, 12]}
          labelRowsPerPage="Pokaż na stronie:"
          component="div"
          count={dataLength !== undefined ? dataLength : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </React.Fragment>
  )
}
