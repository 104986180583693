import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link, Route, Switch } from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Alert from '@material-ui/lab/Alert'
import { useSelector } from 'react-redux'
import { authHeader } from '_helpers/authHeader'
import { Title } from 'components/Title'
import Skeleton from '@material-ui/lab/Skeleton'
import LeasingDealList from './modules/LeasingDeal'
import InsuranceList from './modules/Insurance'
import RegistrationServiceList from './modules/RegistrationService'
import AdditionalServiceList from './modules/AdditionalService'
import UsedTyresList from './modules/UsedTyres'
import SpareTyresList from './modules/SpareTyres'
import FuelFilterList from './modules/FuelFilter'
import OilFilterList from './modules/OilFilter'
import AirFilterList from './modules/AirFilter'
import TimingBeltList from './modules/TimingBelt'
import AirConditioningList from './modules/AirConditioning'
import AccumlatorList from './modules/Accumlator'
import BrakeBlockList from './modules/BrakeBlock'
import EngineOilList from './modules/EngineOil'
import HireItemList from './modules/HireItem'
import RepairList from './modules/Repair'

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
}))

const carSlug = '/cars'
const carIri = (id) => `${carSlug}/${id}`

const CarPartListContainer = ({ match }) => {
  const {
    params: { carId, carPartSlug },
  } = match

  const [fetchedEntities, setFetchedEntities] = useState(null)
  const [car, setCar] = useState(null)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const auth = useSelector((state) => state.auth)

  const classes = useStyles()

  useEffect(() => {
    const fetchData = async (slug) => {
      setLoading(true)
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_ENTRYPOINT}${slug}`,
          {
            method: 'GET',
            headers: {
              accept: 'application/json',
              ...authHeader(),
            },
          }
        )
        return await response.json()
      } catch (error) {
        setError(true)
      }
    }

    const fetchCar = async () => {
      const fetchedCar = await fetchData(carIri(carId))
      setCar(fetchedCar)
    }

    const fetchCarParts = async () => {
      const carPartsPagination = await fetchData(
        `${carIri(carId)}/${carPartSlug}_collections`
      )
      setFetchedEntities(carPartsPagination.content)
      setLoading(false)
    }

    setError(false)
    Promise.all([fetchCar(), fetchCarParts()])
  }, [auth.data.token, carId, carPartSlug])

  const currentCarIri = carIri(carId)

  return (
    <>
      {error && (
        <Alert severity="error">wystąpił błąd podczas pobierania danych</Alert>
      )}
      {car && (
        <>
          <Title>
            <Link to={`/fleet${carIri(carId)}`} className={classes.link}>
              {` ${car.fullName} `}
            </Link>
          </Title>
        </>
      )}
      {loading && (
        <TableContainer>
          <Table stickyHeader>
            <TableBody>
              {[...Array(6)].map((i, key) => (
                <TableRow hover key={key}>
                  <TableCell colSpan={11}>
                    <Skeleton animation="wave" />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {fetchedEntities && car && (
        <Switch>
          <Route path="/fleet/cars/:carId/leasing_deal">
            <LeasingDealList
              entities={fetchedEntities}
              carIri={currentCarIri}
            />
          </Route>
          <Route path="/fleet/cars/:carId/insurance">
            <InsuranceList entities={fetchedEntities} carIri={currentCarIri} />
          </Route>
          <Route path="/fleet/cars/:carId/registration_service">
            <RegistrationServiceList
              entities={fetchedEntities}
              carIri={currentCarIri}
            />
          </Route>
          <Route path="/fleet/cars/:carId/additional_service">
            <AdditionalServiceList
              entities={fetchedEntities}
              carIri={currentCarIri}
            />
          </Route>
          <Route path="/fleet/cars/:carId/tyres">
            <UsedTyresList
              entities={fetchedEntities}
              carIri={currentCarIri}
              currentDistanceMeter={car.distanceMeter}
            />
          </Route>
          <Route path="/fleet/cars/:carId/spare_tyre">
            <SpareTyresList
              entities={fetchedEntities}
              carIri={currentCarIri}
              currentDistanceMeter={car.distanceMeter}
            />
          </Route>
          <Route path="/fleet/cars/:carId/fuel_filter">
            <FuelFilterList
              entities={fetchedEntities}
              carIri={currentCarIri}
              currentDistanceMeter={car.distanceMeter}
            />
          </Route>
          <Route path="/fleet/cars/:carId/oil_filter">
            <OilFilterList
              entities={fetchedEntities}
              carIri={currentCarIri}
              currentDistanceMeter={car.distanceMeter}
            />
          </Route>
          <Route path="/fleet/cars/:carId/air_filter">
            <AirFilterList
              entities={fetchedEntities}
              carIri={currentCarIri}
              currentDistanceMeter={car.distanceMeter}
            />
          </Route>
          <Route path="/fleet/cars/:carId/timing_belt">
            <TimingBeltList
              entities={fetchedEntities}
              carIri={currentCarIri}
              currentDistanceMeter={car.distanceMeter}
            />
          </Route>
          <Route path="/fleet/cars/:carId/air_conditioning">
            <AirConditioningList
              entities={fetchedEntities}
              carIri={currentCarIri}
              currentDistanceMeter={car.distanceMeter}
            />
          </Route>
          <Route path="/fleet/cars/:carId/accumlator">
            <AccumlatorList
              entities={fetchedEntities}
              carIri={currentCarIri}
              currentDistanceMeter={car.distanceMeter}
            />
          </Route>
          <Route path="/fleet/cars/:carId/brake_blocks">
            <BrakeBlockList
              entities={fetchedEntities}
              carIri={currentCarIri}
              currentDistanceMeter={car.distanceMeter}
            />
          </Route>
          <Route path="/fleet/cars/:carId/engine_oil">
            <EngineOilList
              entities={fetchedEntities}
              carIri={currentCarIri}
              currentDistanceMeter={car.distanceMeter}
            />
          </Route>
          <Route path="/fleet/cars/:carId/hire_item">
            <HireItemList entities={fetchedEntities} />
          </Route>
          <Route path="/fleet/cars/:carId/repair">
            <RepairList entities={fetchedEntities} />
          </Route>
        </Switch>
      )}
    </>
  )
}

export default CarPartListContainer
