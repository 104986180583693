import React from 'react'
import MomentUtils from '@date-io/moment'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import FormHelperText from '@material-ui/core/FormHelperText'

export default function DatePickerField({
  fieldName,
  type,
  value,
  violations = [],
  updatableParentEntity,
  setUpdatableParentEntity,
  readOnly = false,
}) {
  const [currentValue, setCurrentValue] = React.useState(value || '')

  const fieldViolations = violations.filter(
    violation => violation.propertyPath === type
  )

  const handleDateChange = date => {
    setCurrentValue(date)
    setUpdatableParentEntity({
      ...updatableParentEntity,
      [type]: date,
    })
  }

  return (
    <React.Fragment>
      {fieldName && (
        <div>
          <b>{fieldName}:</b>
        </div>
      )}
      <div>
        <div>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              format="DD.MM.YYYY"
              value={currentValue ? new Date(currentValue) : null}
              onChange={handleDateChange}
              readOnly={readOnly}
            />
          </MuiPickersUtilsProvider>
          {fieldViolations.length
            ? fieldViolations.map((violation, i) => (
                <FormHelperText key={i} error={true}>
                  {violation.message}
                </FormHelperText>
              ))
            : ''}
        </div>
      </div>
    </React.Fragment>
  )
}
