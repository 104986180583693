import React, { useEffect, useState } from 'react'
import {
  SortingState,
  EditingState,
  IntegratedSorting,
  DataTypeProvider,
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn,
} from '@devexpress/dx-react-grid-material-ui'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TableCell from '@material-ui/core/TableCell'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import CancelIcon from '@material-ui/icons/Cancel'
import { withStyles } from '@material-ui/core/styles'
import { useFetch } from '_helpers/useFetch'
import { authHeader } from '_helpers/authHeader'
import { useSelector } from 'react-redux'
import api from 'api'
import { notification } from '_helpers/notification'
import Chip from '@material-ui/core/Chip'
import Alert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import { ContextMenu, ContextMenuTrigger } from 'react-contextmenu'

const styles = (theme) => ({
  lookupEditCell: {
    padding: theme.spacing(1),
  },
  inputRoot: {
    width: '100%',
  },
  selectMenu: {
    position: 'absolute !important',
  },
  contextmenu: {
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid rgba(0, 0, 0, 0.15)',
    borderRadius: '0.25rem',
    color: '#373a3c',
    fontSize: '16px',
    margin: '2px 0 0',
    minWidth: '160px',
    outline: 'none',
    opacity: '0',
    padding: '5px 0',
    pointerEvents: 'none',
    textAlign: 'left',
    transition: 'opacity 250ms ease',
  },
})

const AddButton = ({ onExecute }) => (
  <Button color="primary" onClick={onExecute} title="Create new row">
    Dodaj wiersz
  </Button>
)

const EditButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Edit row">
    <EditIcon />
  </IconButton>
)

const DeleteButton = ({ onExecute }) => (
  <IconButton
    onClick={() => {
      // eslint-disable-next-line
      if (window.confirm('Are you sure you want to delete this row?')) {
        onExecute()
      }
    }}
    title="Delete row"
  >
    <DeleteIcon />
  </IconButton>
)

const CommitButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Save changes">
    <SaveIcon />
  </IconButton>
)

const CancelButton = ({ onExecute }) => (
  <IconButton color="secondary" onClick={onExecute} title="Cancel changes">
    <CancelIcon />
  </IconButton>
)

const commandComponents = {
  add: AddButton,
  edit: EditButton,
  delete: DeleteButton,
  commit: CommitButton,
  cancel: CancelButton,
}

const Command = ({ id, onExecute }) => {
  const CommandButton = commandComponents[id]
  return <CommandButton onExecute={onExecute} />
}

const globalSalesValues = {
  resetFrequency: [
    {
      name: 'Nigdy',
      value: 'never',
    },
    {
      name: 'Codziennie',
      value: 'daily',
    },
    {
      name: 'Co miesiąc',
      value: 'monthly',
    },
    {
      name: 'Co kwartał',
      value: 'oncePerQuarter',
    },
    {
      name: 'Co rok',
      value: 'annually',
    },
  ],
  typeInvoice: [
    {
      name: 'Faktura',
      value: 'FV',
    },
    {
      name: 'Faktura korygująca',
      value: 'FC',
    },
  ],
  typeTemplateData: [
    {
      name: 'Typ faktury',
      value: '{{TYPE}}',
    },
    {
      name: 'Numer',
      value: '{{NUMBER}}',
    },
    {
      name: 'Dzień',
      value: '{{DAY}}',
    },
    {
      name: 'Miesiąc',
      value: '{{MONTH}}',
    },
    {
      name: 'Rok',
      value: '{{YEAR}}',
    },
  ],
}

const BooleanFormatter = ({ value }) => <Chip label={value ? 'Tak' : 'Nie'} />

const BooleanEditor = ({ value, onValueChange }) => (
  <Select
    input={<Input />}
    value={value ? 'Tak' : 'Nie'}
    onChange={(event) => onValueChange(event.target.value === 'Tak')}
    style={{ width: '100%' }}
  >
    <MenuItem value="Tak">Tak</MenuItem>
    <MenuItem value="Nie">Nie</MenuItem>
  </Select>
)

const BooleanTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={BooleanFormatter}
    editorComponent={BooleanEditor}
    {...props}
  />
)

const ExampleFormatter = ({ row, value, column }) => {
  const date = new Date()
  let text = String(value)
  if (column.name === 'example') {
    text = row.template
  }
  text = text.replace('{{TYPE}}', row.typeInvoice)
  text = text.replace('{{NUMBER}}', row.currentNumber)
  text = text.replace('{{DAY}}', date.getDate())
  text = text.replace(
    '{{MONTH}}',
    date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  )
  text = text.replace('{{YEAR}}', date.getFullYear())

  return <>{text}</>
}

const ExampleTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={ExampleFormatter}
    editorComponent={ExampleFormatter}
    {...props}
  />
)

const availableValues = {
  resetFrequency: globalSalesValues.resetFrequency,
  typeInvoice: globalSalesValues.typeInvoice,
}

const LookupEditCellBase = ({
  availableColumnValues,
  value,
  onValueChange,
  classes,
}) => (
  <TableCell className={classes.lookupEditCell}>
    <Select
      value={value}
      onChange={(event) => onValueChange(event.target.value)}
      MenuProps={{
        className: classes.selectMenu,
      }}
      input={<Input classes={{ root: classes.inputRoot }} />}
    >
      {availableColumnValues.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.name}
        </MenuItem>
      ))}
    </Select>
  </TableCell>
)
const LookupCellBase = ({ availableColumnValues, value, classes }) => {
  const text = availableColumnValues.find((el) => el.value === value)
  return <TableCell className={classes.lookupEditCell}>{text.name}</TableCell>
}
export const LookupEditCell = withStyles(styles)(LookupEditCellBase)
export const LookupCell = withStyles(styles)(LookupCellBase)

const Cell = (props) => {
  const { column } = props
  const availableColumnValues = availableValues[column.name]
  if (availableColumnValues) {
    return (
      <LookupCell {...props} availableColumnValues={availableColumnValues} />
    )
  }
  return <Table.Cell {...props} />
}

const EditCell = (props) => {
  const { column } = props
  const availableColumnValues = availableValues[column.name]
  if (availableColumnValues) {
    return (
      <LookupEditCell
        {...props}
        availableColumnValues={availableColumnValues}
      />
    )
  }
  return <TableEditRow.Cell {...props} />
}

const getRowId = (row) => row.id

const MENU_TYPE = 'MULTI'
const TemplateEditor = (props) => {
  const { value, onValueChange, classes, column } = props

  function collect(props) {
    return { name: props.name }
  }

  const [selectionStart, setSelectionStart] = React.useState(0)

  const updateSelectionStart = (value) => {
    setSelectionStart(value)
  }

  const [valueInput, setValueInput] = useState(value)

  useEffect(() => {
    onValueChange(valueInput)
    // eslint-disable-next-line
  }, [valueInput])

  const [variableKeys] = useState(globalSalesValues.typeTemplateData)

  const insertAt = (str, sub, pos) =>
    `${str.slice(0, pos)}${sub}${str.slice(pos)}`

  const handleClick = (data) => {
    setValueInput(insertAt(valueInput, data, selectionStart))
  }

  const handleChange = (e) => {
    updateSelectionStart(e.target.selectionStart)
    setValueInput(e.target.value)
  }

  const input = React.useRef()

  useEffect(() => {
    input.current.setSelectionRange(selectionStart, selectionStart)
    // eslint-disable-next-line
  }, [value])

  return (
    <>
      <ContextMenuTrigger
        id={MENU_TYPE + column.name}
        name="intro"
        holdToDisplay={1000}
        collect={collect}
      >
        <Input
          value={value}
          onChange={(event) => handleChange(event)}
          onClick={(e) => updateSelectionStart(e.target.selectionStart)}
          inputProps={{
            ref: input,
          }}
        />
      </ContextMenuTrigger>
      <ContextMenu id={MENU_TYPE + column.name} className={classes.contextmenu}>
        {variableKeys.map((el, key) => (
          <MenuItem key={key} onClick={() => handleClick(el.value)}>
            <>{el.name}</>
          </MenuItem>
        ))}
      </ContextMenu>
    </>
  )
}

export const TemplateEditorCell = withStyles(styles)(TemplateEditor)

const TemplateTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={({ value }) => <>{value}</>}
    editorComponent={TemplateEditorCell}
    {...props}
  />
)

function InvoiceSettingsFunc({ classes }) {
  const [open, setOpen] = React.useState(true)
  const [booleanColumns] = useState(['defaultNumbering'])
  const [exampleColumns] = useState(['example', 'currentNumber'])
  const [templateColumns] = useState(['template'])
  const [columns] = useState([
    { name: 'name', title: 'Nazwa' },
    { name: 'typeInvoice', title: 'Typ faktury' },
    { name: 'template', title: 'Wzorzec' },
    { name: 'currentNumber', title: 'Nr początkowy' },
    { name: 'resetFrequency', title: 'Reset' },
    { name: 'defaultNumbering', title: 'Domyślna?' },
    { name: 'example', title: 'Przykład' },
  ])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  const auth = useSelector((state) => state.auth)

  const [refresh, setRefresh] = React.useState(false)

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      ...authHeader(),
    },
  }
  const optionsDelete = { ...options, method: 'DELETE' }
  const optionsUpdate = { ...options, method: 'PUT' }
  const optionsNew = { ...options, method: 'POST' }
  const args = [auth.data.token, refresh]
  const res = useFetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}${api.invoicingNumberings}`,
    options,
    args
  )

  useEffect(() => {
    if (res?.state?.data) {
      setRows(res.state.data.content)
    }
    // eslint-disable-next-line
  }, [res])
  const [rows, setRows] = useState([])

  const [sorting, getSorting] = useState([])
  const [editingRowIds, getEditingRowIds] = useState([])
  const [addedRows, setAddedRows] = useState([])
  const [rowChanges, setRowChanges] = useState({})
  const [deletedIdsState, setDeleteIdsState] = useState(false)
  const [changes, setChanges] = useState(false)
  const [newRecord, setNewRecord] = useState(false)

  const changeAddedRows = (value) => {
    setAddedRows(
      value.map((row) =>
        Object.keys(row).length
          ? row
          : {
              name: '',
              template: '[ ] - [ ] / [ ] / [ ]',
              currentNumber: 1,
              resetFrequency: availableValues.resetFrequency[0].value,
              typeInvoice: availableValues.typeInvoice[0].value,
              example: '',
              defaultNumbering: false,
            }
      )
    )
  }

  useEffect(() => {
    const abort = new AbortController()
    const fetchDelete = async (id) => {
      const url = `${process.env.REACT_APP_API_ENTRYPOINT}${api.invoicingNumberings}/${id}`
      fetch(url, optionsDelete)
        .then((res) => {
          if (res.status === 204) {
            notification('success', 'Rekord został usunięty', 'Usunięto')
            setRefresh((state) => !state)
          }
        })

        .catch((err) => {
          console.log(err)
          notification(
            'error',
            err.detail !== undefined
              ? err.detail
              : 'Rekord nie został usunięty',
            'Błąd'
          )
        })
    }
    if (deletedIdsState && deletedIdsState.length > 0) {
      deletedIdsState.forEach((el) => {
        fetchDelete(el)
      })
      setDeleteIdsState(false)
    }
    return () => {
      setDeleteIdsState(false)
      return abort.abort()
    }
    // eslint-disable-next-line
  }, [deletedIdsState])

  useEffect(() => {
    const abort = new AbortController()
    const fetchUpdate = async (data) => {
      const url = `${process.env.REACT_APP_API_ENTRYPOINT}${api.invoicingNumberings}/${data.id}`
      const dataUpdate = {
        name: data.name,
        typeInvoice: data.typeInvoice,
        template: data.template,
        resetFrequency: data.resetFrequency,
        defaultNumbering: data.defaultNumbering,
      }
      fetch(url, { ...optionsUpdate, body: JSON.stringify(dataUpdate) })
        .then((res) => res.json())
        .then((res) => {
          if (res.id !== undefined && res.id === data.id) {
            setRefresh((state) => !state)
            notification('success', 'Rekord został zaktualizowany', 'Zapisano')
          } else {
            notification(
              'error',
              res.detail !== undefined
                ? res.detail
                : 'Rekord nie został zaktualizowany',
              'Błąd'
            )
          }
        })
    }

    if (changes) {
      Object.keys(changes).forEach((el) => {
        const row = rows.find((i) => i.id === Number(el))
        fetchUpdate({ ...row, ...changes[el] })
      })
      setChanges(false)
    }

    return () => {
      setChanges(false)
      return abort.abort()
    }
    // eslint-disable-next-line
  }, [changes])

  useEffect(() => {
    const abort = new AbortController()
    const fetchNew = async (data) => {
      const url = `${process.env.REACT_APP_API_ENTRYPOINT}${api.invoicingNumberings}`
      const dataNew = {
        name: data.name,
        typeInvoice: data.typeInvoice,
        template: data.template,
        resetFrequency: data.resetFrequency,
        defaultNumbering: data.defaultNumbering,
      }
      fetch(url, { ...optionsNew, body: JSON.stringify(dataNew) })
        .then((res) => res.json())
        .then((res) => {
          if (res.id !== undefined) {
            setRefresh((state) => !state)
            notification('success', 'Rekord został dodany', 'Zapisano')
          } else {
            notification(
              'error',
              res.detail !== undefined
                ? res.detail
                : 'Rekord nie został dodany',
              'Błąd'
            )
          }
        })
    }

    if (newRecord) {
      newRecord.forEach((el) => {
        fetchNew(el)
      })
      setNewRecord(false)
    }

    return () => {
      setNewRecord(false)
      return abort.abort()
    }
    // eslint-disable-next-line
  }, [newRecord])

  const deleteRows = (deletedIds) => {
    const rowsForDelete = rows.slice()
    deletedIds.forEach((rowId) => {
      const index = rowsForDelete.findIndex((row) => row.id === rowId)
      if (index > -1) {
        rowsForDelete.splice(index, 1)
      }
    })
    return rowsForDelete
  }

  const commitChanges = ({ added, changed, deleted }) => {
    let changedRows
    if (added) {
      setNewRecord(added)
      const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0
      changedRows = [
        ...rows,
        ...added.map((row, index) => ({
          id: startingAddedId + index,
          ...row,
        })),
      ]
    }
    if (changed) {
      setChanges(changed)
      changedRows = rows.map((row) =>
        changed[row.id] ? { ...row, ...changed[row.id] } : row
      )
    }
    if (deleted) {
      changedRows = deleteRows(deleted)
      setDeleteIdsState(deleted)
    }
    setRows(changedRows)
  }

  return (
    <Paper>
      <Grid rows={rows} columns={columns} getRowId={getRowId}>
        <BooleanTypeProvider for={booleanColumns} />
        <ExampleTypeProvider for={exampleColumns} />
        <TemplateTypeProvider for={templateColumns} />
        <SortingState sorting={sorting} onSortingChange={getSorting} />
        <EditingState
          editingRowIds={editingRowIds}
          onEditingRowIdsChange={getEditingRowIds}
          rowChanges={rowChanges}
          onRowChangesChange={setRowChanges}
          addedRows={addedRows}
          onAddedRowsChange={changeAddedRows}
          onCommitChanges={commitChanges}
        />

        <IntegratedSorting />

        <Table cellComponent={Cell} />
        <TableHeaderRow showSortingControls />
        <TableEditRow cellComponent={EditCell} />
        <TableEditColumn
          width={170}
          showAddCommand={!addedRows.length}
          showEditCommand
          showDeleteCommand
          commandComponent={Command}
        />
      </Grid>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="info" variant="filled">
          Podczas edycji kliknij prawym przyciskiem myszy na Wzorzec by wstawić
          dane
        </Alert>
      </Snackbar>
    </Paper>
  )
}

export const InvoiceSettings = withStyles(styles)(InvoiceSettingsFunc)
