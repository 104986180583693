import React from 'react'
import { get } from '_helpers/getProp'
import CustomerFieldFactory from './factory/CustomerFieldFactory'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  gridParent: {
    display: 'grid',
    gridAutoRows: 'minmax(32px, auto)',
    columnGap: '25px',
    rowGap: '5px',
  },
  gridChild: {
    alignSelf: 'center',
    display: 'grid',
    gridTemplateColumns: '200px 1fr',
    borderBottom: '1px solid lightgrey',
    paddingBottom: '5px',
  },
  form: {
    position: 'relative',
    marginTop: '10px',
    marginBottom: '30px',
  },
})

const customerForm = (
  classes,
  customer,
  updatableCustomer,
  setUpdatableCustomer,
  header,
  violations
) => {
  return (
    <div className={classes.gridParent}>
      {header.map((y, k) => (
        <div className={classes.gridChild} key={`trc-${k}`}>
          <CustomerFieldFactory
            fieldName={y.name}
            type={y.prop}
            value={get(customer, y.prop, '')}
            violations={violations}
            updatableParentEntity={updatableCustomer}
            setUpdatableParentEntity={setUpdatableCustomer}
            customerId={customer.id}
          />
        </div>
      ))}
    </div>
  )
}

export default function Customer({ customer, header }) {
  const classes = useStyles()
  const [updatableCustomer, setUpdatableCustomer] = React.useState([])

  const [violations] = React.useState([])

  return (
    <div className={classes.form}>
      {customerForm(
        classes,
        customer,
        updatableCustomer,
        setUpdatableCustomer,
        header,
        violations
      )}
    </div>
  )
}
