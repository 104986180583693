import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { validate } from 'email-validator'
import { authActions } from '_actions'
import { Login } from './Login'

export const LoginContainer = () => {
  const [state, setState] = useState({
    email: '',
    password: '',
    submitted: false,
  })
  const dispatch = useDispatch()

  const handleChange = e => {
    const { name, value } = e.target
    setState(state => ({ ...state, [name]: value }))
  }

  const handleSubmit = e => {
    e.preventDefault()
    setState(state => ({ ...state, submitted: true }))
    const { email, password } = state

    if (validate(email) && password) {
      dispatch(authActions.login(email, password))
    }
  }

  const isLogging = useSelector(state => state.auth.isLogging)
  const { email, password, submitted } = state

  return (
    <Login
      values={{ email, password }}
      handlers={{ handleChange, handleSubmit }}
      status={{ submitted, isLogging }}
    />
  )
}
