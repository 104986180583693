export const prefixPaths = (prefix, items, paths = ['path']) =>
  items.map(item => ({
    ...item,
    ...paths.reduce(
      (obj, path) =>
        Object.assign(
          obj,
          item[path] && {
            [path]: `${prefix}${item[path]}`.replace(/\/$/, ''),
          }
        ),
      {}
    ),
  }))
