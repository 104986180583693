import React from 'react'
import { prefixPaths } from '_helpers/prefixPaths'
import { generateRoutes } from '_helpers/generateRoutes'
import { generateBreadcrumbs } from '_helpers/generateBreadcrumbs'
import { DriveEta, Archive, AirportShuttle, VpnKey, ListAlt } from '@material-ui/icons'
import { Route, Redirect } from 'react-router-dom'
import { Page } from 'pages/Page'
import { NotFound } from 'pages/NotFound'
import CarList from './CarList'
import CarPartListContainer from './carParts/CarPartListContainer'
import CarDetailContainer from './CarDetailContainer'
import CarModelList from './Model/CarModelList'
import CarCreationContainer from './CarCreationContainer'
import CarModelDeatilContainer from './Model/CarModelDetailContainer'
import CarModelCreationContainer from './Model/CarModelCreationContainer'
import CarArchiveList from './Archive/CarArchiveList'
import RentalPlaceList from '../RentalPlace/RentalPlaceList'
import RentalPlaceDetailContainer from '../RentalPlace/RentalPlaceDetailContainer'
import RentalPlaceCreationContainer from '../RentalPlace/RentalPlaceCreationContainer'
import CanRegisterContainer from './CanRegister/CanRegisterContainer'
import AdditionalList from './AdditionalList/AdditionalList'

const menu = () => [
  routes()[0],
  { path: '/archive', icon: Archive, title: 'Archiwum' },
  { path: '/models', icon: AirportShuttle, title: 'Modele' },
  { path: '/rentalPlaces', icon: VpnKey, title: 'Wypożyczalnie' },
  { path: '/optional-service', icon: ListAlt, title: 'Usługi dodatkowe' },
  {
    path: '/optional-accessories',
    icon: ListAlt,
    title: 'Wyposażenie dodatkowe',
  },
  { path: '/pernament-accessories', icon: ListAlt, title: 'Wyposażenie stałe' },
]

const routes = () => [
  {
    title: 'Pojazdy',
    type: Route,
    render: () => <CarList link="cars" />,
    path: '/cars',
    exact: true,
    icon: DriveEta,
  },
  {
    title: 'Dodawanie pojazdu',
    type: Route,
    component: CarCreationContainer,
    path: '/cars/new/:carSerieId',
    exact: true,
  },
  {
    title: 'Edycja pojazdu',
    type: Route,
    component: CarDetailContainer,
    path: '/cars/:carId',
    exact: true,
  },
  {
    title: 'Pojazdy',
    type: Redirect,
    from: '/carscan',
    to: '/cars',
    exact: true,
  },
  {
    title: 'Edycja pojazdu',
    type: Redirect,
    from: '/carscan/:carId',
    to: '/cars/:carId',
    exact: true,
  },
  {
    title: 'Rejestr CAN',
    type: Route,
    component: CanRegisterContainer,
    path: '/carscan/:carId/register/:registerId',
    exact: true,
  },
  {
    title: 'Edycja części pojazdu',
    type: Route,
    component: CarPartListContainer,
    path: '/cars/:carId/:carPartSlug',
    exact: true,
  },
  {
    title: 'Modele',
    type: Route,
    component: CarModelList,
    path: '/models',
    exact: true,
  },
  {
    title: 'Dodawanie wersji modelu',
    type: Route,
    component: CarModelCreationContainer,
    path: '/models/newSerie/:modelId',
    exact: true,
  },
  {
    title: 'Edycja wersji modelu',
    type: Route,
    component: CarModelDeatilContainer,
    path: '/models/series/:serieId',
    exact: true,
  },
  {
    title: 'Archiwum',
    type: Route,
    render: () => <CarArchiveList link="cars" />,
    path: '/archive',
    exact: true,
    icon: DriveEta,
  },
  {
    title: 'Wypożyczalnie',
    type: Route,
    render: () => (
      <RentalPlaceList link="rentalPlaces" endPoint="/car_rental_places" />
    ),
    path: '/rentalPlaces',
    exact: true,
    icon: DriveEta,
  },
  {
    title: 'Dodawanie wypożyczalnii',
    type: Route,
    render: () => (
      <RentalPlaceCreationContainer
        link="rentalPlaces"
        endPoint="/car_rental_places"
      />
    ),
    path: '/rentalPlaces/new',
    exact: true,
    icon: DriveEta,
  },
  {
    title: 'Edycja wypożyczalnii',
    type: Route,
    render: (params) => (
      <RentalPlaceDetailContainer
        link="rentalPlaces"
        endPoint="/car_rental_places"
        match={params.match}
      />
    ),
    path: '/rentalPlaces/:rentalPlaceId',
    exact: true,
    icon: DriveEta,
  },
  {
    title: 'Usługi dodatkowe',
    type: Route,
    render: () => (
      <AdditionalList
        endPoint="/optional_services"
        title="Usługi dodatkowe"
        fields={[
          {
            name: 'Nazwa',
            prop: 'name',
            type: 'text',
          },
          {
            name: 'Cena',
            prop: 'price',
            type: 'number',
          },
        ]}
      />
    ),
    path: '/optional-service',
    exact: true,
    icon: ListAlt,
  },
  {
    title: 'Wyposażenie dodatkowe',
    type: Route,
    render: () => (
      <AdditionalList
        endPoint="/optional_accessories"
        title="Wyposażenie dodatkowe"
        fields={[
          {
            name: 'Nazwa',
            prop: 'name',
            type: 'text',
          },
          {
            name: 'Cena',
            prop: 'price',
            type: 'number',
          },
        ]}
      />
    ),
    path: '/optional-accessories',
    exact: true,
    icon: ListAlt,
  },
  {
    title: 'Wyposażenie stałe',
    type: Route,
    render: () => (
      <AdditionalList
        endPoint="/pernament_accessories"
        title="Wyposażenie stałe"
        fields={[
          {
            name: 'Nazwa',
            prop: 'name',
            type: 'text',
          },
        ]}
      />
    ),
    path: '/pernament-accessories',
    exact: true,
    icon: ListAlt,
  },
  {
    title: 'Edycja części pojazdu',
    type: Redirect,
    from: '/carscan/:carId',
    to: '/cars/:carId',
    exact: true,
  },
  {
    title: 'Flota',
    type: Redirect,
    from: '/',
    to: '/cars',
    exact: true,
  },
  {
    type: Route,
    component: NotFound,
    path: '/*',
  },
]

export const Fleet = (props) => {
  const { match, location } = props

  return (
    <Page
      menu={prefixPaths(match.url, menu())}
      routes={generateRoutes(
        prefixPaths(match.url, routes(), ['path', 'from', 'to'])
      )}
      breadcrumbs={generateBreadcrumbs(
        location.pathname,
        prefixPaths(match.url, routes(), ['path', 'from'])
      )}
    />
  )
}
