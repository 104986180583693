import React from 'react'
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'

delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

export default function MapItem(props) {
  const { lastCanRecord } = props
  const state = {
    lat: lastCanRecord.lat,
    lng: lastCanRecord.lng,
    zoom: 7,
  }
  const point = [lastCanRecord.lat, lastCanRecord.lng]
  return props ? (
    <Map
      center={[state.lat, state.lng]}
      zoom={state.zoom}
      style={{ width: '100%', height: '100%' }}
    >
      <TileLayer
        attribution='&copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      <Marker position={point}>
        <Popup>
          <span>Popup</span>
        </Popup>
      </Marker>
    </Map>
  ) : (
    'Data is loading...'
  )
}
