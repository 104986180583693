import React from 'react'
import { prefixPaths } from '_helpers/prefixPaths'
import { generateRoutes } from '_helpers/generateRoutes'
import { generateBreadcrumbs } from '_helpers/generateBreadcrumbs'
import {
  Business,
  Receipt,
  People,
  AccountCircle,
  Settings as SettingsIcon,
} from '@material-ui/icons'
import { Route, Redirect } from 'react-router-dom'
import { Page } from 'pages/Page'
import { NotFound } from 'pages/NotFound'
import { General } from './General'
import Company from './Company'
import { InvoiceSettings } from './InvoiceSettings'
import { ProfileContainer } from './Profile'
import { List as UserList } from './Users/List'
import { Add as UserAdd } from './Users/Add'
import { Edit as UserEdit } from './Users/Edit'

const menu = () => [
  routes()[7],
  { path: '/users', icon: People, title: 'Użytkownicy' },
  routes()[3],
  routes()[4],
  routes()[5],
]

const routes = () => [
  {
    title: 'Użytkownicy',
    type: Route,
    render: () => <UserList link="/settings/users" />,
    path: '/users',
    exact: true,
  },
  {
    title: 'Dodaj użytkownika',
    type: Route,
    component: UserAdd,
    path: '/users/add',
    exact: true,
  },
  {
    title: 'Edycja użytkownika',
    type: Route,
    component: UserEdit,
    path: '/users/:userId',
  },
  {
    title: 'Fakturowanie',
    type: Route,
    component: InvoiceSettings,
    path: '/invoices',
    exact: true,
    icon: Receipt,
  },
  {
    title: 'Ustawienia',
    type: Route,
    component: General,
    path: '/general',
    exact: true,
    icon: SettingsIcon,
  },
  {
    title: 'Profil',
    type: Route,
    component: ProfileContainer,
    path: '/profile',
    exact: true,
    icon: AccountCircle,
  },
  {
    title: 'Ustawienia',
    type: Redirect,
    from: '/',
    to: '/general',
    exact: true,
  },
  {
    title: 'Dane firmowe',
    type: Route,
    component: Company,
    path: '/company',
    exact: true,
    icon: Business,
  },
  {
    type: Route,
    component: NotFound,
    path: '/*',
  },
]

export const Settings = (props) => {
  const { match, location } = props
  return (
    <Page
      menu={prefixPaths(match.url, menu())}
      routes={generateRoutes(
        prefixPaths(match.url, routes(), ['path', 'from', 'to'])
      )}
      breadcrumbs={generateBreadcrumbs(
        location.pathname,
        prefixPaths(match.url, routes(), ['path', 'from'])
      )}
    />
  )
}
