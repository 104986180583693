import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link, Route, Switch } from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Alert from '@material-ui/lab/Alert'
import { useSelector } from 'react-redux'
import { authHeader } from '_helpers/authHeader'
import { Title } from 'components/Title'
import Skeleton from '@material-ui/lab/Skeleton'

import FitnessServiceList from './modules/FitnessService'
import RepairList from './modules/RepairList'
import { EquipmentDetailContainer } from './modules/EquipmentDetailContainer'

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
}))

const equipmentSlug = '/fitness_equipments'
const equipmentIri = (id) => `${equipmentSlug}/${id}`

const EquipmentPagesContainer = ({ match }) => {
  const {
    params: { equipmentId, equipmentPartSlug },
  } = match

  const [fetchedEntities, setFetchedEntities] = useState(null)
  const [equipment, setEquipment] = useState(null)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const auth = useSelector((state) => state.auth)

  const classes = useStyles()

  useEffect(() => {
    const fetchData = async (slug) => {
      setLoading(true)
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_ENTRYPOINT}${slug}`,
          {
            method: 'GET',
            headers: {
              accept: 'application/json',
              ...authHeader(),
            },
          }
        )
        return await response.json()
      } catch (error) {
        setError(true)
      }
    }

    const fetchEquipment = async () => {
      const fetchedEquipment = await fetchData(equipmentIri(equipmentId))
      setEquipment(fetchedEquipment)
    }

    const fetchEquipmentParts = async () => {
      const equipmentPartsPagination = await fetchData(
        `${equipmentIri(equipmentId)}/${equipmentPartSlug}_collections`
      )
      setFetchedEntities(equipmentPartsPagination?.content)
      setLoading(false)
    }

    setError(false)
    equipmentPartSlug !== 'detail' &&
      Promise.all([fetchEquipment(), fetchEquipmentParts()])
    Promise.all([fetchEquipment()])
  }, [auth.data.token, equipmentId, equipmentPartSlug])

  const currentEquipmentIri = equipmentIri(equipmentId)

  return (
    <>
      {error && (
        <Alert severity="error">wystąpił błąd podczas pobierania danych</Alert>
      )}
      {equipment && (
        <>
          <Title>
            <Link to={`/${equipmentIri(equipmentId)}`} className={classes.link}>
              {` ${equipment.fullName} `}
            </Link>
          </Title>
        </>
      )}
      {equipmentPartSlug !== 'detail' && loading && (
        <TableContainer>
          <Table stickyHeader>
            <TableBody>
              {[...Array(6)].map((i) => (
                <TableRow hover key={i}>
                  <TableCell colSpan={11}>
                    <Skeleton animation="wave" />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Switch>
        <Route path="/equipment/list/:equipmentId/fitness_service">
          {fetchedEntities && equipment && (
            <FitnessServiceList
              entities={fetchedEntities}
              equipmentIri={currentEquipmentIri}
            />
          )}
        </Route>
        <Route path="/equipment/list/:equipmentId/repair">
          {fetchedEntities && equipment && (
            <RepairList
              entities={fetchedEntities}
              equipmentIri={currentEquipmentIri}
            />
          )}
        </Route>
        <Route path="/equipment/list/:equipmentId/detail">
          <EquipmentDetailContainer equipmentId={equipmentId} />
        </Route>
      </Switch>
    </>
  )
}

export default EquipmentPagesContainer
