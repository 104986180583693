import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Equipment from './Equipment'
import { authHeader } from '_helpers/authHeader'
import { useFetch } from '_helpers/useFetch'
import { Title } from 'components/Title'

import Alert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  img: {
    width: '348px',
  },
}))
export const EquipmentContainer = ({ match }) => {
  const classes = useStyles()
  const {
    params: { equipmentId },
  } = match

  const [query, setQuery] = useState({})

  const auth = useSelector((state) => state.auth)

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const args = [auth.data.token, equipmentId]

  const res = useFetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}/fitness_equipments/${equipmentId}`,
    options,
    args,
    query
  )

  const handleSetCar = (data) => {
    res.setState(data)
    setQuery({ refresh: true })
  }

  return (
    <>
      <Backdrop
        className={classes.backdrop}
        open={res.state.isLoading !== undefined ? res.state.isLoading : false}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {res.state.error && (
        <Alert severity="error">wystąpił błąd podczas pobierania danych</Alert>
      )}

      {res.state.data && (
        <>
          <Title>Maszyna</Title>
          <Equipment
            equipment={res.state.data}
            setEquipment={handleSetCar}
            header={[
              {
                name: 'ID',
                prop: 'id',
              },
              {
                name: 'Identyfikator',
                prop: 'identifier',
              },
              {
                name: 'Marka',
                prop: 'model.brand.name',
              },
              {
                name: 'Maszyna',
                prop: 'fullName',
              },
              {
                name: 'Rok produkcji',
                prop: 'yearOfProduction',
              },
              {
                name: 'Data zakupu',
                prop: 'dateOfObtainment',
              },
              {
                name: 'Cennik',
                prop: 'segment',
              },
              {
                name: 'Status',
                prop: 'itemStatus',
              },
              {
                name: 'Historia zamówień',
                prop: 'hireItem.cost',
              },
              {
                name: 'Awarie',
                prop: 'repair',
              },
              {
                name: 'Utrzymanie',
                prop: 'fitnessService',
              },
            ]}
          />
        </>
      )}
    </>
  )
}
