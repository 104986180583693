import { fetchData } from './fetchData'
import { handleAuthError } from './handleAuthError'
import { processError } from './processError'

export const fetchDataHandleAuthError = (
  url,
  method = 'GET',
  request = {},
  handleSuccess = null,
  handleError = null,
  dispatch,
  action
) => {
  fetchData(url, method, request, null, false).then(
    response => handleSuccess(response),
    error => {
      handleAuthError.default(dispatch, action, error)

      handleError(
        processError(error)
      )
    }
  )
}
