import React, { useEffect, useState } from 'react'
import TablePagination from '@material-ui/core/TablePagination'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Table from '@material-ui/core/Table'
import Collapse from '@material-ui/core/Collapse'
import Skeleton from '@material-ui/lab/Skeleton'
import Alert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import { get } from '_helpers/getProp'
import { fetchDataHandleAuthError } from '../../_helpers/fetchDataHandleAuthError'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import AddNamedEntityButton from '../fields/AddNamedEntityButton'
import { getPLNFromApi } from '_helpers/currency'
import { hireStatus } from 'dataconfig'
import Moment from 'react-moment'

const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'grid',
    padding: '9px',
  },
  paragraph: {
    margin: '5px',
  },
  paragraphWide: {
    margin: '5px',
    gridColumn: '-1/1',
  },
  align: {
    verticalAlign: 'middle',
  },
  cursor: {
    cursor: 'pointer',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  tableHC: {
    display: 'flex',
    alignItems: 'center',
  },
  padNone: {
    paddingBottom: 0,
    paddingTop: 0,
  },
}))

export default function NestedTableList({
  data,
  isLoading,
  error,
  outerListMapping,
  outerEndpoint,
  mediumListMapping,
  mediumListEndpointFunction,
  mediumCreateComponentFunction = () => <></>,
  mediumEndpoint,
  innerListMapping,
  innerListEndpointFunction,
  innerCreateComponentFunction,
  colWidth,
  dataLength,
  pagination,
  queryParams,
  setQueryParams = () => {},
}) {
  const outerEndpointIri = (id) => `${outerEndpoint}/${id}`

  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [pageNested, setPageNested] = useState(0)
  const [pageInner, setPageInner] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(12)
  const [rowsPerPageNested, setRowsPerPageNested] = useState(12)
  const [rowsPerPageInner, setRowsPerPageInner] = useState(12)
  const [countTotalNested, setCountTotalNested] = useState(0)
  const [countTotalInner, setCountTotalInner] = useState(0)
  const [mediumListId, setMediumListId] = useState(null)
  const [mediumId, setMediumId] = useState(null)
  const [mediumListEntities, setMediumListEntities] = useState([])
  const [innerListId, setInnerListId] = useState(null)
  const [innerId, setInnerId] = useState(null)
  const [innerListEntities, setInnerListEntities] = useState([])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setQueryParams({
      ...queryParams,
      page: newPage + 1,
    })
  }

  const handleChangePageNested = (event, newPage) => {
    setPageNested(newPage)
  }
  const handleChangePageInner = (event, newPage) => {
    setPageInner(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    const perPage = +event.target.value
    setRowsPerPage(perPage)
    setPage(0)
    setQueryParams({
      ...queryParams,
      perPage: perPage,
      page: 1,
    })
  }

  const handleChangeRowsPerPageNested = (event) => {
    const perPage = +event.target.value
    setRowsPerPageNested(perPage)
    setPageNested(0)
  }
  const handleChangeRowsPerPageInner = (event) => {
    const perPage = +event.target.value
    setRowsPerPageInner(perPage)
    setPageInner(0)
  }
  const [refresh, setRefresh] = useState(false)
  useEffect(() => {
    const abort = new AbortController()
    const query = `?perPage=${rowsPerPageNested}&page=${pageNested + 1}`
    if (mediumId && mediumId !== undefined) {
      fetchDataHandleAuthError(
        `${mediumId}${query}`,
        'GET',
        {},
        (collection) => {
          setMediumListEntities(collection.content)
          setCountTotalNested(collection.totalItems)
        }
      )
    }
    return () => {
      abort.abort()
    }
  }, [rowsPerPageNested, pageNested, mediumId, refresh])

  useEffect(() => {
    const abort = new AbortController()
    const query = `?perPage=${rowsPerPageInner}&page=${pageInner + 1}`
    if (innerId && innerId !== undefined) {
      fetchDataHandleAuthError(
        `${innerId}${query}`,
        'GET',
        {},
        (collection) => {
          setInnerListEntities(collection.content)
          setCountTotalInner(collection.totalItems)
        }
      )
    }
    return () => {
      abort.abort()
    }
  }, [rowsPerPageInner, pageInner, innerId])

  const handleExpandMediumListClick = (id) => {
    const nextId = mediumListId === id ? null : id
    setMediumListId(nextId)
    setPageNested(0)
    if (nextId) {
      const endpoint = mediumListEndpointFunction(
        data.find((item) => item.id === nextId)
      )
      setMediumId(endpoint)
    }
  }
  const handleExpandMediumListClickAlternate = (id) => {
    const nextId = mediumListId === id ? null : id
    setMediumListId(nextId)
    setPageNested(0)
    if (nextId) {
      const endpoint = mediumListEndpointFunction(
        data.find((item) => item.rentableItem.id === nextId)
      )
      setMediumId(endpoint)
    }
  }

  const handleExpandInnerListClick = (id) => {
    const nextId = innerListId === id ? null : id
    setInnerListId(nextId)
    setPageInner(0)
    if (nextId) {
      const endpoint = innerListEndpointFunction(
        mediumListEntities.find((item) => item.id === nextId)
      )
      setInnerId(endpoint)
    }
  }

  return (
    <React.Fragment>
      <Table>
        <TableHead>
          <TableRow>
            {outerListMapping.map((x, i) => (
              <TableCell key={`thc-${i}`}>
                <div className={classes.tableHC}>
                  <span>{x.name}</span>
                </div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {isLoading &&
            [...Array(12)].map((i, index) => (
              <TableRow key={i + '_' + index}>
                <TableCell colSpan={10}>
                  <Skeleton animation="wave" />
                </TableCell>
              </TableRow>
            ))}
          {error && (
            <TableRow>
              <TableCell colSpan={10} style={{ padding: 0 }}>
                <Alert severity="error">
                  wystąpił błąd podczas pobierania danych
                </Alert>
              </TableCell>
            </TableRow>
          )}
          {!isLoading &&
            data &&
            data.map((row, index) => {
              return (
                <React.Fragment key={row.id + '_' + index}>
                  <TableRow>
                    {outerListMapping.map((e, i) => {
                      if (
                        e.prop ===
                          'profitabilityItem.wholeProfitability.profitabilityRate' ||
                        e.prop === 'profitabilityItem.predictedProfitableMonths'
                      ) {
                        return (
                          <TableCell key={i}>{get(row, e.prop, '')}%</TableCell>
                        )
                      }
                      if (
                        e.prop ===
                          'profitabilityItem.wholeProfitability.allCost' ||
                        e.prop ===
                          'profitabilityItem.wholeProfitability.usageCost'
                      ) {
                        const cost = get(row, e.prop, '')
                        return (
                          <TableCell key={i}>
                            {getPLNFromApi(cost * -1)}
                          </TableCell>
                        )
                      }
                      if (e.name === 'STATUS') {
                        const statusId = get(row, e.prop, '')
                        const statusTrans = hireStatus.find(
                          (el) => el.id === statusId
                        )
                        return (
                          <TableCell key={i}>
                            {statusTrans ? statusTrans.name : statusId}
                          </TableCell>
                        )
                      }
                      if (
                        e.prop === 'car.dateOfObtainment' ||
                        e.prop === 'dateOfDisposal'
                      ) {
                        return (
                          <TableCell key={i}>
                            <Moment format="DD.MM.YYYY">
                              {get(row, e.prop, '')}
                            </Moment>
                          </TableCell>
                        )
                      }
                      if (e.prop === 'profitablityValue') {
                        return (
                          <TableCell key={i}>
                            {getPLNFromApi(get(row, e.prop, ''))}
                          </TableCell>
                        )
                      }
                      if (e.name === 'MARKA') {
                        return (
                          <TableCell key={i}>
                            <AddNamedEntityButton
                              endpoint={`${outerEndpoint}/${row.id}`}
                              method={'PUT'}
                              fieldName="Marka"
                              updatableEntitiesList={data}
                              setUpdatableEntitiesList={() =>
                                setQueryParams({
                                  ...queryParams,
                                  page: 1,
                                })
                              }
                              value={get(row, e.prop, '')}
                            >
                              {get(row, e.prop, '')}
                            </AddNamedEntityButton>
                          </TableCell>
                        )
                      }
                      return e?.collapsable ? (
                        <TableCell
                          className={classes.cursor}
                          key={i}
                          onClick={() => {
                            row.id
                              ? handleExpandMediumListClick(row.id)
                              : handleExpandMediumListClickAlternate(
                                  row.rentableItem.id
                                )
                          }}
                        >
                          {mediumListId === row.id ||
                          mediumListId === row?.rentableItem?.id ? (
                            <ArrowDownwardIcon
                              className={classes.align}
                              fontSize="small"
                            />
                          ) : (
                            <ArrowForwardIcon
                              className={classes.align}
                              fontSize="small"
                            />
                          )}
                          {e.link ? (
                            <Button
                              component={Link}
                              color="primary"
                              to={e.link(get(row, e.prop, row.id))}
                            >
                              {e.prop ? get(row, e.prop, row.id) : ''}
                            </Button>
                          ) : e.prop ? (
                            get(row, e.prop, '')
                          ) : (
                            ''
                          )}
                        </TableCell>
                      ) : (
                        <TableCell key={i}>
                          {e.link ? (
                            <Button
                              component={Link}
                              color="primary"
                              to={e.link(get(row, e.prop, row.id))}
                            >
                              {get(row, e.prop, '')}
                            </Button>
                          ) : (
                            get(row, e.prop, '')
                          )}
                        </TableCell>
                      )
                    })}
                  </TableRow>

                  {mediumListMapping && (
                    <TableRow>
                      <TableCell className={classes.padNone} colSpan={10}>
                        <Collapse
                          in={
                            mediumListId === row.id ||
                            mediumListId === row?.rentableItem?.id
                          }
                          timeout="auto"
                          unmountOnExit
                        >
                          <div
                            className={classes.grid}
                            style={{
                              gridTemplateColumns: `repeat(auto-fit, minmax(${colWidth}, 1fr))`,
                            }}
                          >
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell key={`thc-id`}>
                                    <div className={classes.tableHC}>
                                      <span>ID</span>
                                    </div>
                                  </TableCell>
                                  {mediumListMapping.map((x, i) => (
                                    <TableCell key={`thc-${i}`}>
                                      <div className={classes.tableHC}>
                                        <span>{x.name}</span>
                                      </div>
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {mediumListEntities.map((row, i) => {
                                  return (
                                    <React.Fragment key={i}>
                                      <TableRow>
                                        {innerListMapping && (
                                          <TableCell
                                            className={classes.cursor}
                                            onClick={() =>
                                              handleExpandInnerListClick(row.id)
                                            }
                                          >
                                            {innerListId === row.id ? (
                                              <ArrowDownwardIcon
                                                className={classes.align}
                                                fontSize="small"
                                              />
                                            ) : (
                                              <ArrowForwardIcon
                                                className={classes.align}
                                                fontSize="small"
                                              />
                                            )}

                                            {row.id}
                                          </TableCell>
                                        )}
                                        {!innerListMapping && (
                                          <TableCell className={classes.cursor}>
                                            {row.id}
                                          </TableCell>
                                        )}
                                        {mediumListMapping.map((e, i) => {
                                          if (e.name === 'MODEL') {
                                            return (
                                              <TableCell key={i}>
                                                <AddNamedEntityButton
                                                  endpoint={`${mediumEndpoint}/${row.id}`}
                                                  method={'PUT'}
                                                  fieldName="Model"
                                                  updatableEntitiesList={
                                                    mediumListEntities
                                                  }
                                                  setUpdatableEntitiesList={
                                                    setMediumListEntities
                                                  }
                                                  value={get(row, e.prop, '')}
                                                  setRefresh={setRefresh}
                                                >
                                                  {get(row, e.prop, '')}
                                                </AddNamedEntityButton>
                                              </TableCell>
                                            )
                                          }
                                          return (
                                            <TableCell key={i}>
                                              {e.link ? (
                                                <Button
                                                  component={Link}
                                                  color="primary"
                                                  to={e.link(row.id)}
                                                >
                                                  {e.prop
                                                    ? get(row, e.prop, row.id)
                                                    : ''}
                                                </Button>
                                              ) : e.prop ? (
                                                get(row, e.prop, '')
                                              ) : (
                                                ''
                                              )}
                                            </TableCell>
                                          )
                                        })}
                                      </TableRow>
                                      {innerListMapping && (
                                        <TableRow>
                                          <TableCell
                                            className={classes.padNone}
                                            colSpan={10}
                                          >
                                            <Collapse
                                              in={innerListId === row.id}
                                              timeout="auto"
                                              unmountOnExit
                                            >
                                              <div
                                                // className={classes.grid}
                                                style={{
                                                  gridTemplateColumns: `repeat(auto-fit, minmax(${colWidth}, 1fr))`,
                                                }}
                                              >
                                                <Table>
                                                  <TableHead>
                                                    <TableRow>
                                                      <TableCell key={`thc-id`}>
                                                        <div
                                                          className={
                                                            classes.tableHC
                                                          }
                                                        >
                                                          <span>ID</span>
                                                        </div>
                                                      </TableCell>
                                                      {innerListMapping.map(
                                                        (x, i) => (
                                                          <TableCell
                                                            key={`thc-${i}`}
                                                          >
                                                            <div
                                                              className={
                                                                classes.tableHC
                                                              }
                                                            >
                                                              <span>
                                                                {x.name}
                                                              </span>
                                                            </div>
                                                          </TableCell>
                                                        )
                                                      )}
                                                    </TableRow>
                                                  </TableHead>
                                                  <TableBody>
                                                    {innerListEntities.map(
                                                      (row, i) => {
                                                        return (
                                                          <React.Fragment
                                                            key={i}
                                                          >
                                                            <TableRow>
                                                              {mediumListMapping && (
                                                                <TableCell
                                                                  className={
                                                                    classes.cursor
                                                                  }
                                                                >
                                                                  {row.id}
                                                                </TableCell>
                                                              )}
                                                              {innerListMapping.map(
                                                                (e, i) => {
                                                                  return (
                                                                    <TableCell
                                                                      key={i}
                                                                    >
                                                                      {e.link ? (
                                                                        <Button
                                                                          component={
                                                                            Link
                                                                          }
                                                                          to={e.link(
                                                                            row.id
                                                                          )}
                                                                          className={
                                                                            classes.link
                                                                          }
                                                                        >
                                                                          {get(
                                                                            row,
                                                                            e.prop,
                                                                            ''
                                                                          )}
                                                                        </Button>
                                                                      ) : (
                                                                        get(
                                                                          row,
                                                                          e.prop,
                                                                          ''
                                                                        )
                                                                      )}
                                                                    </TableCell>
                                                                  )
                                                                }
                                                              )}
                                                            </TableRow>
                                                          </React.Fragment>
                                                        )
                                                      }
                                                    )}
                                                    {innerCreateComponentFunction && (
                                                      <TableRow>
                                                        <TableCell>
                                                          {innerCreateComponentFunction(
                                                            row.id
                                                          )}
                                                        </TableCell>
                                                      </TableRow>
                                                    )}
                                                  </TableBody>
                                                </Table>
                                                {pagination && (
                                                  <TablePagination
                                                    rowsPerPageOptions={[2, 12]}
                                                    labelRowsPerPage="Pokaż na stronie:"
                                                    component="div"
                                                    count={
                                                      countTotalInner !==
                                                      undefined
                                                        ? countTotalInner
                                                        : 0
                                                    }
                                                    rowsPerPage={
                                                      rowsPerPageInner
                                                    }
                                                    page={pageInner}
                                                    onChangePage={
                                                      handleChangePageInner
                                                    }
                                                    onChangeRowsPerPage={
                                                      handleChangeRowsPerPageInner
                                                    }
                                                  />
                                                )}
                                              </div>
                                            </Collapse>
                                          </TableCell>
                                        </TableRow>
                                      )}
                                    </React.Fragment>
                                  )
                                })}
                                <TableRow>
                                  <TableCell>
                                    {mediumCreateComponentFunction(row.id)}
                                    {outerEndpoint &&
                                      mediumEndpoint &&
                                      innerListMapping && (
                                        <AddNamedEntityButton
                                          endpoint={mediumEndpoint}
                                          fieldName="Model"
                                          updatableEntitiesList={
                                            mediumListEntities
                                          }
                                          setUpdatableEntitiesList={
                                            setMediumListEntities
                                          }
                                          initialEntityState={{
                                            brand: outerEndpointIri(row.id),
                                          }}
                                          method={'POST'}
                                          variant={'contained'}
                                        >
                                          Dodaj model
                                        </AddNamedEntityButton>
                                      )}
                                  </TableCell>
                                  <TableCell>
                                    {pagination && (
                                      <TablePagination
                                        rowsPerPageOptions={[2, 12]}
                                        labelRowsPerPage="Pokaż na stronie:"
                                        component="div"
                                        count={
                                          countTotalNested !== undefined
                                            ? countTotalNested
                                            : 0
                                        }
                                        rowsPerPage={rowsPerPageNested}
                                        page={pageNested}
                                        onChangePage={handleChangePageNested}
                                        onChangeRowsPerPage={
                                          handleChangeRowsPerPageNested
                                        }
                                      />
                                    )}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </div>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              )
            })}
        </TableBody>
      </Table>
      {pagination && (
        <TablePagination
          rowsPerPageOptions={[1, 2, 12]}
          labelRowsPerPage="Pokaż na stronie:"
          component="div"
          count={dataLength !== undefined ? dataLength : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </React.Fragment>
  )
}
