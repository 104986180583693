import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Title } from 'components/Title'
import Table from 'components/table/TableList'
import { authHeader } from '_helpers/authHeader'
import { useFetch } from '_helpers/useFetch'
import Button from '@material-ui/core/Button'

export const List = (link) => {
  const auth = useSelector((state) => state.auth)
  const user = useSelector((state) => state.user.profile)

  const [queryParams, setQueryParams] = React.useState({
    search: '',
    page: 1,
  })
  const dispatch = useDispatch()

  const mainData = [
    {
      name: 'NAZWISKO',
      prop: 'lastName',
    },
    {
      name: 'IMIĘ',
      prop: 'firstName',
    },
    {
      name: 'EMAIL',
      prop: 'email',
    },
    {
      name: 'STATUS',
      prop: 'accepted',
    },
  ]

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const args = [auth.data.token, dispatch]

  const res = useFetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}/users`,
    options,
    args,
    queryParams
  )

  return (
    <React.Fragment>
      <Title>Użytkownicy</Title>
      <Table
        data={res.state.data?.content}
        isLoading={res.state.isLoading}
        error={res.state.error}
        mainData={mainData}
        dataLength={res.state.data?.totalItems}
        pagination={true}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        {...link}
      />
      {user?.roles?.includes('ROLE_SUPER_ADMIN') && (
        <div style={{ marginTop: '20px' }}>
          <Button
            component={Link}
            to="/settings/users/add"
            variant="contained"
            color="primary"
          >
            Dodaj użytkownika
          </Button>
        </div>
      )}
    </React.Fragment>
  )
}
