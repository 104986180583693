import React, { useState } from 'react'
import AccountCircle from '@material-ui/icons/AccountCircle'
import { Menu, MenuItem, IconButton } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Tooltip from '@material-ui/core/Tooltip'
const items = [{ path: '/settings/profile', title: 'Profil' }]

export const UserMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null)

  const profile = useSelector((state) => state.user.profile)

  const handleMenu = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      {profile && (
        <Tooltip title={profile?.firstName}>
          <IconButton
            aria-label="user profile"
            aria-controls="user-menu"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
        </Tooltip>
      )}
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorEl}
        onClose={handleClose}
      >
        {items.map((item, i) => {
          return (
            <MenuItem
              onClick={handleClose}
              key={`um-${i}`}
              component={Link}
              to={item.path}
            >
              {item.title}
            </MenuItem>
          )
        })}
        <MenuItem onClick={handleClose}>Zamknij</MenuItem>
      </Menu>
    </>
  )
}
