import React, { useState } from 'react'
import NewInvoice from '../modals/NewInvoice'
import Button from '@material-ui/core/Button'
import { getPLNFromApi } from '_helpers/currency'
import api from 'api'
import { Link } from 'react-router-dom'

export default function InvoiceField({ fieldName, lease, value }) {
  const [open, setOpen] = useState(false)
  const handleClickNewInvoice = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <React.Fragment>
      <div>
        <b>{fieldName && `${fieldName}`}</b>
      </div>
      <div>
        {value}
        {value && (
          <>
            <Button
              style={{ marginLeft: 30 }}
              to={`/invoices/list/${lease.invoice.id}`}
              component={Link}
            >
              Szczegóły
            </Button>
          </>
        )}
        {value === null && lease.cost > 0 && (
          <Button type="button" size="small" onClick={handleClickNewInvoice}>
            Wystaw fakturę
          </Button>
        )}
      </div>
      <NewInvoice
        name="Najem"
        open={open}
        close={handleClose}
        cost={getPLNFromApi(lease.cost)}
        type="/hire"
        iri={`${api.iri.hires}${lease.id}`}
      />
    </React.Fragment>
  )
}
