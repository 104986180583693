import React from 'react'

import SelectMultipleEntityField from '../../components/fields/SelectMultipleEntityField'
import SelectEntityField from '../../components/fields/SelectEntityField'
import StringField from '../../components/fields/StringField'
import MoneyField from '../../components/fields/MoneyField'
import KilometreField from '../../components/fields/KilometreField'
import GalleryField from '../../components/fields/GalleryField'
import RentableItemPartField from '../../components/fields/RentableItemPartField'
import DatePickerField from '../../components/fields/DatePickerField'

export default function CarFormFieldFactory({
  fieldName,
  type,
  value,
  violations,
  updatableParentEntity,
  setUpdatableParentEntity,
  carId,
}) {
  switch (type) {
    case 'optionalAccessories':
      return (
        <SelectMultipleEntityField
          fieldName={fieldName}
          selectableEntities={value}
          type={type}
          endPoint="/optional_accessories"
          violations={violations}
          renderValue={(item) => `${item.name} - ${item.price / 100}zł`}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'pernamentAccessories':
      return (
        <SelectMultipleEntityField
          fieldName={fieldName}
          selectableEntities={value}
          type={type}
          endPoint="/pernament_accessories"
          violations={violations}
          renderValue={(item) => item.name}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'optionalServices':
      return (
        <SelectMultipleEntityField
          fieldName={fieldName}
          selectableEntities={value}
          type={type}
          endPoint="/optional_services"
          violations={violations}
          renderValue={(item) => `${item.name} - ${item.price / 100}zł`}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'rentalPlace':
      return (
        <SelectEntityField
          fieldName={fieldName}
          selectableEntity={value}
          type={type}
          endPoint="/car_rental_places"
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'segment':
      return (
        <SelectEntityField
          fieldName={fieldName}
          selectableEntity={value}
          type={type}
          endPoint="/car_segments"
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'evidenceNumber':
      return (
        <StringField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'vin':
      return (
        <StringField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'registrationNumber':
      return (
        <StringField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'color':
      return (
        <StringField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'driverAgeRequirement':
      return (
        <StringField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'status':
      return (
        <StringField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
          readOnly={true}
        />
      )
    case 'id':
      return (
        <StringField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
          readOnly={true}
        />
      )
    case 'distanceMeter':
      return (
        <KilometreField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'purchasePrice':
      return (
        <MoneyField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'photos':
      return (
        <GalleryField
          fieldName={fieldName}
          photos={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'leasingDeal':
      return (
        <RentableItemPartField
          fieldName={fieldName}
          value={value}
          listEndpoint={carId && `/fleet/cars/${carId}/leasing_deal`}
          type={type}
          readOnly={!carId}
        />
      )
    case 'insurance':
      return (
        <RentableItemPartField
          fieldName={fieldName}
          value={value}
          listEndpoint={carId && `/fleet/cars/${carId}/insurance`}
          type={type}
          readOnly={!carId}
        />
      )
    case 'registrationService':
      return (
        <RentableItemPartField
          fieldName={fieldName}
          value={value}
          listEndpoint={carId && `/fleet/cars/${carId}/registration_service`}
          type={type}
          readOnly={!carId}
        />
      )
    case 'additionalService':
      return (
        <RentableItemPartField
          fieldName={fieldName}
          value={value}
          listEndpoint={carId && `/fleet/cars/${carId}/additional_service`}
          type={type}
          readOnly={!carId}
        />
      )
    case 'tyres':
      return (
        <RentableItemPartField
          fieldName={fieldName}
          value={value}
          listEndpoint={carId && `/fleet/cars/${carId}/tyres`}
          type={type}
          readOnly={!carId}
        />
      )
    case 'spareTyre':
      return (
        <RentableItemPartField
          fieldName={fieldName}
          value={value}
          listEndpoint={carId && `/fleet/cars/${carId}/spare_tyre`}
          type={type}
          readOnly={!carId}
        />
      )
    case 'fuelFilter':
      return (
        <RentableItemPartField
          fieldName={fieldName}
          value={value}
          listEndpoint={carId && `/fleet/cars/${carId}/fuel_filter`}
          type={type}
          readOnly={!carId}
        />
      )
    case 'oilFilter':
      return (
        <RentableItemPartField
          fieldName={fieldName}
          value={value}
          listEndpoint={carId && `/fleet/cars/${carId}/oil_filter`}
          type={type}
          readOnly={!carId}
        />
      )
    case 'airFilter':
      return (
        <RentableItemPartField
          fieldName={fieldName}
          value={value}
          listEndpoint={carId && `/fleet/cars/${carId}/air_filter`}
          type={type}
          readOnly={!carId}
        />
      )
    case 'timingBelt':
      return (
        <RentableItemPartField
          fieldName={fieldName}
          value={value}
          listEndpoint={carId && `/fleet/cars/${carId}/timing_belt`}
          type={type}
          readOnly={!carId}
        />
      )
    case 'accumlator':
      return (
        <RentableItemPartField
          fieldName={fieldName}
          value={value}
          listEndpoint={carId && `/fleet/cars/${carId}/accumlator`}
          type={type}
          readOnly={!carId}
        />
      )
    case 'airConditioning':
      return (
        <RentableItemPartField
          fieldName={fieldName}
          value={value}
          listEndpoint={carId && `/fleet/cars/${carId}/air_conditioning`}
          type={type}
          readOnly={!carId}
        />
      )
    case 'brakeBlocks':
      return (
        <RentableItemPartField
          fieldName={fieldName}
          value={value}
          listEndpoint={carId && `/fleet/cars/${carId}/brake_blocks`}
          type={type}
          readOnly={!carId}
        />
      )
    case 'engineOil':
      return (
        <RentableItemPartField
          fieldName={fieldName}
          value={value}
          listEndpoint={carId && `/fleet/cars/${carId}/engine_oil`}
          type={type}
          readOnly={!carId}
        />
      )
    case 'hireItem':
      return (
        <RentableItemPartField
          fieldName={fieldName}
          value={value}
          listEndpoint={carId && `/fleet/cars/${carId}/hire_item`}
          type={type}
          readOnly={!carId}
        />
      )
    case 'repair':
      return (
        <RentableItemPartField
          fieldName={fieldName}
          value={value}
          listEndpoint={carId && `/fleet/cars/${carId}/repair`}
          type={type}
          readOnly={!carId}
        />
      )
    case 'dailyPrice':
      return (
        <MoneyField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'dateOfObtainment':
      return (
        <DatePickerField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'canRegister':
      return (
        <SelectEntityField
          fieldName={fieldName}
          selectableEntity={value}
          type={type}
          allowedForCarId={carId}
          endPoint={`/can_registers`}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    default:
      return 'Nic'
  }
}
