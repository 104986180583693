import React, { useState } from 'react'
import { useFetch } from '_helpers/useFetch'
import { authHeader } from '_helpers/authHeader'
import { useSelector, useDispatch } from 'react-redux'
import Moment from 'react-moment'

import TableList from '../../../components/table/TableList'

const endPoint = `${process.env.REACT_APP_API_ENTRYPOINT}/can_alerts`

const header = [
  {
    name: 'KLIENT',
    prop: 'hireItem.hire.customer.personalDetails.companyName',
  },
  {
    name: 'OD',
    prop: 'hireItem.startDate',
    renderValue: (row) => (
      <Moment format="DD.MM.YYYY">{row?.hireItem?.startDate}</Moment>
    ),
  },
  {
    name: 'DO',
    prop: 'hireItem.deadlineDate',
    renderValue: (row) => (
      <Moment format="DD.MM.YYYY">{row?.hireItem?.deadlineDate}</Moment>
    ),
  },
  {
    name: 'ZGŁOSZENIE',
    prop: 'message',
  },
  {
    name: 'CZAS',
    prop: 'createdAt',
    renderValue: (row) => (
      <Moment format="HH:mm DD.MM.YYYY">{row?.createdAt}</Moment>
    ),
  },
]

const CanAlerts = ({ carId }) => {
  const [queryParams, setQueryParams] = useState({
    perPage: 6,
    'order[createdAt]': 'DESC',
    'register.car': carId,
  })

  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  const args = [auth.data.token, dispatch]
  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const alertsRes = useFetch(endPoint, options, args, queryParams)

  return (
    <>
      <TableList
        data={alertsRes.state.data?.content}
        isLoading={alertsRes.state.isLoading}
        error={alertsRes.state.error}
        mainData={header}
        dataLength={alertsRes.state.data?.totalItems}
        colWidth="260px"
        pagination={false}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
      />
    </>
  )
}

export default CanAlerts
