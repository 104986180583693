import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormHelperText from '@material-ui/core/FormHelperText'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import PrintIcon from '@material-ui/icons/Print'
import Moment from 'react-moment'
import { authHeader } from '_helpers/authHeader'
import { polishPlural } from '_helpers/polishPlural'

const useStyles = makeStyles(theme => ({
  centerBox: {
    textAlign: 'center',
    padding: '5px 0px',
  },
  rightIcon: {
    float: 'right',
    fontWeight: 'strong',
  },
}))
function CustomRow(field) {
  const data = field.field

  return (
    <TableRow>
      <TableCell>{data.name ? data.name : data.id}</TableCell>
      <TableCell>
        {' '}
        <Moment format="DD.MM.YYYY">{data.createdAt}</Moment>
      </TableCell>
      {data.id !== undefined && <CustomCell field={`/pdfs/${data.id}/print`} />}
    </TableRow>
  )
}

function CustomCell(field) {
  const name = field.field

  const [pdf, setPdf] = useState()
  const handlePrint = pdf => {
    window.open(pdf)
  }
  useEffect(() => {
    const fetchData = async slug => {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENTRYPOINT}${slug}`,
        {
          method: 'GET',
          headers: {
            accept: 'application/pdf',
            ...authHeader(),
          },
        }
      )
      const blob = await response.blob()

      const file = new Blob([blob], { type: 'application/pdf' })

      const fileURL = URL.createObjectURL(file)

      setPdf(fileURL)
    }
    fetchData(name)
  }, [name])

  return (
    <TableCell>
      <PrintIcon onClick={() => handlePrint(pdf)}></PrintIcon>
    </TableCell>
  )
}

export default function DocsField({
  fieldName,
  type,
  value,
  title = '',
  violations = [],
  readOnly = false,
  center = false,
}) {
  const classes = useStyles()

  const [open, setOpen] = React.useState(false)

  const fieldViolations = violations.filter(
    violation => violation.propertyPath === type
  )

  const handleOpen = () => {
    setOpen(!readOnly && true)
  }
  const handleClose = () => setOpen(false)

  return (
    <React.Fragment>
      <div>
        <b>{fieldName && `${fieldName}`}</b>
      </div>
      <div>
        <div onClick={handleOpen} className={center ? classes.centerBox : ''}>
          {value !== '' ? (
            <div>
              {value.length}
              {polishPlural(
                ' dokument',
                ' dokumenty',
                ' dokumentów',
                value.length
              )}
              <ChevronRightIcon className={classes.rightIcon} />
            </div>
          ) : (
            <span>Brak</span>
          )}

          {fieldViolations.length
            ? fieldViolations.map((violation, i) => (
                <FormHelperText key={i} error={true}>
                  {violation.message}
                </FormHelperText>
              ))
            : ''}
        </div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {title || 'Historia wydrukowanych dokumentów dla najmu'}
          </DialogTitle>
          <DialogContent>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Nazwa dokumentu</TableCell>
                    <TableCell align="right">Data</TableCell>
                    <TableCell align="right">drukuj</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {value !== '' &&
                    value.map((row, key) => (
                      <CustomRow key={key} field={row} />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Zamknij
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment>
  )
}
