import React from 'react'
import NumberField from './NumberField'

export default function KilometreField({
  fieldName,
  type,
  value,
  violations,
  updatableParentEntity,
  setUpdatableParentEntity,
  readOnly = false,
}) {
  return (
    <NumberField
      fieldName={fieldName}
      value={value}
      type={type}
      violations={violations}
      updatableParentEntity={updatableParentEntity}
      setUpdatableParentEntity={setUpdatableParentEntity}
      renderValue={value => (value ? `${value} km` : '')}
      uploadValue={value => parseInt(value)}
      readOnly={readOnly}
    />
  )
}
