import { leaseConstants } from '_constants'

export const leaseReducer = (state = {}, action) => {
  switch (action.type) {
    case leaseConstants.DATA_SUCCESS:
    case leaseConstants.DATA_UPDATE:
      return {
        ...state,
        ...action.payload,
      }
    case leaseConstants.DATA_FAILURE:
    case leaseConstants.DATA_ERASE:
      return {}
    default:
      return state
  }
}
