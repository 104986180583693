import React from 'react'

import CarPartList from '../CarPartList'
import Warning from '../../../../components/Warning'
import KilometreField from '../../../../components/fields/KilometreField'
import CarPartModelField from '../../../../components/fields/CarPartModelField'
import MoneyField from '../../../../components/fields/MoneyField'
import CheckboxField from '../../../../components/fields/CheckboxField'

const moduleName = 'Klocki hamulcowe'
const moduleEndpoint = '/brake_blocks'
const moduleType = 'brakeBlocks'

export const BrakeBlockValue = ({ item }) => {
  return (
    <>
      {`${item?.model?.fullName} `}
      {item.warnings.length ? (
        <Warning messages={item.warnings.map(warning => warning.message)} />
      ) : (
        <></>
      )}
    </>
  )
}

const compareFunction = (a, b) =>
  new Date(b.deadlineDate).getTime() - new Date(a.deadlineDate).getTime()

const updateDeadlineDistance = item => ({
  ...item,
  deadlineDistance: parseInt(item.startDistance) + parseInt(item.limitDistance),
})

export default function BrakeBlockList({
  entities,
  carIri,
  currentDistanceMeter,
}) {
  const createSpecificColumns = createSetRow => [
    {
      id: 'startDistance',
      label: 'Początkowy stan licznika',
      minWidth: 50,
      align: 'center',
      renderValue: (item, violations) => (
        <KilometreField
          type={'startDistance'}
          value={item.startDistance}
          violations={violations ? violations : []}
          updatableParentEntity={item}
          setUpdatableParentEntity={row => {
            const setRow = createSetRow(item.id)
            setRow(updateDeadlineDistance(row))
          }}
        />
      ),
    },

    {
      id: 'limitDistance',
      label: 'Limit kilometrów',
      minWidth: 50,
      align: 'center',
      renderValue: (item, violations) => (
        <KilometreField
          type={'limitDistance'}
          value={item.limitDistance}
          violations={violations ? violations : []}
          updatableParentEntity={item}
          setUpdatableParentEntity={row => {
            const setRow = createSetRow(item.id)
            setRow(updateDeadlineDistance(row))
          }}
        />
      ),
    },
    {
      id: 'deadlineDistance',
      label: 'Maksymalny stan licznika',
      minWidth: 50,
      align: 'center',
      renderValue: (item, violations) => (
        <KilometreField
          key={item.deadlineDistance}
          type={'deadlineDistance'}
          value={item.deadlineDistance}
          violations={violations ? violations : []}
          updatableParentEntity={item}
          setUpdatableParentEntity={createSetRow(item.id)}
          readOnly
        />
      ),
    },

    {
      id: 'model',
      label: 'Model',
      minWidth: 150,
      align: 'center',
      renderValue: (item, violations) => (
        <CarPartModelField
          type={'model'}
          carPartType={moduleType}
          modelEntity={item.model}
          violations={violations ? violations : []}
          updatableParentEntity={item}
          setUpdatableParentEntity={createSetRow(item.id)}
        />
      ),
    },
    {
      id: 'cost',
      label: 'Cena',
      minWidth: 100,
      renderValue: (item, violations) => (
        <MoneyField
          type={'cost'}
          value={item.cost}
          violations={violations ? violations : []}
          updatableParentEntity={item}
          setUpdatableParentEntity={createSetRow(item.id)}
        />
      ),
    },
    {
      id: 'includeCost',
      label: 'Wliczaj',
      minWidth: 50,
      renderValue: (item, violations) => (
        <CheckboxField
          type={'includeCost'}
          value={item.includeCost}
          violations={violations ? violations : []}
          updatableParentEntity={item}
          setUpdatableParentEntity={createSetRow(item.id)}
        />
      ),
    },
  ]

  const createPrototype = () => {
    return {
      id: 0,
      car: carIri,
      model: null,
      cost: 0,
      includeCost: false,
      startDistance: currentDistanceMeter,
      limitDistance: 0,
      deadlineDistance: currentDistanceMeter,
    }
  }

  return (
    <CarPartList
      entities={entities}
      moduleEndpoint={moduleEndpoint}
      moduleName={moduleName}
      createPrototype={createPrototype}
      compareFunction={compareFunction}
      createSpecificColumns={createSpecificColumns}
    />
  )
}
