import React from 'react'
import { prefixPaths } from '_helpers/prefixPaths'
import { generateRoutes } from '_helpers/generateRoutes'
import { generateBreadcrumbs } from '_helpers/generateBreadcrumbs'
import {
  Motorcycle,
  Archive,
  ListAlt,
  AirportShuttle,
} from '@material-ui/icons'
import { Route, Redirect } from 'react-router-dom'
import { Page } from 'pages/Page'
import { NotFound } from 'pages/NotFound'
import { List } from './List'
import { ArchiveList } from './ArchiveList'
import { EquipmentContainer } from './Equipment'
import EquipmentPagesContainer from './Equipment/equipmentPages/EquipmentPagesContainer'
import AdditionalList from 'pages/Fleet/AdditionalList/AdditionalList'
import FitnessModelList from './Model/FitnessModelList'
import FitnessModelCreationContainer from './Model/FitnessModelCreationContainer'
import FitnessModelDetailContainer from './Model/FitnessModelDetailContainer'
import {EquipmentCreateContainer} from "./Equipment/EquipmentCreateContainer";

const menu = () => [
  routes()[0],
  { path: '/models', icon: AirportShuttle, title: 'Modele' },
  routes()[1],
  routes()[5],
]

const routes = () => [
  {
    title: 'Lista maszyn',
    type: Route,
    render: () => <List link="list" />,
    path: '/list',
    exact: true,
    icon: Motorcycle,
  },
  {
    title: 'Archiwum',
    type: Route,
    render: () => <ArchiveList link="archive" />,
    path: '/archive',
    exact: true,
    icon: Archive,
  },
  {
    title: 'Maszyny',
    type: Redirect,
    from: '/',
    to: '/list',
    exact: true,
  },
  {
    title: 'Maszyna',
    type: Route,
    component: EquipmentContainer,
    path: '/list/:equipmentId',
    exact: true,
  },
  {
    title: 'Szczegóły',
    type: Route,
    component: EquipmentPagesContainer,
    path: '/list/:equipmentId/:equipmentPartSlug',
    exact: true,
  },
  {
    title: 'Kategorie',
    type: Route,
    render: () => (
      <AdditionalList
        endPoint="/fitness_categories"
        title="Kategorie"
        fields={[
          {
            name: 'Nazwa',
            prop: 'name',
            type: 'text',
          },
        ]}
      />
    ),
    path: '/fitness-categories',
    exact: true,
    icon: ListAlt,
  },
  {
    title: 'Modele',
    type: Route,
    component: FitnessModelList,
    path: '/models',
    exact: true,
  },
  {
    title: 'Dodawania modelu maszyny',
    type: Route,
    component: FitnessModelCreationContainer,
    path: '/models/newModel/:brandId',
    exact: true,
  },
  {
    title: 'Edycja modelu maszyny',
    type: Route,
    component: FitnessModelDetailContainer,
    path: '/models/:modelId',
    exact: true,
  },
  {
    title: 'Dodawanie maszyny',
    type: Route,
    component: EquipmentCreateContainer,
    path: '/models/:modelId/new',
    exact: true,
  },
  {
    title: 'Maszyna archiwalna',
    type: Route,
    component: EquipmentContainer,
    path: '/archive/:equipmentId',
    exact: true,
  },
  {
    type: Route,
    component: NotFound,
    path: '/*',
  },
]

export const Equipments = (props) => {
  const { match, location } = props
  return (
    <Page
      menu={prefixPaths(match.url, menu())}
      routes={generateRoutes(
        prefixPaths(match.url, routes(), ['path', 'from', 'to'])
      )}
      breadcrumbs={generateBreadcrumbs(
        location.pathname,
        prefixPaths(match.url, routes(), ['path', 'from'])
      )}
    />
  )
}
