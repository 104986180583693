import React, { useState } from 'react'
import { Title } from 'components/Title'
import TableList from 'components/table/TableList'
import { authHeader } from '_helpers/authHeader'
import { useSelector, useDispatch } from 'react-redux'
import { useFetch } from '_helpers/useFetch'
import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import { Link } from 'react-router-dom'

import { requestActions } from '_actions'
import { notification } from '_helpers/notification'
import { requestConstants } from '_constants'
import { fetchDataHandleAuthError } from '../../_helpers/fetchDataHandleAuthError'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(theme => ({
  gridParent: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '-48px',
    marginBottom: '20px',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

export default function RentalPlaceList({ link, endPoint }) {
  const iriFunction = (id) => `${endPoint}/${id}`

  const classes = useStyles()
  const [queryParams, setQueryParams] = useState({
    page: 1,
  })
  const auth = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const createHandleDelete = (id) => {
    return () => {
      const url = iriFunction(id)
      dispatch(requestActions.start(url))
      fetchDataHandleAuthError(
        url,
        'DELETE',
        {},
        () => {
          dispatch(requestActions.success())
          notification('success', 'Rekord usunięty', 'Zapisano')
          setQueryParams({
            ...queryParams,
            deleted: id,
          })
        },
        (error) => {
          notification(
            'error',
            error.response.violations.length
              ? 'Incorrect form'
              : error.response.detail,
            error.response.title
          )
        },
        dispatch,
        requestConstants.FAILURE
      )
    }
  }

  const mainData = [
    {
      name: 'ID',
      prop: 'id',
    },
    {
      name: 'NAZWA',
      prop: 'name',
    },
    {
      name: 'ADRES',
      prop: 'address',
    },
    {
      name: 'MIASTO',
      prop: 'city',
    },
    {
      name: 'FLOTA',
      prop: 'rentableItems.length',
    },
    {
      name: 'AKCJE',
      prop: '',
      renderValue: (row) => <DeleteIcon onClick={createHandleDelete(row.id)} />,
    },
  ]
  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const args = [auth.data.token, dispatch]

  const res = useFetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}${endPoint}`,
    options,
    args,
    queryParams
  )

  return (
    <React.Fragment>
      <Title>Wypożyczalnie</Title>
      <div className={classes.gridParent}>
        <Button
          component={Link}
          color="primary"
          to={'rentalPlaces/new'}
          className={classes.link}
        >
          Dodaj wypożyczalnie
        </Button>
      </div>
      <TableList
        data={res.state.data?.content}
        isLoading={res.state.isLoading}
        error={res.state.error}
        mainData={mainData}
        dataLength={res.state.data?.totalItems}
        colWidth="260px"
        pagination={true}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        link={link}
      />
    </React.Fragment>
  )
}
