import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import { authHeader } from '_helpers/authHeader'
import { useSelector, useDispatch } from 'react-redux'
import { useFetch } from '_helpers/useFetch'
import NestedTableList from 'components/table/NestedTableList'
import { makeStyles } from '@material-ui/core/styles'
import api from 'api'

const useStyles = makeStyles({
  gridParent: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '-48px',
    marginBottom: '20px',
  },
})
const typingTimeout = 1000
const reportsEndpoint = `${api.reports}/profitabilities`

const replacementEndpointFunction = (item) =>
  `/car_series/${item?.rentableItem?.carSerie?.id}/replacements`
let outerListMapping
outerListMapping = [
  {
    name: 'ID',
    prop: 'rentableItem.id',
    link: (id) => `/reports/profitability/${id}`,
  },
  {
    name: 'MARKA',
    prop: 'rentableItem.carSerie.model.brand.name',
  },
  {
    name: 'MODEL',
    prop:
      api.appType === 'cars'
        ? 'rentableItem.carSerie.model.name'
        : 'rentableItem.model.name',
  },
  {
    name: 'ROCZNIK',
    prop: 'rentableItem.productionYear',
  },
  {
    name: 'SILNIK',
    prop: 'rentableItem.carSerie.enginePower.name',
  },
  {
    name: 'TYP',
    prop: 'rentableItem.carSerie.bodyType.name',
  },
  {
    name: 'RENTOWNOŚĆ W ROKU  BIEŻĄCYM',
    prop: 'profitabilityItem.wholeProfitability.profitabilityRate',
  },
  {
    name: 'PRZEWIDYWALANA RENTOWNOŚĆ',
    prop: 'profitabilityItem.predictedProfitableMonths',
  },
  {
    name: 'KOSZT TOTAL',
    prop: 'profitabilityItem.wholeProfitability.allCost',
  },
  {
    name: 'UTRZYMANIE',
    prop: 'profitabilityItem.wholeProfitability.usageCost',
  },

  {
    name: 'ZAMIENNIKI',
    prop: '',
    collapsable: true,
  },
]

if (api.appType === 'fitness') {
  const removeValFromIndex = [1, 3, 4, 5, 10]

  const indexSet = new Set(removeValFromIndex)

  outerListMapping = outerListMapping.filter((value, i) => !indexSet.has(i))
}

const mediumListMapping = [
  {
    name: 'MARKA',
    prop: 'model.brand.name',
    link: (id) => `/fleet/models/series/${id}`,
  },
  {
    name: 'MODEL',
    prop: 'model.name',
  },
  {
    name: 'SILNIK',
    prop: 'enginePower.name',
  },
  {
    name: 'TYP',
    prop: 'bodyType.name',
  },
]
export default function List(link) {
  const classes = useStyles()
  const [queryParams, setQueryParams] = useState({
    search: '',
    page: 1,
  })
  const [typingState, setTypingState] = useState({
    timeoutId: null,
  })
  const [searchText, setSearchText] = useState('')
  const auth = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const args = [auth.data.token, dispatch]

  const res = useFetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}${reportsEndpoint}`,
    options,
    args,
    queryParams
  )
  console.log(res?.state?.data)

  const handleSearchChange = (e) => {
    const search = e.target.value
    setSearchText(search)

    clearTimeout(typingState.timeoutId)
    const timeoutId = setTimeout(() => {
      setTypingState({
        ...typingState,
        timeoutId: null,
      })
      setQueryParams({
        ...queryParams,
        search: search,
        page: 1,
      })
    }, typingTimeout)

    setTypingState({
      ...typingState,
      timeoutId: timeoutId,
    })
  }

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to={'/reports/profitability/whole'}
      >
        Zobacz dla całej firmy
      </Button>
      <div className={classes.gridParent}>
        <TextField
          label="Szukaj pojazdu"
          value={searchText}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <NestedTableList
        data={res.state.data?.content}
        isLoading={res.state.isLoading}
        error={res.state.error}
        outerListMapping={outerListMapping}
        mediumListMapping={api.appType === 'cars' ? mediumListMapping : null}
        mediumListEndpointFunction={
          api.appType === 'cars' ? replacementEndpointFunction : null
        }
        dataLength={res.state.data?.totalItems}
        colWidth="260px"
        pagination={true}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        {...link}
      />
    </React.Fragment>
  )
}
