import { fetchData } from '_helpers/fetchData'

const get = () =>
  fetchData(`${process.env.REACT_APP_API_SCHEMA_PATH}`).then(
    response => ({ response }),
    error => ({ error })
  )

export const schemaService = {
  get,
}
