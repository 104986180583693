import CircularProgress from '@material-ui/core/CircularProgress'
import SaveIcon from '@material-ui/icons/Save'
import React from 'react'
import CarFormFieldFactory from './CarFormFieldFactory'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  gridParent: {
    display: 'grid',
    gridAutoRows: 'minmax(32px, auto)',
    gridTemplateColumns: 'repeat(2,1fr)',
    columnGap: '25px',
    rowGap: '5px',
  },
  gridChild: {
    alignSelf: 'center',
    display: 'grid',
    gridTemplateColumns: '200px 1fr',
    borderBottom: '1px solid lightgrey',
    paddingBottom: '5px',
  },
  icon: {
    position: 'absolute',
    right: '0',
    top: '-45px',
  },
})
const HEADER = [
  {
    name: 'Identyfikator',
    prop: 'id',
  },
  {
    name: 'Bieżący status',
    prop: 'status',
  },
  {
    name: 'Nr. ewidencyjny',
    prop: 'evidenceNumber',
  },
  {
    name: 'Nr. podwozia',
    prop: 'vin',
  },
  {
    name: 'Nr. rejestracyjny',
    prop: 'registrationNumber',
  },
  {
    name: 'Kolor',
    prop: 'color',
  },
  {
    name: 'Wiek kierowcy',
    prop: 'driverAgeRequirement',
  },
  {
    name: 'Wypożyczalnia',
    prop: 'rentalPlace',
  },
  {
    name: 'Wyposażenie stałe',
    prop: 'pernamentAccessories',
  },
  {
    name: 'Wyposażenie opcjonalne',
    prop: 'optionalAccessories',
  },
  {
    name: 'Usługi dodatkowe',
    prop: 'optionalServices',
  },
  {
    name: 'Galeria zdjęć',
    prop: 'photos',
  },
  {
    name: 'Koszt zakupu',
    prop: 'purchasePrice',
  },
  {
    name: 'Data nabycia',
    prop: 'dateOfObtainment',
  },
  {
    name: 'Najmy',
    prop: 'hireItem',
  },
  {
    name: 'Naprawy',
    prop: 'repair',
  },
  {
    name: 'Umowa leasingowa',
    prop: 'leasingDeal',
  },
  {
    name: 'Ubezpieczenia',
    prop: 'insurance',
  },
  {
    name: 'Stan licznika',
    prop: 'distanceMeter',
  },
  {
    name: 'Przeglądy rejestracyjne',
    prop: 'registrationService',
  },
  {
    name: 'Planowane serwisy',
    prop: 'additionalService',
  },
  {
    name: 'Opony założone',
    prop: 'tyres',
  },
  {
    name: 'Opona zapasowa',
    prop: 'spareTyre',
  },
  {
    name: 'Olej silnikowy',
    prop: 'engineOil',
  },
  {
    name: 'Filtr paliwa',
    prop: 'fuelFilter',
  },
  {
    name: 'Filtr oleju',
    prop: 'oilFilter',
  },
  {
    name: 'Filtr powietrza',
    prop: 'airFilter',
  },
  {
    name: 'Pasek rozrządu',
    prop: 'timingBelt',
  },
  {
    name: 'Klocki hamulcowe',
    prop: 'brakeBlocks',
  },
  {
    name: 'Akumlator',
    prop: 'accumlator',
  },
  {
    name: 'Klimatyzacja',
    prop: 'airConditioning',
  },
  {
    name: 'Segment cennika',
    prop: 'segment',
  },
  {
    name: 'Cena dziennego wypożyczenia',
    prop: 'dailyPrice',
  },
  {
    name: 'Rejestr CAN',
    prop: 'canRegister',
  },
]

const CarForm = ({
  car,
  updatableCar,
  setUpdatableCar,
  handleSave,
  loading,
  violations,
}) => {
  const classes = useStyles()
  // eslint-disable-next-line
  // const [newCar, setNewCar] = useState(car)
  // useEffect(() => {
  //   setNewCar(car)
  // }, [car])

  return (
    <div className={classes.gridParent}>
      {HEADER.map((y, k) => (
        <div className={classes.gridChild} key={`trc-${k}`}>
          <CarFormFieldFactory
            fieldName={y.name}
            type={y.prop}
            value={car?.id ? car[y.prop] : null}
            violations={violations}
            updatableParentEntity={updatableCar}
            setUpdatableParentEntity={setUpdatableCar}
            carId={car?.id ? car.id : null}
          />
        </div>
      ))}
      <div className={classes.icon}>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <SaveIcon onClick={handleSave} color="primary" />
        )}
      </div>
    </div>
  )
}
export default CarForm
