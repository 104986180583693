import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { authActions } from '_actions'
import { AppBar } from './AppBar'
import { SideBar } from './SideBar'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'

export const RegularBar = ({ sideMenuItems }) => {
  const theme = useTheme()
  const is = useMediaQuery(theme.breakpoints.down('lg'))

  const [state, setState] = useState({
    isExpanded: false,
  })

  const dispatch = useDispatch()

  const handleExpand = () => {
    setState(state => ({
      ...state,
      isExpanded: !state.isExpanded,
    }))
  }

  const handleLogout = () => {
    dispatch(authActions.logout())
  }

  const { isExpanded } = state

  return (
    <>
      <AppBar
        status={{
          isExpanded: is ? !isExpanded : isExpanded,
          withMenuIcon: true,
        }}
        handlers={{ handleExpand, handleLogout }}
      />
      <SideBar
        status={{ isExpanded: is ? isExpanded : !isExpanded }}
        handlers={{ handleExpand }}
        sideMenuItems={sideMenuItems}
      />
    </>
  )
}
