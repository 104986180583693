import React from 'react'
import Select from '@material-ui/core/Select'
import buildUrl from 'build-url'
import FormHelperText from '@material-ui/core/FormHelperText'

import { useDispatch } from 'react-redux'
import { authHeader } from '_helpers/authHeader'
import { authActions } from '../../_actions'

export default function SelectEntitySimpleField({
  fieldName,
  type,
  endPoint,
  getParameters,
  violations,
  updatableParentEntity,
  setUpdatableParentEntity,
  id,
  setId,
  setValue = () => {},
  iriFetch = false,
}) {
  const dispatch = useDispatch()

  const [fetchedEntities, setFetchedEntities] = React.useState([])
  const iri = (id) => (iriFetch ? iriFetch(id) : `${endPoint}/${id}`)

  const fieldViolations = violations.filter(
    violation => violation.propertyPath === type
  )

  React.useEffect(() => {
    const fetchData = async slug => {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENTRYPOINT}${slug}`,
        {
          method: 'GET',
          headers: {
            accept: 'application/json',
            ...authHeader(),
          },
        }
      )

      if (response.status === 401 || response.status === 403) {
        dispatch(authActions.logout())
      }

      const json = await response.json()

      setFetchedEntities(json.content)
    }
    fetchData(
      buildUrl(endPoint, {
        queryParams: getParameters,
      })
    )
  }, [dispatch, endPoint, getParameters, updatableParentEntity])

  const handleChange = event => {
    const select = event.target
    const newId = parseInt(select.value)
    setId(newId)
    setValue(select.options[select.selectedIndex].text)
    setUpdatableParentEntity({
      ...updatableParentEntity,
      [type]: newId ? iri(newId) : null,
    })
  }

  return (
    <React.Fragment>
      <div>
        <b>{fieldName}: </b>
        <Select native fullWidth value={id} onChange={handleChange}>
          <option key={0} value={0}></option>
          {fetchedEntities.map(item => {
            return (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            )
          })}
        </Select>
        {fieldViolations.length
          ? fieldViolations.map((violation, i) => (
              <FormHelperText key={i} error={true}>
                {violation.message}
              </FormHelperText>
            ))
          : ''}
      </div>
    </React.Fragment>
  )
}
