import React from 'react'

import SaveIcon from '@material-ui/icons/Save'
import CircularProgress from '@material-ui/core/CircularProgress'

import { authHeader } from '_helpers/authHeader'
import { get } from '_helpers/getProp'
import EquipmentFieldFactory from './factory/EquipmentFieldFactory'
import { makeStyles } from '@material-ui/core/styles'
import { notification } from '_helpers/notification'
import ReactivateButton from 'pages/Fleet/buttons/ReactivateButton'
import ArchiveButton from 'pages/Fleet/buttons/ArchiveButton'

const useStyles = makeStyles({
  gridParent: {
    display: 'grid',
    gridAutoRows: 'minmax(32px, auto)',
    gridTemplateColumns: 'repeat(2,1fr)',
    columnGap: '25px',
    rowGap: '5px',
  },
  gridChild: {
    alignSelf: 'center',
    display: 'grid',
    gridTemplateColumns: '200px 1fr',
    borderBottom: '1px solid lightgrey',
    paddingBottom: '5px',
  },
  icon: {
    position: 'absolute',
    right: '0',
    top: '-45px',
  },
  form: {
    position: 'relative',
    marginTop: '10px',
    marginBottom: '30px',
  },
  buttonContainer: {
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'row-reverse',
    '& > *': {
      marginLeft: '10px',
    },
  },
})
const equipmentForm = (
  classes,
  equipment,
  updatableEquipment,
  setUpdatableEquipment,
  header,
  handleSave,
  loading,
  violations
) => {
  return (
    <div className={classes.gridParent}>
      {header.map((y, k) => (
        <div className={classes.gridChild} key={`trc-${k}`}>
          <EquipmentFieldFactory
            fieldName={y.name}
            type={y.prop}
            value={get(equipment, y.prop, '')}
            violations={violations}
            updatableParentEntity={updatableEquipment}
            setUpdatableParentEntity={setUpdatableEquipment}
            equipmentId={equipment.id}
          />
        </div>
      ))}
      <div className={classes.icon}>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <SaveIcon onClick={handleSave} color="primary" />
        )}
      </div>
    </div>
  )
}

export default function Equipment({ equipment, setEquipment, header }) {
  const [updatableEquipment, setUpdatableEquipment] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [violations, setViolations] = React.useState([])
  const classes = useStyles()
  const handleSave = () => {
    setLoading(true)

    fetch(
      `${process.env.REACT_APP_API_ENTRYPOINT}/fitness_equipments/${equipment.id}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(),
        },
        body: JSON.stringify(updatableEquipment),
      }
    )
      .then((response) => {
        setLoading(false)
        if (response.ok) {
          return response.json()
        }
        throw response.json()
      })
      .then((response) => {
        setEquipment((prevState) => ({
          ...prevState,
          ...response,
        }))

        notification('success', 'Zapisano', 'Komunikat')
        setViolations([])
      })
      .catch((errorPromise) => {
        errorPromise.then((error) => {
          if (error.violations) {
            setViolations(error.violations)
            let errors = error.violations.map((e) => {
              return JSON.stringify(e.message)
            })

            errors = errors.join(',')

            notification('error', errors, 'Błąd')
          }
        })
      })
  }

  return (
    <div className={classes.form}>
      {equipmentForm(
        classes,
        equipment,
        updatableEquipment,
        setUpdatableEquipment,
        header,
        handleSave,
        loading,
        violations
      )}
      <div className={classes.buttonContainer}>
        {equipment?.archived?.id ? (
          <ReactivateButton item={equipment} setItem={setEquipment} />
        ) : (
          <ArchiveButton item={equipment} setItem={setEquipment} />
        )}
      </div>
    </div>
  )
}
