import React from 'react'
import { useSelector } from 'react-redux'
import { authHeader } from '_helpers/authHeader'
import { useFetch } from '_helpers/useFetch'
import { Title } from 'components/Title'

import Alert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { EquipmentCreate } from './EquipmentCreate'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  img: {
    width: '348px',
  },
}))
export const EquipmentCreateContainer = ({ match }) => {
  const classes = useStyles()
  const {
    params: { modelId },
  } = match

  const [fitnessEquipment, setFitnessEquipment] = React.useState({})

  const auth = useSelector((state) => state.auth)

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const args = [auth.data.token, modelId]

  const res = useFetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}/fitness_models/${modelId}`,
    options,
    args
  )
  const fitnessModelIri = `/fitness_models/${modelId}`

  return (
    <>
      <Backdrop className={classes.backdrop} open={res.state.isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {res.state.error && (
        <Alert severity="error">wystąpił błąd podczas pobierania danych</Alert>
      )}

      {res.state.data && (
        <>
          <Title>Maszyna {res.state.data.fullName}</Title>
          <EquipmentCreate
            fitnessEquipment={fitnessEquipment}
            setFitnessEquipment={setFitnessEquipment}
            fitnessModelIri={fitnessModelIri}
          />
        </>
      )}
    </>
  )
}
