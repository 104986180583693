import React from 'react'
import { useDispatch } from 'react-redux'
import { AppBar } from './AppBar'
import { authActions } from '_actions'

export const HomeAppBar = () => {
  const dispatch = useDispatch()

  const handleLogout = () => {
    dispatch(authActions.logout())
  }

  return (
    <>
      <AppBar
        status={{ isExpanded: true, withMenuIcon: false }}
        handlers={{ handleLogout }}
      />
    </>
  )
}
