import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import Input from '@material-ui/core/Input'

import { useDispatch } from 'react-redux'
import { authHeader } from '_helpers/authHeader'
import { authActions } from '_actions'

export default function SelectMultipleEntityField({
  fieldName,
  type,
  selectableEntities = [],
  endPoint,
  renderValue,
  updatableParentEntity,
  setUpdatableParentEntity,
}) {
  const iri = (id) => `${endPoint}/${id}`

  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const [fetchedEntities, setFetchedEntities] = React.useState([])
  const [selectedEntities, setSelectedEntities] = React.useState([])
  const [selectedIds, setSelectedIds] = React.useState([])

  React.useEffect(() => {
    if (selectableEntities && selectableEntities.length > 0) {
      setSelectedEntities(selectableEntities)
      setSelectedIds(selectableEntities.map((item) => item.id))
    }
  }, [selectableEntities])

  const handleOpen = () => {
    setOpen(true)
    const fetchData = async (slug) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENTRYPOINT}${slug}`,
        {
          method: 'GET',
          headers: {
            accept: 'application/json',
            ...authHeader(),
          },
        }
      )

      if (response.status === 401 || response.status === 403) {
        dispatch(authActions.logout())
      }

      const json = await response.json()
      setFetchedEntities(json.content)
    }
    if (endPoint) {
      fetchData(endPoint)
    }
  }
  const handleClose = () => setOpen(false)

  const handleChange = (event) => {
    const select = event.target
    const newIds = select.value
    setSelectedIds(newIds)
    const newSelectedEntities = fetchedEntities
      .filter((item) => newIds.some((newId) => item.id === newId))
      .map((item) => {
        return {
          ...item,
          iri: iri(item.id),
        }
      })
    const newSelectedIris = newSelectedEntities.map((item) => item.iri)
    setSelectedEntities(newSelectedEntities)
    setUpdatableParentEntity({
      ...updatableParentEntity,
      [type]: newSelectedIris,
    })
  }

  return (
    <React.Fragment>
      <div>
        <b>{fieldName}:</b>
      </div>
      <div>
        <div onClick={handleOpen}>
          {selectedEntities.map((item, index) => {
            return (
              <span key={item.id}>
                {item.name}
                {index < selectedEntities.length - 1 ? ', ' : ''}
              </span>
            )
          })}
          <span>
            <ExpandMoreIcon
              style={{
                float: 'right',
                fontWeight: 'strong',
              }}
            />
          </span>
        </div>
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{fieldName}</DialogTitle>
          <DialogContent>
            <Select
              multiple
              value={selectedIds}
              fullWidth
              onChange={handleChange}
              renderValue={() => selectedEntities.map(renderValue).join(', ')}
              input={<Input />}
            >
              {fetchedEntities.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  <Checkbox
                    color="primary"
                    checked={selectedIds.some(
                      (selectedId) => selectedId === item.id
                    )}
                  />
                  <ListItemText primary={renderValue(item)} />
                </MenuItem>
              ))}
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Akceptuj
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment>
  )
}

SelectMultipleEntityField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  selectableEntities: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.oneOf([null]),
  ]),
  endPoint: PropTypes.string.isRequired,
  renderValue: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  violations: PropTypes.array.isRequired,
  updatableParentEntity: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  setUpdatableParentEntity: PropTypes.func.isRequired,
}
