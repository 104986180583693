import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import CarDetail from './CarDetail'
import { authHeader } from '_helpers/authHeader'
import Alert from '@material-ui/lab/Alert'
import { Title } from 'components/Title'
import { makeStyles } from '@material-ui/core/styles'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))
const CarDetailContainer = ({ match }) => {
  const classes = useStyles()
  const {
    params: { carId },
  } = match

  const [car, setCar] = useState(null)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const auth = useSelector((state) => state.auth)

  useEffect(() => {
    const fetchData = async (slug) => {
      setLoading(true)
      setError(false)
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_ENTRYPOINT}/${slug}`,
          {
            method: 'GET',
            headers: {
              accept: 'application/json',
              ...authHeader(),
            },
          }
        )
        const result = await response.json()
        setCar(() => result)
      } catch (error) {
        setError(true)
      }
      setLoading(false)
    }
    fetchData(`cars/${carId}`)
    return () => {
      setCar(null)
    }
  }, [auth.data.token, carId, refresh])

  const handleSetCar = (data) => {
    setCar(data)
    setRefresh((prevState) => !prevState)
  }

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {error && (
        <Alert severity="error">wystąpił błąd podczas pobierania danych</Alert>
      )}

      {car && (
        <>
          <Title>{car.fullName}</Title>
          <CarDetail car={car} setCar={handleSetCar} />
        </>
      )}
    </>
  )
}

export default CarDetailContainer
