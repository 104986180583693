import React from 'react'

import ModelModuleWithDateAndDistanceValue from './ModelModuleWithDateAndDistance'

const moduleName = 'Filtr paliwa'
const moduleEndpoint = '/fuel_filters'
const moduleType = 'fuelFilter'

export default function FuelFilterList({
  entities,
  carIri,
  currentDistanceMeter,
}) {
  return (
    <ModelModuleWithDateAndDistanceValue
      entities={entities}
      carIri={carIri}
      currentDistanceMeter={currentDistanceMeter}
      moduleEndpoint={moduleEndpoint}
      moduleName={moduleName}
      moduleType={moduleType}
    />
  )
}
