import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Select from '@material-ui/core/Select'
import FormHelperText from '@material-ui/core/FormHelperText'

import { useDispatch } from 'react-redux'
import { authHeader } from '_helpers/authHeader'
import { authActions } from '_actions'
import { hireStatus } from 'dataconfig'

export default function SelectEntityField(props) {
  const {
    fieldName,
    type,
    selectableEntity,
    endPoint,
    violations,
    updatableParentEntity,
    setUpdatableParentEntity,
    configVariable,
    allowedForCarId = false,
  } = props
  const dispatch = useDispatch()

  const [open, setOpen] = React.useState(false)
  const [fetchedEntities, setFetchedEntities] = React.useState([])
  const [id, setId] = React.useState(
    typeof selectableEntity === 'string'
      ? selectableEntity
      : selectableEntity?.id
  )
  const [value, setValue] = React.useState(
    typeof selectableEntity === 'string'
      ? selectableEntity
      : selectableEntity?.name
  )
  React.useEffect(() => {
    setId(
      typeof selectableEntity === 'string'
        ? selectableEntity
        : selectableEntity?.id
    )
    setValue(
      typeof selectableEntity === 'string'
        ? selectableEntity
        : selectableEntity?.name
    )
  }, [selectableEntity])
  const iri = (id) => `${endPoint}/${id}`

  const fieldViolations = violations.filter(
    (violation) => violation.propertyPath === type
  )

  const handleOpen = () => {
    setOpen(true)
    const fetchData = async (slug) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENTRYPOINT}${slug}`,
        {
          method: 'GET',
          headers: {
            accept: 'application/json',
            ...authHeader(),
          },
        }
      )

      if (response.status === 401 || response.status === 403) {
        dispatch(authActions.logout())
      }

      const json = await response.json()

      setFetchedEntities([{}, ...json.content])
    }
    if (endPoint) {
      const fetchingEndpointFunction = allowedForCarId
        ? `${endPoint}?allowedForCarId=${allowedForCarId}`
        : `${endPoint}?type=${type}`
      fetchData(fetchingEndpointFunction)
    } else if (configVariable === 'kindDocumentTemplate') {
      setFetchedEntities(
        hireStatus
          .filter((el) => el.type !== 'rentableItem')
          .map((el) => ({
            id: el.id,
            name: el.name,
          }))
      )
    }
  }
  const handleClose = () => setOpen(false)

  const handleChange = (event) => {
    const select = event.target
    const newId = type === 'kind' ? select.value : parseInt(select.value)
    setId(newId)
    setValue(select.options[select.selectedIndex].text)
    setUpdatableParentEntity({
      ...updatableParentEntity,
      [type]: newId ? (type === 'kind' ? newId : iri(newId)) : null,
    })
  }

  return (
    <React.Fragment>
      <div>
        <b>{fieldName}:</b>
      </div>
      <div>
        <div onClick={handleOpen}>
          {value}
          <span>
            <ExpandMoreIcon
              style={{
                float: 'right',
                fontWeight: 'strong',
              }}
            />
          </span>
          {fieldViolations.length
            ? fieldViolations.map((violation, i) => (
                <FormHelperText key={i} error={true}>
                  {violation.message}
                </FormHelperText>
              ))
            : ''}
        </div>
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{fieldName}</DialogTitle>
          <DialogContent>
            <Select native value={id} fullWidth onChange={handleChange}>
              {fetchedEntities.map((item, key) => {
                return (
                  <option key={item.id + '_' + key} value={item.id}>
                    {item.name}
                  </option>
                )
              })}
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Akceptuj
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment>
  )
}

SelectEntityField.propTypes = {
  selectableEntity: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.oneOf([null]),
  ]),
  fieldName: PropTypes.string.isRequired,
  endPoint: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  type: PropTypes.string.isRequired,
  violations: PropTypes.array.isRequired,
  updatableParentEntity: PropTypes.object.isRequired,
  setUpdatableParentEntity: PropTypes.func.isRequired,
  configVariable: PropTypes.string,
}
