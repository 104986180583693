import React from 'react'
import { NavLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import {
  Home,
  Person,
  DriveEta,
  VpnKey,
  Description,
  InsertDriveFile,
  TrendingUp,
  Settings,
  Motorcycle,
} from '@material-ui/icons'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.contrastText,
    '& .MuiListItemIcon-root': {
      color: theme.palette.primary.contrastText,
    },
  },
  active: {
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    '& .MuiListItemIcon-root': {
      color: theme.palette.secondary.contrastText,
    },
  },
  icon: {
    minWidth: '40px',
  },
}))

export const TopMenu = () => {
  const classes = useStyles()
  const theme = useTheme()
  const is = useMediaQuery(theme.breakpoints.down('lg'))
  const items = [
    { path: '/home', icon: Home, title: !is ? 'Home' : '' },
    { path: '/customers', icon: Person, title: 'Klienci' },
    process.env.REACT_APP_TYPE === 'cars'
      ? { path: '/fleet', icon: DriveEta, title: 'Flota' }
      : { path: '/equipment', icon: Motorcycle, title: 'Maszyny' },
    { path: '/leases', icon: VpnKey, title: 'Najmy' },
    { path: '/invoices', icon: Description, title: 'Faktury' },
    { path: '/documents', icon: InsertDriveFile, title: 'Dokumenty' },
    { path: '/reports', icon: TrendingUp, title: 'Raporty' },
    { path: '/settings', icon: Settings, title: !is ? 'Ustawienia' : '' },
  ]
  return (
    <>
      {items.map((item, i) => {
        const IconComponent = item.icon
        return (
          <NavLink
            key={`tm-${i}`}
            to={item.path}
            className={classes.link}
            activeClassName={classes.active}
            exact={!!item.exact}
          >
            <ListItem button>
              <ListItemIcon className={classes.icon}>
                <IconComponent />
              </ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItem>
          </NavLink>
        )
      })}
    </>
  )
}
