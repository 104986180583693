import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import Avatar from '@material-ui/core/Avatar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import TextField from '@material-ui/core/TextField'
import SendIcon from '@material-ui/icons/Send'
import AddIcon from '@material-ui/icons/Add'
import IconButton from '@material-ui/core/IconButton'
import api from 'api'
import { authHeader } from '_helpers/authHeader'
import { useDispatch } from 'react-redux'
import { requestActions } from '_actions'
import { notification } from '_helpers/notification'

function SendDialog(props) {
  const { onClose, selectedValue, open, selected } = props
  const [emailField, setEmailField] = useState([''])
  const [toSend, setToSend] = useState(false)
  const [data, setData] = useState([])
  const [text, setText] = useState('')

  const dispatch = useDispatch()

  useEffect(() => {
    const abort = new AbortController()
    const fetchData = async (slug) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENTRYPOINT}${slug}`,
        {
          method: 'POST',
          body: JSON.stringify({
            invoices: selected.map((el) => `${api.iri.invoices}${el}`),
          }),
          redirect: 'follow',
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
            ...authHeader(),
          },
        }
      )
      const json = await response.json()
      setData(json)
    }

    if (toSend) {
      fetchData(api.pdfsInvoicesFull)
    }
    return () => {
      return abort.abort()
    }
  }, [toSend, selected])

  useEffect(() => {
    const abort = new AbortController()
    dispatch(requestActions.start(api.emails))
    const fetchData = async (body) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENTRYPOINT}${api.emails}`,
        {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
            ...authHeader(),
          },
        }
      )
      const res = await response.json()
      if (res === 'SENT') {
        dispatch(requestActions.success())
        onClose(false)
        notification('success', 'Dokumenty wysłane', 'Wysłano')
      } else {
        onClose(false)
        notification(
          'error',
          'Błąd wysyłki emaili - sprawdź dane',
          'Błąd wysyłki'
        )
      }
    }

    if (toSend && data.id !== undefined) {
      const body = {
        pdf: `/pdfs/${data.id}`,
        subject: 'Faktury',
        text: text,
        addresses: emailField.filter((el) => el !== ''),
      }
      fetchData(body)
      setToSend(false)
      setEmailField([''])
      setData([])
    }

    return () => {
      return abort.abort()
    }
    // eslint-disable-next-line
  }, [data, toSend])

  const handleClose = () => {
    onClose(selectedValue)
  }
  const handleListItemClick = () => {
    setToSend(true)
    onClose(true)
  }
  const handleInput = (value, id) => {
    setEmailField((r) => {
      r[id] = value
      return r
    })
  }

  const handleInputText = (value) => {
    setText(value)
  }

  const handleClickAddAddress = () => {
    setEmailField((r) => [...r, ''])
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">Wyślij e-mailem</DialogTitle>
      <DialogContent>
        {emailField.map((el, i) => (
          <TextField
            key={i}
            autoFocus
            margin="dense"
            id={`name${i}`}
            label="Email Address"
            type="email"
            fullWidth
            onChange={(e) => handleInput(e.target.value, i)}
          />
        ))}
        <TextField
          margin="dense"
          id="message"
          label="wiadomość"
          multiline
          rows="4"
          fullWidth
          onChange={(e) => handleInputText(e.target.value)}
        />
        <List>
          <ListItem autoFocus button onClick={handleClickAddAddress}>
            <ListItemAvatar>
              <Avatar>
                <AddIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Dodaj kolejny adres" />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Anuluj
        </Button>

        <Button
          variant="contained"
          onClick={(e) => handleListItemClick()}
          startIcon={<SendIcon />}
          color="primary"
        >
          Wyślij
        </Button>
      </DialogActions>
    </Dialog>
  )
}

SendDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  selected: PropTypes.array,
}

const SendDialogBtn = React.forwardRef(({ selected }, ref) => {
  const [open, setOpen] = React.useState(false)
  const [selectedValue, setSelectedValue] = React.useState('')

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = (value) => {
    setOpen(false)
    setSelectedValue(value)
  }

  return (
    <div ref={ref}>
      <IconButton aria-label="send" onClick={handleClickOpen}>
        <SendIcon />
      </IconButton>

      <SendDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        selected={selected}
      />
    </div>
  )
})

export default SendDialogBtn
