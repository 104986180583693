import React, { useEffect } from 'react'
import clsx from 'clsx'
import {
  AppBar as MuiAppBar,
  Toolbar,
  IconButton,
  Badge,
  List,
} from '@material-ui/core'
import { Menu, Notifications, ExitToApp } from '@material-ui/icons'
import { TopMenu } from 'components/menu/TopMenu'
import { UserMenu } from 'components/menu/UserMenu'
import { useStyles } from './AppBarStyles'
import PropTypes from 'prop-types'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart'
import Avatar from '@material-ui/core/Avatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { Link as RouterLink } from 'react-router-dom'
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant'

export const AppBar = ({ status, handlers }) => {
  const classes = useStyles()
  const theme = useTheme()
  const is = useMediaQuery(theme.breakpoints.down('lg'))
  const [informations, setInformations] = React.useState([])

  useEffect(() => {
    const socket = new WebSocket(process.env.REACT_APP_WEBSOCKET_HOST)

    socket.addEventListener('open', function () {
      socket.send(localStorage.getItem('token'))
    })

    socket.addEventListener('message', function (e) {
      setInformations(JSON.parse(e.data))
    })

    return () => {
      socket.close()
    }
  }, [])

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  // const resLeases = useFetch(api)

  return (
    <MuiAppBar
      position="absolute"
      className={clsx(classes.appBar, status.isExpanded || classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        {status.withMenuIcon && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handlers.handleExpand}
            className={clsx(
              classes.menuButton,
              status.isExpanded || classes.menuButtonHidden
            )}
          >
            <Menu />
          </IconButton>
        )}
        <img
          className={classes.logo}
          src={`/images/${
            process.env.REACT_APP_TYPE === 'cars' ? 'logo' : 'logo-f-sm'
          }.png`}
          alt="logo"
        />
        <List className={classes.topMenu}>
          <TopMenu />
        </List>
        <Tooltip
          title={
            is
              ? process.env.REACT_APP_TYPE === 'cars'
                ? 'Przełącz na fitness'
                : 'Przełącz na samochody'
              : ''
          }
        >
          <Button
            variant="contained"
            component={Link}
            href={
              process.env.REACT_APP_TYPE === 'cars'
                ? process.env.REACT_APP_ADMIN_FITNESS_URL
                : process.env.REACT_APP_ADMIN_CARS_URL
            }
            startIcon={<SwapHorizIcon />}
            className={classes.menuButton}
            color="secondary"
          >
            {!is &&
              (process.env.REACT_APP_TYPE === 'cars'
                ? 'Przełącz na fitness'
                : 'Przełącz na samochody')}
          </Button>
        </Tooltip>
        <UserMenu />
        <IconButton color="inherit" aria-describedby={id} onClick={handleClick}>
          <Badge badgeContent={informations.length} color="secondary">
            <Notifications />
          </Badge>
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          classes={{ root: classes.popover }}
        >
          <div>
            <List className={classes.root} dense={true}>
              {informations.map((el, index) => {
                switch (el.classType) {
                  case 'customer':
                    return (
                      <React.Fragment key={index}>
                        <ListItem
                          alignItems="flex-start"
                          component={RouterLink}
                          to={`/settings/users/${el.user.id}`}
                        >
                          <ListItemAvatar>
                            <Avatar>
                              <PersonAddIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary="Nowy użytkownik"
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  className={classes.inline}
                                  color="textPrimary"
                                >
                                  {el.user.email}
                                </Typography>
                                {' — Zatwierdź użytkownika'}
                              </React.Fragment>
                            }
                          />
                          <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="delete">
                              <ArrowForwardIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Divider variant="inset" component="li" />
                      </React.Fragment>
                    )
                  case 'breakdown':
                    return (
                      <React.Fragment key={index}>
                        <ListItem
                          alignItems="flex-start"
                          component={RouterLink}
                          to={`/leases/current/${el.hireItem.hire.id}/breakId/${el.id}/${el.hireItem.id}`}
                        >
                          <ListItemAvatar>
                            <Avatar>
                              <NotificationImportantIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary="Nowe zgłoszenie"
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  className={classes.inline}
                                  color="textPrimary"
                                >
                                  {el?.hireItem?.hire?.customer?.user?.name}
                                </Typography>
                                {' — zgłoszenie awarii samochodu'}
                              </React.Fragment>
                            }
                          />
                          <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="delete">
                              <ArrowForwardIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Divider variant="inset" component="li" />
                      </React.Fragment>
                    )
                  case 'hire':
                    const date = new Date(el.startDate)
                    return (
                      <React.Fragment key={index}>
                        <ListItem
                          alignItems="flex-start"
                          component={RouterLink}
                          to={`/leases/current/${el.id}`}
                        >
                          <ListItemAvatar>
                            <Avatar>
                              <AddShoppingCartIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary="Nowa rezerwacja"
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  className={classes.inline}
                                  color="textPrimary"
                                >
                                  {el?.customer?.user?.name}
                                </Typography>
                                {' — Zamówiona rezerwacja od '}{date.toLocaleDateString()}
                              </React.Fragment>
                            }
                          />
                          <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="delete">
                              <ArrowForwardIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Divider variant="inset" component="li" />
                      </React.Fragment>
                    )
                  default:
                    return false
                }
              })}
            </List>
          </div>
        </Popover>

        <Tooltip title="Log out">
          <IconButton onClick={handlers.handleLogout} color="inherit">
            <ExitToApp />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </MuiAppBar>
  )
}

AppBar.propTypes = {
  handlers: PropTypes.shape({
    handleExpand: PropTypes.func,
    handleLogout: PropTypes.func.isRequired,
  }).isRequired,
  status: PropTypes.shape({
    isExpanded: PropTypes.bool,
    withMenuIcon: PropTypes.bool,
  }).isRequired,
}
