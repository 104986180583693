import React from 'react'
import { prefixPaths } from '_helpers/prefixPaths'
import { generateRoutes } from '_helpers/generateRoutes'
import { generateBreadcrumbs } from '_helpers/generateBreadcrumbs'
import { DriveEta, Archive, AirportShuttle } from '@material-ui/icons'
import { Route, Redirect } from 'react-router-dom'
import { Page } from 'pages/Page'
import { NotFound } from 'pages/NotFound'
import { List } from './List'
import { LeaseContainer } from './Lease'
import { Breakdowns } from './Breakdowns'
import { Archive as ArchiveLease } from './Archive'
import api from 'api'

const menu = () => {
  if (api.appType === 'fitness') {
    return [routes()[0], routes()[2]]
  } else {
    return [routes()[0], routes()[1], routes()[2]]
  }
}

const routes = () => [
  {
    title: 'Bieżące najmy',
    type: Route,
    render: () => <List link="current" />,
    path: '/current',
    exact: true,
    icon: DriveEta,
  },
  {
    title: 'Zgłoszenia',
    type: Route,
    render: () => <Breakdowns link="current" />,
    path: '/notifications',
    exact: true,
    icon: Archive,
  },
  {
    title: 'Archiwum',
    type: Route,
    render: () => <ArchiveLease link="archive" />,
    path: '/archive',
    exact: true,
    icon: AirportShuttle,
  },
  {
    title: 'Edycja najmu',
    type: Route,
    component: LeaseContainer,
    path: '/archive/:leaseId',
    exact: true,
  },
  {
    title: 'Edycja najmu',
    type: Route,
    component: LeaseContainer,
    path: '/current/:leaseId',
    exact: true,
  },
  {
    title: 'Edycja najmu',
    type: Route,
    component: LeaseContainer,
    path: '/current/:leaseId/breakId/:breakIdM/:carIdM',
    exact: false,
  },
  {
    title: 'Najmy',
    type: Redirect,
    from: '/',
    to: '/current',
    exact: true,
  },
  {
    type: Route,
    component: NotFound,
    path: '/*',
  },
]

export const Leases = props => {
  const { match, location } = props
  return (
    <Page
      menu={prefixPaths(match.url, menu())}
      routes={generateRoutes(
        prefixPaths(match.url, routes(), ['path', 'from', 'to'])
      )}
      breadcrumbs={generateBreadcrumbs(
        location.pathname,
        prefixPaths(match.url, routes(), ['path', 'from'])
      )}
    />
  )
}
