import React from 'react'
import { useSelector } from 'react-redux'
import EquipmentDetail from './EquipmentDetail'
import { authHeader } from '_helpers/authHeader'
import { useFetch } from '_helpers/useFetch'
import Alert from '@material-ui/lab/Alert'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import MobileStepper from '@material-ui/core/MobileStepper'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 'auto',
    maxWidth: 400,
    overflow: 'hidden',
    display: 'block',
    width: '100%',
  },
}))
export const EquipmentDetailContainer = (match) => {
  const classes = useStyles()
  const theme = useTheme()

  const { equipmentId } = match

  const auth = useSelector((state) => state.auth)

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const args = [auth.data.token, equipmentId]

  const res = useFetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}/fitness_equipments/${equipmentId}`,
    options,
    args
  )
  const [activeStep, setActiveStep] = React.useState(0)

  const tutorialSteps = { ...res?.state?.data?.photos }

  const maxSteps = res?.state?.data?.photos?.length

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }
  return (
    <>
      <Backdrop className={classes.backdrop} open={res.state.isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {res.state.error && (
        <Alert severity="error">wystąpił błąd podczas pobierania danych</Alert>
      )}

      {res.state.data && (
        <>
          <EquipmentDetail
            equipment={res.state.data}
            setEquipment={res.setState}
            header={[
              {
                name: 'Marka',
                prop: 'model.brand.name',
              },
              {
                name: 'Wymiary',
                prop: 'model.size',
              },
              {
                name: 'Masa',
                prop: 'model.weight',
              },
              {
                name: 'Opis',
                prop: 'model.description',
              },
              {
                name: 'Zdjęcia',
                prop: 'photos',
              },
            ]}
          />
          <div className={classes.root}>
            <Paper square elevation={0} className={classes.header}>
              <Typography>{tutorialSteps[activeStep].id}</Typography>
            </Paper>
            <img
              className={classes.img}
              src={`${process.env.REACT_APP_API_ENTRYPOINT}${tutorialSteps[activeStep].url}`}
              alt={tutorialSteps[activeStep].id}
            />
            <MobileStepper
              steps={maxSteps}
              position="static"
              variant="text"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                >
                  Następny
                  {theme.direction === 'rtl' ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  {theme.direction === 'rtl' ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  Poprzedni
                </Button>
              }
            />
          </div>
        </>
      )}
    </>
  )
}
