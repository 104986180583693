import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import { getPLNFromApi } from '_helpers/currency'

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
})

export default function InvoiceTable({ data }) {
  const rows = data?.rows
  const TAX_RATE = rows[0].vatRate

  function subtotal(items) {
    return items
      .map(({ valueNettoAfterDiscount }) => valueNettoAfterDiscount)
      .reduce((sum, i) => sum + i, 0)
  }

  const invoiceSubtotal = subtotal(rows)
  const invoiceTaxes = (TAX_RATE / 100) * invoiceSubtotal
  const invoiceTotal = invoiceTaxes + invoiceSubtotal
  const classes = useStyles()

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="spanning table">
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={4}>
              Szczegóły
            </TableCell>
            <TableCell align="right">Cena</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Nazwa towaru lub usługi</TableCell>
            <TableCell align="right">Ilość</TableCell>
            <TableCell align="right">Cena netto</TableCell>
            <TableCell align="right">Rabat</TableCell>
            <TableCell align="right">Wartość netto</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell>
                {data?.hire ? (
                  <Button
                    component={Link}
                    color="primary"
                    to={`/leases/current/${data?.hire?.id}`}
                  >
                    {row.name}
                  </Button>
                ) : (
                  row.name
                )}
              </TableCell>
              <TableCell align="right">{row.quantity}</TableCell>
              <TableCell align="right">
                {getPLNFromApi(row.priceNetto)}
              </TableCell>
              <TableCell align="right">{row.discountRate} %</TableCell>
              <TableCell align="right">
                {getPLNFromApi(row.quantity * row.valueNettoAfterDiscount)}
              </TableCell>
            </TableRow>
          ))}

          <TableRow>
            <TableCell rowSpan={3} />
            <TableCell colSpan={3}>Suma netto</TableCell>
            <TableCell align="right">
              {getPLNFromApi(invoiceSubtotal)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2}>Kwota VAT</TableCell>
            <TableCell align="right">{`${TAX_RATE.toFixed(0)} %`}</TableCell>
            <TableCell align="right">{getPLNFromApi(invoiceTaxes)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={3}>Suma brutto</TableCell>
            <TableCell align="right">{getPLNFromApi(invoiceTotal)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right">
              <b>Sprzedawca:</b>
            </TableCell>
            <TableCell>
              {data?.seller?.firstName} {data?.seller?.lastName}
            </TableCell>
            <TableCell align="right">
              <b>Zapłacono:</b>
            </TableCell>
            <TableCell>{data?.paid && getPLNFromApi(data?.paid)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
