import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import { Title } from 'components/Title'
import Table from 'components/table/TableList'
import { authHeader } from '_helpers/authHeader'
import { useSelector, useDispatch } from 'react-redux'
import { useFetch } from '_helpers/useFetch'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  gridParent: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '-48px',
    marginBottom: '20px',
  },
})
const typingTimeout = 1000

export default function CarList(link) {
  const classes = useStyles()
  const [queryParams, setQueryParams] = useState({
    search: '',
    page: 1,
  })
  const [typingState, setTypingState] = useState({
    timeoutId: null,
  })
  const [searchText, setSearchText] = useState('')
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()

  const mainData = [
    {
      name: 'MARKA',
      prop: 'carSerie.model.brand.name',
    },
    {
      name: 'MODEL',
      prop: 'carSerie.model.name',
    },
    {
      name: 'ROCZNIK',
      prop: 'productionYear',
    },
    {
      name: 'SILNIK',
      prop: 'carSerie.enginePower.name',
    },
    {
      name: 'TYP',
      prop: 'carSerie.bodyType.name',
    },
    {
      name: 'NR REJ',
      prop: 'registrationNumber',
    },
    {
      name: 'LOKALIZACJA',
      prop: 'rentalPlace.city',
    },
    {
      name: 'STATUS',
      prop: 'status',
    },
    {
      name: 'ZGŁOSZENIA',
      prop: 'warnings.length',
    },
  ]
  const expandData = [
    {
      name: 'Stan licznika',
      prop: 'distanceMeter',
    },
    {
      name: 'Ładowność',
      prop: 'carSerie.loadCapacity.name',
    },
    {
      name: 'Pojemność',
      prop: 'carSerie.maximumTrunkSpace.name',
    },
    {
      name: 'Opony',
      prop: 'tyres.size',
    },
    {
      name: 'Cena za dzień',
      prop: 'dailyPrice',
    },
  ]
  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const args = [auth.data.token, dispatch]

  const res = useFetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}/cars`,
    options,
    args,
    queryParams
  )

  const handleSearchChange = e => {
    const search = e.target.value
    setSearchText(search)

    clearTimeout(typingState.timeoutId)
    const timeoutId = setTimeout(() => {
      setTypingState({
        ...typingState,
        timeoutId: null,
      })
      setQueryParams({
        ...queryParams,
        search: search,
        page: 1,
      })
    }, typingTimeout)

    setTypingState({
      ...typingState,
      timeoutId: timeoutId,
    })
  }

  return (
    <React.Fragment>
      <Title>Pojazdy</Title>
      <div className={classes.gridParent}>
        <TextField
          label="Szukaj pojazdu"
          value={searchText}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <Table
        data={res.state.data?.content}
        isLoading={res.state.isLoading}
        error={res.state.error}
        mainData={mainData}
        expandData={expandData}
        dataLength={res.state.data?.totalItems}
        colWidth="260px"
        pagination={true}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        {...link}
      />
    </React.Fragment>
  )
}
