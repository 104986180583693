import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Skeleton from '@material-ui/lab/Skeleton'
import Alert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import { useSelector, useDispatch } from 'react-redux'
import Moment from 'react-moment'
import { Title } from 'components/Title'
import { authHeader } from '_helpers/authHeader'
import { get } from '_helpers/getProp'
import { useFetch } from '_helpers/useFetch'
import { polishPlural } from '_helpers/polishPlural'
import CustomCell from 'components/table/CustomCell'

const useStyles = makeStyles((theme) => ({
  seeMore: {
    paddingTop: theme.spacing(3),
    marginTop: 'auto',
  },
}))

export default function HomeBox({
  match,
  title,
  keys,
  header,
  linkMore,
  queryParams,
}) {
  const classes = useStyles()

  const auth = useSelector((state) => state.auth)

  const dispatch = useDispatch()

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const args = [auth.data.token, dispatch, match, queryParams]

  const res = useFetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}/${match}`,
    options,
    args,
    queryParams
  )

  return (
    <React.Fragment>
      <Title>
        {title} ({res.state.data?.totalItems})
      </Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            {header.map((name, i) => (
              <TableCell key={`hb-head-${i}`}> {name} </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {res.state.isLoading &&
            [...Array(5)].map((i, r) => (
              <TableRow key={r}>
                <TableCell colSpan={6}>
                  <Skeleton animation="wave" />
                </TableCell>
              </TableRow>
            ))}
          {res.state.error && (
            <TableRow>
              <TableCell colSpan={6} style={{ padding: 0 }}>
                <Alert severity="error">
                  wystąpił błąd podczas pobierania danych
                </Alert>
              </TableCell>
            </TableRow>
          )}

          {res.state.data?.content &&
            res.state.data.content.slice(0, 5).map((row) => (
              <TableRow key={row.id}>
                {keys.map((e, i) => {
                  const value = row[e]

                  if (typeof value === 'string' && value.charAt(0) === '/') {
                    return i === 0 ? (
                      <CustomCell
                        key={`${i}-srp`}
                        fieldName={row[e]}
                        fieldProp="hire.customer.user.name"
                        isArray={false}
                      />
                    ) : (
                      <CustomCell
                        key={`${i}-srp`}
                        fieldName={row[e]}
                        fieldProp="car.fullName"
                        isArray={false}
                      />
                    )
                  } else {
                    if (e === 'hiredItems.length') {
                      const car = get(row, e, '')
                      return (
                        <TableCell key={i}>
                          {car}
                          {polishPlural(
                            ' pojazd',
                            ' pojazdy',
                            ' pojazdów',
                            car
                          )}
                        </TableCell>
                      )
                    }
                    if (
                      e === 'startDate' ||
                      e === 'endDate' ||
                      e === 'invoice.paymentDate' ||
                      e === 'createdAt' ||
                      e === 'breakdownDatetime'
                    ) {
                      return (
                        <TableCell key={i}>
                          <Moment format="DD.MM.YYYY">{get(row, e, '')}</Moment>
                        </TableCell>
                      )
                    }

                    return <TableCell key={i}>{get(row, e, '')}</TableCell>
                  }
                })}
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <div className={classes.seeMore}>
        {linkMore ? (
          <Button
            component={Link}
            to={linkMore}
            variant="outlined"
            color="primary"
          >
            pokaż wszystkie
          </Button>
        ) : (
          <Button
            component={Link}
            to={`/home/${match}`}
            variant="outlined"
            color="primary"
          >
            więcej
          </Button>
        )}
      </div>
    </React.Fragment>
  )
}
