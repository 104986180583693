import { fetchData } from '_helpers/fetchData'
import api from '../api'

const data = (id) =>
  fetchData(`${api.hires}/${id}`).then(
    (response) => ({ response }),
    (error) => ({ error })
  )

export const leaseService = {
  data,
}
