import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'
import { authHeader } from '_helpers/authHeader'
import api from 'api'

const BuyerComponent = (props) => {
  const { classes, setBuyer, buyer, error } = props
  const [data, setData] = useState([])
  const [inputValue, setInputValue] = useState('')

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  useEffect(() => {
    const abortController = new AbortController()
    const fetchData = async () => {
      fetch(
        `${process.env.REACT_APP_API_ENTRYPOINT}${api.customers}?search=${inputValue}&perPage=20`,
        {
          ...options,
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setData(res.content)
        })
    }
    fetchData()
    return () => {
      abortController.abort()
    }
    // eslint-disable-next-line
  }, [inputValue])

  const [val] = useState(null)
  return (
    <>
      <Typography variant="h6" color="textPrimary" gutterBottom>
        Nabywca
      </Typography>
      <Autocomplete
        className={classes.formControl}
        id="combo-box-demo"
        options={data}
        defaultValue={val}
        value={buyer}
        onChange={(event, newValue) => {
          setBuyer(newValue)
        }}
        getOptionLabel={(option) =>
          option.personalDetails ? option.personalDetails.companyName : ''
        }
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Wybierz lub wpisz"
            variant="outlined"
            error={error?.buyer}
          />
        )}
      />
      <TextField
        label="Ulica"
        value={
          buyer && buyer?.personalDetails?.street
            ? buyer?.personalDetails?.street
            : ''
        }
        margin="normal"
        name="buyerStreet"
        variant="outlined"
        InputProps={{
          readOnly: true,
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        label="Miasto"
        value={
          (buyer &&
            `${buyer?.personalDetails?.postCode} ${buyer?.personalDetails?.city}`) ||
          ''
        }
        margin="normal"
        variant="outlined"
        InputProps={{
          readOnly: true,
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        label="NIP"
        value={(buyer && buyer?.personalDetails?.nip) || ''}
        margin="normal"
        variant="outlined"
        InputProps={{
          readOnly: true,
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </>
  )
}

BuyerComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  setBuyer: PropTypes.func.isRequired,
  buyer: PropTypes.object,
  error: PropTypes.object.isRequired,
}

export default BuyerComponent
