import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Moment from 'react-moment'

import MoneyField from 'components/fields/MoneyField'
import DatePickerField from 'components/fields/DatePickerField'
import StringField from 'components/fields/StringField'

import CarPartList from '../CarPartList'

const moduleName = 'Najmy'

export const HireItemValue = ({ item }) => {
  return (
    <>
      <Moment format="DD.MM.YYYY">{item?.hire?.startDate}</Moment>
      {' - '}
      <Moment format="DD.MM.YYYY">{item?.hire?.endDate}</Moment>
      {` | ${item?.hire?.customer?.user?.name} `}
    </>
  )
}

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
}))

const compareFunction = (a, b) =>
  new Date(b.endDate).getTime() - new Date(a.endDate).getTime()

export default function HireItemList({ entities }) {
  const classes = useStyles()
  const createSpecificColumns = createSetRow => [
    {
      id: 'hire',
      label: 'Wynajem',
      minWidth: 100,
      renderValue: (item, violations) => (
        <Link to={`/leases/leases/${item.hire.id}`} className={classes.link}>
          {item.hire.id}
        </Link>
      ),
    },
    {
      id: 'customerName',
      label: 'Klient',
      minWidth: 100,
      renderValue: (item, violations) => (
        <StringField value={item?.hire?.customer?.user?.name} readOnly />
      ),
    },
    {
      id: 'car',
      label: 'Auto',
      minWidth: 100,
      renderValue: (item, violations) => (
        <StringField value={item?.car?.fullName} readOnly />
      ),
    },
    {
      id: 'startDate',
      label: 'Data rozpoczęcia',
      minWidth: 200,
      renderValue: (item, violations) => (
        <DatePickerField value={item?.hire?.startDate} readOnly />
      ),
    },
    {
      id: 'endDate',
      label: 'Data zakończenia',
      minWidth: 200,
      renderValue: (item, violations) => (
        <DatePickerField value={item?.hire?.endDate} readOnly />
      ),
    },
    {
      id: 'sourceRentalPlace',
      label: 'Wypożyczalnia startowa',
      minWidth: 100,
      renderValue: (item, violations) => (
        <StringField value={item?.hire?.sourceRentalPlace?.name} readOnly />
      ),
    },
    {
      id: 'targetRentalPlace',
      label: 'Wypożyczalnia końcowa',
      minWidth: 100,
      renderValue: (item, violations) => (
        <StringField value={item?.hire?.targetRentalPlace?.name} readOnly />
      ),
    },
    {
      id: 'hireItemStatus',
      label: 'Status',
      minWidth: 100,
      renderValue: (item, violations) => (
        <StringField value={item?.hireItemStatus} readOnly />
      ),
    },
    {
      id: 'cost',
      label: 'Wartość',
      minWidth: 100,
      renderValue: (item, violations) => (
        <MoneyField value={item.cost} readOnly />
      ),
    },
  ]

  return (
    <CarPartList
      entities={entities}
      moduleName={moduleName}
      compareFunction={compareFunction}
      createSpecificColumns={createSpecificColumns}
      addable={false}
      editable={false}
    />
  )
}
