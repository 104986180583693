import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { requestActions } from '_actions'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { Button } from '@material-ui/core'
import { Save } from '@material-ui/icons'
import { StringType } from './fields/StringType'
import { PasswordRepeatType } from './fields/PasswordRepeatType'
import { useStyles } from './FormFactoryStyles'
import { Loader } from 'components/Loader'
import { Title } from 'components/Title'
import { requestConstants } from '_constants'

const fieldTypes = {
  string: StringType,
  password: StringType,
  passwordRepeat: PasswordRepeatType,
}

export const FormFactory = ({
  title = null,
  url,
  method = 'PUT',
  properties,
  resource = null,
  handleSuccess = null,
  submitFunction = null,
  disabledInput = false,
}) => {
  const classes = useStyles()
  const [state, setState] = useState({
    values: {},
    errors: {},
    validators: {},
    errorMessageSetters: {},
    invalid: 0,
    isProcessing: false,
    url:
      url.includes(':id') && resource ? url.replace(':id', resource.id) : url,
  })
  const dispatch = useDispatch()

  const setValue = (name, value) => {
    const isInvalid = value instanceof Error
    setState(state => ({
      ...state,
      values: { ...state.values, [name]: !isInvalid && value },
      errors: { ...state.errors, [name]: isInvalid },
      invalid:
        state.invalid +
        (!!state.errors[name] === !isInvalid && -1 + isInvalid * 2),
    }))
  }

  const setValidator = (name, validator, values) => {
    setState(state => ({
      ...state,
      validators: {
        ...state.validators,
        [name]: { validator, values: values || [name] },
      },
    }))
  }

  const setErrorMessageSetter = (name, errorMessageSetter) => {
    setState(state => ({
      ...state,
      errorMessageSetters: {
        ...state.errorMessageSetters,
        [name]: errorMessageSetter,
      },
    }))
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (
      Object.keys(state.validators).length &&
      !Object.keys(state.validators).reduce(
        (status, name) =>
          state.validators[name].validator(
            ...Object.keys(state.values)
              .filter(item => state.validators[name].values.includes(item))
              .map(item => state.values[item])
          ) && status,
        true
      )
    ) {
      return
    }

    if (submitFunction) {
      submitFunction(state.values)
      return
    }

    setState(state => ({ ...state, isProcessing: true }))
    dispatch(requestActions.start(state.url))
    fetchDataHandleAuthError(
      state.url,
      method,
      { body: JSON.stringify(state.values) },
      () => {
        setState(state => ({ ...state, isProcessing: false }))
        dispatch(requestActions.success())
        notification(
          'success',
          method === 'PUT' ? 'Rekord zaktualizowany' : 'Rekord dodany',
          'Zapisano'
        )
        handleSuccess && handleSuccess(state.values)
      },
      error => {
        setState(state => ({ ...state, isProcessing: false }))
        error.response.violations.map(item =>
          state.errorMessageSetters[item.propertyPath](item.message)
        )
        notification(
          'error',
          error.response.violations.length
            ? 'Incorrect form'
            : error.response.detail,
          error.response.title
        )
      },
      dispatch,
      requestConstants.FAILURE
    )
  }

  return (
    <>
      {title && <Title>{title}</Title>}
      {state.isProcessing && <Loader />}
      <form onSubmit={handleSubmit} className={classes.root}>
        {Object.keys(properties).map(name => {
          const FieldComponent = fieldTypes[properties[name].type]
          return (
            <div
              key={name}
              style={{
                marginBottom: '12px',
              }}
            >
              <FieldComponent
                name={name}
                type={properties[name].type}
                label={properties[name].description}
                hint={properties[name].hint}
                value={resource && resource[name]}
                disabled={state.isProcessing || disabledInput}
                validate={properties[name].validate || []}
                setValue={setValue}
                setValidator={setValidator}
                setErrorMessageSetter={setErrorMessageSetter}
              />
            </div>
          )
        })}
        {!disabledInput && (
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            size="small"
            startIcon={<Save />}
            className={classes.save}
            disabled={state.isProcessing || !!state.invalid}
          >
            Zapisz
          </Button>
        )}
      </form>
    </>
  )
}
