import { take, call } from 'redux-saga/effects'
import { authConstants } from '_constants'
import { schemaActions, userActions } from '_actions'

export function* authFlow() {
  while (true) {
    yield take([authConstants.LOGIN_SUCCESS, authConstants.LOGIN_RESTORE])
    yield call(schemaActions.fetch)
    yield call(userActions.fetchProfile)

    yield take([authConstants.REFRESH_FAILURE, authConstants.LOGOUT])
    yield call(schemaActions.erase)
    yield call(userActions.eraseProfile)
  }
}
