import React, { useState } from 'react'
import clsx from 'clsx'
import { useTheme } from '@material-ui/core/styles'
import {
  XAxis,
  YAxis,
  Cell,
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  Tooltip,
  Legend,
  Bar,
} from 'recharts'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import { Title } from 'components/Title'
import { red } from '@material-ui/core/colors'
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 505,
  },
  info: {
    color: red[600],
  },
}))

export default function Chart(props) {
  const { data, reportId } = props
  const [state, setState] = useState({ ...data, activeIndex: 0 })

  const handleClick = (index) => {
    setState({
      ...state,
      activeIndex: index,
    })
  }
  let content
  const { profitabilityYears } = state
  if (reportId === 'whole') {
    content = profitabilityYears.content
  } else {
    content = profitabilityYears
  }

  const activeItem = content[state.activeIndex]

  const theme = useTheme()
  const classes = useStyles()
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight)

  return (
    <React.Fragment>
      <Grid item xs={6}>
        <Paper className={fixedHeightPaper}>
          <Title>Dane prognozowane i historyczne</Title>
          <ResponsiveContainer>
            <BarChart data={content} layout="vertical">
              <XAxis type="number" />

              <YAxis type="category" dataKey="year"></YAxis>
              <CartesianGrid />
              <Tooltip />
              <Legend />

              <Bar
                dataKey="profitabilityRate"
                name="Rentowność (%)"
                fill={theme.palette.primary.main}
              >
                {content &&
                  content.map((entry, index) => (
                    <Cell
                      onClick={(e) => handleClick(index)}
                      cursor="pointer"
                      fill={
                        index === state.activeIndex
                          ? '#82ca9d'
                          : theme.palette.primary.main
                      }
                      key={`cell-${index}`}
                    />
                  ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Paper>
      </Grid>

      <Grid item xs={6}>
        <Paper className={fixedHeightPaper}>
          <ResponsiveContainer>
            <BarChart data={[activeItem]}>
              <XAxis type="category" dataKey="year"></XAxis>
              <YAxis type="number"></YAxis>
              <CartesianGrid />

              <Legend />

              <Bar
                dataKey="usageCost"
                name="Koszty utrzymania"
                fill="#0088FE"
              />
              <Bar
                dataKey="leasingCost"
                name="Koszty leasingu / rat"
                fill="#00C49F"
              />
              <Bar
                dataKey="repairsCost"
                name="Naprawy / Awarie"
                fill="#FFBB28"
              />
              <Bar dataKey="income" name="Przychód" fill="#FF8042" />
              <Bar dataKey="benefit" name="Dochód" fill="rgb(148, 103, 189)" />
              <Bar
                dataKey="purchaseAmortedCost"
                name="Amortyzacja"
                fill="rgb(214, 39, 40)"
              />
            </BarChart>
          </ResponsiveContainer>

          <Typography component="p" variant="h6">
            {`${activeItem.year}, Rentowność: ${activeItem.profitabilityRate}% `}
            {activeItem.overMinimnumProfitabilityRate && (
              <span className={classes.info}>
                - Poniżej zakładanego poziomu!
              </span>
            )}
          </Typography>
        </Paper>
      </Grid>
    </React.Fragment>
  )
}
