import React, { useState } from 'react'
import Button from '@material-ui/core/Button'

import { fetchDataHandleAuthError } from '../../../_helpers/fetchDataHandleAuthError'
import { useDispatch } from 'react-redux'
import { requestActions } from '_actions'
import { notification } from '_helpers/notification'
import { requestConstants } from '_constants'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DatePickerField from '../../../components/fields/DatePickerField'
import MoneyField from '../../../components/fields/MoneyField'
import api from 'api'

const endPoint = api.archived
const carIriFunction = (id) => `${api.iri.equipment}${id}`

export default function ArchiveButton({ item, setItem }) {
  const dispatch = useDispatch()
  const [entity, setEntity] = useState(
    api.appType === 'cars'
      ? {
          car: carIriFunction(item.id),
        }
      : {
          fitnessEquipment: carIriFunction(item.id),
        }
  )
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleSend = () => {
    dispatch(requestActions.start(endPoint))
    fetchDataHandleAuthError(
      endPoint,
      'POST',
      { body: JSON.stringify(entity) },
      (response) => {
        setItem((prevState) => ({
          ...prevState,
          archived: response,
        }))
        handleClose()
        dispatch(requestActions.success())
        notification('success', 'Rekord dodany', 'Zapisano')
      },
      (error) => {
        notification(
          'error',
          error.response.violations.length
            ? 'Incorrect form'
            : error.response.detail,
          error.response.title
        )
      },
      dispatch,
      requestConstants.FAILURE
    )
  }

  return (
    <>
      <Button onClick={handleOpen} color="primary" variant="outlined">
        Archiwizuj
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Archiwizacja</DialogTitle>
        <DialogContent>
          <DatePickerField
            fieldName={'Data pozbycia się'}
            type={'dateOfDisposal'}
            updatableParentEntity={entity}
            setUpdatableParentEntity={setEntity}
          />
          <MoneyField
            fieldName={'Cena sprzedaży'}
            value={0}
            type={'sellmentPrice'}
            updatableParentEntity={entity}
            setUpdatableParentEntity={setEntity}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Anuluj
          </Button>
          <Button onClick={handleSend} color="primary">
            Akceptuj
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
