import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import Avatar from '@material-ui/core/Avatar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import TextField from '@material-ui/core/TextField'
import CheckIcon from '@material-ui/icons/Check'
import DialogContentText from '@material-ui/core/DialogContentText'
import Typography from '@material-ui/core/Typography'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { useDispatch } from 'react-redux'
import { requestActions } from '_actions'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { requestConstants } from '_constants'
import api from 'api'
import { setCurrencyToApi } from '_helpers/currency'

const useStyles = makeStyles({
  toPaid: {
    display: 'flex',
    alignItems: 'baseline',
    marginTop: '10px',
    '& span': {
      marginRight: '10px',
    },
  },
})

function StatusDialog(props) {
  const classes = useStyles()
  const { onClose, open, toBePaid } = props
  const [paid, setPaid] = React.useState(0)
  const [date, setDate] = React.useState(new Date())
  const handleClose = () => {
    onClose(0)
  }
  const handleListItemClick = () => {
    onClose(paid)
  }
  const handleInput = (value) => {
    setPaid(value)
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Rozliczono </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Zapłacono"
          type="number"
          fullWidth
          value={paid}
          onChange={(e) => handleInput(e.target.value)}
          inputProps={{
            max: toBePaid,
          }}
        />
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label="data"
            format="DD.MM.YYYY"
            value={date}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            onChange={(date) => setDate(date)}
          />
        </MuiPickersUtilsProvider>
        <DialogContentText className={classes.toPaid}>
          <span>Pozostało do zapłaty:</span>
          <Typography component={'span'} variant={'subtitle1'}>
            {toBePaid} zł
          </Typography>
        </DialogContentText>
      </DialogContent>

      <List>
        <ListItem autoFocus button onClick={() => setPaid(toBePaid)}>
          <ListItemAvatar>
            <Avatar>
              <CheckIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Zapłacono" />
        </ListItem>
      </List>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Anuluj
        </Button>

        <Button
          variant="contained"
          onClick={(e) => handleListItemClick()}
          color="primary"
        >
          Zatwierdź
        </Button>
      </DialogActions>
    </Dialog>
  )
}

StatusDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  toBePaid: PropTypes.number.isRequired,
}

export default function StatusDialogBtn(props) {
  const { label, toBePaid, invoiceId, onChangePay } = props
  const [open, setOpen] = React.useState(false)
  const [selectedValue, setSelectedValue] = React.useState(0)
  const dispatch = useDispatch()

  React.useEffect(() => {
    const abort = new AbortController()
    const fetchData = async () => {
      await fetchDataHandleAuthError(
        `${api.invoices}/settlement/${invoiceId}`,
        'PUT',
        {
          body: JSON.stringify({
            paid: setCurrencyToApi(selectedValue),
            fullPaid: selectedValue === toBePaid,
          }),
        },
        () => {
          dispatch(requestActions.success())
          notification('success', 'Wpłata zaksięgowana', 'Rozliczono')
          setSelectedValue(0)
          onChangePay()
        },
        (error) => {
          notification(
            'error',
            error.response.violations.length
              ? 'Incorrect form'
              : error.response.detail,
            error.response.title
          )
          setSelectedValue(0)
        },
        dispatch,
        requestConstants.FAILURE
      )
    }
    if (selectedValue > 0) {
      fetchData()
    }
    return () => {
      return abort.abort()
    }
    // eslint-disable-next-line
  }, [selectedValue])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = (value) => {
    setOpen(false)
    setSelectedValue(value)
  }

  return (
    <div>
      <Button color="primary" onClick={handleClickOpen}>
        {label}
      </Button>
      <StatusDialog open={open} onClose={handleClose} toBePaid={toBePaid} />
    </div>
  )
}

StatusDialogBtn.propTypes = {
  label: PropTypes.string.isRequired,
  toBePaid: PropTypes.number.isRequired,
  invoiceId: PropTypes.number.isRequired,
  onChangePay: PropTypes.func.isRequired,
}
