import React from 'react'

import { LeasingDealValue } from '../LeasingDeal'
import { InsuranceValue } from '../Insurance'
import { SimpleDateModuleValue } from '../SimpleDateModule'
import { AdditionalServiceValue } from '../AdditionalService'
import { TyresValue } from '../Tyres'
import { ModelModuleWithDateAndDistanceValue } from '../ModelModuleWithDateAndDistance'
import { BrakeBlockValue } from '../BrakeBlock'
import { EngineOilValue } from '../EngineOil'
import { HireItemValue } from '../HireItem'
import { RepairValue } from '../Repair'

export default function RentableItemValueFactory({ type, item }) {
  switch (type) {
    case 'leasingDeal':
      return <LeasingDealValue item={item} />
    case 'insurance':
      return <InsuranceValue item={item} />
    case 'additionalService':
      return <AdditionalServiceValue item={item} />
    case 'tyres':
      return <TyresValue item={item} />
    case 'spareTyre':
      return <TyresValue item={item} />
    case 'airConditioning':
      return <SimpleDateModuleValue item={item} />
    case 'registrationService':
      return <SimpleDateModuleValue item={item} />
    case 'brakeBlocks':
      return <BrakeBlockValue item={item} />
    case 'engineOil':
      return <EngineOilValue item={item} />
    case 'hireItem':
      return <HireItemValue item={item} />
    case 'repair':
      return <RepairValue item={item} />
    default:
      return <ModelModuleWithDateAndDistanceValue item={item} />
  }
}
