import React, { useState } from 'react'
import RentalPlaceCreation from './RentalPlaceCreation'

const RentalPlaceCreationContainer = ({ link, endPoint }) => {
  const [rentalPlace, setRentalPlace] = useState({
    stat: true,
  })

  return (
    <>
      <RentalPlaceCreation
        rentalPlace={rentalPlace}
        setRentalPlace={setRentalPlace}
        endPoint={endPoint}
        link={link}
      />
    </>
  )
}

export default RentalPlaceCreationContainer
