import React from 'react'
import { useSelector } from 'react-redux'

import StringField from 'components/fields/StringField'
import MoneyField from 'components/fields/MoneyField'
import DateField from '../fields/DateField'
import StatusField from '../fields/StatusField'
import DocsField from '../fields/DocsField'
import {hireStatus, invoiceStatusTrans} from 'dataconfig'
import InvoiceField from '../fields/InvoiceField'

export default function LeaseFieldFactory({
  fieldName,
  type,
  value,
  violations,
  updatableParentEntity,
  setUpdatableParentEntity,
}) {
  const lease = useSelector((state) => state.lease)
  switch (type) {
    case 'id':
      return (
        <StringField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
          readOnly={true}
        />
      )
    case 'customer.personalDetails.companyName':
      return (
        <StringField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
          readOnly={true}
        />
      )

    case 'startDate':
      return <DateField fieldName={fieldName} value={value} />
    case 'endDate':
      return <DateField fieldName={fieldName} value={value} />

    case 'sourceRentalPlace.name':
      return (
        <StringField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
          readOnly={true}
        />
      )

    case 'hireStatus':
      const listStatus = hireStatus.filter((el) => el.type === 'hire')
      const val = hireStatus.find((el) => el.id === value)
      let info = ''

      hireStatus.forEach((el) => {
        const hires = lease.hiredItems.filter(
          (st) => st.hireItemStatus === el.id
        )
        if (hires.length > 0) {
          info += ` ${el.name} (${hires.length}), `
        }
      })
      return (
        <>
          <StatusField
            fieldName={fieldName}
            selectableEntity={
              val !== undefined ? val : { id: value, name: value, type: 'hire' }
            }
            listStatus={listStatus}
            leaseId={lease.id}
          />
          <StringField
            fieldName=""
            value={info}
            type={type}
            violations={violations}
            updatableParentEntity={updatableParentEntity}
            setUpdatableParentEntity={setUpdatableParentEntity}
            readOnly={true}
          />
        </>
      )
    case 'message':
      return (
        <StringField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
          readOnly={true}
        />
      )

    case 'documents':
      return (
        <DocsField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
        />
      )
    case 'cost':
      return (
        <MoneyField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
          readOnly={true}
        />
      )
    case 'invoice.invoiceStatus':
      return (
        <>
          <InvoiceField fieldName={fieldName} value={invoiceStatusTrans[value] !== undefined ? invoiceStatusTrans[value] : value} lease={lease} />
          {lease.invoiceDocuments.length > 0 && (
            <DocsField
              fieldName="Lista faktur"
              value={lease.invoiceDocuments}
              type={type}
              violations={violations}
              updatableParentEntity={updatableParentEntity}
              setUpdatableParentEntity={setUpdatableParentEntity}
              title="Lista wszystkich faktur powiązanych z najmem"
            />
          )}
        </>
      )

    default:
      return 'Nic'
  }
}
