import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { useHistory } from 'react-router-dom'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import Checkbox from '@material-ui/core/Checkbox'
import { authHeader } from '_helpers/authHeader'
import api from 'api'
import { notification } from '_helpers/notification'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  '@keyframes spin': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
  spinner: {
    height: '24px',
    animationName: '$spin',
    animationDuration: '1s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
  },
  formError: {
    display: 'block',
    color: 'red',
  },
}))

export const Add = () => {
  const history = useHistory()
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
  })
  const [open, setOpen] = React.useState(false)

  const handleClose = () => {
    history.push('/')
  }
  function AlertDialogSlide() {
    return (
      <div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {'Aktywuj konto'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Sprawdź swoją skrzynkę mailową i dokończ rejestrację.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Zamknij
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
  const classes = useStyles()

  const initialState = {
    email: '',
    firstName: '',
    lastName: '',
    isSuperAdmin: true,
    isSubmitting: false,
    errorMessage: null,
  }

  const [data, setData] = React.useState(initialState)

  const handleInputChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    })
  }
  const options = [
    {
      id: 0,
      name: 'Administrator',
      checked: false,
      disabled: false,
    },
    {
      id: 1,
      name: 'Super Administrator',
      checked: false,
      disabled: false,
    },
    {
      id: 2,
      name: 'Pracownik',
      checked: false,
      disabled: false,
    },
  ]

  const [checked, setChecked] = React.useState(options)
  const handleSwitch = (value, index) => {
    let newData

    setChecked(() => {
      newData = [...checked]
      newData[index].checked = value
      newData[0].disabled = false
      newData[1].disabled = false
      newData[2].disabled = false
      return newData
    })

    if (checked[0].checked === true || checked[1].checked === true) {
      setChecked(() => {
        newData = [...checked]
        newData[2].disabled = true
        return newData
      })
    }

    if (checked[1].checked === true) {
      setChecked(() => {
        newData = [...checked]
        newData[0].checked = true
        return newData
      })
    }

    if (checked[2].checked === true) {
      setChecked(() => {
        newData = [...checked]
        newData[0].disabled = true
        newData[1].disabled = true
        return newData
      })
    }
  }

  const handleFormSubmit = (event) => {
    event.preventDefault()
    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null,
    })
    fetch(
      `${process.env.REACT_APP_API_ENTRYPOINT}/users/registerAdministrator`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(),
        },
        body: JSON.stringify({
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          isCarAdmin: api.appType === 'cars' ? checked[0].checked : false,
          isFitnessAdmin:
            api.appType === 'fitness' ? checked[0].checked : false,
          isSuperAdmin: checked[1].checked,
          isCarEmployee: api.appType === 'cars' ? checked[2].checked : false,
          isFitnessEmployee:
            api.appType === 'fitness' ? checked[2].checked : false,
        }),
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json()
        } else if (res.status === 500) {
          notification('error', 'Wybierz rolę', 'Błąd')
        }
        throw res.json()
      })
      .then((res) => {
        setOpen(true)
        notification('success', 'Aktywuj konto', 'Wysłano')
      })
      .catch((errorPromise) => {
        setData({
          ...data,
          isSubmitting: false,
          errorMessage: errorPromise.message || errorPromise.statusText,
        })
        errorPromise.then((error) => {
          if (error.violations) {
            let errors = error.violations.map((e) => {
              return JSON.stringify(`${e.propertyPath} : ${e.message}`)
            })
            errors = errors.join('\r\n')
            notification('error', errors, 'Błąd')
          }
        })
      })
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Rejestracja
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleFormSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
                value={data.firstName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
                value={data.lastName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={data.email}
                onChange={handleInputChange}
              />
            </Grid>
            {data.errorMessage && (
              <span className={classes.formError}>{data.errorMessage}</span>
            )}
          </Grid>
          <FormControl className={classes.submit} component="fieldset">
            <FormLabel component="legend">Rola</FormLabel>

            <FormGroup>
              {checked.map((option, index) => {
                return (
                  <FormControlLabel
                    key={`option-${index}`}
                    control={
                      <Checkbox
                        disabled={option.disabled}
                        checked={option.checked}
                        onChange={(e) => handleSwitch(e.target.checked, index)}
                      />
                    }
                    label={option.name}
                  />
                )
              })}
            </FormGroup>
          </FormControl>
          <Button
            disabled={data.isSubmitting}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {data.isSubmitting ? (
              <img
                className={classes.spinner}
                src={`/images/${
                  process.env.REACT_APP_TYPE === 'cars' ? 'logo' : 'logo-f-sm'
                }.png`}
                alt="loading icon"
              />
            ) : (
              'Dalej'
            )}
          </Button>
        </form>
      </div>
      <Box mt={5}></Box>
      <AlertDialogSlide />
    </Container>
  )
}
