import React from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import PrintIcon from '@material-ui/icons/Print'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import PostAddIcon from '@material-ui/icons/PostAdd'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import AddDialogBtn from './../Modals/Add'
import StatusDialogBtn from './../Modals/Status'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { DeleteDialog } from '../Modals/Delete'
import { useHistory } from 'react-router-dom'
import { PrintDialog } from '../Modals/Print'
import { invoicesPrintType as invoices } from 'dataconfig'
import { getCurrencyNumberFromApi } from '_helpers/currency'
const useStyles = makeStyles({
  sticky: {
    position: 'sticky',
    top: '64px',
  },
})
export default function Sidebar(props) {
  const classes = useStyles()
  const { invoiceId, toBePaid, refresh, hire } = props
  const [open, setOpen] = React.useState(false)
  const [openPrint, setOpenPrint] = React.useState(false)

  const history = useHistory()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = (value) => {
    setOpen(false)
    if (!value) {
      history.push('/invoices/list')
    }
  }

  const handleClickOpenPrint = () => {
    setOpenPrint(true)
  }

  const handleClosePrint = (value) => {
    setOpenPrint(false)
  }
  const handleStay = (value) => {
    setOpenPrint(false)
  }
  const handleNewModal = (value) => {
    setOpenPrint(false)
  }

  return (
    <div className={classes.sticky}>
      <List component="nav" aria-label="invoice actions">
        <ListItem button disabled={toBePaid === 0}>
          <ListItemIcon>
            <AttachMoneyIcon />
          </ListItemIcon>

          <StatusDialogBtn
            label="Rozlicz stan"
            toBePaid={getCurrencyNumberFromApi(toBePaid)}
            invoiceId={Number(invoiceId)}
            onChangePay={refresh}
          />
        </ListItem>
        <ListItem button onClick={handleClickOpenPrint}>
          <ListItemIcon>
            <PrintIcon />
          </ListItemIcon>
          <ListItemText primary="Drukuj fakturę" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to={`/invoices/list/new/similar/${invoiceId}`}
          disabled={hire !== false}
        >
          <ListItemIcon>
            <PostAddIcon />
          </ListItemIcon>
          <ListItemText primary="Wystaw podobną" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <NoteAddIcon />
          </ListItemIcon>

          <AddDialogBtn label="Wystaw do niej" invoiceID={invoiceId} />
        </ListItem>
        <ListItem
          button
          component={Link}
          to={`/invoices/list/edit/${invoiceId}`}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText primary="Edytuj" />
        </ListItem>
        <ListItem button onClick={handleClickOpen}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText primary="Usuń" />
        </ListItem>
      </List>
      <DeleteDialog
        selectedValue={''}
        open={open}
        onClose={handleClose}
        numSelected={1}
        selected={[invoiceId]}
      />
      <PrintDialog
        onClose={handleClosePrint}
        open={openPrint}
        selectedValue={invoices[1]}
        newModal={handleNewModal}
        stay={handleStay}
        numSelected={1}
        selected={[invoiceId]}
        print={openPrint}
      />
    </div>
  )
}
