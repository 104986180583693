import React from 'react'
import { useSelector } from 'react-redux'
import InvoiceDataCols from './InvoiceDataCols'
import InvoiceTable from './InvoiceTable'
import TabsContainer from './Tabs'
import Sidebar from './Sidebar'
import { authHeader } from '_helpers/authHeader'
import { useFetch } from '_helpers/useFetch'
import { Title } from 'components/Title'
import Alert from '@material-ui/lab/Alert'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import api from 'api'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  root: {
    marginTop: theme.spacing(6),
  },
}))

export const InvoiceContainer = ({ match }) => {
  const classes = useStyles()
  const {
    params: { invoiceId },
  } = match
  const [refresh, setRefresh] = React.useState(false)
  const handleRefresh = () => {
    setRefresh((state) => !state)
  }

  const auth = useSelector((state) => state.auth)

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const args = [auth.data.token, invoiceId, refresh]

  const res = useFetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}${api.invoices}/${invoiceId}`,
    options,
    args
  )

  const resData = { ...res.state.data }

  return (
    <>
      <Backdrop className={classes.backdrop} open={res.state.isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {res.state.error && (
        <Alert severity="error">wystąpił błąd podczas pobierania danych</Alert>
      )}
      <Grid container spacing={3}>
        <Grid item xs={9}>
          {res.state.data && <Title>{res.state.data.number}</Title>}
          <div className={classes.root}>
            {resData.customer && (
              <InvoiceDataCols customer={resData.customer} data={resData} />
            )}
          </div>
          <div className={classes.root}>
            {resData.rows && <InvoiceTable data={resData} />}
          </div>
          <div className={classes.root}>
            {(resData.warnings || resData.connectedInvoices) && (
              <TabsContainer data={resData} />
            )}
          </div>
        </Grid>
        <Grid item xs={3}>
          <Sidebar
            invoiceId={invoiceId}
            toBePaid={resData.toBePaid}
            refresh={handleRefresh}
            hire={resData?.hire !== null}
          />
        </Grid>
      </Grid>
    </>
  )
}
