import { tokenExpiration } from './tokenExpiration'

const INTERVAL_PERIOD = 10 * 1000 // 10s
const EXP_LIMIT = 2 * 6 * INTERVAL_PERIOD // 2mins

let interval = null

const start = (dispatch, getState, logout, refresh) => {
  if (!interval) {
    interval = setInterval(() => {
      const token = getState().auth.data?.token
      const isRefreshing = !!getState().auth.isRefreshing
      const exp = token && tokenExpiration(token)
      if (!exp) {
        dispatch(logout())
      } else if (Date.now() > exp - EXP_LIMIT && !isRefreshing) {
        dispatch(refresh())
      }
    }, INTERVAL_PERIOD)
  }
}

const clear = () => {
  if (interval) {
    clearInterval(interval)
    interval = null
  }
}

export const tokenRefresh = {
  start,
  clear,
}
