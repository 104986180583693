import React from 'react'

import StringField from 'components/fields/StringField'

export default function CustomerFieldFactory({
  fieldName,
  type,
  value,
  violations,
  updatableParentEntity,
  setUpdatableParentEntity,
}) {
  switch (type) {
    case 'personalDetails.companyName':
      return (
        <StringField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
          readOnly={true}
        />
      )
    case 'personalDetails.nip':
      return (
        <StringField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
          readOnly={true}
        />
      )
    case 'personalDetails.street':
      return (
        <StringField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
          readOnly={true}
        />
      )
    case 'personalDetails.city':
      return (
        <StringField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
          readOnly={true}
        />
      )
    case 'personalDetails.postCode':
      return (
        <StringField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
          readOnly={true}
        />
      )
    case 'user.email':
      return (
        <StringField
          fieldName={fieldName}
          value={value}
          type={type}
          violations={violations}
          updatableParentEntity={updatableParentEntity}
          setUpdatableParentEntity={setUpdatableParentEntity}
          readOnly={true}
        />
      )

    default:
      return 'Nic'
  }
}
