import React from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import CanAlerts from './CanAlerts'
import CanLimits from './CanLimits'

export const CanRegisterContainer = ({ match }) => {
  const {
    params: { carId, registerId },
  } = match

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} key={`limits`}>
        <Paper>
          <CanLimits registerId={registerId} />
        </Paper>
      </Grid>
      <Grid item xs={12} key={`alerts`}>
        <Paper>
          <CanAlerts carId={carId} />
        </Paper>
      </Grid>
    </Grid>
  )
}

export default CanRegisterContainer
