import React from 'react'
import { useSelector } from 'react-redux'
import { FormFactory } from 'components/factory/FormFactory'
import { getDefinitionProperties } from '_helpers/getDefinitionProperties'
import api from 'api'
import * as schemaFitness from '_schema/fitnessCustomer'
import * as schemaCar from '_schema/carCustomer'

export const CustomerForm = ({ resource }) => {
  console.log(schemaCar)
  const schemaCustomer =
    api.appType === 'fitness' ? schemaFitness.customer : schemaCar.customer
  const definitions = useSelector((state) => state.schema?.definitions)
  const properties = getDefinitionProperties(
    definitions[schemaCustomer.definition],
    schemaCustomer.properties
  )

  return (
    <FormFactory
      title="Dane klienta"
      url={schemaCustomer.set}
      method="POST"
      properties={properties}
      resource={resource}
    />
  )
}
