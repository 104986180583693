import React, { useState } from 'react'
import { Title } from 'components/Title'
import { useSelector } from 'react-redux'
import { authHeader } from '_helpers/authHeader'
import { useFetch } from '_helpers/useFetch'
import FitnessModelCreation from './FitnessModelCreation'

const fitnessBrandEndpoint = '/fitness_brands'
const fitnessBrandIriFunction = id => `${fitnessBrandEndpoint}/${id}`

const FitnessModelCreationContainer = ({ match }) => {
  const {
    params: { brandId },
  } = match
  const [fitnessModel, setFitnessModel] = useState({})

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const auth = useSelector((state) => state.auth)
  const args = [auth.data.token, brandId]

  const fitnessBrandIri = fitnessBrandIriFunction(brandId)
  const fitnessBrandRes = useFetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}${fitnessBrandIri}`,
    options,
    args
  )

  return (
    <>
      <>
        <Title>{fitnessBrandRes?.state?.data?.name}</Title>
        <FitnessModelCreation
          fitnessModel={fitnessModel}
          setFitnessModel={setFitnessModel}
          fitnessBrandIri={fitnessBrandIri}
        />
      </>
    </>
  )
}

export default FitnessModelCreationContainer
