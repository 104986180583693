import { tokenVerify } from './tokenVerify'

export const isLogged = (dispatch, restore, logout) => {
  const token = localStorage.getItem('token')
  const isLogged = tokenVerify(token)

  if (isLogged) {
    dispatch(restore(token))
  } else if (isLogged === false) {
    dispatch(logout())
  }
}
