import { put, call, takeLatest } from 'redux-saga/effects'
import { leaseConstants } from '_constants'
import { leaseService } from '_services'
import { handleAuthError } from '_helpers/handleAuthError'
import { processError } from '_helpers/processError'

function* fetchData(data) {
  yield put({ type: leaseConstants.DATA_REQUEST })

  const { response, error } = yield call(leaseService.data, data.id)

  if (response) {
    yield put({
      type: leaseConstants.DATA_SUCCESS,
      payload: { ...response },
    })

    return
  }

  yield call(
    handleAuthError.saga,
    leaseConstants.DATA_FAILURE,
    processError(error).response
  )

  return
}

function* eraseData() {
  yield put({ type: leaseConstants.DATA_ERASE })

  return
}

function* updateData(values) {
  yield put({
    type: leaseConstants.DATA_UPDATE,
    payload: values.data,
  })
}

export default function* leaseActions() {
  yield takeLatest(leaseConstants.DATA_REMOVE_SAGA, eraseData)
  yield takeLatest(leaseConstants.DATA_UPDATE_SAGA, updateData)
  yield takeLatest(leaseConstants.DATA_FETCH_SAGA, fetchData)
}
