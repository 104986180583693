import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import SaveIcon from '@material-ui/icons/Save'
import { makeStyles } from '@material-ui/core/styles'

import { useFetch } from '_helpers/useFetch'
import { authHeader } from '_helpers/authHeader'
import LimitField from '../../../components/fields/LimitField'
import { fetchDataHandleAuthError } from '../../../_helpers/fetchDataHandleAuthError'
import { requestActions } from '_actions'
import { notification } from '_helpers/notification'
import { requestConstants } from '_constants'

export const limitMappings = [
  {
    recordIndex: 'currentDistanceMeter',
    fieldName: 'Stan Licznika',
    renderLastValue: (value) => `${+value} km`,
    isInteger: true,
  },
  {
    recordIndex: 'engineWorkSeconds',
    fieldName: 'Czas pracy silnika',
    renderLastValue: (value) => `${Math.floor(+value / (60 * 60))} h`,
    isInteger: false,
  },
  {
    limitIndex: 'averageFuelConsumptionLimit',
    recordIndex: 'momentaryAverageFuelConsumption',
    fieldName: 'Średnie spalanie',
    isInteger: false,
  },
  {
    limitIndex: 'momentaryFuelConsumptionLimit',
    recordIndex: 'momentaryFuelConsumption',
    fieldName: 'Bieżące spalanie',
    isInteger: false,
  },
  {
    limitIndex: 'axleLoadLimit',
    recordIndex: 'axleLoad',
    fieldName: 'Nacisk na osie',
    isInteger: false,
  },
  {
    limitIndex: 'mainbreakPressLimit',
    recordIndex: 'mainbreakPress',
    fieldName: 'Hamulec główny',
    isInteger: false,
  },
  {
    limitIndex: 'handbreakPressLimit',
    recordIndex: 'handbreakPress',
    fieldName: 'Hamulec ręczny',
    isInteger: false,
  },
  {
    limitIndex: 'restrictorPlaceLimit',
    recordIndex: 'restrictorPlace',
    fieldName: 'Położenie przepustnicy',
    isInteger: false,
  },
  {
    limitIndex: 'revolutionsPerMinuteLimit',
    recordIndex: 'revolutionsPerMinute',
    fieldName: 'Obroty silnika',
    isInteger: true,
  },
  {
    limitIndex: 'speedLimit',
    recordIndex: 'speed',
    fieldName: 'Prędkość',
    isInteger: true,
  },
  {
    limitIndex: 'torqueLimit',
    recordIndex: 'torque',
    fieldName: 'Moment obrotowy',
    isInteger: false,
  },
]

const header = () =>
  Object.keys([0, 1]).map((e, i) => (
    <Grid item xs={6} key={`header-${i}`}>
      <Grid container spacing={3}>
        <Grid item xs={4} key={`none-${i}`}></Grid>
        <Grid item xs={4} key={`current-${i}`}>
          Bieżące
        </Grid>
        <Grid item xs={4} key={`limits-${i}`}>
          Limity
        </Grid>
      </Grid>
    </Grid>
  ))

const useStyles = makeStyles({
  buttonContainer: {
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'row-reverse',
    '& > *': {
      marginRight: '20px',
      marginLeft: '20px',
    },
  },
})

const CanLimits = ({ registerId }) => {
  const endPoint = `/can_registers/${registerId}`
  const absoluteEndPoint = `${process.env.REACT_APP_API_ENTRYPOINT}${endPoint}`

  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  const args = [auth.data.token, dispatch]
  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const limitsRes = useFetch(absoluteEndPoint, options, args)
  const limitAggregator = limitsRes?.state?.data?.canLimitAggregator
  const lastRecord = limitsRes?.state?.data?.lastRecord
  const [updateableEntity, setUpdateableEntity] = useState({})

  useEffect(() => {
    setUpdateableEntity({
      canLimitAggregator: limitAggregator,
    })
  }, [limitAggregator])

  const [violations, setViolations] = useState([])

  const setLimitValue = (value) => {
    console.log(value)
    setUpdateableEntity({
      canLimitAggregator: value,
    })
  }

  const handleSave = () => {
    fetchDataHandleAuthError(
      endPoint,
      'PUT',
      { body: JSON.stringify(updateableEntity) },
      (response) => {
        dispatch(requestActions.success())
        notification('success', 'Rekord zmieniony', 'Zapisano')
        setViolations([])
      },
      (error) => {
        notification(
          'error',
          error.response.violations.length
            ? 'Niepoprawny formularz'
            : error.response.detail,
          error.response.title
        )
        setViolations(
          error.response.violations.map((violation) => ({
            ...violation,
            propertyPath: violation.propertyPath.split('.')[1],
          }))
        )
      },
      dispatch,
      requestConstants.FAILURE
    )
  }

  const classes = useStyles()

  return (
    <>
      <Grid container spacing={3}>
        {header()}
        {limitAggregator &&
          limitMappings.map((mapping, index) => (
            <Grid item xs={6} key={`limit-${index}`}>
              <LimitField
                fieldName={mapping.fieldName}
                type={mapping.limitIndex}
                limitValue={limitAggregator[mapping.limitIndex]}
                lastValue={lastRecord[mapping.recordIndex]}
                renderLastValue={mapping.renderLastValue}
                violations={violations}
                updatableParentEntity={updateableEntity.canLimitAggregator}
                setUpdatableParentEntity={setLimitValue}
                isInteger={mapping.isInteger}
              />
            </Grid>
          ))}
        {limitAggregator && (
          <Grid item xs={6} key={`save`} className={classes.buttonContainer}>
            <SaveIcon onClick={handleSave} color="primary" />
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default CanLimits
