import { useEffect, useState } from 'react'
import { requestActions } from '_actions'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { requestConstants } from '_constants'
import { useDispatch } from 'react-redux'

export const useRemoveDoc = () => {
  const [state, setState] = useState(false)
  const [ret, setRet] = useState(true)
  const [url, setUrl] = useState('')

  const dispatch = useDispatch()

  const startRemove = () => {
    setState(true)
  }

  const setNewUrl = (url) => {
    setUrl(url)
  }

  useEffect(() => {
    const abort = new AbortController()
    if (state && url) {
      dispatch(requestActions.start(url))
      fetchDataHandleAuthError(
        url,
        'DELETE',
        {},
        () => {
          dispatch(requestActions.success())
          notification('success', 'Rekord usunięty', 'Zapisano')
          setRet(false)
        },
        (error) => {
          notification(
            'error',
            error.response.violations.length
              ? 'Incorrect form'
              : error.response.detail,
            error.response.title
          )
          setRet(false)
        },
        dispatch,
        requestConstants.FAILURE
      )
    }
    return () => abort.abort()
    // eslint-disable-next-line
  }, [state, url])

  return { ret, startRemove, setNewUrl }
}
