import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { lighten, makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Skeleton from '@material-ui/lab/Skeleton'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'

import { authHeader } from '_helpers/authHeader'
import { useFetch } from '_helpers/useFetch'
import { useSelector, useDispatch } from 'react-redux'

import DeleteDialogBtn from './Modals/Delete'

import AddIcon from '@material-ui/icons/Add'
import { red } from '@material-ui/core/colors'
import api from 'api'
import { hireStatus } from 'dataconfig'

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy)
}

const headCells = [
  {
    id: 'kind',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  { id: 'name', numeric: false, disablePadding: false, label: 'Nazwa' },
  {
    id: 'suggestedSegments',
    numeric: false,
    disablePadding: false,
    label: 'Sugerowany segment',
  },
  {
    id: 'suggestedRentalPlaces',
    numeric: false,
    disablePadding: false,
    label: 'Sugerowana wypożyczalnia',
  },
]

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all invoices' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  flex: {
    display: 'flex',
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  gridParent: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '-48px',
    marginBottom: '20px',
  },
}))

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles()
  const { numSelected } = props
  const { searchText } = props
  const { selected } = props
  const { handleRefreshList } = props

  const { handleSearchChange } = props
  return (
    <>
      <div className={classes.gridParent}>
        <TextField
          label="Szukaj szablonu"
          value={searchText}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        <div className={classes.title}>
          {numSelected > 0 ? (
            <Typography color="inherit" variant="subtitle1">
              {numSelected} selected
            </Typography>
          ) : (
            <Typography variant="h6" id="tableTitle">
              Szablony
            </Typography>
          )}
        </div>
        <div className={classes.spacer} />
        <div className={classes.actions}>
          {numSelected > 0 && (
            <div className={classes.flex}>
              <Tooltip title="Delete">
                <DeleteDialogBtn
                  numSelected={numSelected}
                  selected={selected}
                  handleRefreshList={handleRefreshList}
                />
              </Tooltip>
            </div>
          )}
        </div>
      </Toolbar>
    </>
  )
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  selected: PropTypes.array,
  handleRefreshList: PropTypes.func.isRequired,
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },

  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3,256px)',
    gridColumnGap: '30px',
    alignItems: 'baseline',
  },
  delete: {
    backgroundColor: red[600],
    color: '#fff',
  },
}))

export default function EnhancedTable() {
  const endPoint = `${process.env.REACT_APP_API_ENTRYPOINT}${api.documentTemplate}`
  const typingTimeout = 1000
  const auth = useSelector((state) => state.auth)
  const [queryParams, setQueryParams] = useState({
    search: '',
    page: 1,
    refresh: false,
  })
  const [typingState, setTypingState] = useState({
    timeoutId: null,
  })
  const [searchText, setSearchText] = useState('')
  const dispatch = useDispatch()

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }
  const args = [auth.data.token, dispatch]
  const res = useFetch(endPoint, options, args, queryParams)
  let rows = []

  rows = { ...res.state.data }
  const dataLength = rows.totalItems ? rows.totalItems : 0
  rows = rows?.content

  const handleSearchChange = (e) => {
    const search = e.target.value
    setSearchText(search)

    clearTimeout(typingState.timeoutId)
    const timeoutId = setTimeout(() => {
      setTypingState({
        ...typingState,
        timeoutId: null,
      })
      setQueryParams({
        ...queryParams,
        search: search,
        page: 1,
      })
    }, typingTimeout)

    setTypingState({
      ...typingState,
      timeoutId: timeoutId,
    })
  }

  const classes = useStyles()
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('number')
  const [selected, setSelected] = React.useState([])
  const [page, setPage] = React.useState(0)

  const [rowsPerPage, setRowsPerPage] = React.useState(12)

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc'
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows?.map((n) => n.number)

      setSelected(newSelecteds)
      return
    }

    setSelected([])
  }

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setQueryParams({
      ...queryParams,
      page: newPage + 1,
    })
  }

  const handleChangeRowsPerPage = (event) => {
    const perPage = +event.target.value
    setRowsPerPage(perPage)
    setPage(0)
    setQueryParams({
      ...queryParams,
      perPage: perPage,
      page: 1,
    })
  }

  const handleRefreshList = () => {
    setQueryParams((old) => ({
      ...old,
      refresh: !old.refresh,
    }))
    setSelected([])
  }

  const isSelected = (id) => selected.indexOf(id) !== -1

  return (
    <div className={classes.root}>
      <div className={classes.grid}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          component={Link}
          to={'/documents/templates/new'}
        >
          Utwórz nowy szablon
        </Button>
      </div>

      <EnhancedTableToolbar
        numSelected={selected.length}
        searchText={searchText}
        selected={selected}
        handleSearchChange={handleSearchChange}
        handleRefreshList={handleRefreshList}
      />
      <div className={classes.tableWrapper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={'medium'}
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows?.length ? rows?.length : 0}
          />
          <TableBody>
            {res.state.isLoading &&
              [...Array(rowsPerPage)].map((i, key) => (
                <TableRow key={key}>
                  <TableCell colSpan={10}>
                    <Skeleton animation="wave" />
                  </TableCell>
                </TableRow>
              ))}
            {!res.state.isLoading &&
              rows !== undefined &&
              stableSort(rows, getSorting(order, orderBy)).map((row, index) => {
                const isItemSelected = isSelected(row.id)
                const labelId = `enhanced-table-checkbox-${index}`
                const status = hireStatus.find((el) => el.id === row.kind)
                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </TableCell>
                    <TableCell align="left">{status !== undefined ? status.name : row.kind}</TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      align="left"
                    >
                      <Button
                        component={Link}
                        color="primary"
                        to={`templates/${row.id}`}
                      >
                        {row.name}
                      </Button>
                    </TableCell>

                    <TableCell>
                      {row.suggestedSegments.map((e) => {
                        return <div key={e.id}>{e.name}</div>
                      })}
                    </TableCell>
                    <TableCell>
                      {row.suggestedRentalPlaces.map((e) => {
                        return <div key={e.id}>{e.name}</div>
                      })}
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        rowsPerPageOptions={[4, 8, 12]}
        labelRowsPerPage="Pokaż na stronie:"
        component="div"
        count={dataLength}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  )
}
