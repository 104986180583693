import React, {useEffect, useState} from 'react'
import { Schemable } from 'components/Schemable'
import { FormFactory } from 'components/factory/FormFactory'
import { getDefinitionProperties } from '_helpers/getDefinitionProperties'
import { useSelector, useDispatch } from 'react-redux'
import * as schema from '_schema/company.json'
import { useFetch } from '_helpers/useFetch'
import api from 'api'
import { authHeader } from '_helpers/authHeader'
import { Grid } from '@material-ui/core'
import { notification } from '_helpers/notification'

const endPoint = `${process.env.REACT_APP_API_ENTRYPOINT}${api.companyConfig}`

export default function Company() {
  const definitions = useSelector((state) => state.schema?.definitions)
  const [properties, setProperties] = useState()
  const [propertiesAddress, setPropertiesAddress] = useState()
  const [propertiesAddressMailing, setPropertiesAddressMailind] = useState()
  const [dataSend, setDataSend] = useState(false)

  const user = useSelector((state) => state.user.profile)

  useEffect(() => {
    if (definitions !== undefined) {
      setProperties(
        getDefinitionProperties(
          definitions[schema.company.definition],
          schema.company.properties
        )
      )
      setPropertiesAddress(
        getDefinitionProperties(
          definitions[schema.mainAddress.definition],
          schema.mainAddress.properties
        )
      )
      setPropertiesAddressMailind(
        getDefinitionProperties(
          definitions[schema.mailingAddress.definition],
          schema.mailingAddress.properties
        )
      )
    }
  }, [definitions])

  const dispatch = useDispatch()
  const [queryParams] = useState({})
  const auth = useSelector((state) => state.auth)

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }
  const args = [auth.data.token, dispatch]

  const res = useFetch(endPoint, options, args, queryParams)

  const handleSubmit = (data) => {
    setDataSend(data)
  }
  const handleSucess = (data) => {
    res.setState((prevState) => {
      return {
        ...prevState,
        data: {
          ...prevState.data,
          ...data,
        },
      }
    })
  }

  useEffect(() => {
    const abotr = new AbortController()
    const fetchData = () => {
      const opt = {
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
          ...authHeader(),
        },
        method: 'PUT',
        body: JSON.stringify({
          ...dataSend,
        }),
      }
      const url = `${process.env.REACT_APP_API_ENTRYPOINT}${
        schema.company.set.includes(':id')
          ? schema.company.set.replace(':id', res.state.data.id)
          : endPoint
      }`
      fetch(url, opt).then((res) => {
        if (res.status !== 200) {
          notification(
            'error',
            'Błąd wysyłki formularza. Spróbuj jeszcze raz.',
            'Błąd zapisu'
          )
        } else {
          notification('success', 'Ustawienia zostały zapisane', 'Zapisano')
        }
        setDataSend(false)
      })
    }

    if (dataSend) {
      fetchData()
      setDataSend(false)
    }

    return () => {
      abotr.abort()
    }
  }, [dataSend, res.state])

  return (
    <Schemable>
      {res?.state?.data && definitions && (
        <Grid spacing={2} container>
          <Grid item md={6}>
            <FormFactory
              title="Dane firmowe"
              url={schema.company.set}
              method="PUT"
              properties={properties}
              resource={res.state.data}
              handleSuccess={handleSucess}
              disabledInput={!user?.roles?.includes('ROLE_SUPER_ADMIN')}
            />
          </Grid>
          <Grid item md={6}>
            <FormFactory
              title="Adres firmowy"
              url={schema.mainAddress.set}
              method="PUT"
              properties={propertiesAddress}
              resource={res.state.data.mainAddress}
              submitFunction={(data) => handleSubmit({ mainAddress: data })}
              disabledInput={!user?.roles?.includes('ROLE_SUPER_ADMIN')}
            />
            <FormFactory
              title="Adres do korespondencji"
              url={schema.mailingAddress.set}
              method="PUT"
              properties={propertiesAddressMailing}
              resource={res.state.data.mailingAddress}
              // handleSuccess={handleSuccess}
              submitFunction={(data) => handleSubmit({ mailingAddress: data })}
              disabledInput={!user?.roles?.includes('ROLE_SUPER_ADMIN')}
            />
          </Grid>
        </Grid>
      )}
    </Schemable>
  )
}
