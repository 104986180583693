import React from 'react'
import NumberField from './NumberField'
import DoubleNumberField from './DoubleNumberField'
import Grid from '@material-ui/core/Grid'

export default function LimitField({
  fieldName,
  type,
  limitValue = null,
  lastValue = null,
  showLastValue = true,
  violations = [],
  updatableParentEntity,
  setUpdatableParentEntity,
  renderLastValue = (value) =>
    `${+value} ${limitValue?.unit ? limitValue.unit : ''}`,
  isInteger = true,
}) {
  const [currentValue, setCurrentValue] = React.useState(limitValue || {})

  const fieldViolations = violations.filter(
    (violation) => violation.propertyPath === type
  )

  const setLimitValue = (value) => {
    setCurrentValue(value)
    setUpdatableParentEntity({
      ...updatableParentEntity,
      [type]: value,
    })
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        {fieldName && (
          <div>
            <b>{fieldName}:</b>
          </div>
        )}
      </Grid>
      {showLastValue && (
        <Grid item xs={4}>
          <NumberField
            fieldName={''}
            value={lastValue}
            renderValue={(value) =>
              value !== '' ? renderLastValue(value) : 'Brak danych'
            }
            readOnly
          />
        </Grid>
      )}
      <Grid item xs={4}>
        {limitValue && (
          <DoubleNumberField
            fieldName={''}
            firstValue={limitValue.minimum}
            secondValue={limitValue.maximum}
            unit={limitValue.unit}
            firstType={'minimum'}
            secondType={'maximum'}
            violations={fieldViolations}
            renderValue={(firstValue, secondValue) =>
              `${+firstValue} - ${+secondValue} ${limitValue.unit}`
            }
            updatableParentEntity={currentValue}
            setUpdatableParentEntity={setLimitValue}
            inputProps={isInteger ? { step: '1' } : { step: '0.01' }}
          />
        )}
      </Grid>
    </Grid>
  )
}
